import { roundToQuarter } from '@utils'
import { useLocalStore } from 'mobxReactLite'

const roundDateToQuarterHours = (date) => {
  date = new Date(date)
  date.setMinutes(Math.round(date.getMinutes() / 15) * 15)
  date.setSeconds(0)

  return date.toISOString()
}

const beginningOfDay = (date) => {
  if (!date) {
    return null
  }

  date = new Date(date)
  date.setHours(0, 0, 0)
  return date.toISOString()
}

const useTimeAndDateStore = ({ startAt, endAt, detailValue, computeValue, noHours }) => {
  const date = beginningOfDay(startAt)
  startAt = startAt ? roundDateToQuarterHours(startAt) : null
  endAt = endAt ? roundDateToQuarterHours(endAt) : null

  return useLocalStore(() => ({
    detailValue: !!detailValue ? parseFloat(detailValue) : 0.0,
    date: date,
    startAt: startAt,
    endAt: endAt,
    noHours: noHours,
    timestamp: new Date().toISOString(),

    get valueError() {
      if (computeValue && this.computedValue <= 0 && !this.noHours) {
        return 'Hours must be greater than 0'
      } else if (!computeValue && this.detailValue < 0) {
        return 'Hours must be greater than or equal to 0'
      } else {
        return null
      }
    },

    get computedValue() {
      if (!this.startAt || !this.endAt || this.noHours) {
        return '0'
      }

      const hours = moment(this.endAt).diff(moment(this.startAt), 'hours', true)
      return roundToQuarter(hours)
    },

    setStartAt(value) {
      const newVal = this.addTimeToDay(this.date, value)

      if (this.startAt === newVal) {
        this.forceUpdate()
      }

      this.startAt = newVal
    },

    setEndAt(value) {
      const newVal = this.addTimeToDay(this.date, value)

      if (this.endAt === newVal) {
        this.forceUpdate()
      }

      this.endAt = newVal
    },

    setNoHours(noHoursChecked) {
      if (noHoursChecked) {
        this.date = null
        this.startAt = null
        this.endAt = null
      }

      this.noHours = noHoursChecked
    },

    setDate(value) {
      if (value) {
        let date = new Date(value)
        date.setHours(0, 0, 0)
        date = date.toISOString()

        this.date = date
        this.startAt = this.addTimeToDay(date, this.startAt)
        this.endAt = this.addTimeToDay(date, this.endAt)
      } else {
        this.date = null
        this.startAt = null
        this.endAt = null
      }
    },

    setValue(value) {
      this.detailValue = value
    },

    addTimeToDay(date, dateTime) {
      if (!date && !dateTime) {
        return null
      } else if (!date) {
        date = dateTime
      } else if (!dateTime) {
        dateTime = date
      }

      date = new Date(date)
      dateTime = new Date(dateTime)
      date.setHours(dateTime.getHours())
      date.setMinutes(Math.round(dateTime.getMinutes() / 15) * 15)
      date.setSeconds(0)

      return date.toISOString()
    },

    // Due to the rounding logic, some "change" events revert back to
    // their previous values. This causes child components to become stale,
    // since their props technically don't change
    forceUpdate() {
      this.timestamp = new Date().toISOString()
    },
  }))
}

export default useTimeAndDateStore
