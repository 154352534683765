// Pagination links
// Designed to consume JSON data from ApplicationController#paging_metadata_for
//
// Usage:
//   const exampleData = {
//     page: 1,
//     limit: 15,
//     total: 100,
//     next: 2,
//     previous: null
//   }
//   <Paginate {...exampleData} onClick={(pageNum) => someAction(pageNum))} />
//

import { Button, Icon } from 'react-mdl'

const propTypes = {
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  next: PropTypes.number,
  previous: PropTypes.number,

  // Passing an onClick action will render a <button />
  onClick: PropTypes.func,
  // Passing a href will render an <a href="..." />
  href: PropTypes.string,
}

const Paginate = ({ page, limit, total, next, previous, onClick, href }) => {
  const recordRange = page * limit
  const endRecord = recordRange < total ? recordRange : total
  const startRecord = endRecord <= 0 ? 0 : (page - 1) * limit + 1

  return (
    <div
      role="navigation"
      aria-label="Pagination Navigation"
      className="d-flex flex__justify--center flex__items--center u-padding--10">
      <Button
        href={href}
        className="mdl-button--icon u-margin--none"
        onClick={() => (onClick ? onClick(previous) : null)}
        disabled={!previous}
        aria-label={previous ? `Go to Page ${previous}` : null}>
        <Icon name="keyboard_arrow_left" aria-hidden="true" />
      </Button>

      <span className="u-padding--left-20 u-padding--right-20">
        {startRecord} - {endRecord} of {total}
      </span>

      <Button
        href={href}
        className="mdl-button--icon u-margin--none"
        onClick={() => (onClick ? onClick(next) : null)}
        disabled={!next}
        aria-label={next ? `Go to Page ${next}` : null}>
        <Icon name="keyboard_arrow_right" aria-hidden="true" />
      </Button>
    </div>
  )
}

Paginate.propTypes = propTypes
export default Paginate
