import { Grid, Cell } from 'react-mdl'
import { useFormContext } from 'react-hook-form'
import RadioCollection from '@components/shared/RadioCollection'
import { FC } from '@components/shared'
const { useState } = React

const OpenPeriodFields = ({ parkOpenPeriods }) => {
  const { fields } = useFormContext()
  const [openPeriod, setOpenPeriod] = useState(fields.open_period.value)
  const dateFormat = 'MM/DD/YYYY'

  const openPeriodOptions = parkOpenPeriods.map((option) => {
    if (option.value === 'date_range') {
      return { value: option.value, name: <span className="visuallyhidden">{option.name}</span> }
    } else {
      return option
    }
  })

  console.log('OpenPeriodFields')

  return (
    <div className="d-flex">
      <RadioCollection
        className="u-margin--top-5"
        options={openPeriodOptions}
        name={fields.open_period.input_name}
        value={openPeriod}
        label={fields.open_period.label}
        onChange={(e) => setOpenPeriod(e.target.value)}
      />
      <Grid className="mdl-grid--no-spacing u-margin--none">
        <Cell col={5} className="u-padding--top-5">
          <FC.Datepicker
            field={fields.open_start_at}
            dateFormat={dateFormat}
            disabled={openPeriod !== 'date_range'}
          />
        </Cell>
        <Cell col={1} className="d-flex flex__items--center flex__justify--center">
          to
        </Cell>
        <Cell col={5} className="u-padding--top-5">
          <FC.Datepicker
            field={fields.open_end_at}
            dateFormat={dateFormat}
            disabled={openPeriod !== 'date_range'}
          />
        </Cell>
      </Grid>
    </div>
  )
}

export default OpenPeriodFields
