import { Grid } from 'react-mdl'
import { findOptionIndexes, mergeCurrentAttributes } from './conditionStatement'

// A statement is one of the lines of text in the Condition Details UI
// This returns a list of conditions in a sentence format Example:
//   City Is Las Vegas
//   AND
//   Hair Color Is Brown
//   OR
//   Resident Declaration Is Nevada Resident

const Operator = ({ text }) => (
  <div className="text--small text--lightgray text--bold text--uppercase">{text}</div>
)

// Returns a single statement but if it is not a valid statement it will return null
const formattedDetail = ({ key, conditionStatement, conditionAttributes }) => {
  const optionIndexes = findOptionIndexes(conditionStatement, conditionAttributes)
  // Checking here in case a segmented list still exists where those choices are no longer available.
  // This will prevent the page from Erroring out and rendering the data that is available
  if (!optionIndexes) return null

  const results = mergeCurrentAttributes(conditionAttributes, optionIndexes)
  const { operator, path, units, value: has_value } = results
  const { value, min_value, max_value, unit } = conditionStatement

  // debugger
  const valueString =
    operator &&
    !has_value &&
    (min_value && max_value
      ? `${min_value} and ${max_value} ${unit || (units && units[0]) || ''}`
      : value)

  const showPath = valueString ? [...path, valueString] : path

  // This will now show all of the conditions
  const showString = showPath.join(' ')

  return (
    <div key={key} className="text--med">
      {showString}
    </div>
  )
}
const Section = ({ style, children }) => (
  <div className="bg--lightgray p-10" style={{ flex: 1, ...style }}>
    {children}
  </div>
)

const SectionBody = ({ type, conditionAttributes, conditions }) => {
  const combine = (items, formatKey, operator) => {
    if (!items.length) {
      return items
    }

    return (items || []).reduce(
      (prev, curr, idx) =>
        prev === null ? [curr] : [prev, <Operator key={formatKey(idx)} text={operator} />, curr],
      null
    )
  }

  // Returns a list of each statement. The filter checks to remove the statement if it is null
  const formatDetails = (operator) =>
    conditions[operator]
      .map((detail, idx) =>
        formattedDetail({
          key: `${type}-${operator}-attribute-${idx}`,
          conditionStatement: detail,
          conditionAttributes: conditionAttributes,
        })
      )
      .filter((e) => e)

  const all = combine(formatDetails('and'), (idx) => `${type}-and-${idx}`, 'and')
  const any = combine(formatDetails('or'), (idx) => `${type}-or-${idx}`, 'or')

  const everything = [all, any].filter((i) => i.length)

  if (everything.length) return <>{combine(everything, () => `${type}-join`, 'or')}</>
  else return <div className={'data-table__no-results-slim'}>No conditions have been added</div>
}

const SegmentedListDetails = ({ segmentedList, conditionAttributes }) => (
  <Grid className="p-0">
    <Section style={{ margin: '0 4px 0 0' }}>
      <h2 className="text--med-lg m-0">Conditions Review</h2>
      <div className="bg--lightgray p-20">
        <SectionBody
          type="include"
          conditionAttributes={conditionAttributes}
          conditions={segmentedList.query_parameters}
        />
      </div>
    </Section>
  </Grid>
)

export default SegmentedListDetails
