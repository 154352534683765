import { Cell, Grid, Textfield } from 'react-mdl'
import { formatDateFromDb, formatDateToDb } from '@utils'
import { Rangepicker } from '@components/shared'
const { useState, useCallback } = React
const { moment } = window

const dateFormat = 'MM/DD/YYYY'

const BookingDates = ({ booking, unavailableDates, pricingSummary }) => {
  const [dates, setDates] = useState([booking.start_at, booking.end_at])
  const [dateErrors, setDateErrors] = useState([])

  const onCalendarChange = (value) => {
    const newValue = value.length === 2 ? value : [value[0], '']
    setDates(newValue)
    setDateErrors([])
  }

  const onInputChange = useCallback(
    (field) => (e) => {
      const value = e.target.value
      const valueIndex = field === 'start_at' ? 0 : 1
      let newValues = [...dates]
      let newErrors = [null, null]

      newValues[valueIndex] = value

      const fmtValue = formatDateFromDb(value)
      if (unavailableDates.includes(fmtValue)) {
        newErrors[valueIndex] = 'Unavailable Date'
      }

      setDates(newValues)
      setDateErrors(newErrors)
    },
    [dates, unavailableDates, setDates, setDateErrors]
  )

  const legendData = [
    { name: 'Selected', color: 'lightblue' },
    { name: 'Available', color: 'white' },
    { name: 'Unavailable', color: 'lightgray' },
  ]

  return (
    <fieldset>
      <h2 className="mdl-card__title-text my-20">Check-In &amp; Check-Out Dates</h2>
      <Grid className="mdl-grid--nested pb-20">
        <Cell col={7} tablet={12} style={{ minWidth: '650px' }}>
          <div className="flatpickr-calendar__inline-container">
            <Rangepicker
              value={dates}
              label="Check In and Check Out *"
              aria-label="Check In and Check Out"
              placeholder="Select Date Range"
              dateFormat={dateFormat}
              onChange={onCalendarChange}
              options={{
                showMonths: 2,
                inline: true,
                disable: unavailableDates ?? [],
                altInput: true,
                altInputClass: 'hide',
                allowInput: false,
                minDate: moment().format(dateFormat),
              }}
            />

            <div className="d-flex flex__justify--start p-20">
              {legendData.map(({ name, color }) => (
                <div key={name} className="d-flex flex__items--center">
                  <span
                    className={`bg--${color} u-border--lightgray`}
                    style={{ width: '20px', height: '20px' }}
                    aria-hidden></span>
                  <span className="mx-10">{name}</span>
                </div>
              ))}
            </div>
          </div>
        </Cell>
        <Cell col={5} tablet={12}>
          <Grid className="mdl-grid--nested">
            <Cell col={5} tablet={3} className="u-padding--top-5">
              <Textfield
                floatingLabel
                className="always-floating-label"
                type="date"
                label="Check-In"
                value={formatDateToDb(dates[0], 'YYYY-MM-DD') ?? ''}
                onChange={onInputChange('start_at')}
                name="camp_booking[start_at]"
                min={formatDateToDb(moment().format(), 'YYYY-MM-DD')}
                error={dateErrors[0]}
              />
            </Cell>
            <Cell col={1} className="d-flex flex__items--center flex__justify--center">
              to
            </Cell>
            <Cell col={5} tablet={3} className="u-padding--top-5">
              <Textfield
                floatingLabel
                className="always-floating-label"
                type="date"
                label="Check-Out"
                value={formatDateToDb(dates[1], 'YYYY-MM-DD') ?? ''}
                onChange={onInputChange('end_at')}
                name="camp_booking[end_at]"
                min={moment().format()}
                error={dateErrors[1]}
              />
            </Cell>

            <Cell col={12} className="bg--well p-20 mt-10">
              <p className="text--lightgray">Pricing Record - Transaction Type</p>
              <ul className="px-20">
                {(pricingSummary ?? []).map((pricing) => (
                  <li key={pricing.id}>
                    {pricing.transaction_type}:{' '}
                    <strong>${parseFloat(pricing.amount).toFixed(2)}</strong>
                  </li>
                ))}
              </ul>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </fieldset>
  )
}

export default BookingDates
