import React from 'react'

import ExecutionerDrawApplicationProductItem from './ExecutionerDrawApplicationProductItem'
const { SortableHOC, ArrayUtils } = window

export default class ExecutionerDrawApplicationProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assignedOptions: props.assignedOptions,
    }
    this.onAddProduct = this.onAddProduct.bind(this)
    this.onRemoveProduct = this.onRemoveProduct.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.clearSelections = this.clearSelections.bind(this)
  }

  onAddProduct(val) {
    if (!val) return

    const options = ArrayUtils.arrayAdd(
      { draw_application_product_id: val.id },
      this.state.assignedOptions,
      'priority'
    )

    this.setState({ assignedOptions: options })
  }

  onRemoveProduct(idx) {
    this.setState({
      assignedOptions: ArrayUtils.arrayDelete(idx, this.state.assignedOptions),
    })
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState({
      assignedOptions: ArrayUtils.arrayReorder(this.state.assignedOptions, oldIndex, newIndex),
    })
  }

  clearSelections() {
    this.state.assignedOptions.forEach((option, idx) => {
      this.onRemoveProduct(idx, option)
    })
  }

  get availableOptions() {
    const assigned = this.state.assignedOptions.filter((o) => !o._destroy)
    return this.props.allOptions.filter(
      (o) => !assigned.find((ao) => ao.draw_application_product_id === o.id)
    )
  }

  render() {
    const SortableItem = SortableHOC.SortableElement(({ idx, item, sortIndex }) => {
      const productFromItem =
        this.props.allOptions.find((o) => o.id === item.draw_application_product_id) || {}
      return (
        <ExecutionerDrawApplicationProductItem
          index={idx}
          item={item}
          product={productFromItem}
          onRemoveItem={this.onRemoveProduct}
        />
      )
    })

    const SortableList = SortableHOC.SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((item, idx) => (
            <SortableItem
              key={`item-${idx}`}
              index={idx}
              idx={idx}
              item={item}
              sortIndex={item.priority}
            />
          ))}
        </div>
      )
    })

    return (
      <div>
        <MdlSelect
          options={this.availableOptions}
          onOptionSelected={this.onAddProduct}
          nameField="display_name"
          includeBlankOption="Select Draw Application Products"
          label="Draw Application Products"
        />

        <SortableList
          items={this.state.assignedOptions}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
          helperClass="sortableHelper"
          lockAxis="y"
        />
      </div>
    )
  }
}
