import { formatDollars } from '@utils'
import { DataTable, Currency } from '@components/shared'

const statusClass = {
  pending: 'text--lightgray',
  active: 'text--green',
  complete: 'text--lightblue',
  canceled: 'text--brightred',
}

const BookingHistory = ({ bookingHistory }) => {
  const columns = [
    {
      name: 'status',
      display: 'Status',
      cellFormatter: (status) => <span className={statusClass[status]}>{status}</span>,
      nosort: true,
    },
    {
      name: 'nights_dates',
      display: 'Booked Nights',
      nosort: true,
    },
    {
      name: 'nights_quantity',
      display: 'Nights',
      nosort: true,
      numeric: true,
    },
    {
      name: 'created_at_formatted',
      display: 'Created At',
      nosort: true,
    },
    {
      name: 'site_name',
      display: 'Site',
      nosort: true,
    },
    {
      name: 'subtotal',
      display: 'Subtotal',
      nosort: true,
      numeric: true,
      cellFormatter: (amount) => formatDollars(amount),
    },
    {
      name: 'amount_paid',
      display: 'Amount Paid',
      nosort: true,
      numeric: true,
      cellFormatter: (amount) => <Currency.Colors amount={amount} />,
    },
  ]

  const mappedRows = bookingHistory.map((row) => ({
    ...row,
    className: row.status === 'canceled' ? 'text--lightergray' : '',
  }))

  return (
    <div className="p-20 pb-10 bg--lightgray">
      <h4 className="text--med mt-5">Booking History</h4>
      <DataTable
        topic="Booking History"
        rows={mappedRows}
        columns={columns}
        rowKeyColumn="id"
        noResultsText="No Booking History"
        initialSortBy="created_at"
        initialIsSortAcending={false}
      />
    </div>
  )
}

export default BookingHistory
