import { Icon } from 'react-mdl'
import classNames from 'classnames'

const Well = ({ icon, iconClass, content, className }) => {
  return (
    <div className={classNames('well icon-well', className)}>
      {icon ? <Icon name={icon} className={iconClass} /> : null}
      <p dangerouslySetInnerHTML={{ __html: content }}></p>
    </div>
  )
}

export default Well
