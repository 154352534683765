// Hook for building a search query and pushing updates
// to browser url from a search form
//
// query can be an object or an array
// ex array:  ['park', 'rustic', 'angostura']
// ex object: { name: 'angostura', site_type: 'rustic' }
//
// Example usage w/ DataTable and Paginate:
//
//   const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
//     query: ['some filter'],
//     order: 'name'
//     asc: 1,
//     page: 1
//   })
//   const { data = { results: [] }, loading } = useFetch(searchUrl, [searchUrl])
//
//   return (
//     <>
//       <SearchForm
//         searchFilters={searchState.query}
//         onSearch={(filters) => searchDispatch({ type: 'query', value: filters })} />
//
//       <DataTable
//         rows={data.results}
//         loading={loading}
//         onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })} />
//
//       {data.meta ? (
//         <Paginate
//           {...data.meta}
//           onClick={(pageNum) => searchDispatch({ type: 'page', value: pageNum })} />
//       ) : (
//         undefined
//       )}
//     </>
//   )
//

import { useHistoryState } from './useHistoryState'
const { useReducer } = React

export const useSearchQuery = (endpoint, initParams) => {
  const { replaceState, paramsToString } = useHistoryState()

  const [searchState, searchDispatch] = useReducer((params, action) => {
    const newParams = { ...params }

    switch (action.type) {
      case 'order':
        newParams.order = action.name
        newParams.asc = action.isAcending ? 1 : 0
        break
      case 'query':
        // if the query changes, reset to page 1
        if (newParams.page !== undefined) {
          newParams.page = 1
        }

        if (Array.isArray(action.value)) {
          newParams.query = (action.value || []).sort()
        } else {
          newParams.query = action.value || {}
        }

        break
      // little hack, this lets you set any parameter instead of limiting it to query
      case 'map':
        Object.assign(newParams, action.value)
        break

      default:
        newParams[action.type] = action.value
        break
    }

    replaceState(newParams)

    return newParams
  }, initParams)

  const updatedSearchUrl = (param) => `${endpoint}${paramsToString({ ...searchState, ...param })}`
  const searchQuery = paramsToString(searchState)
  const searchUrl = `${endpoint}.json${searchQuery}`
  return { searchState, searchDispatch, searchUrl, searchQuery, updatedSearchUrl }
}
