import { Card, Tooltip } from 'react-mdl'
import queryString from 'query-string'
import useFetch from 'use-http'
import { AttributeChip, DataTable, SearchForm } from '@components/shared'
import { onClickLink } from '@utils'
import { useSearchQuery } from '@hooks'

const { useMemo } = React

const StatusChip = ({ status }) => {
  const getModifer = (value) => {
    switch (value.toUpperCase()) {
      case 'OPEN':
        return 'mdl-active-chip'
      case 'CLOSED':
        return 'mdl-danger-chip'
      default:
        return ''
    }
  }

  return <AttributeChip className={getModifer(status)}>{status.toUpperCase()}</AttributeChip>
}

const WarningHelperText = (props) => (
  // Need to pass down props coming from react-mdl
  <span {...props}>
    <strong>This park is inactive.</strong> It must have sites, a Vendor Location ID, and Users
    assigned to the park to be active. It cannot be searched <strong>on storefront</strong> until
    active.
  </span>
)

const WarningIcon = ({ active }) => {
  return (
    !active && (
      <Tooltip label={<WarningHelperText />}>
        <i className="material-icons text--darkyellow u-margin--none">warning</i>
      </Tooltip>
    )
  )
}

const getLocationAddress = (location) => {
  const address = location.addr2.length ? `${location.addr1} ${location.addr2}` : location.addr1
  return `${address}, ${location.city}, ${location.state_code} ${location.postal_code}`
}

const columns = [
  {
    name: 'status',
    display: 'Status',
    cellFormatter: (status) => <StatusChip status={status} />,
  },
  {
    name: 'active',
    display: 'Active',
    hideHeader: true,
    cellFormatter: (active) => <WarningIcon active={active} />,
    nosort: true,
  },
  {
    name: 'parkNumber',
    display: 'Park ID',
  },
  {
    name: 'name',
    display: 'Name',
    cellFormatter: (name, row) => <a href={row.url}>{name}</a>,
  },
  {
    name: 'classification',
    display: 'Classification',
  },
  {
    name: 'location',
    display: 'Location',
    nosort: true,
  },
]

const propTypes = {
  // String of endpoint to request park data
  endpoint: PropTypes.string.isRequired,

  // Array of park classifications
  park_classifications: PropTypes.array.isRequired,
}

const SearchParks = ({ endpoint, park_classifications: parkClassifications }) => {
  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
  })
  const { data = { results: [] }, loading } = useFetch(searchUrl, [searchUrl])

  const classificationNameById = parkClassifications.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current.id]: current.name,
    }),
    {}
  )

  const rows = useMemo(
    () =>
      data.results.map((park) => ({
        id: park.id,
        status: park.status,
        active: park.active,
        parkNumber: park.park_number,
        name: park.name,
        classification: classificationNameById[park.park_classification_id],
        location:
          park.location && park.location.location ? getLocationAddress(park.location.location) : '',
        url: `${endpoint}/${park.id}`,
      })),
    [data.results, endpoint, classificationNameById]
  )

  const topic = searchState.query.length > 0 ? 'Park Search Results' : 'All Parks'

  return (
    <Card shadow={0} style={{ width: '100%' }}>
      <SearchForm
        searchFilters={searchState.query}
        onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
        placeholder="Search Parks"
        helperText="Search Park Name, Park ID, Classification"
      />

      <DataTable
        className="data-table--chevron"
        rows={rows}
        columns={columns}
        onRowClick={(e, row) => onClickLink(e, row.url)}
        rowKeyColumn="parkNumber"
        initialSortBy="name"
        topic={topic}
        loading={loading}
      />
    </Card>
  )
}

SearchParks.propTypes = propTypes
export default SearchParks
