import qs from 'qs'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { Grid, Card, Cell, Button, Checkbox } from 'react-mdl'
import { DataTable, Paginate } from '@components/shared'
import AvailabilitySearchForm from './AvailabilitySearchForm'
import { onKeyActivate } from '../../../../utils'
import SelectedSites from './SelectedSites'
import ChangeBookingSite from './ChangeBookingSite'
import { formatCurrency } from '@utils'
const { Expandy } = window
const { Fragment, useState, useEffect, useMemo, useCallback } = React

const initialSortBy = 'name'
const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

const ParentZones = ({ loop, section }) => (
  <div>
    {loop || '-'}
    <br />
    {section ? <span className="text--med-small text--lightgray">{section}</span> : '-'}
  </div>
)

const filterListCount = 7

const columns = [
  {
    name: 'name',
    display: 'Name',
  },
  {
    name: 'parent_zone_names',
    display: 'Loops & Sections',
    nosort: true,
    cellFormatter: ({ loop, section }) => <ParentZones loop={loop} section={section} />,
  },
  {
    name: 'site_type_name',
    display: 'Site Type',
  },
  {
    name: 'min_stay',
    display: 'Min. Stay',
    nosort: true,
  },
  {
    name: 'max_occupancy',
    display: 'Max. Occ',
    nosort: true,
  },
  {
    name: 'max_vehicles',
    display: 'Max. Vehicle',
    nosort: true,
  },
  {
    name: 'subtotal',
    display: 'Subtotal',
    cellFormatter: (value) => `$${formatCurrency(value)}`,
  },
]

const propTypes = {
  endpoint: PropTypes.string.isRequired,
}

const SearchSites = ({
  endpoint,
  siteTypes,
  loops,
  sections,
  reservationsPath,
  editedBooking: initEditedBooking = null,
}) => {
  const [editedBooking, setEditedBooking] = useState(initEditedBooking)

  // Maintain state of current search filters and sort options
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || {},
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
    booking_id: params.booking_id,
  })

  // Fetch sites on load or when the searchUrl changes
  const { loading, get } = useFetch(searchUrl)
  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    get().then((res) => setData(res))
  }, [searchUrl, get])

  const [selectedSites, setSelectedSites] = useState([])

  const addSite = useCallback(
    (row) => () => {
      const params = searchState.query

      if (editedBooking) {
        setEditedBooking((current) => ({
          ...current,
          zone: row,
          zone_id: row.id,
          start_at: params.start_date,
          end_at: params.end_date,
          occupant_count: params.occupants,
          vehicle_count: params.vehicles,
        }))
      } else {
        const newSite = { ...row, ...params }
        setSelectedSites((current) => [...current, newSite])
      }
    },
    [setSelectedSites, searchState, editedBooking]
  )

  const removeSite = useCallback(
    (row) => () => {
      setSelectedSites((current) => current.filter((s) => s.id !== row.id))
    },
    [setSelectedSites]
  )

  const columnsWithAddAction = useMemo(
    () => [
      ...columns,
      {
        name: 'add_booking',
        hideHeader: true,
        nosort: true,
        cellFormatter: (_, row) => {
          const isSelected = selectedSites.map((s) => s.id).includes(row.id)
          const addActionText = editedBooking ? 'Select Site' : 'Add Booking'
          return (
            <Button
              raised
              className="mdl-button--gray u-pull--right"
              onClick={addSite(row)}
              disabled={isSelected}>
              {addActionText}
            </Button>
          )
        },
      },
    ],
    [addSite, selectedSites, editedBooking]
  )

  const [filtersExpanded, setFiltersExpanded] = useState(false)
  const [filtersShowAll, setFiltersShowAll] = useState(false)

  const moreFilters = [
    { name: 'Site Type', key: 'site_types', values: siteTypes },
    { name: 'Loops', key: 'loops', values: loops },
    { name: 'Sections', key: 'sections', values: sections },
  ]

  const [filtersQuery, setFiltersQuery] = useState({})

  const filtersSections = moreFilters.map(({ name, key, values }) => {
    // Determine if all options have been selected to mark All checkbox and disable others
    const all = !filtersQuery[key]
    // Shows 7 or all depending on the state of 'Show All...'
    const optionsList = Object.entries(values)
    const showList = filtersShowAll ? optionsList : optionsList.slice(0, filterListCount)
    // Gets the checkboxes that are displayed on the screen
    const checkboxes = showList.map(([rowName, id]) => {
      const idList = filtersQuery[key]

      return (
        <Checkbox
          key={rowName}
          label={rowName}
          disabled={all}
          checked={!idList || !!idList.find((i) => i === id)}
          onKeyDown={onKeyActivate('click')}
          className={'px-20'}
          onChange={(event) => {
            setFiltersQuery({
              ...filtersQuery,
              [key]: event.currentTarget.checked ? [...idList, id] : idList.filter((i) => i !== id),
            })
          }}
        />
      )
    })

    return (
      <fieldset className="mdl-cell mdl-cell--4-col" key={key}>
        <legend>{name}</legend>
        <Checkbox
          key={'all'}
          label={'All'}
          checked={all}
          onKeyDown={onKeyActivate('click')}
          className={'px-20'}
          onChange={(event) => {
            if (event.currentTarget.checked) setFiltersQuery({ ...filtersQuery, [key]: null })
            else setFiltersQuery({ ...filtersQuery, [key]: [] })
          }}
        />
        {checkboxes}
      </fieldset>
    )
  })

  const handleSearch = (e) => {
    e.preventDefault()

    const filters = { ...searchState.query, ...filtersQuery }
    searchDispatch({ type: 'query', value: filters })
    setFiltersExpanded(false)
  }

  return (
    <Fragment>
      {editedBooking ? (
        <ChangeBookingSite booking={editedBooking} reservationsPath={reservationsPath} />
      ) : (
        <SelectedSites
          selectedSites={selectedSites}
          remove={removeSite}
          removeAll={() => setSelectedSites([])}
          reservationsPath={reservationsPath}
        />
      )}

      <Card shadow={0} className="block--full overflow-visible">
        <AvailabilitySearchForm
          searchFilters={{ ...searchState.query, ...filtersQuery }}
          onSearch={(filters) =>
            searchDispatch({ type: 'query', value: { ...filters, ...filtersQuery } })
          }
        />

        {/* We only show results if a search with required query params was performed */}
        {data.meta ? (
          <Fragment>
            <Grid className="block--full px-20 py-0">
              <Cell col={9}>
                <h2 className="text--med text--bold my-0">Available Sites: {data.meta.total}</h2>
              </Cell>

              <Cell col={3}>
                <Expandy
                  text={'More Filters'}
                  expanded={filtersExpanded}
                  className="mdl-js-ripple-effect mdl-button--light u-pull--right"
                  onClick={() => setFiltersExpanded(!filtersExpanded)}
                />
              </Cell>
            </Grid>

            <div className="more-filters-expandy">
              {filtersExpanded && (
                <Grid className="mdl-shadow--2dp px-20">
                  {filtersSections}
                  {!filtersShowAll &&
                    moreFilters.some(
                      ({ values }) => Object.keys(values).length > filterListCount
                    ) && (
                      <Cell col={12}>
                        <button
                          type="button"
                          className="mdl-button mdl-js-button"
                          onClick={() => setFiltersShowAll(true)}
                          onKeyDown={onKeyActivate('click')}>
                          Show All...
                        </button>
                      </Cell>
                    )}

                  <Cell col={12} className="mt-10">
                    <Button
                      ripple
                      className="mdl-button--gray mdl-button--wide u-pull--right"
                      onClick={handleSearch}
                      onKeyDown={onKeyActivate(handleSearch)}>
                      Filter
                    </Button>

                    {!Object.values(filtersQuery).filter((val) => val && val.length !== 0)
                      .length !== 0 ? (
                      <Button
                        className="mdl-button--light mdl-button--wide u-pull--right"
                        onKeyDown={onKeyActivate('click')}
                        onClick={() => {
                          setFiltersShowAll(false)
                          setFiltersQuery({})
                        }}>
                        Clear Filters
                      </Button>
                    ) : null}
                  </Cell>
                </Grid>
              )}
            </div>

            <DataTable
              topic="Available Sites"
              loading={loading}
              rows={data.results}
              columns={columnsWithAddAction}
              rowKeyColumn="id"
              onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
              initialSortBy={searchState.order}
              initialIsSortAcending={searchState.asc === 1}
            />

            <Paginate
              {...data.meta}
              onClick={(num) => searchDispatch({ type: 'page', value: num })}
            />
          </Fragment>
        ) : null}
      </Card>
    </Fragment>
  )
}

SearchSites.propTypes = propTypes
export default SearchSites
