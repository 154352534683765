import { Cell, Grid, Icon } from 'react-mdl'
import useFetch from 'use-http'
import { RadioCollection } from '@components/shared'
import { formatDateFromDb } from '@utils'

const CampaignStatusToggle = ({ campaign, options, endpoint }) => {
  const isRecurring = campaign.frequency === 'recurring'
  const isExpiring = !campaign.non_expiring

  const hasEndTime = isExpiring && !!campaign.end_time

  const endTime = hasEndTime ? new Date(campaign.end_time) : null
  const expired = hasEndTime && endTime.getTime() < new Date().getTime()

  const [status, setStatus] = React.useState(expired ? 'inactive' : campaign.status)

  const disabled = isRecurring && isExpiring && expired

  // MM/DD/YYYY HH:MM AM/PM
  const formatTS = (ts) => formatDateFromDb(ts, 'l LT')

  const inactiveMessage =
    disabled || expired
      ? `This campaign became inactive at ${formatTS(endTime)}`
      : 'This campaign must be set to active to start collecting metrics'

  const { patch } = useFetch(endpoint, {
    headers: window.__AMS__.formToken,
    cachePolicy: 'no-cache',
  })

  const onChange = async (e) => {
    const value = e.target.value
    await patch({ status: value, format: 'json' })
    setStatus(value)
  }

  return (
    <Grid noSpacing>
      <Cell col={2} className="m-0">
        <RadioCollection
          label="Status"
          name="outreach_campaign[status]"
          value={status}
          options={options}
          onChange={onChange}
          disabled={disabled}
        />
      </Cell>
      <Cell col={8} className="pt-10">
        {(status !== 'active' || expired) && (
          <div className="bg--lightgray p-10 text--med text--bold">
            {expired && <Icon name="warning" className="text--orange mr-5" />}
            {inactiveMessage}
          </div>
        )}
      </Cell>
    </Grid>
  )
}

export default CampaignStatusToggle
