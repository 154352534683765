import { Grid, Cell, Button } from 'react-mdl'
import { formatCurrency } from '@utils'
import BookingHistory from '../BookingHistory'
import BookingCheckIn from './BookingCheckIn'
const { useState } = React
const { Expandy } = window

const Bookings = ({ reservation, bookingHistory, reservationPath }) => {
  const [expanded, setExpanded] = useState({})

  return (
    <React.Fragment>
      <div>
        <Grid>
          <Cell col={8} className="u-margin--y-none">
            <h1 className="text--med-lg">Bookings</h1>
          </Cell>
          {/* TODO: adding bookings to reservaton is post-MVP
          <Cell col={4}>
            <Button
              // onClick={() => setModalOpen(true)}
              accent
              type="button"
              className="mdl-button--wide mdl-button--wide-centered u-pull--right pt-4">
              New Booking
            </Button>
          </Cell>
          */}
        </Grid>

        <Grid className={'mdl-color-text--grey-600'} style={{ backgroundColor: '#edf4fc' }}>
          <Cell col={1}>Status</Cell>
          <Cell col={2}>Primary Occupant</Cell>
          <Cell col={2}>Site & Loop</Cell>
          <Cell col={2}>Check-in</Cell>
          <Cell col={2}>Check-out</Cell>
          <Cell col={2}></Cell>
          <Cell col={1}></Cell>
        </Grid>

        {/*What gets displayed on the row*/}
        <ul
          className="mdlext-accordion mdlext-js-accordion mdlext-accordion--vertical u-border--top u-border--bottom"
          role="tablist"
          aria-multiselectable="true">
          {/*Current keys used */}
          {reservation.bookings.map(
            ({
              id,
              booking_number,
              primary_occupant,
              zone,
              start_at,
              end_at,
              status_name,
              occupant_count,
              vehicle_count,
              checked_in_at,
              checked_out_at,
            }) => (
              <li key={id} className="text--med-small u-border--bottom">
                <Grid className={'d-flex block--full '}>
                  <Cell col={1}>{status_name}</Cell>
                  <Cell col={2} className={'text--darkblue'}>
                    {primary_occupant.first_name} {primary_occupant.last_name}
                  </Cell>
                  <Cell col={2}>
                    <span className={'text--darkblue'}>{zone.name}</span>
                    <br /> <span className="text--small">{zone.parent_medium_zone_name} </span>
                  </Cell>
                  <Cell col={2}>{moment(start_at).format('LL')}</Cell>
                  <Cell col={2}>{moment(end_at).format('LL')}</Cell>
                  <Cell col={2}>
                    <BookingCheckIn
                      id={id}
                      primaryOccupant={primary_occupant}
                      startAt={start_at}
                      checkedInAt={checked_in_at}
                      checkedOutAt={checked_out_at}
                      reservationPath={reservationPath}
                    />
                  </Cell>
                  <Cell col={1}>
                    <Expandy
                      onClick={() => setExpanded({ ...expanded, [id]: !expanded[id] })}
                      expanded={expanded[id]}
                      aria-label={`Expand `}
                      className="u-pull--right "
                    />
                  </Cell>
                </Grid>
                {expanded[id] && (
                  <div>
                    {/*Start of Expandy*/}
                    <Grid>
                      <Cell col={12} className="u-margin--y-none">
                        <strong className={'text--med text--darkblue'}>
                          SITE: {zone.name}, LOOP: {zone.parent_medium_zone_name},{' '}
                          {zone.parent_large_zone_name}
                        </strong>
                      </Cell>
                    </Grid>

                    <Grid className={'mdl-color-text--grey-600'}>
                      {/*Booking Overview*/}
                      <Cell col={12} className={'text--med-lg'}>
                        <h1 className="text--med-lg">Booking Overview</h1>
                      </Cell>
                      <Cell col={2}>Check-in</Cell>
                      <Cell col={2}>Check-out</Cell>
                      <Cell col={2}>No. of Occupants</Cell>
                      <Cell col={2}>No. of Vehicles</Cell>
                      <Cell col={4}></Cell>
                    </Grid>

                    <Grid className={'d-flex block--full '}>
                      <Cell col={2}>{moment(start_at).format('LL')}</Cell>
                      <Cell col={2}>{moment(end_at).format('LL')}</Cell>
                      <Cell col={2}>{occupant_count}</Cell>
                      <Cell col={2}>{vehicle_count}</Cell>
                      {/*BUTTON will go below*/}
                      <Cell col={4}></Cell>
                    </Grid>

                    <Grid className={'mdl-color-text--grey-600'}>
                      {/*Primary Occupant Details*/}
                      <Cell col={12} className={'text--med-lg'}>
                        <h1 className={'text--med-lg'}>Primary Occupant Details</h1>
                      </Cell>
                      <Cell col={3}>Full Name</Cell>
                      <Cell col={3}>Phone No</Cell>
                      <Cell col={2}>Date of Birth</Cell>
                      <Cell col={4}>Email</Cell>
                    </Grid>

                    <Grid className={'d-flex block--full '}>
                      <Cell col={3}>
                        {primary_occupant.first_name} {primary_occupant.last_name} &nbsp;
                        {primary_occupant.customer_number}
                      </Cell>
                      <Cell col={3}>{primary_occupant.phone_number}</Cell>
                      <Cell col={2}>{moment(primary_occupant.dob).format('MM/DD/YYYY')}</Cell>
                      <Cell col={4}>{primary_occupant.email}</Cell>
                    </Grid>

                    {bookingHistory ? (
                      <BookingHistory bookingHistory={bookingHistory[booking_number]} />
                    ) : null}
                  </div>
                )}
              </li>
            )
          )}
        </ul>
      </div>

      <Grid className="pb-5 block--full">
        {/*Primary Occupant Details*/}
        <Cell col={12} className={'text--med-lg '}>
          <h1 className="text--med-lg">Add-Ons</h1>
        </Cell>

        {reservation.add_ons.map((a, index) => {
          return (
            <Cell
              col={6}
              key={index}
              className="d-flex"
              style={{ width: '50%', flex: '0 50%', height: '100px' }}>
              <button
                className="m-5 p-0 d-flex block--full bg--white u-border--round mdl-shadow--2dp u-border--lightgray pointer"
                type="button">
                <div style={{ width: '35%', height: '100%', overflow: 'hidden' }}>
                  <img src={a.image_url} alt={a.display_name} aria-hidden width="100%" />
                </div>
                <div className="px-20 pt-10 u--align-left" style={{ width: '35%' }}>
                  <h3 className="text--small mdl-card__title-text">{a.display_name}</h3>
                  <p className="text--small u-overflow-ellipsis">{a.name}</p>
                </div>
                <div className="px-20 pt-10 u--align-left" style={{ width: '10%' }}>
                  <p className="m-0">X{a.quantity}</p>
                </div>
                <div className="px-20 pt-10 u--align-left" style={{ width: '10%' }}>
                  <p className="m-0">${formatCurrency(a.subtotal_with_quantity)}</p>
                </div>
              </button>
            </Cell>
          )
        })}
      </Grid>
    </React.Fragment>
  )
}
export default Bookings
