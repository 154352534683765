import queryString from 'query-string'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { Card } from 'react-mdl'
import { DataTable, SearchForm, NpmapIcon, Paginate, Btn } from '@components/shared'
const { useState, useMemo, useEffect } = React

const propTypes = {
  // String of path to request facility data
  endpoint: PropTypes.string.isRequired,

  // Contract permission to create or edit facilities
  canEdit: PropTypes.bool.isRequired,
}

const initialSortBy = 'name'

const buildColumns = (path, canEdit) => {
  return [
    {
      name: 'icon_name',
      display: 'Icon',
      cellFormatter: (name) => (name ? <NpmapIcon name={name} /> : <span></span>),
      nosort: true,
      colGroupStyle: { width: '10%' },
    },
    {
      name: 'name',
      display: 'Name',
      colGroupStyle: { width: '50%' },
    },
    {
      name: 'facilitable_type_label',
      display: 'Type',
      colGroupStyle: { width: '15%' },
    },
    {
      name: 'edit',
      display: 'Edit',
      hideHeader: true,
      nosort: true,
      colGroupStyle: { width: '5%' },
      cellFormatter: (_, row) => {
        return row.facilitable_type_label === 'Custom' && canEdit ? (
          <Btn.Icon
            light
            className="u-pull--right"
            name="edit"
            label={`Edit Attribute ${row.id}`}
            href={`${path}/${row.id}/edit`}
          />
        ) : null
      },
    },
  ]
}

const SearchFacilities = ({ endpoint, canEdit }) => {
  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
  })
  const { get, loading } = useFetch(searchUrl)
  const [data, setData] = useState({ results: [] })

  useEffect(() => {
    const fetchFacilities = async () => {
      const res = await get()
      setData(res)
    }

    fetchFacilities()
  }, [get, searchUrl])

  const columns = buildColumns(endpoint, canEdit)

  const topic = searchState.query.length > 0 ? 'Facility Search Results' : 'All Facilities'

  return (
    <Card shadow={0} className="block--full">
      <SearchForm
        searchFilters={searchState.query}
        onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
        placeholder="Search Facilities"
        helperText="Search Facility Name"
      />

      <DataTable
        topic={topic}
        loading={loading}
        rows={data.results}
        columns={columns}
        rowKeyColumn="id"
        onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
        initialSortBy={searchState.order}
        initialIsSortAcending={searchState.asc === 1}
      />
    </Card>
  )
}

SearchFacilities.propTypes = propTypes
export default SearchFacilities
