import { InstantFileUpload, RichTextEditor } from '@components/shared'
import HeroImage from '@components/camp/HeroImage'
import ImagesTable from '@components/camp/ImagesTable'
import AttributesTable from '@components/camp/AttributesTable'
import SmallZoneParents from '@components/camp/zones/SmallZoneParents'
import ExpandingSiteTypesTable from '@components/camp/zones/ExpandingSiteTypesTable'
import SitePriceSummaryTable from '@components/camp/zones/SitePriceSummaryTable'
import { Grid, Cell, Textfield } from 'react-mdl'
import qs from 'qs'

const { Fragment } = React
const { useLocalStore, useObserver, observer } = mobxReactLite

const AttributesTableObserver = observer((props) => {
  return <AttributesTable {...props} />
})

const SmallZoneForm = (props) => {
  const store = useLocalStore(() => ({
    name: props.smallZone.name || '',
    lengthValue: props.smallZone.length_value || '',
    widthValue: props.smallZone.width_value || '',
    latitude: props.smallZone.latitude || '',
    longitude: props.smallZone.longitude || '',
    siteType: props.smallZone.site_type,
    active: props.smallZone.active.toString(),
    availableTraits: props.availableTraits,
    images: props.smallZone.images || [],
    pricingRecords: props.pricingRecords,
    siteTraits: props.formatTraits,
    selectedZones: {
      medium: props.smallZone.parent_medium_zone,
      large: props.smallZone.parent_large_zone,
    },
    defaultUpdateCounter: 0,
    inheritedUpdateCounter: 0,

    get premiumAttributes() {
      // exclude defaults that aren't currently applied
      let premiumCategories = store.siteTraits.filter((category) => category.type !== 'default')
      return premiumCategories.flatMap((category) => {
        return category.rows
          .filter((trait) => trait.columns.premium_formatted !== null)
          .map((trait) => trait.columns)
      })
    },

    get parentZoneIds() {
      let selectedZoneValues = Object.values(store.selectedZones).filter((value) => value != null)
      return selectedZoneValues.length > 0 ? selectedZoneValues.map((zone) => zone.id) : []
    },

    handleSiteTypeChange: (siteType) => {
      store.siteType = siteType
      if (siteType != null) {
        Fetchable.get(`${props.smallZonePath}/default_traits?site_type_id=${store.siteType.id}`)
          .then((res) => res.json())
          .then((response) => {
            store.siteTraits[1].rows = response
            store.defaultUpdateCounter += 1
          })
        Fetchable.get(`${props.siteTypesPath}/${store.siteType.id}/variations.json`)
          .then((res) => res.json())
          .then((response) => {
            if (response.statusText) {
              store.pricingRecords = []
            } else {
              store.pricingRecords = response
            }
          })
      } else {
        store.siteTraits[1].rows = []
        store.defaultUpdateCounter += 1
      }
    },

    handleSelectedZoneChange: (zones) => {
      store.selectedZones = zones
      if (store.parentZoneIds.length === 0) {
        store.siteTraits[2].rows = []
        store.inheritedUpdateCounter += 1
      } else {
        let queryString = qs.stringify(
          { parent_zone_ids: store.parentZoneIds },
          { arrayFormat: 'brackets' }
        )
        Fetchable.get(`${props.smallZonePath}/inherited_traits?${queryString}`)
          .then((res) => res.json())
          .then((response) => {
            store.siteTraits[2].rows = response
            store.inheritedUpdateCounter += 1
          })
      }
    },
    handleAttributesChange: (siteTraits) => {
      store.siteTraits = siteTraits
    },
  }))

  return useObserver(() => (
    <Fragment>
      <HeroImage
        name="camp_small_zone[hero_image_attributes][id]"
        endpoint={props.endpoint}
        image={props.smallZone.hero_image}
        imageable={props.heroImageableProps}
      />
      <Grid className="pl-20 pt-20">
        <Cell col={4}>
          <Textfield
            name="camp_small_zone[name]"
            className="textfield__input--full"
            value={store.name}
            onChange={(e) => (store.name = e.target.value)}
            error={props.nameErrors}
            label={`* ${props.displayNames.small} Name`}
            placeholder={`Choose a ${props.displayNames.small} Name`}
            floatingLabel
          />
        </Cell>
        <Cell col={3} className="d-flex pl-20">
          <div className="d-flex flex__items--center">
            <Textfield
              name="camp_small_zone[length_value]"
              label="Dimensions"
              floatingLabel
              style={{ width: 70 }}
              value={store.lengthValue}
              onChange={(e) => (store.lengthValue = e.target.value)}
              placeholder="00"
            />
            <div className="pl-10 text--med-small text--darkgray">X</div>
          </div>
          <div className="d-flex flex__items--center pl-10">
            <Textfield
              name="camp_small_zone[width_value]"
              label=""
              floatingLabel
              style={{ width: 70 }}
              value={store.widthValue}
              onChange={(e) => (store.widthValue = e.target.value)}
              placeholder="00"
            />
            <div className="pl-10 text--med-small text--darkgray">{props.smallZone.width_unit}</div>
          </div>
        </Cell>
        <Cell col={2} className="d-flex flex__items--center pl-20">
          <Textfield
            name="camp_small_zone[latitude]"
            label="Coordinates"
            floatingLabel
            value={store.latitude}
            onChange={(e) => (store.latitude = e.target.value)}
            placeholder="0.00"
          />
          <div className="text--med-small text--darkgray">&nbsp;&deg;N</div>
        </Cell>
        <Cell col={2} className="d-flex flex__items--center pl-10">
          <Textfield
            name="camp_small_zone[longitude]"
            label=""
            floatingLabel
            value={store.longitude}
            onChange={(e) => (store.longitude = e.target.value)}
            placeholder="0.00"
          />
          <div className="text--med-small text--darkgray">&nbsp;&deg;W</div>
        </Cell>
      </Grid>
      <SmallZoneParents
        name="camp_small_zone[parent_zone_id]"
        zones={props.zones}
        selectedZones={store.selectedZones}
        displayNames={props.displayNames}
        active={store.active}
        activeWarningContent={props.activeWarningContent}
        onChange={store.handleSelectedZoneChange}
        premiumAttributes={store.premiumAttributes}
      />
      <Cell col={12} className="p-20">
        <label className="text--small text--darkblue mb-10" htmlFor="camp_small_zone[conditions]">
          Conditions
        </label>
        <div className="text--med text--lightgray">
          Write a short description of the conditions of the site.
        </div>
        <RichTextEditor
          name="camp_small_zone[conditions]"
          id="camp_small_zone_conditions"
          value={props.smallZone.conditions}
          label="Site Conditions"
          characterLimit={1000}
          placeholder="If this is blank, it will not show on storefront."
          hideHeadingBlock={true}
          hideCodeBlock={true}
          hideFileTools={true}
        />
      </Cell>
      <Cell col={12} className="p-20">
        <label
          className="text--small text--darkblue mb-10"
          htmlFor="camp_small_zone[accessibility]">
          Accessibility
        </label>
        <div className="text--med text--lightgray">
          Write a short description of the conditions around accessing this site.
        </div>
        <RichTextEditor
          name="camp_small_zone[accessibility]"
          id="camp_small_zone_accessibility"
          value={props.smallZone.accessibility}
          label="Site Accessibility"
          characterLimit={1000}
          placeholder="If this is blank, it will not show on storefront."
          hideHeadingBlock={true}
          hideCodeBlock={true}
          hideFileTools={true}
        />
      </Cell>
      <ImagesTable
        name="camp_small_zone[images_attributes]"
        label="Images"
        description="Add images of your site for customers to see."
        filePath={props.imagesPath}
        images={store.images}
        edit={true}
      />
      <Cell col={12} className="pl-20 pt-30 mt-20">
        <h2 className="mdl-card__title-text u-margin--bottom-10">
          {props.displayNames.small} Type
        </h2>
        <p className="text--lightergray">Site type determines the base price for this site.</p>
      </Cell>
      <ExpandingSiteTypesTable
        name="camp_small_zone[site_type_id]"
        siteType={store.siteType}
        siteTypes={props.siteTypes}
        siteTypesPath={props.siteTypesPath}
        edit={true}
        onChange={store.handleSiteTypeChange}
      />
      <Cell col={12} className="pl-20 pt-30 mt-20">
        <h2 className="mdl-card__title-text u-margin--bottom-10">
          {props.displayNames.small} Attributes
        </h2>
        <p className="text--lightergray">
          Pricing premiums applied to some attributes will automatically affect the site price. To
          change the premium amount of an attribute, create a new attribute with the details you
          need. Attributes you create will be available to your park only.
        </p>
      </Cell>
      <AttributesTableObserver
        name="camp_small_zone[trait_ids]"
        data={store.siteTraits}
        attributes={store.availableTraits}
        customAttributesPath={props.customAttributesPath}
        emptyText="No default attributes selected."
        onChange={store.handleAttributesChange}
        editable={true}
        defaultUpdateCounter={store.defaultUpdateCounter}
        inheritedUpdateCounter={store.inheritedUpdateCounter}
      />
      <Cell col={12} className="pl-20 pt-30 mt-20">
        <h2 className="mdl-card__title-text">{props.displayNames.small} Price Summary</h2>
        <p className="text--lightergray u-margin--top-20">
          Pricing conditions are applied to some attributes. Therefore, attributes applied to some
          locations and the site will automatically affect the site price. If you wold like to
          override the changes, but can't, please contact your local state office.
        </p>
      </Cell>
      <SitePriceSummaryTable
        siteTypeName={store.siteType ? store.siteType.name : null}
        pricingRecords={store.pricingRecords ? store.pricingRecords.slice() : []}
        premiumAttributes={store.premiumAttributes}
      />
    </Fragment>
  ))
}

export default SmallZoneForm
