import { Grid, Cell, Card, Button } from 'react-mdl'
import { DataTable, Btn, FC } from '@components/shared'
import { formatDateFromDb } from '@utils'
const { Fragment, useMemo } = React

const ParentZones = ({ loop, section }) => (
  <div>
    {loop || '-'}
    <br />
    {section ? <span className="text--med-small text--lightgray">{section}</span> : '-'}
  </div>
)

const SelectedSites = ({ selectedSites, remove, removeAll, reservationsPath }) => {
  const columns = useMemo(
    () => [
      {
        name: 'name',
        display: 'Name',
        nosort: true,
      },
      {
        name: 'parent_zone_names',
        display: 'Loops & Sections',
        nosort: true,
        cellFormatter: ({ loop, section }) => <ParentZones loop={loop} section={section} />,
      },
      {
        name: 'site_type_name',
        display: 'Site Type',
        nosort: true,
      },
      {
        name: 'start_date',
        display: 'Check-In',
        nosort: true,
        cellFormatter: (value) => formatDateFromDb(value),
      },
      {
        name: 'end_date',
        display: 'Check-Out',
        nosort: true,
        cellFormatter: (value) => formatDateFromDb(value),
      },
      {
        name: 'occupants',
        display: 'Occupants',
        nosort: true,
      },
      {
        name: 'vehicles',
        display: 'Vehicles',
        nosort: true,
      },
      {
        name: 'remove',
        nosort: true,
        hideHeader: true,
        cellFormatter: (_, row) => {
          return (
            <Btn.Icon light name="close" label={`Remove Site ${row.name}`} onClick={remove(row)} />
          )
        },
      },
    ],
    [remove]
  )

  const summedSubtotal = useMemo(
    () =>
      selectedSites
        .reduce((acc, current) => {
          acc += current.subtotal ?? 0
          return acc
        }, 0)
        .toFixed(2),
    [selectedSites]
  )

  return (
    <Card shadow={0} className="block--full mb-20">
      <FC.Form action={reservationsPath} method="post">
        {selectedSites.map((site) => {
          const name = 'reservation[bookings_attributes][]'
          return (
            <Fragment key={site.id}>
              <input type="hidden" name={`${name}[zone_id]`} value={site.id} />
              <input type="hidden" name={`${name}[start_at]`} value={site.start_date} />
              <input type="hidden" name={`${name}[end_at]`} value={site.end_date} />
              <input type="hidden" name={`${name}[occupant_count]`} value={site.occupants} />
              <input type="hidden" name={`${name}[vehicle_count]`} value={site.vehicles} />
            </Fragment>
          )
        })}

        <Grid className="p-20 d-flex flex__items--center">
          <Cell col={3}>
            <h2 className="text--med-lg m-0">Sites Selected ({selectedSites.length})</h2>
          </Cell>

          {selectedSites.length === 0 ? (
            <Cell col={12}>
              <p className="text--med text--lightgray">There are currently no sites selected</p>
            </Cell>
          ) : (
            <Cell col={9}>
              <Button className="mdl-button--light mdl-button--wide" onClick={removeAll}>
                Clear All
              </Button>
            </Cell>
          )}
        </Grid>

        {selectedSites.length > 0 ? (
          <Fragment>
            <DataTable
              topic="Selected Sites"
              rows={selectedSites}
              columns={columns}
              rowKeyColumn="id"
            />
            <div className="d-flex flex__justify--end p-20">
              <div className="py-10 px-20 mx-10 bg--lighterblue text--darkblue u-line-height--1">
                {selectedSites.length} {selectedSites.length === 1 ? 'Booking' : 'Bookings'} &mdash;
                ${summedSubtotal}
              </div>
              <Button type="submit" accent>
                Start Reservation
              </Button>
            </div>
          </Fragment>
        ) : null}
      </FC.Form>
    </Card>
  )
}

export default SelectedSites
