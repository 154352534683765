import { SearchForm, DataTable, PageHeading, Paginate } from '@components/shared'
import ZoneTabsHeader from '@camp/zones/ZoneTabsHeader'
import useFetch from 'use-http'
import queryString from 'query-string'
import { useSearchQuery } from '@hooks'

const selectEndpoint = (type, endpoints) => {
  if (type === 'large') {
    return endpoints.largeZones
  } else if (type === 'medium') {
    return endpoints.mediumZones
  } else if (type === 'small') {
    return endpoints.smallZones
  }
}

const selectName = (type) => {
  if (type === 'large') {
    return 'Section'
  } else if (type === 'medium') {
    return 'Loop'
  } else if (type === 'small') {
    return 'Site'
  }
}

const selectHelper = (type) => {
  if (type === 'large') {
    return 'Search Section Name, Site Type'
  } else if (type === 'medium') {
    return 'Search Loop Name, Section, Site Type'
  } else if (type === 'small') {
    return 'Search Site Name, Section, Loop, Site Type'
  }
}

const ZoneView = ({ endpoints, type }) => {
  const endpoint = selectEndpoint(type, endpoints)

  let columns = []
  if (type === 'large') {
    columns.push({
      name: 'name',
      display: 'Section',
      cellFormatter: (name, row) => {
        return <a href={`${endpoints.largeZones}/${row.id}`}>{name}</a>
      },
    })
    columns.push({
      name: 'medium_zones_count',
      display: 'Loops',
      numeric: true,
      nosort: true,
    })
    columns.push({
      name: 'small_zones_count',
      display: 'Sites',
      numeric: true,
      nosort: true,
    })
  } else if (type === 'medium') {
    columns.push({
      name: 'name',
      display: 'Loop',
      cellFormatter: (name, row) => {
        return <a href={`${endpoints.mediumZones}/${row.id}`}>{name}</a>
      },
    })
    columns.push({
      name: 'section_name',
      display: 'Section',
      cellFormatter: (name, row) => {
        return row.parent_zone ? (
          <a href={`${endpoints.largeZones}/${row.parent_zone.id}`}>{row.parent_zone.name}</a>
        ) : (
          undefined
        )
      },
    })
    columns.push({
      name: 'small_zones_count',
      display: 'Sites',
      numeric: true,
      nosort: true,
    })
  } else if (type === 'small') {
    columns.push({
      name: 'name',
      display: 'Name',
      cellFormatter: (name, row) => {
        return <a href={`${endpoints.smallZones}/${row.id}`}>{name}</a>
      },
    })
    columns.push({
      name: 'loop_name',
      display: 'Loop',
      cellFormatter: (name, row) => {
        return row.loop_id ? (
          <a href={`${endpoints.mediumZones}/${row.loop_id}`}>{row.loop_name}</a>
        ) : (
          undefined
        )
      },
    })
    columns.push({
      name: 'section_name',
      display: 'Section',
      cellFormatter: (name, row) => {
        return row.section_id ? (
          <a href={`${endpoints.largeZones}/${row.section_id}`}>{row.section_name}</a>
        ) : (
          undefined
        )
      },
    })
    columns.push({
      name: 'site_type',
      display: 'Site Type',
      cellFormatter: (name, row) => {
        return row.site_type ? row.site_type.name : undefined
      },
    })
  }
  columns.push({
    name: 'attributes',
    display: 'Attributes',
    numeric: true,
    nosort: true,
  })

  const onRowClick = (e, row) => {
    window.location = `${endpoint}/${row.id}`
  }

  let zoneType = 'Section'
  let tabNumber = 2
  let initialSortBy = 'name'

  if (type === 'medium') {
    zoneType = 'Loop'
    tabNumber = 1
  } else if (type === 'small') {
    zoneType = 'Site'
    tabNumber = 0
    initialSortBy = 'site_type'
  }

  const searchText = 'Search ' + selectName(type) + 's'
  const helperText = selectHelper(type)
  let premiumText =
    '* This ' +
    selectName(type).toLowerCase() +
    ' has premium attributes attached to it. Premiums affect the price of a site'
  if (type === 'small') premiumText = ''

  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
  })
  const { data = { results: [] }, loading } = useFetch(searchUrl, [searchUrl])

  const topic =
    searchState.query.length > 0
      ? selectName(type) + ' Search Results'
      : 'All ' + selectName(type) + 's'

  return (
    <React.Fragment>
      {/* TODO: find better way to get translations into React */}
      <PageHeading title="Sites &amp; Sections" />

      <div className="mdl-shadow--2dp bg--white">
        <ZoneTabsHeader activeTab={tabNumber} zoneType={zoneType} endpoints={endpoints} />

        <SearchForm
          searchFilters={searchState.query}
          onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
          placeholder={searchText}
          helperText={helperText}
        />
        <DataTable
          className="data-table--chevron"
          topic={topic}
          loading={loading}
          rows={data.results}
          columns={columns}
          onRowClick={onRowClick}
          rowKeyColumn="id"
          onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
          initialSortBy={searchState.order}
          initialIsSortAcending={searchState.asc === 1}
        />
        {data.meta ? (
          <Paginate
            {...data.meta}
            onClick={(num) => searchDispatch({ type: 'page', value: num })}
          />
        ) : (
          undefined
        )}
        <div className="u-padding--20 u-padding--bottom-10">
          <p className="text--med text--lightergray">{premiumText}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ZoneView
