import { Cell, Grid, Textfield } from 'react-mdl'
import { RadioCollection } from '@components/shared'
const { useState } = React

const BookingOccupant = ({ customer, occupant = {} }) => {
  const initialValue = !occupant?.id || occupant?.customer_id === customer.id ? 'customer' : 'other'
  const [value, setValue] = useState(initialValue)

  const isCustomer = value === 'customer'
  const fieldName = (attr) => {
    return `camp_booking[primary_occupant_attributes][${attr}]`
  }

  return (
    <div className="mb-20">
      <RadioCollection
        className="mb-20"
        name=""
        options={[
          { value: 'customer', name: 'Customer Is the Primary Occupant' },
          { value: 'other', name: 'Other Guest' },
        ]}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      {occupant.id ? <input type="hidden" name={fieldName('id')} value={occupant.id} /> : null}

      {isCustomer ? (
        <Grid className="mdl-grid--nested text--med">
          <input type="hidden" name={fieldName('customer_id')} value={customer.id} />
          <Cell col={3}>
            <div className="text--med-small text--lightgray">Full Name</div>
            {customer.full_name}
          </Cell>
          <Cell col={3}>
            <div className="text--med-small text--lightgray">Phone Number</div>
            {customer.work_phone}
          </Cell>
          <Cell col={3}>
            <div className="text--med-small text--lightgray">Email</div>
            {customer.email_address}
          </Cell>
        </Grid>
      ) : (
        <Grid className="mdl-grid--nested">
          <input type="hidden" name={fieldName('customer_id')} value="" />
          <Cell col={3}>
            <Textfield
              floatingLabel
              label="Occupant First Name"
              placeholder="First Name"
              defaultValue={occupant.first_name || ''}
              name={fieldName('first_name]')}
            />
          </Cell>
          <Cell col={3}>
            <Textfield
              floatingLabel
              label="Occupant Last Name"
              placeholder="Last Name"
              defaultValue={occupant.last_name || ''}
              name={fieldName('last_name]')}
            />
          </Cell>
          <Cell col={3}>
            <Textfield
              floatingLabel
              type="tel"
              label="Occupant Phone Number"
              placeholder="000-000-0000"
              defaultValue={occupant.phone_number || ''}
              name={fieldName('phone_number]')}
            />
          </Cell>
          <Cell col={3}>
            <Textfield
              floatingLabel
              label="Occupant Email"
              placeholder="email@example.com"
              type="email"
              defaultValue={occupant.email || ''}
              name={fieldName('email]')}
            />
          </Cell>
        </Grid>
      )}
    </div>
  )
}

export default BookingOccupant
