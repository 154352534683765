// Retain ctrl+click or cmd+click functionality by opening a new window
//
// Usage:
//
//   <button onClick={(e) => onClickLink(e, 'http://google.com')} />
//
export const onClickLink = (event, url) => {
  if (event.ctrlKey || event.metaKey) {
    window.open(url)
  } else {
    window.location = url
  }
}
