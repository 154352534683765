import React from 'react'

import { IconToggle } from 'react-mdl'

export default class Maskable extends React.Component {
  constructor(props) {
    super(props)

    const rawValue = this.props.value || ''
    const charsVisible = this.props.chars_visible || 4

    this.state = {
      masked: true,
      rawValue: rawValue,
      charsVisible: charsVisible,
    }
  }

  toggleMask = (_e) => {
    this.setState({ masked: !this.state.masked })
  }

  maskedValue = () => {
    return (
      this.state.rawValue
        .substring(0, this.state.rawValue.length - this.state.charsVisible)
        .replace(/\w/g, '*') +
      this.state.rawValue.substring(this.state.rawValue.length - 4, this.state.rawValue.length)
    )
  }

  render = () => {
    var displayValue
    if (this.state.masked) {
      displayValue = this.maskedValue()
    } else {
      displayValue = this.state.rawValue
    }

    return (
      <span>
        {displayValue}&nbsp;
        <IconToggle
          ripple
          onChange={this.toggleMask}
          checked={!this.state.masked}
          name={this.state.masked ? 'visibility' : 'visibility_off'}
        />
      </span>
    )
  }
}
