const { L } = window

export const mapPin = (active = false) => {
  return L.divIcon({
    className: 'camp-div-icon',
    html: mapPinSvg(active),
    iconSize: [50, 58],
    iconAnchor: [25, 50],
    popupAnchor: [-1, -29],
  })
}

export const sitePin = (iconName, active = false) => {
  return L.divIcon({
    className: 'camp-div-icon',
    html: sitePinHtml(iconName, active),
    iconSize: [50, 58],
    iconAnchor: [26, 52],
    popupAnchor: [-1, -30],
  })
}

export const facilityPin = (iconName, active = false) => {
  return L.divIcon({
    className: 'camp-div-icon',
    html: facilityPinHtml(iconName, active),
    iconSize: [32, 32],
    iconAnchor: [16, 30],
    popupAnchor: [-1, -20],
  })
}

export const draggableSitePin = (iconName) => {
  return draggablePin(sitePinHtml(iconName), 50, 55)
}

export const draggableFacilityPin = (iconName) => {
  return draggablePin(facilityPinHtml(iconName), 32, 32)
}

export const draggablePin = (html, width, height) => {
  const el = document.createElement('div')
  el.innerHTML = html
  el.style.position = 'absolute'
  el.style.opacity = '0.5'
  el.style.top = '-1000px'
  el.style.zIndex = 9999
  document.body.appendChild(el)

  const offset = { x: width / 2, y: height }
  return { el, offset }
}

const mapPinSvg = (active) => {
  const fill = active ? '#4e92df' : '#000'
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="58" viewBox="0 0 24 24">
      <g fill="#fff" fill-rule="evenodd">
        <g fill="${fill}">
          <g>
            <path d="M12.15 2.7c4.39 0 7.95 3.438 7.95 7.68 0 2.828-2.65 6.668-7.95 11.52-5.3-4.852-7.95-8.692-7.95-11.52 0-4.242 3.56-7.68 7.95-7.68zm0 4.582c-1.756 0-3.18 1.367-3.18 3.054 0 1.687 1.424 3.055 3.18 3.055 1.756 0 3.18-1.368 3.18-3.055s-1.424-3.054-3.18-3.054z" transform="translate(-30 -152) translate(30 152)"/>
          </g>
        </g>
      </g>
    </svg>
  `
}

const sitePinSvg = (active = false) => {
  const fill = active ? '#4e92df' : '#fff'
  return `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="position:absolute;"
      role="img"
      aria-hidden
      width="50"
      height="58">
      <defs>
        <filter
          id="a"
          width="140%"
          height="133.3%"
          x="-20%"
          y="-14.6%"
          filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadOuter2" />
          <feOffset dy="3" in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
          <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation=".5" />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter3" />
          <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="1" />
          <feColorMatrix
            in="shadowBlurOuter3"
            result="shadowMatrixOuter3"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
            <feMergeNode in="shadowMatrixOuter3" />
          </feMerge>
        </filter>
        <path
          id="b"
          d="M20 48c13.333-12.13 20-21.73 20-28.8C40 8.596 31.046 0 20 0S0 8.596 0 19.2C0 26.27 6.667 35.87 20 48z"
        />
      </defs>
      <g transform="translate(5 4)" fill="none" fill-rule="evenodd">
        <use fill="#000" filter="url(#a)" xlink:href="#b" />
        <use fill="${fill}" xlink:href="#b" />
      </g>
    </svg>
  `
}

const sitePinHtml = (iconName, active = false) => {
  const fill = active ? '#fff' : '#000'
  const assetPath = window.__AMS__.npmapSvgPath
  return `
    <div style="position: absolute; z-index: 300;">
      ${sitePinSvg(active)}
      <svg viewBox="0 0 30 30" class="npmap-icon icon-xl" role="img" focusable="false" style="position: absolute; top: 10px; left: 13px;">
        <use fill="${fill}" href="${assetPath}#icon-${iconName}" aria-hidden="true"></use>
      </svg>
    </div>
  `
}

const facilityPinHtml = (iconName, active = false) => {
  const background = active ? 'bg--lightblue' : 'bg--gray'
  const fill = active ? '#000' : '#fff'
  const assetPath = window.__AMS__.npmapSvgPath
  return `
    <div class="${background} mdl-shadow--3dp u-border--round" style="position: absolute; z-index: 300; width: 34px; height: 34px;">
      <svg viewBox="0 0 30 30" class="npmap-icon icon-xl u-absolute--center" role="img" focusable="false" style="width: 90%; height: 90%;">
        <use fill="${fill}" href="${assetPath}#icon-${iconName}" aria-hidden="true"></use>
      </svg>
    </div>
  `
}
