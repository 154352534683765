import classNames from 'classnames'
import { onKeyActivate } from '@utils'
import { Switch } from 'react-mdl'
import { AttributeChip, NpmapIcon, Btn } from '@components/shared'
import SearchTraits from './index'
const { useMemo } = React

const CustomTraits = ({ endpoint, canEdit }) => {
  const columns = useMemo(
    () => [
      {
        name: 'icon_name',
        display: 'Icon',
        cellFormatter: (name) => (name ? <NpmapIcon name={name} /> : <span></span>),
        nosort: true,
        colGroupStyle: { width: '10%' },
      },
      {
        name: 'name',
        display: 'Name',
        colGroupStyle: { width: '20%' },
      },
      {
        name: 'category_name',
        display: 'Category',
        colGroupStyle: { width: '20%' },
      },
      {
        name: 'premium',
        display: 'Premium',
        colGroupStyle: { width: '20%' },
        cellFormatter: (_, row) => {
          return row.premium_formatted ? (
            <AttributeChip
              value={row.premium_formatted}
              style={{ width: '50%' }}
              className={classNames({
                'mdl-alert-chip': row.active,
                'mdl-chip--disabled': !row.active,
              })}></AttributeChip>
          ) : null
        },
      },
      {
        name: 'traitable_type_label',
        display: 'Type',
        colGroupStyle: { width: '15%' },
      },
      {
        name: 'active',
        display: 'Enabled',
        nosort: true,
        colGroupStyle: { width: '10%' },
        cellFormatter: (active, row) => {
          return row.traitable_type_label === 'Custom' && canEdit ? (
            <Switch
              name="active"
              className="mdl-switch--accent"
              style={{ width: 'auto' }}
              checked={row.active}
              onKeyDown={onKeyActivate((e) => e.target.click())}
              onChange={row.toggleActivate}
            />
          ) : null
        },
      },
      {
        name: 'edit',
        display: 'Edit',
        hideHeader: true,
        nosort: true,
        colGroupStyle: { width: '5%' },
        cellFormatter: (_, row) => {
          return row.traitable_type_label === 'Custom' && canEdit ? (
            <Btn.Icon
              light
              className="u-pull--right"
              name="edit"
              label={`Edit Attribute ${row.id}`}
              href={`${endpoint}/${row.id}/edit`}
            />
          ) : null
        },
      },
    ],
    [endpoint, canEdit]
  )

  return <SearchTraits endpoint={endpoint} columns={columns} />
}

export default CustomTraits
