import React from 'react'
const { SortableHOC } = window

export default class ExecutionerDrawApplicationProductItem extends React.Component {
  constructor(props) {
    super(props)
    this.onRemoveItem = this.onRemoveItem.bind(this)
  }

  onRemoveItem(e) {
    e.preventDefault()
    this.props.onRemoveItem(this.props.index)
  }

  get itemContent() {
    if (this.props.item._destroy) {
      return (
        <span>
          <input type="hidden" name={`${this.inputName}[_destroy]`} value="true" />
        </span>
      )
    } else {
      const SortableHandle = SortableHOC.SortableHandle(() => {
        return (
          <i className="material-icons handle u-pull--left" style={{ cursor: 'move' }}>
            drag_handle
          </i>
        )
      })

      return (
        <div>
          <SortableHandle />
          <span className="mdl-chip mdl-chip--deletable">
            <span className="mdl-chip__text">
              <strong>{this.props.item.priority + 1}</strong> - {this.props.product.display_name}
            </span>
            <button type="button" className="mdl-chip__action" onClick={this.onRemoveItem}>
              <i className="material-icons text--white">clear</i>
            </button>
          </span>
        </div>
      )
    }
  }

  get inputName() {
    return `executioner[executioner_draw_application_products_attributes][${this.props.index}]`
  }

  render() {
    return (
      <div className="mdl-grid block--full u-padding--none">
        {this.itemContent}
        <input type="hidden" name={`${this.inputName}[id]`} value={this.props.item.id} />
        <input
          type="hidden"
          name={`${this.inputName}[draw_application_product_id]`}
          value={this.props.product && this.props.product.id}
        />
        <input
          type="hidden"
          name={`${this.inputName}[priority]`}
          value={this.props.item.priority}
        />
      </div>
    )
  }
}
