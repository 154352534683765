import { DataTable } from '@components/shared'
import { Button, Icon, Switch, Grid, Cell } from 'react-mdl'
import { onKeyActivate } from '@utils'
import useFetch from 'use-http'
const { Expandy } = window
const { useState } = React

const PricingRecordList = ({
  transactionType = {},
  salesChannels = [],
  locationClasses = [],
  productVariations = [],
  endpoint = '',
}) => {
  const { put, response, cache } = useFetch(endpoint, {
    headers: { 'X-CSRF-Token': Fetchable.token },
  })

  const possibleRows = salesChannels
    .filter((salesChannel) => salesChannel.name !== 'All')
    .flatMap((salesChannel, indexOuter) =>
      locationClasses
        .filter((locationClass) => locationClass.name !== 'All')
        .map((locationClass, indexInner) => ({
          id: -1 * (indexOuter * 100 + indexInner * 10 + 1),
          sales_channel: salesChannel.name,
          sales_channel_id: salesChannel.id,
          location_class: locationClass.name,
          location_class_id: locationClass.id,
          className: 'row--disabled',
        }))
    )

  const [expanded, setExpanded] = useState(false)
  const [data, setData] = useState(
    possibleRows.map((row) => {
      let foundRow = productVariations.find(
        (variation) =>
          variation.sales_channel_id === row.sales_channel_id &&
          variation.location_class_id == row.location_class_id
      )
      if (foundRow)
        return {
          ...foundRow,
          sales_channel: row.sales_channel,
          location_class: row.location_class,
        }
      return row
    })
  )

  const updateRow = (row, checked) => {
    const newData = [...data]
    const rowIdx = data.findIndex((r) => r.id === row.id)
    const currentRow = newData[rowIdx]

    currentRow.active = checked
    setData(newData)
  }

  const toggleActivate = (row) => async (e) => {
    updateRow(row, e.target.checked)

    cache.clear()
    const res = await put(`/${row.id}/activate.json`, {
      product_variation: { active: e.target.checked },
    })
  }

  const columns = [
    {
      name: 'sales_channel',
      display: 'Sales Channel',
      numeric: false,
      nosort: false,
    },
    {
      name: 'location_class',
      display: 'Location Class',
      numeric: false,
      nosort: false,
    },
    {
      name: 'customer_price',
      display: 'Base Price',
      numeric: true,
      nosort: true,
    },
    {
      name: 'reservation_fee',
      display: 'Reservation Fee',
      numeric: true,
      nosort: true,
    },
    {
      name: 'active',
      display: 'Enabled',
      nosort: true,
      cellFormatter: (active, row) => {
        return row.id >= 0 ? (
          <Switch
            name="active"
            className="mdl-switch--accent"
            style={{ width: 'auto' }}
            checked={row.active}
            onKeyDown={onKeyActivate((e) => e.target.click())}
            onChange={toggleActivate(row)}
          />
        ) : (
          undefined
        )
      },
    },
    {
      name: 'edit',
      display: 'Edit',
      hideHeader: true,
      nosort: true,
      cellFormatter: (_, row) => {
        return row.id >= 0 ? (
          <Button
            className="mdl-button--icon mdl-button--light u-pull--right"
            aria-label={`Edit Pricing Record ${row.id}`}
            href={`${endpoint}/${row.id}/edit`}>
            <Icon name="edit" className="md-18" aria-hidden="true" />
          </Button>
        ) : (
          <Button
            className="mdl-button--icon mdl-button--accent u-pull--right"
            aria-label={`Create Pricing Record`}
            href={`${endpoint}/new?location_class_id=${row.location_class_id}&sales_channel_id=${row.sales_channel_id}&transaction_type_id=${transactionType.id}`}>
            <Icon name="add" className="md-18" aria-hidden="true" />
          </Button>
        )
      },
    },
  ]

  const loading = false

  const topic = transactionType.transaction_type + ' Pricing Records'

  const onSort = (name, isAscending) => {
    const newData = data.sort((firstEl, secondEl) => {
      if (name === 'sales_channel') {
        return ('' + firstEl.sales_channel).localeCompare(secondEl.sales_channel)
      } else if (name === 'location_class') {
        return ('' + firstEl.location_class).localeCompare(secondEl.location_class)
      }
    })

    if (!isAscending) {
      setData(newData.reverse())
    } else {
      setData(newData)
    }
  }

  return (
    <div>
      <Grid className="mdl-grid--no-spacing u-border--bottom u-padding--x-20 flex__items--center">
        <Cell col={10}>
          <h4 className="u-margin--y-15 text--med">
            {transactionType.transaction_type} ({productVariations.length})
          </h4>
        </Cell>
        <Cell col={2}>
          <Expandy
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
            aria-label={`Expand ${transactionType.transaction_type}`}
            className="u-pull--right"
          />
        </Cell>
      </Grid>

      {expanded ? (
        <DataTable
          topic={topic}
          loading={loading}
          rows={data}
          columns={columns}
          onSort={onSort}
          rowKeyColumn="id"
        />
      ) : null}
    </div>
  )
}

export default PricingRecordList
