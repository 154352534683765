import useFetch from 'use-http'
import { Btn } from '@components/shared'
import ImageUpload from '@components/camp/ImageUpload'
const { useState, Fragment } = React

const HeroImage = ({ name, endpoint, image, imageable }) => {
  const [errors, setErrors] = React.useState(null)
  const { loading, response, ...methods } = useFetch(endpoint, {
    headers: window.__AMS__.formToken,
  })
  const [currentImage, setCurrentImage] = useState(image)

  const addUploadedImage = (image) => {
    setCurrentImage(image)
    setErrors(null)
  }

  const deleteImage = async () => {
    await methods.delete(`/${currentImage.id}`)

    if (response.ok) {
      setCurrentImage(null)
    }
  }

  const backgroundStyle = {
    backgroundImage: currentImage ? `url(${currentImage.image_url})` : undefined,
  }

  return (
    <div className="camp__hero-image" style={backgroundStyle}>
      <div className="d-flex flex__justify--center flex__items--center u-margin--bottom-5">
        <fieldset>
          <legend className="visuallyhidden">
            {currentImage
              ? `Selected cover image name: ${currentImage.image_file_name}`
              : 'No cover image selected'}
          </legend>

          <ImageUpload
            endpoint={endpoint}
            onUpload={addUploadedImage}
            onError={(err) => setErrors(err)}
            label={currentImage ? 'Change Cover Image' : 'Add Cover Image'}
            imageable={imageable}
            id={currentImage?.id}
            category="hero"
          />

          {currentImage ? (
            <Fragment>
              <Btn.Spinner
                aria-label="Remove cover image"
                className="mdl-button--wide mdl-button--wide-centered mdl-button--light"
                onClick={deleteImage}
                loading={loading}>
                Remove
              </Btn.Spinner>
              <input type="hidden" name={name} value={currentImage.id} />
            </Fragment>
          ) : (
            undefined
          )}
        </fieldset>
      </div>

      {errors ? (
        <div className="d-flex flex__justify--center">
          <p className="bg--white text--brightred text--small mdl-shadow--2dp u-border--round u-padding--x-5">
            {errors}
          </p>
        </div>
      ) : (
        undefined
      )}
    </div>
  )
}

export default HeroImage
