import { Grid, Cell, Button } from 'react-mdl'
import useFetch from 'use-http'
import { DataTable, Btn, Modal } from '@components/shared'
import CustomerFormModal from './CustomerFormModal'
import CustomerSearch from './CustomerSearch'
const { Fragment, useState, useMemo, useCallback } = React

const columns = [
  {
    name: 'customer_number',
    display: 'Customer ID',
    colGroupStyle: { width: '5%' },
    nosort: true,
  },
  {
    name: 'full_name',
    display: 'Customer Name',
    nosort: true,
  },
  {
    name: 'dob',
    display: 'Date of Birth',
    nosort: true,
  },
  {
    name: 'work_phone',
    display: 'Phone Number',
    nosort: true,
  },
  {
    name: 'email_address',
    display: 'Email',
    nosort: true,
  },
  {
    name: 'mailing_address',
    display: 'Address',
    nosort: true,
    colGroupStyle: { width: '20%' },
    cellFormatter: (_, row) => <p>{row.mailing_address?.full_address}</p>,
  },
]

const CustomerSelect = ({ endpoint, reservation, reservationPath }) => {
  const { put: updateReservation } = useFetch(`${reservationPath}.json`, {
    headers: window.__AMS__.formToken,
  })
  const [customer, setCustomer] = useState(reservation.customer)
  const [openCustomerForm, setOpenCustomerForm] = useState(false)
  const [openCustomerRemove, setOpenCustomerRemove] = useState(false)

  const cancelUnassign = () => {
    setOpenCustomerRemove(false)
  }

  const customerFormSubmit = (res) => {
    setCustomer(res)
    setOpenCustomerForm(false)
  }

  const addCustomer = useCallback(
    (obj) => async () => {
      // If a customer is already selected, open a modal to confirm
      // removing them
      if (customer) {
        setOpenCustomerRemove(true)
      } else {
        setCustomer(obj)
        await updateReservation('', {
          reservation: { customer_id: obj.id, bypass_booking_validation: true },
        })
      }
    },
    [updateReservation, customer]
  )

  const removeCustomer = useCallback(async () => {
    await updateReservation('', {
      reservation: { customer_id: null, bypass_booking_validation: true },
    })

    setCustomer(null)
    setOpenCustomerRemove(false)
  }, [updateReservation])

  const columnsWithAction = useMemo(
    () => [
      ...columns,
      {
        name: 'add_or_remove',
        hideHeader: true,
        nosort: true,
        cellFormatter: (_, row) => {
          return (
            <Btn.Icon
              type="submit"
              light
              className="u-pull--right"
              name={customer?.id === row.id ? 'close' : 'add'}
              label={`Select Customer ${row.id}`}
              onClick={addCustomer(row)}
            />
          )
        },
      },
    ],
    [customer, addCustomer]
  )

  const firstBooking = reservation.bookings?.[0]

  return (
    <Fragment>
      {customer ? (
        <Fragment>
          <Grid className="p-20">
            <Cell col={6}>
              <p className="text--med text--darkerblue">
                {customer.full_name} <strong>{customer.customer_number}</strong>
              </p>
            </Cell>
            <Cell col={6} className="u--align-right">
              <Button
                type="button"
                raised
                accent
                className="mdl-button--wide"
                onClick={() => setOpenCustomerForm(true)}>
                Edit Customer Details
              </Button>
            </Cell>
          </Grid>
          <DataTable
            topic="Customer Search Results"
            rows={[customer]}
            columns={columnsWithAction}
            rowKeyColumn="id"
          />
        </Fragment>
      ) : (
        <CustomerSearch endpoint={endpoint} columns={columnsWithAction} customer={customer} />
      )}

      <Grid className="p-20">
        <Cell col={12} className="u--align-right">
          <Button
            raised
            accent
            className="mdl-button--wide"
            disabled={customer}
            onClick={() => setOpenCustomerForm(true)}>
            Create New Customer
          </Button>
          <Button
            raised
            className="mdl-button--gray mdl-button--wide"
            disabled={!customer || !firstBooking}
            href={`${reservationPath}/bookings/${firstBooking?.id}/edit`}>
            Continue
          </Button>
        </Cell>
      </Grid>

      <Modal
        open={openCustomerRemove}
        id="unassign_customer"
        onClose={cancelUnassign}
        onCancel={cancelUnassign}>
        <Modal.Title id="unassign_customer">Unassign Customer</Modal.Title>
        <Modal.Content>
          <p className="text--med">
            Are you sure you wish to{' '}
            <strong className="text--brightred">unassign this customer</strong>?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={removeCustomer}>
            Yes Unassign this Customer
          </Button>
          <Button className="mdl-button--light" onClick={cancelUnassign}>
            Cancel this Action
          </Button>
        </Modal.Actions>
      </Modal>

      {openCustomerForm ? (
        <CustomerFormModal
          customer={customer}
          customersPath={endpoint}
          cancel={() => setOpenCustomerForm(false)}
          submit={customerFormSubmit}
        />
      ) : null}
    </Fragment>
  )
}

export default CustomerSelect
