import { Button, Icon, Textfield } from 'react-mdl'
import IsolatedScroll from 'react-isolated-scroll'
import { MapViewContext, DROP_TYPES } from '@components/camp/parks/MapView/mapViewContext'
import { draggableFacilityPin } from '@components/camp/parks/MapView/mapIcons'
import { NpmapIcon } from '@components/shared'
import { sanitizedStringRegex } from '@utils'

const { observer } = mobxReactLite
const { useState, useEffect, useRef, useContext, Fragment } = React

const Toolbar = observer(() => {
  const [menuOpen, setMenuOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setMenuOpen])

  return (
    <Fragment>
      <div className="u-absolute--center map-view__edit-mode-border" aria-hidden />
      <div ref={ref} className="map-view__toolbar bg--white p-20 u-border--round mdl-shadow--2dp">
        <Button
          onClick={() => setMenuOpen((curr) => !curr)}
          className={menuOpen ? 'text--lightblue' : ''}>
          Add Facility{' '}
          <Icon className="m-0" name={menuOpen ? 'arrow_drop_down' : 'arrow_drop_up'} />
        </Button>
        {menuOpen ? <FacilitiesMenu setMenuOpen={setMenuOpen} /> : null}
      </div>
    </Fragment>
  )
})

const FacilitiesMenu = ({ setMenuOpen }) => {
  const store = useContext(MapViewContext)
  const [facilities, setFacilities] = useState(store.park.all_facilities)

  const searchFacilities = (e) => {
    const items = [...facilities]
    const regex = sanitizedStringRegex(e.target.value)

    if (regex) {
      items.forEach((f) => {
        f.hidden = !regex.test(f.name)
      })
    } else {
      items.forEach((f) => (f.hidden = false))
    }

    setFacilities(items)
  }

  const onDragStart = (item) => (e) => {
    const { el, offset } = draggableFacilityPin(item.icon_name)
    e.dataTransfer.setDragImage(el, offset.x, offset.y)
    e.dataTransfer.setData('text/plain', JSON.stringify({ id: item.id, type: DROP_TYPES.FACILITY }))
  }

  return (
    <div className="map-view__toolbar-menu bg--white u-border--round mdl-shadow--2dp">
      <div className="map-view__toolbar-menu__input bg--lightgray px-20 pt-10">
        <Textfield
          floatingLabel
          label="Search Facilities"
          className="textfield__input--full always-floating-label"
          placeholder="Facility Name"
          defaultValue=""
          onChange={searchFacilities}
        />
      </div>
      <IsolatedScroll className="map-view__toolbar-menu__list">
        <ul className="mdl-list m-0">
          {facilities.map((f) => (
            <li
              key={f.id}
              className={`map-view__toolbar-menu__list-item ${f.hidden ? 'hidden' : ''}`}>
              <span role="button" tabIndex="0" onDragStart={onDragStart(f)} draggable="true">
                <NpmapIcon name={f.icon_name} className="mr-5" /> {f.name}
              </span>
            </li>
          ))}
        </ul>
      </IsolatedScroll>
    </div>
  )
}

export default Toolbar
