import { useState } from 'react'
import WaiverQuestionFields from './WaiverQuestionFields'

const WaiverQuestions = ({ questions, questionError }) => {
  // Each question much have a unique index to use in form submission
  const questionsWithIndex = questions.map((question, idx) => ({
    ...question,
    question_index: idx,
  }))

  const [questionsList, setQuestionsList] = useState(questionsWithIndex)
  const checkboxQuestions = questionsList.filter((q) => q.input_type === 'checkbox')
  const checkboxQuestionLength = checkboxQuestions.filter((q) => !q._destroy).length
  const textQuestions = questionsList.filter((q) => q.input_type === 'text')
  const addNewQuestion = (questionType) => {
    const questionIndex = questionsList.length
    setQuestionsList([
      ...questionsList,
      { input_type: questionType, question_index: questionIndex, errors: [] },
    ])
  }
  const removeQuestion = (question_index) => {
    const newQuestions = questionsList.map((q) => {
      if (q.question_index === question_index) {
        q._destroy = true
      }
      return q
    })
    setQuestionsList(newQuestions)
  }

  return (
    <div className="mdl-grid block--full p-0 mt-10">
      <div className="mdl-cell mdl-cell--3-col">
        <dt className="text--med text--normal">
          {I18n.t('events.views.program_waiver_settings.accepting_terms')}
        </dt>
      </div>
      <div className="mdl-cell mdl-cell--9-col">
        {checkboxQuestions.map((question, idx) => (
          <WaiverQuestionFields
            key={`checkbox-${idx}`}
            question={question}
            removeQuestion={removeQuestion}
            checkboxQuestionLength={checkboxQuestionLength}
          />
        ))}
        <button
          type="button"
          className="mdl-button mdl-js-button mdl-button--transparent px-10 mb-20"
          onClick={() => addNewQuestion('checkbox')}>
          {I18n.t('events.views.program_waiver_settings.add_checkbox')}
          <i className="material-icons pl-10 mr-0 text--med-lg" aria-hidden="true">
            add
          </i>
        </button>
        <br />
        {textQuestions.map((question, idx) => (
          <WaiverQuestionFields
            key={`text-${idx}`}
            question={question}
            removeQuestion={removeQuestion}
            checkboxQuestionLength={checkboxQuestionLength}
          />
        ))}
        <button
          type="button"
          className="mdl-button mdl-js-button mdl-button--transparent px-10"
          onClick={() => addNewQuestion('text')}>
          {I18n.t('events.views.program_waiver_settings.add_text_field')}
          <i className="material-icons pl-10 mr-0 text--med-lg" aria-hidden="true">
            add
          </i>
        </button>
        {questionError ? (
          <span className="mdl-textfield__error u-visible">{questionError}</span>
        ) : null}
      </div>
    </div>
  )
}

export default WaiverQuestions
