// Inspired by https://github.com/beizhedenglong/react-hooks-lib
const { useEffect, useRef } = React

export const useDidUpdate = (f, conditions) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }

    // Cleanup effects when f returns a function
    return f && f() //eslint-disable-line
  }, conditions) //eslint-disable-line
}
