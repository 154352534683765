import { Btn } from '@components/shared'
const { Fragment, useMemo } = React

const ImageUpload = ({
  id = null,
  endpoint,
  label,
  imageable = {},
  category = null,
  onUpload = () => {},
  onError = () => {},
}) => {
  const [loading, setLoading] = React.useState(false)
  const hiddenFileInput = React.useRef(null)
  const formMethod = id ? 'PUT' : 'POST'
  const formEndpoint = id ? `${endpoint}/${id}` : endpoint

  const formData = useMemo(() => {
    const data = new FormData()

    if (imageable.id) {
      data.append('image[imageable_id]', imageable.id)
      data.append('image[imageable_type]', imageable.type)
    }

    if (category) {
      data.append('image[image_category]', category)
    }

    return data
  }, [category, imageable])

  const uploadImage = (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }

    setLoading(true)
    formData.append('image[image]', file)

    Fetchable.sendForm(formEndpoint, formMethod, formData)
      .then((res) => res.json())
      .then((response) => {
        if (response && response.errors) {
          onError(response.errors)
        } else {
          onUpload(response.file)
        }
      })
      .catch((res) => alert(res))
      .finally(() => {
        setLoading(false)

        formData.delete('image[image]')
        hiddenFileInput.current.value = null
      })
  }

  const openFileBrowser = () => {
    hiddenFileInput.current.click()
  }

  return (
    <React.Fragment>
      <Btn.Spinner
        loading={loading}
        type="button"
        accent
        onClick={openFileBrowser}
        className="mdl-button--wide mdl-button--wide-centered">
        {label ? label : <Fragment>Upload Image</Fragment>}
      </Btn.Spinner>

      <input type="file" ref={hiddenFileInput} onChange={uploadImage} style={{ display: 'none' }} />
    </React.Fragment>
  )
}

export default ImageUpload
