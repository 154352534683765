import { Button } from 'react-mdl'
import { Modal } from '@components/shared'
const { useState } = React

const ConfirmDeactivate = ({ location, statuses, modalTitle, modalContent }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [status, setStatus] = useState(location.status)
  const statusInputName = (name) => `program_location[${name}]`

  const cancel = (e) => {
    setModalOpen(false)
    e.preventDefault()
  }

  const openModalOnInactive = (e) => {
    setModalOpen(true)
  }

  const cancleDeactivate = (e) => {
    setStatus('active')
    setModalOpen(false)
    e.preventDefault()
  }

  const deactivate = (e) => {
    e.preventDefault()
    setModalOpen(false)
  }

  return (
    <>
      <input type="hidden" name={statusInputName('id')} value={location.status.id || ''} />
      <MdlSelect
        label="Status"
        name={statusInputName('status')}
        options={statuses}
        selected={status}
        onOptionSelected={(option) => {
          if (option.id === 'inactive') {
            openModalOnInactive()
            setStatus(option.id)
          }
        }}
      />
      <Modal open={modalOpen} id="confirm_delete" onClose={cancel} onCancel={cancel}>
        <Modal.Title id="confirm_delete">{modalTitle}</Modal.Title>
        <Modal.Content>{modalContent}</Modal.Content>
        <Modal.Actions>
          <Button type="buton" className="mdl-button--gray" onClick={deactivate}>
            Yes
          </Button>
          <Button type="buton" className="mdl-button--light" onClick={cancleDeactivate}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ConfirmDeactivate
