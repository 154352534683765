import { Textfield } from 'react-mdl'
import { formatCurrency, getNumeric, positiveFractionRegex } from '@utils'

const { useEffect, useState } = React

const getFormatSign = (format) => {
  switch (format) {
    case 'currency':
      return '$'
    case 'percent':
      return '%'
    default:
      return ''
  }
}

const formatValue = (value, format, maxValue) => {
  const newValue = maxValue && getNumeric(value) > getNumeric(maxValue) ? maxValue : value

  return format === 'currency' ? formatCurrency(newValue) : `${getNumeric(newValue)}`
}

const NumericTextfield = ({
  name,
  label,
  floatingLabel,
  value,
  maxValue,
  onFormat,
  className = '',
  format,
  error,
  ...inputProps
}) => {
  const [textfieldValue, setTextfieldValue] = useState('')

  // Format to currency on initial render
  useEffect(() => {
    const formattedValue = format === 'currency' ? formatCurrency(value) : `${getNumeric(value)}`
    onFormat(formattedValue)
  }, [format, onFormat, value])

  // Textfield gets updated when value changes or is formatted
  useEffect(() => {
    setTextfieldValue(value)
  }, [value])

  const handleChange = (event) => {
    // Only accept numeric values and decimal
    if (!positiveFractionRegex.test(event.target.value)) {
      return
    }

    setTextfieldValue(event.target.value)
  }

  // Format to currency on blur
  const handleBlur = (event) => {
    const { value: newValue } = event.target
    const formattedValue = formatValue(newValue, format, maxValue)

    onFormat(formattedValue)
    setTextfieldValue(formattedValue)
  }

  const handleFocus = (event) => {
    if (textfieldValue === '0.00' || textfieldValue === '0') {
      setTextfieldValue('')
    }
  }

  return (
    <div className={`numeric-textfield ${className}`}>
      <Textfield
        label={label}
        name={name}
        floatingLabel={floatingLabel}
        value={textfieldValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={error}
        {...inputProps}
      />
      {format && <span className="numeric-textfield__format-sign">{getFormatSign(format)}</span>}
    </div>
  )
}

export default NumericTextfield
