import CampaignExpandableTable from './CampaignExpandableTable'

const CampaignContentTable = ({ emailTemplate, emailTemplateUrl }) => {
  const columns = [
    {
      name: 'Name',
      width: '60%',
      cellFormatter: (item) => (
        <a
          href={`${emailTemplateUrl}/${item.id}/edit?section=edit`}
          className="text--med-lg text--bold">
          {item.name}
        </a>
      ),
    },
    {
      name: 'Created',
      width: '15%',
      cellFormatter: (item) => (
        <span className="text--med">
          {moment(item.created_at)
            .tz(window.timeZone)
            .format('MM/DD/YYYY')}
        </span>
      ),
    },
    {
      name: 'Last Updated',
      width: '15%',
      cellFormatter: (item) => (
        <span className="text--med">
          {moment(item.updated_at)
            .tz(window.timeZone)
            .format('MM/DD/YYYY hh:mmA')}
        </span>
      ),
    },
  ]

  return (
    <CampaignExpandableTable item={emailTemplate} columns={columns} expandTarget="email-preview" />
  )
}

export default CampaignContentTable
