// MdlSelect is global
import { Grid, Cell, Button, Checkbox } from 'react-mdl'
import ChargeSplitFieldset from '../ChargeSplitFieldset'
import SurchargeFieldset from '../SurchargeFieldset'
import { NumericTextfield } from '@components/shared'
import { useCsrfToken } from '@hooks'
import { formatCurrency, formatTitleCase, generateErrors } from '@utils'

const { useState, Fragment } = React

const RegistrationPricingForm = ({
  name,
  url,
  cancelPath,
  method,
  errors: nestedErrors = {},
  productVariation = {},
  transactionTypes = [],
  salesChannels = [],
  accountFunds = [],
  surcharges = [],
}) => {
  const errors = generateErrors(nestedErrors) || {}
  const { charges = [] } = productVariation || {}
  const { product_variation_surcharges: initialSurcharges = [] } = productVariation || {}
  const csrfToken = useCsrfToken()
  const overrideableDropdownOptions = [
    { id: 0, value: false, name: 'No' },
    { id: 1, value: true, name: 'Yes' },
  ]

  const [isOverrideablePrice, setIsOverrideablePrice] = useState(
    productVariation.is_overrideable_price
  )

  const [selectedTransactionType, setSelectedTransactionType] = useState(
    transactionTypes.find((option) => option.id === productVariation.transaction_type_id)
  )
  const [selectedSalesChannel, setSelectedSalesChannel] = useState(
    salesChannels.find((option) => option.id === productVariation.sales_channel_id)
  )
  const [contractorFee, setContractorFee] = useState(
    formatCurrency(productVariation.contractor_fee)
  )

  const [selectedOverrideable, setSelectedOverrideable] = useState(
    overrideableDropdownOptions.find(
      (option) => option.value === productVariation.is_overrideable_price
    )
  )

  const [minAmount, setMinAmount] = useState(formatCurrency(productVariation.min_amount))

  const [maxAmount, setMaxAmount] = useState(formatCurrency(productVariation.max_amount))

  const handleOptionSelected = (setSelected) => (value) => {
    setSelected(value)
  }

  const handleMinAmountFormat = (value) => {
    setMinAmount(value)
  }

  const handleMaxAmountFormat = (value) => {
    setMaxAmount(value)
  }

  const handleContractorFeeFormat = (value) => {
    setContractorFee(value)
  }

  const handleOverrideable = (setSelected) => (value) => {
    if (value.id === 0) {
      setMinAmount('0.0')
      setMaxAmount('0.0')
      setIsOverrideablePrice(false)
    } else {
      setIsOverrideablePrice(true)
    }
    setSelected(value)
  }

  return (
    <form id={`${name}-form`} action={url} acceptCharset="UTF-8" method="post">
      <Grid className="pl-20 pt-20">
        <input type="hidden" name="_method" value={method} />
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <Cell col={2}>
          <input type="hidden" name={`${name}[active]`} value="0" />
          <Checkbox
            label="Active"
            name={`${name}[active]`}
            defaultChecked={productVariation.active}
            value="1"
          />
        </Cell>
        <Cell col={12}>
          <MdlSelect
            label="Transaction Type"
            name={`${name}[transaction_type_id]`}
            options={transactionTypes}
            nameField="transaction_type"
            selected={selectedTransactionType}
            onOptionSelected={handleOptionSelected(setSelectedTransactionType)}
            className="always-floating-label"
            error={errors.transaction_type_id}
          />
        </Cell>
        <Cell col={12}>
          <MdlSelect
            label="Sales Channel"
            name={`${name}[sales_channel_id]`}
            options={salesChannels}
            selected={selectedSalesChannel}
            onOptionSelected={handleOptionSelected(setSelectedSalesChannel)}
            className="always-floating-label"
            error={errors.sales_channel_id}
          />
        </Cell>
        <Cell col={12}>
          <MdlSelect
            label="Allow instructors to edit this pricing record?"
            name={`${name}[is_overrideable_price]`}
            options={overrideableDropdownOptions}
            selected={selectedOverrideable}
            onOptionSelected={handleOverrideable(setSelectedOverrideable)}
            className="always-floating-label"
            error={errors.is_overrideable_price}
          />
        </Cell>
      </Grid>
      {isOverrideablePrice ? (
        <Grid className="pl-20">
          <Cell col={2}>
            <NumericTextfield
              label="Minimum Price Set Limit"
              name={`${name}[min_amount]`}
              floatingLabel
              value={minAmount}
              onFormat={handleMinAmountFormat}
              format="currency"
              error={errors.min_amount}
            />
          </Cell>
          <Cell col={2}>
            <NumericTextfield
              label="Maximum Price Set Limit"
              name={`${name}[max_amount]`}
              floatingLabel
              value={maxAmount}
              onFormat={handleMaxAmountFormat}
              format="currency"
              error={errors.max_amount}
            />
          </Cell>
        </Grid>
      ) : (
        <Fragment>
          <input type="hidden" name={`${name}[min_amount]`} value="0" />
          <input type="hidden" name={`${name}[max_amount]`} value="0" />
        </Fragment>
      )}

      <div className="u-padding--x-30">
        <h2 className="text--med-lg u-margin--none">Base Price</h2>
      </div>

      {!!charges &&
        charges.map((charge, index) => {
          const inputName = `${name}[charges_attributes][${index}]`
          return (
            <Fragment key={charge.charge_type_cd}>
              <input type="hidden" name={`${inputName}[id]`} value={charge.id || ''} />
              <input type="hidden" name={`${inputName}[charge_type]`} value={charge.charge_type} />
              <ChargeSplitFieldset
                charge={charge}
                chargeType={charge.charge_type}
                chargeName="charge"
                chargeCanBePercent={false}
                // Using hardcoded base price label for event pricing
                // records with a charge type of "state amount"
                label={I18n.t('events.views.program_events.base_price')}
                name={inputName}
                accountFunds={accountFunds}
                className="u-margin--bottom-20"
                errors={errors.charges && errors.charges[index]}
              />
            </Fragment>
          )
        })}

      <Grid className="u-padding--x-20">
        <Cell col={3}>
          <NumericTextfield
            label="Contractor Fee (if applicable)"
            name={`${name}[contractor_fee]`}
            floatingLabel
            value={contractorFee}
            onFormat={handleContractorFeeFormat}
            format="currency"
            error={errors.contractor_fee}
          />
        </Cell>

        <Cell col={12}>
          <h2 className="text--med-lg u-margin--none">Taxes</h2>
          <SurchargeFieldset
            name={`${name}[product_variation_surcharges_attributes]`}
            surcharges={surcharges}
            initialSurcharges={initialSurcharges}
            taxOnly
          />
        </Cell>

        <Cell col={12} className="d-flex flex__justify--end">
          <Button href={cancelPath} className="mdl-button--light mdl-button--wide">
            Cancel
          </Button>
          <Button
            type="submit"
            className="mdl-button--gray mdl-button--wide mdl-button--wide-centered">
            Save
          </Button>
        </Cell>
      </Grid>
    </form>
  )
}

export default RegistrationPricingForm
