import ActivityTypes from './ActivityTypes'
import AppRequirements from './AppRequirements'

const { ArrayUtils, componentHandler } = window

class HasMany extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      records: this.props.records || [{}],
    }

    this.addRecord = this.addRecord.bind(this)
    this.removeRecord = this.removeRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)

    this.associations = {
      activity_type: ActivityTypes,
      app_requirement: AppRequirements,
    }
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
  }

  addRecord(e) {
    e.preventDefault()
    this.setState({
      records: ArrayUtils.arrayAdd({}, this.state.records),
    })
  }

  removeRecord(idx) {
    this.setState({
      records: ArrayUtils.arrayDelete(idx, this.state.records),
    })
  }

  editRecord(idx, record) {
    this.setState({
      records: ArrayUtils.arrayUpdate(idx, this.state.records, record),
    })
  }

  get addRecordButton() {
    return (
      <div className="mdl-card__actions--right">
        <button className="mdl-button mdl-js-button mdl-button--colored" onClick={this.addRecord}>
          {this.props.addRecordText}
        </button>
      </div>
    )
  }

  render() {
    const COMPONENT = this.associations[this.props.association]
    return (
      <div>
        <div className="mdl-cell mdl-cell--12-col">
          {this.state.records.map((record, idx) => (
            <COMPONENT
              key={`record-${idx}`}
              index={idx}
              idx={idx}
              record={record}
              recordCount={this.state.records.length}
              collections={this.props.collections}
              removeRecord={this.removeRecord}
              onChange={this.editRecord}
            />
          ))}
        </div>
        <div className="mdl-cell mdl-cell--12-col">{this.addRecordButton}</div>
      </div>
    )
  }
}

export default HasMany
