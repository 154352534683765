import { Button, Cell, Grid, Textfield } from 'react-mdl'
import { FilterChip } from '@components/shared'
import { useDidUpdate } from '@hooks'

const { useEffect, useState, useRef } = React

const propTypes = {
  // Event handler when search query is submitted. Takes array of search query strings as param
  onSearch: PropTypes.func.isRequired,

  // Request data on component mount
  searchOnMount: PropTypes.bool,

  // Pre-populate search filters
  searchFilters: PropTypes.array,

  // Placeholder text for search input
  placeholder: PropTypes.string,

  // Text underneath search input
  helperText: PropTypes.string,
}

const SearchForm = ({
  onSearch,
  searchOnMount = false,
  searchFilters: initSearchFilters = [],
  placeholder,
  helperText,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [searchFilters, setSearchFilters] = useState(initSearchFilters)

  // Always call onSearch if filters are updated after first render
  useDidUpdate(() => onSearch(searchFilters), [searchFilters])
  // Optionally call onSearch on first render
  if (searchOnMount) {
    useEffect(() => onSearch(searchFilters), [])
  }

  const isIncludedInSearchFilters = (value) =>
    searchFilters.map((filter) => filter.toUpperCase()).includes(value.toUpperCase())

  const handleChange = (event) => {
    setSearchInput(event.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    const trimmedSearchInput = searchInput.trim()
    if (trimmedSearchInput.length) {
      if (!isIncludedInSearchFilters(trimmedSearchInput)) {
        setSearchFilters([...searchFilters, trimmedSearchInput])
      }
      setSearchInput('')
    }
  }

  const handleClearFilters = () => {
    setSearchFilters([])
  }

  const handleRemoveFilter = (filterToRemove) => {
    setSearchFilters(searchFilters.filter((item) => item !== filterToRemove))
  }

  return (
    <Grid noSpacing className="search-form">
      <Cell col={8} tablet={12} className="search-form__textfield-container">
        <Textfield
          label={placeholder}
          className="search-form__textfield"
          value={searchInput}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <div className="search-form__helper-text-container">
          <span className="search-form__helper-text">{helperText}</span>
        </div>
      </Cell>

      <Cell col={4} tablet={12} className="search-form__buttons-container">
        <div className="search-form__buttons">
          <Button
            ripple
            className="search-form__button mdl-button--gray"
            onClick={handleSearch}
            disabled={!searchInput.trim().length}>
            Search
          </Button>
          {!!searchFilters.length && (
            <Button className="search-form__button mdl-button--light" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          )}
        </div>
      </Cell>

      <Cell col={12} className="search-form__filter-chips">
        {!!searchFilters.length ? <h2 className="visuallyhidden">Current Filters</h2> : undefined}
        {searchFilters.map((filter) => (
          <FilterChip key={filter} filter={filter} onRemove={handleRemoveFilter} />
        ))}
      </Cell>
    </Grid>
  )
}

SearchForm.propTypes = propTypes
export default SearchForm
