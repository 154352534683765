import { RadioGroup, Radio } from 'react-mdl'

const RadioCollection = ({ options, name, value, label, disabled, className = '', onChange }) => {
  return (
    <fieldset className={className}>
      <legend className={`text--small ${disabled ? 'text--lightgray' : 'text--darkblue'}`}>
        {label}
      </legend>
      <RadioGroup name={name} value={value} className="mdl-radio-collection" onChange={onChange}>
        {options.map((option, idx) => {
          return (
            <Radio key={`mdl-radio-${idx}`} value={option.value} disabled={disabled}>
              {option.name}
            </Radio>
          )
        })}
      </RadioGroup>
    </fieldset>
  )
}

export default RadioCollection
