import { Modal } from '@components/shared'
import { Button, Cell, Grid } from 'react-mdl'

const { useState } = React

const AddQuestionModal = ({
  assignedQuestions,
  detailType,
  details,
  setAssignedQuestions,
  event,
  disableButton,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [questionId, setQuestionId] = useState(null)

  // assignedIds is what should not be in the list exclude any questions that are marked as destroyed
  const assignedIds = assignedQuestions
    .filter((q) => !q._destroy)
    .map((q) => q[`${detailType}_detail_question_id`])
  const availableQuestions = details[detailType].filter((q) => assignedIds.indexOf(q.id) < 0)

  let detailOptions = availableQuestions.map((question, i) => (
    <option key={i + 1} value={question.id}>
      {question.question}
    </option>
  ))

  detailOptions.unshift(
    <option key={0} value="0">
      {I18n.t('helpers.select.prompt')} {I18n.t('activerecord.models.question')}
    </option>
  )

  const cancel = (e) => {
    e.preventDefault()
    setModalOpen(false)
  }

  const handleAddQuestion = (e) => {
    e.preventDefault()

    const question = details[detailType].filter((q) => q.id === questionId)[0]
    if (question == null) return

    // If a _destroyed record exists use that same id otherwise create a new record

    const questionIdKey = `${detailType}_detail_question_id`
    const existingQuestionId = assignedQuestions.find((q) => q[questionIdKey] === question.id)
    const newAssignedQuestion = {
      id: existingQuestionId && existingQuestionId.id,
      events_program_id: event.id,
      priority: assignedQuestions.length,
      question: question.question,
      instructor_type: question.instructor_type,
      required: question.required,
      [questionIdKey]: question.id,
    }
    setModalOpen(false)

    setAssignedQuestions([
      ...assignedQuestions.filter((q) => q[questionIdKey] !== question.id),
      newAssignedQuestion,
    ])
  }

  return (
    <React.Fragment>
      <Grid className="px-0">
        <Cell col={6} className="ml-0">
          <h2 className="text--med-lg my-0">
            {I18n.t('activerecord.models.events/registration_detail_question')}
          </h2>
        </Cell>
        <Cell col={6}>
          <Button
            onClick={() => {
              setQuestionId(0)
              setModalOpen(true)
            }}
            disabled={disableButton}
            type="button"
            className="mdl-button--wide mdl-button--wide-centered mdl-button--gray u-pull--right ">
            {I18n.t('events.views.program_events.add_detail_question_button')}
          </Button>
        </Cell>
      </Grid>
      <Modal open={modalOpen} id="action_item" onClose={cancel} onCancel={cancel}>
        <Modal.Title id="action_item">
          {I18n.t('events.views.program_events.add_detail_question_modal_title')}
        </Modal.Title>
        <Modal.Content className="u-padding--bottom-50 text--med">
          <div className="mdl-cell--12-col">
            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label textfield__input--full select">
              <select
                className="mdl-textfield__input select"
                name="question"
                onBlur={(e) => setQuestionId(parseInt(e.target.value))}>
                {detailOptions}
              </select>
              <label className="mdl-textfield__label select control-label" htmlFor="question">
                {I18n.t('activerecord.models.question')}
              </label>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={handleAddQuestion}>
            {I18n.t('common.submit')}
          </Button>
          <Button className="mdl-button--light" onClick={cancel}>
            {I18n.t('common.cancel')}
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export default AddQuestionModal
