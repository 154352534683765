import React, { Fragment, useState } from 'react'
import { Cell, Grid, Textfield } from 'react-mdl'

const findFieldOption = (fieldId, collection) => {
  return collection.find((o) => parseInt(o.mappable_field_id) === parseInt(fieldId))
}

const CertificateFieldMappings = ({ fieldMappings, fieldMappingOptions, errors = [] }) => {
  const flatOptions = fieldMappingOptions.flatMap((o) => o.options)
  const [mappings] = useState(fieldMappings)

  return (
    <Grid className="mdl-grid--nested">
      {mappings.map((mapping, i) => (
        <CertificateFieldMapping
          mapping={mapping}
          optionGroups={fieldMappingOptions}
          flatOptions={flatOptions}
          errors={errors[i]}
          i={i}
          key={`mapping_${i}`}
        />
      ))}
    </Grid>
  )
}

const CertificateFieldMapping = ({ mapping, optionGroups, flatOptions, errors, i }) => {
  const initSelected = findFieldOption(mapping.mappable_field_id, flatOptions)
  const [selected, setSelected] = useState(initSelected)
  const inputName = (name) =>
    `program_certification_settings[certificate_field_mappings_attributes][${i}][${name}]`
  const onBlur = (e) => {
    setSelected(findFieldOption(e.target.value, flatOptions))
  }

  let selectClassName = ''
  if (errors['mappable_field']) {
    selectClassName += 'is-invalid'
  }

  return (
    <Fragment>
      {mapping.id ? <input type="hidden" name={inputName('id')} value={mapping.id} /> : null}
      {selected ? (
        <input
          type="hidden"
          name={inputName('mappable_field_type')}
          value={selected.mappable_field_type}
        />
      ) : null}

      <Cell col={4}>
        <Textfield
          floatingLabel
          label={I18n.t('events.views.event_registration_certificates.field_name')}
          name={inputName('field_name')}
          id={`${i}_field_name`}
          defaultValue={mapping.field_name}
          className="textfield__input--full"
          error={errors['field_name']}
        />
      </Cell>
      <Cell col={8}>
        <div
          className={`mdl-textfield mdl-js-textfield mdl-textfield--floating-label ${selectClassName}`}>
          <select
            className="mdl-textfield__input select"
            name={inputName('mappable_field_id')}
            id={`${i}_field_name`}
            onBlur={onBlur}
            defaultValue={selected?.mappable_field_id || ''}>
            <option key={-1} value=""></option>
            {optionGroups.map((group, i) => (
              <optgroup label={group.group_label} key={`${i}_${group.group_key}`}>
                {group.options.map((opt, j) => (
                  <option key={`${i}_${group.group_key}_${j}`} value={opt.mappable_field_id}>
                    {opt.label}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>

          <label className="mdl-textfield__label" htmlFor={`${i}_field_name`}>
            {I18n.t('events.views.event_registration_certificates.field_value')}
          </label>

          {errors['mappable_field'] && (
            <span className="mdl-textfield__error">{errors['mappable_field']}</span>
          )}
        </div>
      </Cell>
    </Fragment>
  )
}

export default CertificateFieldMappings
