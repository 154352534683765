import { RadioCollection, Well } from '@components/shared'
import { Cell, Grid } from 'react-mdl'

const StatusRadioCollection = ({ label, name, options, value, disabled, isNew }) => {
  const [selection, setSelection] = React.useState(value)

  const handleChange = (e) => {
    setSelection(e.target.value)
  }

  return (
    <Grid className="u-padding--left-20">
      <Cell col={4}>
        <RadioCollection
          label={label}
          name={name}
          options={options}
          value={selection}
          onChange={handleChange}
          disabled={disabled}
        />
      </Cell>
      {selection === '1' && !isNew && (
        <Cell col={4}>
          <div className="bg--lightgray text--med-sm text--lightgray u-padding--10 u-margin--top-8 u-margin--left-20">
            This park is searchable on storefront.
          </div>
        </Cell>
      )}
      {isNew === true && (
        <Cell col={5}>
          <Well
            icon={'warning'}
            iconClass={'text--darkyellow'}
            content={`The park must have sites, a Vendor Location ID,
            and Users assigned to the park to be active.
            This park cannot be searched on the storefront until active.`}
          />
        </Cell>
      )}
    </Grid>
  )
}

export default StatusRadioCollection
