import { NumericTextfield } from '@components/shared'
import { formatCurrency, getNumeric } from '@utils'
const { Fragment, useState } = React

const EventPricing = ({ prices, edit }) => {
  const [paidEvent, setPaidEvent] = useState(prices.length !== 0)
  const toggleVariablePrice = () => {
    setPaidEvent(!paidEvent)
  }

  return (
    <Fragment>
      <label className="mdl-checkbox mdl-js-checkbox" key="price-toggle-checkbox-key">
        <input type="hidden" name={'program_event[paid_event]'} value="0" />
        <input
          id="program_event[paid_event]"
          name="program_event[paid_event]"
          className="mdl-checkbox__input"
          type="checkbox"
          onChange={toggleVariablePrice}
          value="1"
          checked={paidEvent}
          disabled={edit}
        />
        <span className="mdl-checkbox__label">
          {I18n.t('events.views.program_events.paid_event')}
        </span>
      </label>

      {paidEvent ? (
        <>
          <h2 className="text--med-lg my-5">{I18n.t('events.views.program_events.base_price')}</h2>
          <p>{I18n.t('events.views.program_events.event_pricing_text')}</p>
          {prices.map((price, idx) => {
            const baseName = `program_event[program_event_prices_attributes][${idx}]`
            const inputValue = price.overridable
              ? price.override_amount || price.default_amount
              : price.default_amount
            return (
              <Fragment key={baseName}>
                <NumericTextfield
                  label={priceLabel(price)}
                  id={`${baseName}[override_amount]`}
                  name={`${baseName}[override_amount]`}
                  value={formatCurrency(inputValue)}
                  disabled={!price.overridable}
                  error={price.overridable ? priceError(price) : null}
                  format="currency"
                  floatingLabel
                  onFormat={() => {}}
                />
                <input type="hidden" name={`${baseName}[id]`} value={price.id || ''} />
                <input
                  type="hidden"
                  name={`${baseName}[product_variation_id]`}
                  value={price.product_variation_id}
                />
                <input type="hidden" name={'program_event[paid_event]'} value="1" />
              </Fragment>
            )
          })}
        </>
      ) : null}
    </Fragment>
  )
}

const minMax = (price) =>
  I18n.t('events.views.program_events.event_pricing_between', {
    min: formatCurrency(price.min_amount),
    max: formatCurrency(price.max_amount),
  })

const priceLabel = (price) => {
  return `${I18n.t('events.views.program_events.base_price')} ${
    price.overridable ? `(${minMax(price)})` : ''
  }`
}

const priceError = (price) => {
  const priceValue = getNumeric(price.override_amount || price.default_amount)
  const minValue = getNumeric(price.min_amount)
  const maxValue = getNumeric(price.max_amount)

  if (priceValue < minValue || priceValue > maxValue) {
    return minMax(price)
  }
}

export default EventPricing
