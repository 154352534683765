import { formatCurrency, generateUuid, getNumeric, getPercent, getSum } from '@utils'
const { useCallback, useReducer, createRef } = React

export const DOLLAR = '0'
export const PERCENT = '1'

const SET_CHARGE_AMOUNT = 'SET_CHARGE_AMOUNT'
const SET_SPLIT_AMOUNT = 'SET_SPLIT_AMOUNT'
const SET_SPLIT_METHOD = 'SET_SPLIT_METHOD'
const SET_ACCOUNT_FUND_ID = 'SET_ACCOUNT_FUND_ID'
const ADD_SPLIT = 'ADD_SPLIT'
const REMOVE_SPLIT = 'REMOVE_SPLIT'

// Reset split amount values to 0.00 so that customer knows to make a change and
// so they can make the change easier
const shouldResetSplitValues = (chargeAmount, splitAmounts) => {
  const hasSomeEmptyValues = splitAmounts.some((amount) => amount === '0.00')
  const splitSum = getSum(...splitAmounts)

  return (
    // If the total charge amount is greater than the sum of the splits AND there are no empty values
    (getNumeric(chargeAmount) > splitSum && !hasSomeEmptyValues) ||
    // OR if the total charge amount is less than the sum of the splits
    getNumeric(chargeAmount) < splitSum
  )
}

const chargeSplitReducer = (state, action) => {
  const { chargeAmount, splits, selectedSplitMethod } = state

  switch (action.type) {
    case SET_CHARGE_AMOUNT: {
      const newChargeAmount = action.value
      const splitAmounts = splits.map((split) => split.amount)
      const splitPercentages = splits.map((split) => split.percent)
      const hasSomeEmptyValues = splitAmounts.some((amount) => amount === '0.00')
      const percentSum = getSum(...splitPercentages)

      // If the split method is percent AND the sum of split percentages equal 100 AND there are no empty values
      if (selectedSplitMethod === PERCENT && percentSum === 100 && !hasSomeEmptyValues) {
        // Then use the split percentage to calculate and set the split amount
        const newSplits = splits.map((split) => ({
          ...split,
          amount: formatCurrency((getNumeric(split.percent) / 100) * getNumeric(newChargeAmount)),
        }))

        return {
          ...state,
          chargeAmount: newChargeAmount,
          splits: newSplits,
        }
      }

      const hasOneEmptyValue = splitAmounts.filter((amount) => amount === '0.00').length === 1
      const splitSum = getSum(...splitAmounts)

      // If there's one empty value left AND there's still an amount of the charge to be split
      if (hasOneEmptyValue && splitSum < getNumeric(newChargeAmount)) {
        // Then fill the empty value with the rest of the charge amount to be split
        const restOfCharge = getNumeric(newChargeAmount) - splitSum
        const indexOfEmptyValue = splitAmounts.indexOf('0.00')

        const newSplits = splits.map((split, index) => ({
          ...split,
          amount: index === indexOfEmptyValue ? formatCurrency(restOfCharge) : split.amount,
          percent:
            index === indexOfEmptyValue
              ? getPercent(restOfCharge, newChargeAmount)
              : getPercent(split.amount, newChargeAmount),
        }))

        return {
          ...state,
          chargeAmount: newChargeAmount,
          splits: newSplits,
        }
      }

      // If the total charge amount is greater than the sum of the splits AND there are no empty values
      // OR if the total charge amount is less than the sum of the splits
      if (shouldResetSplitValues(newChargeAmount, splitAmounts)) {
        // Then the split values need to be updated by the user
        // If only one split amount left, set it equal to charge amount, else set all splits to '0.00'
        const newSplits =
          splits.length === 1
            ? splits.map((split) => ({ ...split, amount: newChargeAmount, percent: 100 }))
            : splits.map((split) => ({ ...split, amount: '0.00', percent: 0 }))

        return {
          ...state,
          chargeAmount: newChargeAmount,
          splits: newSplits,
        }
      }

      // Just update chargeAmount
      return {
        ...state,
        chargeAmount: newChargeAmount,
      }
    }

    case SET_SPLIT_AMOUNT: {
      const { index: changedIndex, value: newValue } = action

      // If split method is percent, then get the dollar split amount and update split amounts
      const newAmount =
        selectedSplitMethod === PERCENT
          ? formatCurrency((getNumeric(newValue) / 100) * getNumeric(chargeAmount))
          : newValue

      const newSplits = splits.map((split, index) => ({
        ...split,
        amount: index === changedIndex ? newAmount : split.amount,
        percent: index === changedIndex ? getPercent(newAmount, chargeAmount) : split.percent,
      }))

      const newSplitAmounts = newSplits.map((split) => split.amount)

      const hasOneEmptyValue = newSplitAmounts.filter((amount) => amount === '0.00').length === 1
      const splitSum = getSum(...newSplitAmounts)

      // If there's one empty value left AND there's still an amount of the charge to be split AND there's more than one split
      if (hasOneEmptyValue && splitSum < getNumeric(chargeAmount) && newSplits.length !== 1) {
        // Then fill the empty value with the rest of the charge amount to be split
        const restOfCharge = getNumeric(chargeAmount) - splitSum
        const indexOfEmptyValue = newSplitAmounts.indexOf('0.00')

        const filledSplits = newSplits.map((split, index) => ({
          ...split,
          amount: index === indexOfEmptyValue ? formatCurrency(restOfCharge) : split.amount,
          percent:
            index === indexOfEmptyValue
              ? getPercent(restOfCharge, chargeAmount)
              : getPercent(split.amount, chargeAmount),
        }))

        return {
          ...state,
          splits: filledSplits,
        }
      }

      // If there are only 2 splits
      if (newSplits.length === 2) {
        // Then balance out the other split so that their sum equals the charge amount
        const restOfCharge = getNumeric(chargeAmount) - newAmount

        const balancedSplits = newSplits.map((split, index) => ({
          ...split,
          amount: index !== changedIndex ? formatCurrency(restOfCharge) : split.amount,
          percent:
            index !== changedIndex
              ? getPercent(restOfCharge, chargeAmount)
              : getPercent(split.amount, chargeAmount),
        }))

        return {
          ...state,
          splits: balancedSplits,
        }
      }

      const hasSomeEmptyValues = newSplitAmounts.some((amount) => amount === '0.00')

      // When there are more than two splits...
      if (newSplits.length > 2) {
        if (
          // If the split sum is different from the charge amount AND there are no empty values
          (splitSum !== getNumeric(chargeAmount) && !hasSomeEmptyValues) ||
          // OR if the new amount is greater than equal to the charge amount
          splitSum > getNumeric(chargeAmount)
        ) {
          // Then reset all the other splits to zero
          const resettedSplits = newSplits.map((split, index) => ({
            ...split,
            amount: index !== changedIndex ? '0.00' : split.amount,
            percent: index !== changedIndex ? 0 : split.percent,
          }))

          return {
            ...state,
            splits: resettedSplits,
          }
        }
      }

      // Just update the changed index in splits
      return {
        ...state,
        splits: newSplits,
      }
    }

    case SET_SPLIT_METHOD: {
      return {
        ...state,
        selectedSplitMethod: action.value,
      }
    }

    case SET_ACCOUNT_FUND_ID: {
      const { index: changedIndex, id } = action
      const newSplits = splits.map((split, index) =>
        index === changedIndex ? { ...split, accountFundId: id } : split
      )
      return {
        ...state,
        splits: newSplits,
      }
    }

    case ADD_SPLIT: {
      const newSplits = [
        ...splits,
        { amount: '0.00', percent: 0, key: generateUuid(), inputRef: createRef() },
      ]

      return {
        ...state,
        splits: newSplits,
      }
    }

    case REMOVE_SPLIT: {
      const removedIndex = action.index
      const newSplits = splits.filter((split, index) => index !== removedIndex)
      const newSplitAmounts = newSplits.map((split) => split.amount)

      // If only one split left
      if (newSplits.length === 1) {
        // Then set it equal to the charge amount
        const filledSplits = newSplits.map((split) => ({
          ...split,
          amount: chargeAmount,
          percent: 100,
        }))

        return {
          ...state,
          splits: filledSplits,
        }
      }

      // If the total charge amount is greater than the sum of the splits AND there are no empty values
      // OR if the total charge amount is less than the sum of the splits
      if (shouldResetSplitValues(chargeAmount, newSplitAmounts)) {
        // Then the split values need to be updated by the user, so reset split amounts to 0.00
        const resettedSplits = newSplits.map((split) => ({ ...split, amount: '0.00', percent: 0 }))

        return {
          ...state,
          splits: resettedSplits,
        }
      }

      // Just update splits with removed split
      return {
        ...state,
        splits: newSplits,
      }
    }

    default:
      return state
  }
}

export const useChargeSplit = ({ chargeAmount, splits, selectedSplitMethod }) => {
  const [state, dispatch] = useReducer(chargeSplitReducer, {
    chargeAmount,
    splits,
    selectedSplitMethod,
  })

  const setChargeAmount = useCallback((value) => dispatch({ type: SET_CHARGE_AMOUNT, value }), [])
  const setSplitAmount = useCallback(
    (index, value) => dispatch({ type: SET_SPLIT_AMOUNT, index, value }),
    []
  )
  const setSplitMethod = useCallback((value) => dispatch({ type: SET_SPLIT_METHOD, value }), [])
  const setAccountFundId = useCallback(
    (index, id) => dispatch({ type: SET_ACCOUNT_FUND_ID, index, id }),
    []
  )
  const addSplit = useCallback(() => dispatch({ type: ADD_SPLIT }), [])
  const removeSplit = useCallback((index) => dispatch({ type: REMOVE_SPLIT, index }), [])

  return [
    state,
    { setChargeAmount, setSplitAmount, setSplitMethod, setAccountFundId, addSplit, removeSplit },
  ]
}
