import queryString from 'query-string'
import { Button, Card, Cell, Grid, Textfield } from 'react-mdl'
import ContactsTable from '@components/outreach/ContactsTable'
import { useHistoryState } from '@hooks'
import { formatDateFromDb } from '@utils'

const FIRST_NAME_KEY = 'first_name'
const LAST_NAME_KEY = 'last_name'
const EMAIL_KEY = 'email_address'
const PHONE_KEY = 'phone_number'

const EMPTY_STATE = {
  [FIRST_NAME_KEY]: '',
  [LAST_NAME_KEY]: '',
  [EMAIL_KEY]: '',
  [PHONE_KEY]: '',
}

const ContactSearch = (props) => {
  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
  const { replaceState } = useHistoryState()

  const [fields, setFields] = React.useState({
    [FIRST_NAME_KEY]: params[FIRST_NAME_KEY] || '',
    [LAST_NAME_KEY]: params[LAST_NAME_KEY] || '',
    [EMAIL_KEY]: params[EMAIL_KEY] || '',
    [PHONE_KEY]: params[PHONE_KEY] || '',
  })

  const [searchQuery, setSearchQuery] = React.useState(fields)

  const updateForm = (state) => {
    setFields(state)
    replaceState(state)
  }

  const setField = (key, event) => updateForm({ ...fields, [key]: event.target.value })

  const onSubmit = () => setSearchQuery(fields)
  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(fields)
    }
  }

  const onClear = () => updateForm({ ...EMPTY_STATE })

  return (
    <section>
      <Card className="block--full">
        <div className="p-20">
          <h2 className="text--med-lg m-0">Segmented Contacts</h2>
          <p className="text--lightgray">
            Included contacts are based off of conditions as of{' '}
            {formatDateFromDb(new Date(), 'MM/DD/YYYY')}
          </p>
        </div>
        <Grid className="block--full mb-20">
          <Cell col={4}>
            <Textfield
              floatingLabel
              label="First Name"
              className="textfield__input--full"
              placeholder="Contact's first name"
              value={fields[FIRST_NAME_KEY]}
              onChange={(e) => setField(FIRST_NAME_KEY, e)}
              onKeyPress={onKeyPress}
            />
          </Cell>

          <Cell col={4}>
            <Textfield
              floatingLabel
              label="Last Name"
              className="textfield__input--full"
              placeholder="Contact's last name"
              value={fields[LAST_NAME_KEY]}
              onChange={(e) => setField(LAST_NAME_KEY, e)}
              onKeyPress={onKeyPress}
            />
          </Cell>

          <Cell col={4}>
            <Textfield
              floatingLabel
              label="Email Address"
              className="textfield__input--full"
              placeholder="Contact's email address"
              value={fields[EMAIL_KEY]}
              onChange={(e) => setField(EMAIL_KEY, e)}
              onKeyPress={onKeyPress}
            />
          </Cell>

          <Cell col={4}>
            <Textfield
              floatingLabel
              label="Phone Number"
              className="textfield__input--full"
              placeholder="Contact's phone number"
              value={fields[PHONE_KEY]}
              onChange={(e) => setField(PHONE_KEY, e)}
              onKeyPress={onKeyPress}
            />
          </Cell>
          <Cell col={12} className="d-flex flex__justify--end">
            <Button
              type="button"
              onClick={onSubmit}
              className="mdl-button--raised mdl-button--gray">
              Search
            </Button>
            <Button
              type="button"
              className="mdl-button--raised mdl-button--light"
              onClick={onClear}>
              Clear Search
            </Button>
          </Cell>
        </Grid>
      </Card>
      <ContactsTable {...props} searchQuery={searchQuery} />
    </section>
  )
}

export default ContactSearch
