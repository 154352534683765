import queryString from 'query-string'
import { Card, Spinner } from 'react-mdl'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { DataTable, Paginate } from '@components/shared'
import { useEffect, useState } from 'react'
import { onKeyActivate } from '../../../utils'

// Time it waits to check to see it download is complete
const processingPollPeriod = 10000

const DownloadList = ({ endpoint, userName }) => {
  const columns = [
    {
      name: 'created_at',
      display: 'Download Date',
      cellFormatter: (createdAt) => moment(createdAt).format('lll'),
    },
    {
      name: 'user_name',
      display: 'User',
    },
    {
      name: 'id',
      display: 'Download',
      cellFormatter: (id, row) =>
        row.processing ? (
          <>
            <em className="u-padding--right-20">Email will be sent when completed.</em>
            <Spinner singleColor />
          </>
        ) : (
          <a href={`${endpoint}/${id}.csv`}>Export CSV</a>
        ),
    },
  ]

  const orderings = columns.map((c) => c.name)

  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

  const ordering = (order) => {
    const isDefined = !!order
    const isValid = isDefined && orderings.includes(order)
    return isValid ? order : 'created_at'
  }

  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query,
    order: ordering(params.order),
    asc: params.asc ? parseInt(params.asc) : 0,
    page: params.page ? parseInt(params.page) : 1,
  })

  const { get, loading } = useFetch(searchUrl, { cachePolicy: 'no-cache' })
  const [data, setData] = useState({ results: [] })

  useEffect(() => {
    const fetchContacts = async () => {
      const res = await get(queryString.stringify())
      setData(res)
    }

    fetchContacts()
  }, [get, searchUrl])

  const fetchContacts = async () => {
    const res = await get(queryString.stringify())
    setData(res)
  }

  // It only checks it if there is a Download List in progress
  const pollDownload = (url) => {
    Fetchable.get(url).then((response) => {
      if (response.status === 204) {
        setTimeout(pollDownload, processingPollPeriod, url)
      } else {
        fetchContacts()
      }
    })
  }

  const download = () => {
    setData({ results: [{ id: 0, processing: true, user_name: userName }, ...data.results] })
    Fetchable.post(endpoint).then((response) => {
      pollDownload(response.url)
    })
  }

  return (
    <section>
      <Card className="block--full">
        <div className="d-flex flex__justify--space-between flex__items--baseline p-20">
          <div>
            <h2 className="text--med-lg m-0">Download List</h2>
            <p className="text--lightgray">
              Download a CSV of your current list or a historical version listed below.
            </p>
          </div>

          <div
            className="mdl-button mdl-js-button mdl-button--wide md-button--wide-centered mdl-button--accent"
            onClick={download}
            onKeyDown={onKeyActivate('click')}
            role="button"
            tabIndex={0}>
            Download
          </div>
        </div>
      </Card>

      <DataTable
        topic="Download List"
        loading={loading}
        rows={data.results}
        columns={columns}
        rowKeyColumn="id"
        noResultsText="No downloads found"
        onSort={(name, isAcending) => {
          searchDispatch({ type: 'order', name, isAcending })
          searchDispatch({ type: 'page', value: 1 })
        }}
        initialSortBy="full_name"
        initialIsSortAcending={searchState.asc === 1}
      />

      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}
    </section>
  )
}

export default DownloadList
