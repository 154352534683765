import React, { useState } from 'react'
import { Textfield, Button, Grid, Cell } from 'react-mdl'
import { useRailsFetch } from '@hooks'

const AddRegistrant = ({ search_path, new_event_registration_path }) => {
  const [email, setEmail] = useState('')
  const [searched, setSearched] = useState(false)
  const { getWithParams, loading, data = { customers: [] } } = useRailsFetch(search_path)

  const onSubmit = async (e) => {
    e.preventDefault()
    await getWithParams('', { query: { email: email } })
    setSearched(true)
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid className="block--full m-10">
        <Cell col={12}>
          <h4 className="text--med-lg mt-0">Add Registrant</h4>
        </Cell>
        <Cell col={4}>
          <Textfield
            label="Email or Name"
            className="w-100"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Cell>
        <Cell col={2}>
          <Button
            raised
            className="mdl-button--gray mt-10"
            type="submit"
            disabled={email.trim().length < 1 || loading}>
            Search
          </Button>
        </Cell>
      </Grid>

      {searched && (
        <table className="modifiable-table">
          <thead>
            <tr>
              <th scope="col">
                <span className="visuallyhidden">Add</span>
              </th>
              <th scope="col">Email</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody className="background--lightergray">
            {data.customers.length > 0 ? (
              data.customers.map((c, idx) => (
                <tr key={idx} className="table-row">
                  <td>
                    <a
                      href={`${new_event_registration_path}?customer_id=${c.id}`}
                      className="mdl-button mdl-button--icon mdl-button--transparent mr-0"
                      aria-label={`Add ${c.first_name} ${c.last_name} as registrant`}>
                      <span className="material-icons" aria-hidden="true">
                        add
                      </span>
                    </a>
                  </td>
                  <td className="mdl-data-table__cell--non-numeric">{c.communications_email}</td>
                  <td className="mdl-data-table__cell--non-numeric">
                    {c.first_name} {c.last_name}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="3"
                  className="mdl-data-table__cell--non-numeric"
                  style={{ padding: '8px', textAlign: 'center' }}>
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </form>
  )
}

export default AddRegistrant
