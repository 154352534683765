import { Grid, Cell, Button } from 'react-mdl'
import ChargeSplitFieldset from '@components/product-variations/ChargeSplitFieldset'
import { generateErrors } from '@utils'
const { useState, Fragment } = React
const { _ } = window

const AddPremium = ({ name, charge, accountFunds }) => {
  // Show premium by default if it's persisted AND not marked for destruction, OR
  // an amount has been filled out, OR if it has any errors
  const [isAdding, setIsAdding] = useState(
    (charge.id && !charge._destroy) || charge.amount || !_.isEmpty(charge.errors)
  )

  const add = () => {
    setIsAdding(true)
  }

  const remove = () => {
    setIsAdding(false)
  }

  return (
    <Fragment>
      <div className="u-padding--x-30">
        <h2 id="premium" className="text--med-lg u-margin--none">
          Premium
        </h2>
      </div>

      {charge.id ? (
        <Fragment>
          <input type="hidden" name={`${name}[_destroy]`} value={!isAdding} />
          <input type="hidden" name={`${name}[id]`} value={charge.id} />
        </Fragment>
      ) : (
        undefined
      )}

      {isAdding ? (
        <ChargeSplitFieldset
          charge={charge}
          chargeName="trait_premium"
          chargeCanBePercent={true}
          label="Premium"
          name={name}
          accountFunds={accountFunds}
          errors={generateErrors(charge) || {}}
        />
      ) : (
        undefined
      )}

      <Grid className="u-padding--x-20">
        <Cell col={12}>
          {isAdding ? (
            <Button
              type="button"
              className="mdl-button--light mdl-button--wide mdl-button--wide-centered u-margin--top-20"
              onClick={remove}>
              Remove Premium
            </Button>
          ) : (
            <Button
              type="button"
              accent
              className="mdl-button--wide mdl-button--wide-centered"
              onClick={add}>
              Add Premium
            </Button>
          )}
        </Cell>
      </Grid>
    </Fragment>
  )
}

export default AddPremium
