import { Button, Card, Cell, Grid, Textfield } from 'react-mdl'
import { DataTable, Paginate, SearchForm } from '@components/shared'
import { useSearchQuery } from '@hooks'
import queryString from 'query-string'
import useFetch from 'use-http'
import { Btn } from '@components/shared'
import { Modal } from '@components/shared'

const { useState, useEffect } = React
const initialSortBy = 'email'
const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

const propTypes = {
  endpoint: PropTypes.string.isRequired,
}

const topic = 'Email Templates'

const EditContacts = ({ endpoint, uploadEndpoint, templateEndpoint }) => {
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
  })

  // Fetches the data
  const { get, cache } = useFetch(searchUrl)
  const { post, put, del, response } = useFetch(endpoint, {
    headers: { ...window.__AMS__.formToken, Accept: 'application/json' },
    cachePolicy: 'no-cache',
  })

  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    get().then((res) => setData(res))
  }, [searchUrl, get])

  const [editable, setEditable] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(null)
  const [updateModalRow, setUpdateModalRow] = React.useState(null)
  const [checkedIds, setCheckedIds] = React.useState([])

  const [uploadModalPage, setUploadModalPage] = React.useState(null)
  const [selectedFile, setSelectedFile] = useState()
  const [uploadErrors, setUploadErrors] = useState()
  const [abortController, setAbortController] = useState()

  const commonColumn = {
    name: 'email',
    display: 'Email',
    cellFormatter: (name, row) => <a href={row.edit_url}>{name}</a>,
  }
  const columns = editable
    ? [
        {
          name: 'id',
          display: (
            <input
              type="checkbox"
              aria-label="checkbox for contact"
              checked={data.results.every((row) => checkedIds.includes(row.id))}
              onChange={(e) => {
                if (e.target.checked) setCheckedIds(data.results.map((row) => row.id))
                else setCheckedIds([])
              }}
            />
          ),
          nosort: true,
          cellFormatter: (value) => (
            <input
              type="checkbox"
              aria-label="checkbox"
              checked={checkedIds.includes(value)}
              onChange={(e) => {
                if (e.target.checked) setCheckedIds([...checkedIds, value])
                else setCheckedIds(checkedIds.filter((id) => id !== value))
              }}
            />
          ),
        },
        commonColumn,
        {
          name: 'buttons',
          display: '',
          nosort: true,
          className: 'mdl-data-table__cell--narrow',
          cellFormatter: (name, row) => (
            <span style={{ white_space: 'nowrap' }}>
              <Btn.Icon
                light
                name="edit"
                className="mr-20"
                onClick={() => setUpdateModalRow(row)}
              />
              <Btn.Icon
                light
                name="delete"
                onClick={() => {
                  setDeleteModal(true)
                  setCheckedIds([row.id])
                }}
              />
            </span>
          ),
        },
      ]
    : [commonColumn]

  async function updateContact(closeModal) {
    const response = updateModalRow.id
      ? await put('/' + updateModalRow.id, { contact: updateModalRow })
      : await post('/', { contact: updateModalRow })

    if (response.errors) {
      setUpdateModalRow({ ...updateModalRow, errors: response.errors })
      return false
    }

    cache.clear()
    const res = await get()
    setData(res)
    if (closeModal) setUpdateModalRow(null)
    return true
  }

  async function deleteContacts() {
    await del('/' + checkedIds[0], { ids: checkedIds })
    if (response.status === 204) {
      cache.clear()
      setData({ ...data, results: data.results.filter((r) => !checkedIds.includes(r.id)) })
      setCheckedIds([])
      setDeleteModal(null)
    }
  }

  const handleSubmission = () => {
    const formData = new FormData()

    setUploadModalPage('uploading')
    setUploadErrors(null)

    formData.append('outreach_distribution_list[upload]', selectedFile)
    formData.append('authenticity_token', window.__AMS__.formToken['X-CSRF-Token'])

    // Rails server will not abort the request once the file has been uploaded
    const controller = new AbortController()
    const signal = controller.signal
    setAbortController(controller)

    fetch(uploadEndpoint, {
      method: 'POST',
      body: formData,
      signal,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result)
        setSelectedFile(null)
        if (result.errors) {
          setUploadErrors(result.errors)
          setUploadModalPage('file')
        } else {
          cache.clear()
          get().then((res) => setData(res))
          setUploadModalPage('success')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const uploadPages = {
    file: (
      <>
        <Modal.Title>Upload Contact List (CSV)</Modal.Title>
        <Modal.Content>
          <p>
            <a href={templateEndpoint}>Download CSV Custom List Format</a>
          </p>
          <p>Upload a list of custom contacts to add</p>
          <Cell col={12} className="ml-0">
            <input
              required="required"
              aria-label="file upload"
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
          </Cell>
          {selectedFile && <Cell col={12}>Attachment: {selectedFile.name}</Cell>}
          {uploadErrors && (
            <Cell col={12}>
              <div className="u-padding--10 bg--lightgray text--brightred">
                <h5>We found the following errors in the file format</h5>
                <ul>
                  {uploadErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            </Cell>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={handleSubmission}>
            Upload
          </Button>
          <Button className="mdl-button--light" onClick={() => setUploadModalPage(null)}>
            Cancel
          </Button>
        </Modal.Actions>
      </>
    ),
    uploading: (
      <>
        <Modal.Title>File uploading...</Modal.Title>
        <Modal.Content>
          <p className="mb-0">Please wait while your list is uploading.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="mdl-button--light"
            onClick={() => {
              abortController.abort()
              setUploadModalPage(null)
            }}>
            Cancel
          </Button>
        </Modal.Actions>
      </>
    ),
    success: (
      <>
        <Modal.Title>File Upload Success</Modal.Title>
        <Modal.Content>
          <p className="mb-0">
            Your contact list has been updated with the data from your uploaded file.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--light" onClick={() => setUploadModalPage(null)}>
            Done
          </Button>
        </Modal.Actions>
      </>
    ),
  }

  return (
    <Card shadow={0} style={{ width: '100%' }}>
      {/*Heading and Search */}
      <Grid style={{ width: '100%' }}>
        <Cell className="mdl-layout-spacer">
          <h2 className="text--med-lg m-0 p-0">Contacts</h2>
          <p className="text--lightgray">View and manage the contacts on this list.</p>
        </Cell>

        <Cell col={6}>
          <div className="d-flex flex__justify--end">
            <Button
              className="mdl-button mdl-button--gray mdl-button--wide mr-20"
              href={endpoint + '.csv'}>
              Export List
            </Button>

            <Button
              className="mdl-button mdl-button--accent mdl-button--wide"
              onClick={() => setEditable(!editable)}>
              {editable ? 'Done Editing' : 'Edit Contact List'}
            </Button>
          </div>
        </Cell>
      </Grid>

      <SearchForm
        searchFilters={searchState.query}
        onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
        placeholder="Search Contact List"
        helperText="Search for contacts by email..."
      />

      {editable && (
        <Grid className="bg--lightgray m-0" style={{ width: '100%' }}>
          <Cell col={2} className="text--med bg--lightgray">
            {checkedIds.length} Contacts selected
          </Cell>
          <Cell col={10} className="">
            <div className="d-flex flex__justify--end">
              <Button
                className="mdl-button--accent mdl-button--wide mr-20"
                onClick={() => setUpdateModalRow(true)}>
                Create Contact
              </Button>
              <Button
                className="mdl-button--accent mdl-button--wide mr-20"
                onClick={() => setUploadModalPage('file')}>
                Upload Contacts (CSV)
              </Button>
              <Button
                className="mdl-button--accent mdl-button--wide"
                disabled={checkedIds.length === 0}
                onClick={() => setDeleteModal(true)}>
                Delete Selected
              </Button>
            </div>
          </Cell>
        </Grid>
      )}

      <DataTable
        rows={data.results}
        columns={columns}
        rowKeyColumn="id"
        topic={topic}
        onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
        initialSortBy={searchState.order}
        initialIsSortAcending={searchState.asc === 1}
      />

      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}

      <Modal
        open={!!uploadModalPage}
        id="confirm_update"
        onClose={() => setUploadModalPage(null)}
        onCancel={() => setUploadModalPage(null)}>
        {uploadPages[uploadModalPage]}
      </Modal>

      {/*Used for Add Contact and Update Contact*/}
      <Modal
        open={!!updateModalRow}
        id="confirm_update"
        onClose={() => setUpdateModalRow(null)}
        onCancel={() => setUpdateModalRow(null)}>
        <Modal.Title id="confirm_delete">
          {updateModalRow && updateModalRow.id ? 'Edit Contact' : 'Add New Contact'}
        </Modal.Title>
        <Modal.Content>
          <p className="mb-0">Enter contact details and add them to this list.</p>
          <Textfield
            floatingLabel
            label="Email"
            placeholder="Email Address"
            className="mt-20 textfield__input--full"
            value={(updateModalRow && updateModalRow.email) || ''}
            error={updateModalRow && updateModalRow.errors && updateModalRow.errors[0]}
            onChange={(e) => setUpdateModalRow({ ...updateModalRow, email: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                updateContact(updateModalRow.id).then(
                  (r) => r && setUpdateModalRow({ ...updateModalRow, email: '', errors: null })
                )
              }
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={updateContact}>
            {updateModalRow && updateModalRow.id ? 'Update Contact' : 'Add Contact'}
          </Button>
          <Button className="mdl-button--light" onClick={() => setUpdateModalRow(null)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={deleteModal}
        id="confirm_delete"
        onClose={() => setDeleteModal(null)}
        onCancel={() => setDeleteModal(null)}>
        <Modal.Title id="confirm_delete">
          Delete Contact{checkedIds.length > 1 ? 's' : ''}
        </Modal.Title>
        <Modal.Content>
          You are about to delete
          {checkedIds.length > 1 ? ' multiple contacts ' : ' this contact '}
          from your custom list. Would you like to continue?
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={deleteContacts}>
            Delete Contact{checkedIds.length > 1 ? 's' : ''}
          </Button>
          <Button className="mdl-button--light" onClick={() => setDeleteModal(null)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </Card>
  )
}
EditContacts.propTypes = propTypes
export default EditContacts
