import Autosuggest from 'react-autosuggest'
import { sanitizedStringRegex } from '@utils'
import {
  TypeaheadInput,
  TypeaheadSuggestionsContainer,
  TypeaheadSuggestion,
} from './TypeaheadElements'

// Customize how to get the label and value from your list of options
// By default, it expects arrays with an identifier and a value
// e.g. [[1, 'Foo'], [2, 'Bar']]
const _getOptionValue = (option) => {
  return option ? option[1] : ''
}
const _getOptionId = (option) => {
  return option ? option[0] : ''
}

// Extract props that should not be applied to the input element
// and pass `inputProps` down
const Typeahead = ({
  options,
  onChange,
  onSelect,
  getOptionValue = _getOptionValue,
  getOptionId = _getOptionId,
  inputRef,
  name,
  value = '',
  tagName = 'div',
  wrapperClass = '',
  wrapperStyle = {},
  inputClass = '',
  inputStyle = {},
  fullWidth = false,
  error,
  ...inputProps
}) => {
  // Autosuggest requires a value be present because it's a controlled component
  // So here we use either an explicit value from the parent or empty string
  const [localValue, setLocalValue] = React.useState(value || '')
  // If the parent passes an onChange handler, use its value prop instead of local state
  const inputValue = onChange ? value : localValue
  const inputOnChange = (event, { newValue, method }) => {
    if (!!onChange) {
      onChange(event, newValue)
    } else {
      setLocalValue(newValue)
    }
  }

  // Suggestions are populated from options prop once the user focuses on the input
  const [suggestions, setSuggestions] = React.useState([])

  // Calculate suggestions via regex, etc
  const calculateSuggestions = (value) => {
    const regex = sanitizedStringRegex(value)

    if (regex) {
      return options.filter((o) => regex.test(getOptionValue(o)))
    } else {
      return []
    }
  }

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    // Available reasons:
    // 'input-changed'
    // 'input-focused'
    // 'escape-pressed'
    // 'suggestions-revealed'
    // 'suggestion-selected'

    // Show all available options on focus or change if input is blank
    if (['input-focused', 'input-changed'].includes(reason) && value.length === 0) {
      setSuggestions(options)
    } else {
      setSuggestions(calculateSuggestions(value))
    }
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  // Expose a ref in case access to input element is needed (can be overridden)
  const tempRef = React.useRef()
  const textInputRef = inputRef || tempRef
  const storeTextfieldtRef = (textInput) => {
    if (textInput !== null) {
      textInputRef.current = textInput
    }
  }

  // When an option is selected, store it for use in a hidden input
  // or to pass back up to the parent component
  const defaultSelectedOption = options.find((opt) => getOptionValue(opt) === value) || null
  const [selectedOption, setSelectedOption] = React.useState(defaultSelectedOption)
  const onSuggestionSelected = (e, { suggestion, suggestionValue, suggestionIndex, method }) => {
    if (method === 'enter') {
      e.preventDefault()
    }

    setSelectedOption(suggestion)

    if (!!onSelect) {
      onSelect(suggestion, suggestionValue, suggestionIndex)
    }
  }

  const shouldRenderSuggestions = (value) => {
    // Display options even when input is blank
    return true
  }

  // If you select an option via the arrow keys, then tab or click away,
  // select the highlighted selection
  const inputOnBlur = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      setSelectedOption(highlightedSuggestion)
    }
  }

  return (
    <div className={wrapperClass} style={wrapperStyle}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getOptionValue}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderInputComponent={TypeaheadInput}
        renderSuggestionsContainer={TypeaheadSuggestionsContainer}
        renderSuggestion={(suggestion, { query }) => (
          <TypeaheadSuggestion suggestion={getOptionValue(suggestion)} query={query} />
        )}
        inputProps={{
          ...inputProps,
          className: inputClass,
          style: inputStyle,
          fullWidth: fullWidth,
          value: inputValue,
          onChange: inputOnChange,
          onBlur: inputOnBlur,
          ref: storeTextfieldtRef,
          error,
        }}
      />

      <input type="hidden" value={getOptionId(selectedOption)} name={name} />
    </div>
  )
}

Typeahead.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  getOptionValue: PropTypes.func,
  getOptionId: PropTypes.func,
  wrapperClass: PropTypes.string,
  wrapperStyle: PropTypes.object,
  inputClass: PropTypes.string,
  inputStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
}

export default Typeahead
