import { FilterChip } from '@components/shared'
import { getNumeric, formatCurrency } from '@utils'

const { useState } = React
const EMDASH = '\u2014'

const getSurchargeValue = (surcharge = {}) => {
  if (surcharge.percent) {
    return `${getNumeric(surcharge.percent)}%`
  }

  return `$${formatCurrency(surcharge.amount)}`
}

const SurchargeFieldset = ({
  label,
  name,
  includeBlankOption,
  surchargeType,
  surcharges = [],
  initialSurcharges = [],
  taxOnly,
}) => {
  const taxSurcharges = surcharges.filter((surcharge) => surcharge.surcharge_type === 'tax')
  const otherSurcharges = surcharges.filter((surcharge) => surcharge.surcharge_type !== 'tax')

  const [selectedSurcharges, setSelectedSurcharges] = useState(
    surcharges.filter((surcharge) =>
      initialSurcharges.some((initial) => initial.surcharge_id === surcharge.id)
    )
  )

  const handleOptionSelected = (value) => {
    setSelectedSurcharges([...selectedSurcharges, value])
  }

  const handleRemove = (removedSurcharge) => () => {
    setSelectedSurcharges(
      selectedSurcharges.filter((surcharge) => surcharge.id !== removedSurcharge.id)
    )
  }

  return (
    <div>
      {initialSurcharges.map((surcharge, index) => (
        <React.Fragment key={index}>
          <input
            type="hidden"
            name={`${name}[${index}][_destroy]`}
            value={index + 1 > selectedSurcharges.length}
          />
          <input type="hidden" name={`${name}[${index}][id]`} value={surcharge.id} />
        </React.Fragment>
      ))}
      {selectedSurcharges.map(
        (surcharge, index) =>
          index + 1 > initialSurcharges.length && (
            <React.Fragment key={index}>
              <input type="hidden" name={`${name}[${index}][_destroy]`} value={false} />
              <input type="hidden" name={`${name}[${index}][id]`} />
            </React.Fragment>
          )
      )}
      <MdlSelect
        label="Tax"
        options={taxSurcharges.filter(
          (surcharge) => !selectedSurcharges.some((selected) => selected.id === surcharge.id)
        )}
        onOptionSelected={handleOptionSelected}
        className="u-margin--right-20 always-floating-label"
        includeBlankOption="Select Tax"
      />
      {!taxOnly && (
        <MdlSelect
          label="Surcharge"
          options={otherSurcharges.filter(
            (surcharge) => !selectedSurcharges.some((selected) => selected.id === surcharge.id)
          )}
          onOptionSelected={handleOptionSelected}
          className="always-floating-label"
          includeBlankOption="Select Surcharge"
        />
      )}
      <div className="d-flex flex__wrap--wrap">
        {selectedSurcharges.map((surcharge, index) => (
          <input
            key={surcharge.id}
            type="hidden"
            name={`${name}[${index}][surcharge_id]`}
            value={surcharge.id}
          />
        ))}
        {selectedSurcharges.map((surcharge, index) => (
          <FilterChip
            key={index}
            filter={`${surcharge.name} ${EMDASH} ${getSurchargeValue(surcharge)}`}
            onRemove={handleRemove(surcharge)}
          />
        ))}
      </div>
    </div>
  )
}

export default SurchargeFieldset
