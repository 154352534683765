import SupplyKitOrderRow from './SupplyKitOrderRow'
import { Paginate } from '@components/shared'

export default class SupplyKitOrdersTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      supplier: props.supplier,
      orders: props.orders,
      page: props.page,
      pages: props.pages,
      totalRecords: props.totalRecords,
      fetchOrders: props.fetchOrders,
      filterStatus: props.filterStatus,
    }

    this.handlePageClick = this.handlePageClick.bind(this)
  }

  get orderRows() {
    return this.state.orders.map((order, i) => {
      return (
        <SupplyKitOrderRow
          locationData={this.props.locationData}
          order={order}
          key={order.id}
          onTrackingNumberChange={this.props.onTrackingNumberChange}
        />
      )
    })
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      orders: newProps.orders,
      page: newProps.page,
      pages: newProps.pages,
      totalRecords: newProps.totalRecords,
      filterStatus: newProps.filterStatus,
    })
  }

  handlePageClick(pageNumClick) {
    this.state.fetchOrders(this.state.supplier, this.state.filterStatus.id, pageNumClick)
  }

  render() {
    return (
      <div>
        <table
          style={{ tableLayout: 'fixed' }}
          className="mdl-data-table mdl-js-data-table block--full">
          <thead>
            <tr>
              <th className="mdl-data-table__cell--non-numeric">Status</th>
              <th className="mdl-data-table__cell--non-numeric">Order Date</th>
              <th className="mdl-data-table__cell--non-numeric">Location Name</th>
              <th className="mdl-data-table__cell--non-numeric">Kit Description</th>
              <th className="mdl-data-table__cell--non-numeric">Quantity</th>
              <th className="mdl-data-table__cell--non-numeric">Tracking Number</th>
              <th className="mdl-data-table__cell--non-numeric">Last Order Date</th>
              <th className="mdl-data-table__cell--non-numeric">Last Order Qty</th>
              <th className="mdl-data-table__cell--non-numeric">YTD Shipped</th>
              <th></th>
            </tr>
          </thead>
          {this.orderRows}
        </table>

        {this.state.pages > 1 && (
          <div>
            <Paginate
              page={this.state.page}
              limit={100}
              total={this.state.totalRecords}
              next={this.state.page === this.state.pages ? null : this.state.page + 1}
              previous={this.state.page - 1}
              onClick={this.handlePageClick}
            />
          </div>
        )}
      </div>
    )
  }
}
