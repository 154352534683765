import { Grid, Cell } from 'react-mdl'
import Table from '@components/shared/Table'
import Typeahead from '@components/shared/Typeahead/Typeahead'

const LocationDetails = ({ options, selected }) => {
  const [selectedLocation, setSelectedLocation] = React.useState(selected)
  const [inputValue, setInputValue] = React.useState(
    selectedLocation ? selectedLocation.name_with_number : ''
  )

  const handleSelected = (location) => {
    setSelectedLocation(location)
  }

  const columns = [
    { name: 'category', display: 'Title' },
    {
      name: 'first_name',
      display: 'Name',
      cellFormatter: (_, row) => (
        <span>
          {row.first_name} {row.last_name}
        </span>
      ),
    },
    { name: 'phone', display: 'Phone No.' },
    { name: 'email', display: 'Email' },
  ]

  const rows = React.useMemo(() => {
    return selectedLocation ? selectedLocation.location_contacts : []
  }, [selectedLocation])

  return (
    <React.Fragment>
      <div className="bg--well u-padding--bottom-40">
        <Grid className="u-padding--20">
          <Cell col={12}>
            <h2 className="mdl-card__title-text u-margin--top-20">Location Details</h2>
          </Cell>
          <Cell col={5}>
            <Typeahead
              label="Assign Vendor Location"
              placeholder="Vendor Location ID"
              value={inputValue}
              name="camp_park[location_id]"
              options={options}
              getOptionValue={(option) => (option ? option.name_with_number : '')}
              getOptionId={(option) => (option ? option.id : '')}
              onChange={(e, value) => setInputValue(value)}
              onSelect={handleSelected}
            />
          </Cell>
          <Cell col={12}>
            {selectedLocation && (
              <address>
                <h3 className="text--med-small text--lightgray u-margin--none">Physical Address</h3>
                <p className="text--med">
                  {selectedLocation.location.addr1}
                  <br />
                  {selectedLocation.location.addr2}
                  {selectedLocation.location.addr2 ? <br /> : null}
                  {`${selectedLocation.location.city} ${selectedLocation.location.state_code}, ${selectedLocation.location.postal_code}`}
                </p>
              </address>
            )}
          </Cell>
          <Cell col={12}>
            <h2 className="mdl-card__title-text u-margin--top-20">Park Location Contacts</h2>
          </Cell>
        </Grid>
        {rows.length > 0 ? (
          <div className="table__container--responsive">
            <Table className="block--full">
              <Table.Caption>Park Location Contacts</Table.Caption>
              <Table.Head>
                {columns.map((column) => (
                  <Table.Header key={column.name} column={column} />
                ))}
              </Table.Head>
              {rows && <Table.Body rows={rows} columns={columns} rowKeyColumn="id" />}
            </Table>
          </div>
        ) : (
          <div className="u-padding--x-20 bg--well">
            <span className="u-padding--left-8 text--med">No contacts for this location.</span>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default LocationDetails
