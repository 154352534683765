import { Card } from 'react-mdl'
import { Btn } from '@components/shared'
import { onClickLink } from '@utils'
import { DataTable, Paginate, SearchFormDropdown } from '@components/shared'
import { formatDateFromDb } from '@utils'
import { useSearchQuery } from '@hooks'
import queryString from 'query-string'
import useFetch from 'use-http'

const { useState, useEffect } = React
const initialSortBy = 'created_at'
const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

const propTypes = {
  endpoint: PropTypes.string.isRequired,
}

const topic = 'Segment Lists'

const SegmentedList = ({ endpoint, users }) => {
  // Using search query params
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 0,
    page: params.page ? parseInt(params.page) : 1,
  })

  // Fetches the data
  const { get } = useFetch(searchUrl)
  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    get().then((res) => setData(res))
  }, [searchUrl, get])

  const columns = [
    {
      name: 'status',
      display: '',
      nosort: true,
    },
    {
      name: 'name',
      display: 'Name',
      cellFormatter: (name, row) => <a href={row.url}>{name}</a>,
    },
    {
      name: 'created_at',
      display: 'Created',
      cellFormatter: (value) => formatDateFromDb(value),
    },
    {
      name: 'buttons',
      display: '',
      nosort: true,
      className: 'mdl-data-table__cell--narrow',
      cellFormatter: (name, row) => (
        <span style={{ white_space: 'nowrap' }}>
          <Btn.Icon
            light
            name="content_copy"
            className="mr-20"
            href={`${endpoint}/new?segmented_list_id=${row.id}`}
          />
        </span>
      ),
    },
  ]

  return (
    <Card shadow={0} style={{ width: '100%' }}>
      <SearchFormDropdown
        searchFilters={searchState.query}
        onSearch={(filters, user_id) => {
          searchDispatch({ type: 'map', value: { query: filters, user_id: user_id } })
        }}
        placeholder="Search Segmentation Lists"
        helperText="Segmentation Lists Search"
        users={users}
      />
      <DataTable
        className="data-table--chevron"
        rows={data.results}
        columns={columns}
        onRowClick={(e, row) => onClickLink(e, row.url)}
        rowKeyColumn="url"
        topic={topic}
        onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
        initialSortBy={searchState.order}
        initialIsSortAcending={searchState.asc === 1}
      />

      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}
    </Card>
  )
}
SegmentedList.propTypes = propTypes
export default SegmentedList
