import * as yup from 'yup'
const { useMemo } = React

const createDynamicYupSchema = (key, obj) => {
  const validationType = obj.type
  const validations = obj.validators

  if (!yup[validationType]) {
    return
  }

  let validator = yup[validationType]()

  validations.forEach((validation) => {
    const { name, options } = validation
    if (!validator[name]) return

    // Must parse regex string back into raw regex
    if (name === 'matches') {
      const [regexString, message] = options
      // Remove trailing forward slashes
      const regex = new RegExp(regexString.slice(1, -1))
      validator = validator[name](regex, message)
    } else {
      validator = validator[name](...options)
    }
  })

  return validator
}

const parseForm = (form) => {
  // Loop through the form object's attributes
  return Object.entries(form).reduce((schema, [key, field]) => {
    // If the "attribute" is an array, it's a nested collection
    // that needs to be parsed recursively
    if (key === 'errors' || key === 'id') {
      // skip over top-level errors array
      // skip `id` since it doesn't need to be validated as a number
      return schema
    } else if (Array.isArray(field)) {
      // We only need the first field's validators to apply the same schema
      // to all nested objects
      const firstNestedObject = parseForm(field[0])
      schema[key] = yup.array().of(yup.object().shape(firstNestedObject))
    } else {
      const newSchema = createDynamicYupSchema(key, field)
      if (newSchema) {
        schema[key] = newSchema
      }
    }

    return schema
  }, {})
}

export const useYupModel = (form, rootKey = null, options = {}) => {
  const schema = useMemo(() => {
    // Dynamically generate the schema based on incoming form object
    const yupSchema = yup.object().shape({ ...options, ...parseForm(form) })
    // Optionall include a root key for the form
    // e.g. `my_resource_name[some_attr]` where `my_resource_name` is the root
    return rootKey ? yup.object().shape({ [rootKey]: yupSchema }) : yupSchema
  }, [form, rootKey, options])

  return { schema }
}

// Copy the last item in an array of nested attributes,
// remove its id and _destroy attributes, and append a new index to its
// input_name
//
// TODO: potentially replace with react-hook-form fieldArray
//
export const useNestedAttributes = () => {
  const buildObject = (obj, inputName, defaultValue = {}) => {
    return Object.entries(obj).reduce((newObject, [key, field]) => {
      if (field.input_name) {
        newObject[key] = {
          ...field,
          value: defaultValue[key] || null,
          errors: null,
          input_name: `${inputName}[${key}]`,
        }
      } else {
        if (Array.isArray(field)) {
          // If the field is an array, it's a has_many association.
          // So we append an index of `0` and pass the 1st object to create a new record.
          newObject[key] = buildObject(field[0], `${inputName}[${key}][0]`)
        } else {
          // Else, it's a has_one association
          newObject[key] = buildObject(field, `${inputName}[${key}]`)
        }
      }

      return newObject
    }, {})
  }

  const append = (associationName, nestedObjects, defaultValue = {}) => {
    const newIndex = nestedObjects.length
    const lastObject = nestedObjects[newIndex - 1]

    // Get base name of `id` attribute so we can add a nested association
    // e.g. `resource[nested_attributes]` -> `resource[nested_attributes][1]`
    const inputName = `${lastObject.id.base_input_name}[${newIndex}]`
    const obj = buildObject(lastObject, inputName, defaultValue)

    return [...nestedObjects, obj]
  }

  return { append }
}
