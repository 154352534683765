import { Button } from 'react-mdl'
import { Modal } from '@components/shared'

const DeleteButton = (props) => {
  const ref = React.useRef()

  const [modalOpen, setModalOpen] = React.useState(false)

  const cancel = () => {
    setModalOpen(false)
  }

  return (
    <React.Fragment>
      <a
        href={props.path}
        data-method="delete"
        ref={ref}
        tabIndex={props.tabIndex}
        style={{ display: 'none' }}>
        Delete
      </a>
      <Button
        type="button"
        className="mdl-button--wide mdl-button--wide-centered mdl-button--light"
        onClick={() => setModalOpen(true)}>
        {props.buttonText || 'Delete'}
      </Button>

      <Modal open={modalOpen} id="confirm_delete" onClose={cancel} onCancel={cancel}>
        <Modal.Title id="confirm_delete">{props.modalTitle}</Modal.Title>
        <Modal.Content>{props.modalText}</Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={() => ref.current.click()}>
            Delete
          </Button>
          <Button className="mdl-button--light" onClick={cancel}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export default DeleteButton
