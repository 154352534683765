import { Button, Cell, Grid, Textfield } from 'react-mdl'
import { FilterChip } from '@components/shared'
import { useDidUpdate } from '@hooks'

// This is a copy of SearchForm, only difference is a select was added Todo: they will need to
// be refactored together in the future + update and test current instances of SearchForm
const { useEffect, useState } = React

const propTypes = {
  // Event handler when search query is submitted. Takes array of search query strings as param
  onSearch: PropTypes.func.isRequired,

  // Request data on component mount
  searchOnMount: PropTypes.bool,

  // Pre-populate search filters
  searchFilters: PropTypes.array,

  // Placeholder text for search input
  placeholder: PropTypes.string,

  // Text underneath search input
  helperText: PropTypes.string,

  // Text underneath search input
  users: PropTypes.array,
}

const SearchFormDropdown = ({
  onSearch,
  searchOnMount = false,
  searchFilters: initSearchFilters = [],
  placeholder,
  helperText,
  users,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [searchFilters, setSearchFilters] = useState(initSearchFilters)
  const [userIdFilter, setUserIdFilter] = useState(null)

  // Always call onSearch if filters are updated after first render
  useDidUpdate(() => onSearch(searchFilters, userIdFilter), [searchFilters, userIdFilter])

  // Optionally call onSearch on first render
  useEffect(() => {
    if (searchOnMount) onSearch(searchFilters, userIdFilter)
  }, [onSearch, searchFilters, searchOnMount, userIdFilter])

  const isIncludedInSearchFilters = (value) =>
    searchFilters.map((filter) => filter.toUpperCase()).includes(value.toUpperCase())

  const handleChange = (event) => {
    setSearchInput(event.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    const trimmedSearchInput = searchInput.trim()
    if (trimmedSearchInput.length) {
      if (!isIncludedInSearchFilters(trimmedSearchInput)) {
        setSearchFilters([...searchFilters, trimmedSearchInput])
      }
      setSearchInput('')
    }
  }

  const handleClearFilters = () => {
    setSearchFilters([])
  }

  const handleRemoveFilter = (filterToRemove) => {
    setSearchFilters(searchFilters.filter((item) => item !== filterToRemove))
  }

  return (
    <Grid noSpacing className="search-form">
      <Cell col={6} tablet={12} className="search-form__textfield-container">
        <Textfield
          label={placeholder}
          className="search-form__textfield"
          value={searchInput}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <div className="search-form__helper-text-container">
          <span className="search-form__helper-text">{helperText}</span>
        </div>
      </Cell>

      <Cell col={3} tablet={12} className="search-form__textfield-container">
        <MdlSelect
          label="Created by (User)"
          options={users}
          className="always-floating-label"
          includeBlankOption="Name"
          selected={userIdFilter}
          onOptionSelected={(user) => setUserIdFilter(user && user.id)}
        />
      </Cell>

      <Cell col={3} tablet={12} className="search-form__buttons-container">
        <div className="search-form__buttons">
          <Button
            ripple
            className="search-form__button mdl-button--gray"
            onClick={handleSearch}
            disabled={!searchInput.trim().length && !userIdFilter}>
            Search
          </Button>
          {!!searchFilters.length && (
            <Button className="search-form__button mdl-button--light" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          )}
        </div>
      </Cell>

      <Cell col={12} className="search-form__filter-chips">
        {!!searchFilters.length ? <h2 className="visuallyhidden">Current Filters</h2> : undefined}
        {searchFilters.map((filter) => (
          <FilterChip key={filter} filter={filter} onRemove={handleRemoveFilter} />
        ))}
      </Cell>
    </Grid>
  )
}

SearchFormDropdown.propTypes = propTypes
export default SearchFormDropdown
