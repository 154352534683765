import { Cell, Checkbox, Grid, RadioGroup, Radio, Textfield } from 'react-mdl'

const { FlatDatetimepicker } = window

const CampaignSettingsForm = ({ emailCampaign, errors }) => {
  const frequencies = { once: 'Send Once', recurring: 'Recurring every' }
  const intervalUnits = { hours: 'Hours', days: 'Days' }

  const [frequency, setFrequency] = React.useState(emailCampaign.frequency)
  const [interval, setIntervalVal] = React.useState(emailCampaign.interval || 1)
  const [intervalUnit, setIntervalUnit] = React.useState(emailCampaign.interval_unit || 'hours')

  const [nonExpiring, setNonExpiring] = React.useState(emailCampaign.non_expiring)

  const [startTime, setStartTime] = React.useState(emailCampaign.start_time)
  const [endTime, setEndTime] = React.useState(emailCampaign.end_time)

  const dateFormat = 'MMMM DD, YYYY - hh:mmA z'
  const fieldName = (attr) => `outreach_email_campaign[${attr}]`
  const errorMsg = (attr) => (errors[attr] || []).filter((m) => m !== 'is invalid').join(', ')

  return (
    <section>
      <h2 className="text--med-lg m-0">Settings</h2>
      <fieldset>
        <legend className="text--med text--lightgray px-0">Frequency</legend>
        <RadioGroup
          name={fieldName('frequency')}
          className="mdl-radio-collection d-inline-block"
          onChange={(e) => setFrequency(e.target.value)}
          value={frequency}>
          {Object.keys(frequencies).map((key, idx) => {
            return (
              <Radio key={`mdl-radio-${idx}`} value={key}>
                {frequencies[key]}
              </Radio>
            )
          })}
        </RadioGroup>
        <Textfield
          type="number"
          name={fieldName('interval')}
          label=""
          min="1"
          max="365"
          value={interval}
          onChange={(e) => setIntervalVal(parseInt(e.target.value) || 1)}
          style={{ width: '50px', margin: '0 10px 0 10px' }}
          disabled={frequency === 'once'}
        />
        <MdlSelect
          name={fieldName('interval_unit')}
          label=""
          className=""
          style={{ width: '80px' }}
          selected={intervalUnit}
          onOptionSelected={(option) => setIntervalUnit(option.id)}
          disabled={frequency === 'once'}
          options={Object.keys(intervalUnits).map((key) => ({
            id: key,
            name: intervalUnits[key],
          }))}
        />
        {frequency !== 'once' && (
          <>
            <Checkbox
              label="Non-Expiring"
              checked={nonExpiring}
              className="ml-10 u-max-width--120"
              onChange={(e) => setNonExpiring(e.target.checked)}
            />

            <input type="hidden" name={fieldName('non_expiring')} value={nonExpiring} />
          </>
        )}
      </fieldset>
      <Grid className="block--full pl-0">
        <Cell className="ml-0" col={3}>
          <FlatDatetimepicker
            label="* Start Time"
            name={fieldName('start_time')}
            dateFormat={dateFormat}
            value={startTime}
            onChange={(dates) => setStartTime(dates[0])}
            error={errorMsg('start_time')}
          />
        </Cell>
        {!nonExpiring && frequency !== 'once' && (
          <Cell col={3}>
            <FlatDatetimepicker
              label="* End Time"
              name={fieldName('end_time')}
              dateFormat={dateFormat}
              value={endTime}
              onChange={(dates) => setEndTime(dates[0])}
              error={errorMsg('end_time')}
            />
          </Cell>
        )}
      </Grid>
    </section>
  )
}

export default CampaignSettingsForm
