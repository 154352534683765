import qs from 'qs'
import useFetch from 'use-http'
import { useHistoryState } from '@hooks'

const { createContext } = React
const { _ } = window
const { reaction } = mobx
const { useLocalStore } = mobxReactLite

export const MAP_MODES = {
  PARK: 'PARK',
  SECTION: 'SECTION',
  LOOP: 'LOOP',
}

export const DROP_TYPES = {
  SITE: 'SITE',
  FACILITY: 'FACILITY',
}

const EVENTS = {
  ADD: 'placed',
  REMOVE: 'unplaced',
  MOVE: 'moved',
}

export const MapViewContext = createContext(null)
const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

export const useMapViewStore = (park, endpoint, backPath, canEdit) => {
  const { replaceState } = useHistoryState()
  const { initialSection, initialLoop, initialSite } = findInitialZones(park)
  const initialMode = initialLoop
    ? MAP_MODES.LOOP
    : initialSection
    ? MAP_MODES.SECTION
    : MAP_MODES.PARK

  const { post, put, del, response } = useFetch(endpoint, {
    headers: window.__AMS__.formToken,
    cachePolicy: 'no-cache',
  })

  const store = useLocalStore(() => ({
    park,
    backPath,
    map: null,
    layerGroup: null,
    canEdit: canEdit,
    editMode: params.edit_mode || false,
    markers: {},
    mode: initialMode,
    hoverMarker: null,
    selectedSection: initialSection ?? null,
    selectedLoop: initialLoop ?? null,
    selectedSite: initialSite ?? null,
    isDragging: false,
    lastEvent: null,
    setLastEvent(obj) {
      this.lastEvent = obj
    },
    get allMarkers() {
      return Object.values(this.markers).flat()
    },
    get loopsInSection() {
      return this.selectedSection
        ? this.park.medium_zones.filter((s) => s.parent_zone_id === this.selectedSection.id)
        : []
    },
    get loopMarkersInSection() {
      const ids = this.loopsInSection.map((i) => i.id)
      return this.selectedSection
        ? this.markers.loopMarkers.filter((m) => ids.includes(m.item_id))
        : []
    },
    get sitesInSection() {
      return this.selectedSection
        ? this.park.small_zones.filter((s) => s.parent_large_zone_id === this.selectedSection.id)
        : []
    },
    get siteMarkersInSection() {
      const ids = this.sitesInSection.map((i) => i.id)
      return this.selectedSection
        ? this.markers.siteMarkers.filter((m) => ids.includes(m.item_id))
        : []
    },
    get sitesInLoop() {
      const sites = this.selectedLoop
        ? this.park.small_zones.filter((s) => s.parent_zone_id === this.selectedLoop.id)
        : []

      // Sort unplaced sites at the top when in edit mode
      return this.editMode
        ? _.sortBy(sites, (s) => [!s.unplaced, s.name])
        : _.sortBy(sites, (s) => s.name)
    },
    get siteMarkersInLoop() {
      if (!this.selectedLoop) {
        return []
      }

      const ids = this.sitesInLoop.map((i) => i.id)
      const sites = this.markers.siteMarkers.filter((m) => ids.includes(m.item_id))
      const loop = this.markers.loopMarkers.find((m) => m.item_id === this.selectedLoop.id)
      return [loop, ...sites].filter((i) => !!i)
    },
    setMarkers(markers) {
      this.markers = markers
    },
    setHoverMarker(marker) {
      this.hoverMarker = marker
    },
    setSelectedSection(section) {
      this.selectedSection = section
      this.mode = section ? MAP_MODES.SECTION : MAP_MODES.PARK
    },
    setSelectedLoop(loop) {
      this.selectedLoop = loop
      this.mode = loop ? MAP_MODES.LOOP : MAP_MODES.SECTION
    },
    setSelectedSite(site) {
      this.selectedSite = site
    },
    setMap(map) {
      this.map = map
    },
    toggleEditMode() {
      if (this.canEdit) {
        this.editMode = !this.editMode
      }
    },
    // When a site is added or removed, we need to update its parents
    // if there are any unplaced sites
    refreshUnplacedSites() {
      this.park.large_zones.forEach((section) => {
        section.any_unplaced_sites = this.park.small_zones
          .filter((s) => s.parent_large_zone_id === section.id && s.active)
          .some((s) => s.unplaced)
      })
      this.park.medium_zones.forEach((loop) => {
        loop.any_unplaced_sites = this.park.small_zones
          .filter((s) => s.parent_zone_id === loop.id && s.active)
          .some((s) => s.unplaced)
      })
    },
    // Push changes from the API to individual edited sites
    updateSiteStore(params) {
      const idx = this.park.small_zones.findIndex((i) => i.id === params.id)
      if (idx !== -1) {
        this.park.small_zones[idx] = params
        this.refreshUnplacedSites()
      }
    },
    addSite: async function(site, marker) {
      const coords = marker.marker.getLatLng()

      this.markers.siteMarkers.push(marker)

      const res = await put(`/update_site/${site.id}`, {
        latitude: coords.lat,
        longitude: coords.lng,
      })

      if (response.ok) {
        this.updateSiteStore(res)
        this.lastEvent = { verb: EVENTS.ADD, name: res.name }
      }
    },
    updateSite: async function(site, marker) {
      const coords = marker.getLatLng()

      const res = await put(`/update_site/${site.id}`, {
        latitude: coords.lat,
        longitude: coords.lng,
      })

      if (response.ok) {
        this.updateSiteStore(res)
        this.lastEvent = { verb: EVENTS.MOVE, name: res.name }
      }
    },
    removeSite: async function(site) {
      const idx = this.markers.siteMarkers.findIndex((i) => i.item_id === site.id)
      if (idx !== -1) {
        this.markers.siteMarkers.splice(idx, 1)
      }

      const res = await put(`/update_site/${site.id}`, {
        latitude: null,
        longitude: null,
      })

      if (response.ok) {
        this.updateSiteStore(res)
        this.lastEvent = { verb: EVENTS.REMOVE, name: res.name }
      }
    },
    updateFacilityStore(params, remove = false) {
      const idx = this.park.facility_locations.findIndex((i) => i.id === params.id)

      if (remove && idx !== 1) {
        this.park.facility_locations.splice(idx, 1)
      } else if (idx !== -1) {
        this.park.facility_locations[idx] = params
      } else {
        this.park.facility_locations.push(params)
      }
    },
    addFacility: async function(facLocation, marker) {
      const coords = marker.marker.getLatLng()

      this.markers.facilityMarkers.push(marker)

      const res = await post(`/add_facility`, {
        facility_id: facLocation.facility_id,
        latitude: coords.lat,
        longitude: coords.lng,
      })

      if (response.ok) {
        // TODO: clean this up
        // need to re-bind all the events on the marker once the API responds w/ a saved record
        marker.item_id = res.id
        marker.markable.id = res.id
        marker.rebindEvents()
        this.updateFacilityStore(res)
        this.lastEvent = { verb: EVENTS.ADD, name: res.name }
      }
    },
    updateFacility: async function(fac, marker) {
      const coords = marker.getLatLng()

      const res = await put(`/update_facility/${fac.id}`, {
        latitude: coords.lat,
        longitude: coords.lng,
      })

      if (response.ok) {
        this.updateFacilityStore(res)
        this.lastEvent = { verb: EVENTS.MOVE, name: res.name }
      }
    },
    removeFacility: async function(fac) {
      const idx = this.markers.facilityMarkers.findIndex((i) => i.item_id === fac.id)
      if (idx !== -1) {
        this.markers.facilityMarkers.splice(idx, 1)
      }

      await del(`/remove_facility/${fac.id}`)

      if (response.ok) {
        this.updateFacilityStore(fac, true)
        this.lastEvent = { verb: EVENTS.REMOVE, name: fac.name }
      }
    },
  }))

  // In edit mode, display a popup for events like adding, removing, or moving a site
  reaction(
    () => !!store.lastEvent,
    (obj) => {
      if (obj) {
        store.setLastEvent(null)
      }
    },
    { delay: 5000 }
  )

  // Add or remove selections from the browser URL
  reaction(
    () => [store.selectedSite, store.selectedLoop, store.selectedSection, store.editMode],
    () => {
      replaceState({
        section_id: store.selectedSection?.id,
        loop_id: store.selectedLoop?.id,
        site_id: store.selectedSite?.id,
        edit_mode: store.editMode || undefined,
      })
    }
  )

  return store
}

// Set initial zones on load based on URL params
const findInitialZones = (park) => {
  const initialLoop = park.medium_zones.find(
    (z) => z.id === (params?.loop_id ? parseInt(params.loop_id) : null)
  )

  const initialSection = park.large_zones.find((z) => {
    if (initialLoop) {
      return z.id === initialLoop.parent_zone_id
    } else {
      return z.id === (params?.section_id ? parseInt(params.section_id) : null)
    }
  })

  const initialSite = park.small_zones.find(
    (z) => z.id === (params?.site_id ? parseInt(params.site_id) : null)
  )

  return { initialSection, initialLoop, initialSite }
}
