// Input for selecting a date range
//
// Usage:
//   <Rangepicker
//     value={["2020-10-10T00:00:00-05:00", "2020-10-12T00:00:00-05:00"]}
//     label="Select Dates"
//     placeholder="Select Date Range"
//     dateFormat={'MM/DD/YYYY'}
//     onChange={(value) => {
//       setStartDate(value[0])
//       setEndDate(value[1])
//     }}
//     options={{ showMonths: 2 }}
//   />
//

import { formatDateFromDb } from '@utils'
const { FlatDatepicker } = window

const propTypes = {
  // Array of either a single date or start/end dates
  value: PropTypes.arrayOf(PropTypes.string).isRequired,

  // display format ex: 'MM/DD/YYYY'
  dateFormat: PropTypes.string.isRequired,

  // placeholder to display
  placeholder: PropTypes.string,

  // disable the rangepicker
  disabled: PropTypes.bool,

  // onChange function that will be passed an array of
  // formatted dates based on dateFormat
  onChange: PropTypes.func,

  // flatpickr options such as { showMonths: 2 }
  options: PropTypes.object,
}

const Rangepicker = ({
  value,
  placeholder,
  dateFormat,
  disabled,
  onChange,
  enableSingleDate,
  options = {},
  ...props
}) => {
  return (
    <FlatDatepicker
      placeholder={placeholder || `${dateFormat} to ${dateFormat}`}
      value={value.map((date) => formatDateFromDb(date, dateFormat))}
      dateFormat={dateFormat}
      onChange={(dates) => {
        if (onChange) {
          // FlatDatepicker already maps date array to formatted db dates
          onChange(dates)
        }
      }}
      options={{ allowInput: true, mode: 'range', ...options }}
      disabled={disabled}
      {...props}
    />
  )
}

Rangepicker.propTypes = propTypes
export default Rangepicker
