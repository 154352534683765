import ReactDOMServer from 'react-dom/server'
import { Card, Grid, Cell, Button, Icon } from 'react-mdl'
import { NpmapIcon } from '@components/shared'

export const sitePopup = (park, site, editMode = false) => {
  return ReactDOMServer.renderToStaticMarkup(
    <Card style={{ maxWidth: '280px' }}>
      <Grid className="block--full">
        <Cell col={12}>
          <div className="d-inline-block bg--lightgray p-10">
            <NpmapIcon name={site.icon_name} className="mr-10" style={{ width: '18px' }} />
            {site.name}
          </div>
        </Cell>
        <Cell col={12}>
          <p className="text--lightgray">Site Type</p>
          <span className="text--med">{site.site_type.name}</span>
        </Cell>
        <Cell col={12}>
          <p className="text--lightgray">Min/Max Stay</p>
          <span className="text--med">
            Min {site.site_type.min_stay} Night(s) / Max {site.site_type.max_stay} Night(s)
          </span>
        </Cell>
        <Cell col={6}>
          <p className="text--lightgray">Max Occupancy</p>
          <span className="text--med">{site.site_type.max_occupancy}</span>
        </Cell>
        <Cell col={6}>
          <p className="text--lightgray">Max Vehicles</p>
          <span className="text--med">{site.site_type.max_vehicles}</span>
        </Cell>
        <Cell col={12}>
          {editMode ? (
            <Button
              id={`remove-site-popup-${site.id}`}
              className="mdl-button--light block--full mt-10">
              Remove from Map
            </Button>
          ) : null}

          {editMode ? (
            <Button
              accent
              className="block--full mt-10"
              href={`${park.path}/sites/${site.id}/edit`}
              target="_BLANK">
              Edit Site Details <Icon name="open_in_new" className="md-18" />
            </Button>
          ) : (
            <Button
              accent
              className="block--full mt-10"
              href={`${park.path}/sites/${site.id}`}
              target="_BLANK">
              View Site Details <Icon name="open_in_new" className="md-18" />
            </Button>
          )}
        </Cell>
      </Grid>
    </Card>
  )
}

export const facilityPopup = (park, fac, editMode = false) => {
  return ReactDOMServer.renderToStaticMarkup(
    <Card style={{ maxWidth: '280px' }}>
      <Grid className="block--full">
        <Cell col={12}>
          <div className="d-inline-block bg--lightgray p-10">
            <NpmapIcon name={fac.icon_name} className="mr-10" style={{ width: '18px' }} />
            {fac.name}
          </div>
        </Cell>
        <Cell col={12}>
          <p className="text--lightgray">Description</p>
          <div dangerouslySetInnerHTML={{ __html: fac.description }}></div>
        </Cell>
        <Cell col={12}>
          {editMode ? (
            <Button
              id={`remove-facility-popup-${fac.id}`}
              className="mdl-button--light block--full mt-10">
              Remove from Map
            </Button>
          ) : null}
        </Cell>
      </Grid>
    </Card>
  )
}
