import { Button, Icon } from 'react-mdl'
import classNames from 'classnames'
import BtnSpinner from './BtnSpinner'

// Custom button helper components

// Workaround for react-mdl's IconButton, which doesn't use
// aria-hidden for presentation-only icons
const BtnIcon = ({ className, label, name, light = false, gray = false, ...props }) => {
  let btnClass = classNames('mdl-button--icon', className, {
    'mdl-button--light': light,
    'mdl-button--gray': gray,
  })

  return (
    <Button type="button" className={btnClass} aria-label={label} {...props}>
      <Icon name={name} aria-hidden="true" />
    </Button>
  )
}

export const Btn = {
  Icon: BtnIcon,
  Spinner: BtnSpinner,
}
