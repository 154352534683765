import qs from 'qs'

const moment = window.moment

export const getNumeric = (value) => (!!+value ? +value : 0)

export const formatCurrency = (value) => getNumeric(value).toFixed(2)

export const formatDollars = (value) => {
  const amount = getNumeric(value)

  // Display negative dollars, e.g. "-$5.00"
  if (amount < 0) {
    return `-$${Math.abs(amount).toFixed(2)}`
  } else {
    return `$${amount.toFixed(2)}`
  }
}

export const formatFieldName = (resource) => (attr) => `${resource}[${attr}]`

export const formatTitleCase = (string) => {
  return string.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase())
}

export const getPercent = (partial, total) =>
  getNumeric(total) > 0 ? +((getNumeric(partial) / getNumeric(total)) * 100).toFixed(2) : 0

export const getSum = (...values) =>
  values.reduce((accumulator, current) => accumulator + getNumeric(current), 0)

export const roundToQuarter = (value) => {
  return (Math.round(parseFloat(value) * 4) / 4).toFixed(2)
}

export const formatDateToDb = (dateString, dateFormat = 'MM/DD/YYYY') => {
  return dateString ? moment(dateString, dateFormat).format('YYYY-MM-DD') : undefined
}

export const formatDateFromDb = (dateString, dateFormat = 'MM/DD/YYYY') => {
  return dateString ? moment(dateString, 'YYYY-MM-DD').format(dateFormat) : undefined
}

export const paramsToString = (obj) => {
  const queryString = qs.stringify(obj, { arrayFormat: 'brackets' })
  return queryString.length > 0 ? `?${queryString}` : ''
}
