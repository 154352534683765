// Returns an array of indexes in the segmented attributes tree for each level matching the condition statement.
// Used to set the initial state for new/edit UI from a complete condition statement
export const findOptionIndexes = (conditionStatement, children, currentStatement = {}) => {
  let childPath = []
  const index = children.findIndex((child) => {
    const s = { ...currentStatement, ...child }
    if (child.children) {
      childPath = findOptionIndexes(conditionStatement, child.children, s)
      return childPath
    } else {
      // Base Case - Will always terminate here
      return (
        s.column === conditionStatement.column &&
        s.operator === conditionStatement.operator &&
        (!s.value || s.value === conditionStatement.value)
      )
    }
  })

  if (index === -1) return null
  return [index, ...childPath]
}

// Given an array of indexes in the segmented attributes tree returns all the attributes to get to that point.
export const mergeCurrentAttributes = (
  conditionChildren,
  indexes,
  currentStatement = { path: [] }
) => {
  if (indexes.length === 0) {
    return {}
  }
  const [headIndex, ...tailIndexes] = indexes
  const child = conditionChildren[headIndex]
  const c = { ...currentStatement, ...child }
  delete c.children
  c.path = [...c.path, child.name]
  if (tailIndexes.length > 0 && child.children)
    return mergeCurrentAttributes(child.children, tailIndexes, c)
  else return c
}
