import { Button, Grid, Cell } from 'react-mdl'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { Btn, FC, RichTextEditor, Modal } from '@components/shared'
import { useNestedAttributes } from '@hooks'
const { useState, useEffect, Fragment } = React
const { Expandy, MdlSelect, _ } = window

const GatesFields = ({ addressProps }) => {
  const { fields } = useFormContext()
  const { append } = useNestedAttributes()
  const initialGates = Object.entries(fields.park_gates_attributes || {}).map((s) => s?.[1])
  const [gates, setGates] = useState(initialGates)
  const [deletingGate, setDeletingGate] = useState(null)

  const addGate = () => {
    setGates(append('park_gates_attributes', gates, { name: 'New Gate' }))
  }

  const deleteGate = (e) => {
    const { gate, idx } = deletingGate
    e.preventDefault()

    if (gate.id?.value) {
      const allGates = [...gates]
      allGates[idx]._destroy.value = true
      setGates(allGates)
    } else {
      setGates(gates.filter((_s, i) => i !== idx))
    }

    setDeletingGate(null)
  }

  const deleteConfirm = (gate, idx) => () => {
    setDeletingGate({ gate, idx })
  }

  const deleteCancel = (e) => {
    e.preventDefault()
    setDeletingGate(null)
  }

  return (
    <Fragment>
      {gates.map((gate, idx) => {
        return (
          <Fragment key={`gate-${idx}`}>
            <input type="hidden" value={gate.id.value || ''} name={gate.id.input_name} />
            <input
              type="hidden"
              value={gate._destroy.value || false}
              name={gate._destroy.input_name}
            />
            {gate._destroy.value !== true ? (
              <GateFields
                gate={gate}
                idx={idx}
                defaultExpanded={gates.length === 1}
                deleteConfirm={deleteConfirm}
                addressProps={addressProps}
              />
            ) : (
              undefined
            )}
          </Fragment>
        )
      })}

      <Grid className="u-padding--20">
        <Cell col={12}>
          <Button accent type="button" onClick={addGate}>
            Add Another Gate
          </Button>
        </Cell>
      </Grid>

      <Modal open={!!deletingGate} id="confirm_delete" onClose={deleteCancel}>
        <Modal.Title id="confirm_delete">Are you sure you want to delete this gate?</Modal.Title>
        <Modal.Content>
          <p>
            Deleting this gate will delete it permanently from the system and it will no longer be
            seen under the park detail operations.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" className="mdl-button--gray" onClick={deleteGate}>
            Delete
          </Button>
          <Button type="button" className="mdl-button--light" onClick={deleteCancel}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  )
}

const GateFields = ({ gate, idx, defaultExpanded = false, deleteConfirm, addressProps }) => {
  const { setValue, errors } = useFormContext()
  const gateErrors = _.get(errors, `${gate.id.base_input_name}[${idx}]`)
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)
  const address = gate.address_attributes

  useEffect(() => {
    if (gateErrors) {
      setIsExpanded(true)
    }
  }, [gateErrors])

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Grid className="mdl-grid--no-spacing">
      <Cell
        col={12}
        className={classNames('u-border--bottom u-padding--x-10', {
          'mdl-shadow--2dp': isExpanded,
        })}>
        <Grid>
          <Cell col={10}>
            <h4 className="text--med-lg u-margin--y-none">{gate.name.value}</h4>
          </Cell>
          <Cell col={2}>
            <Expandy
              onClick={toggleExpanded}
              expanded={isExpanded}
              aria-label={`Expand Gate ${gate.name.value}`}
              className="u-pull--right"
            />
            {idx > 0 ? (
              <Btn.Icon
                light
                name="delete"
                label="Delete Gate"
                className="u-pull--right"
                onClick={deleteConfirm(gate, idx)}
              />
            ) : (
              undefined
            )}
          </Cell>
        </Grid>
      </Cell>
      <Cell col={12} className={classNames('bg--lightgray', { hide: !isExpanded })}>
        <Grid className="u-padding--20">
          <Cell col={6}>
            <FC.Input field={gate.name} />
          </Cell>
          <Cell col={6}>
            <FC.Input field={gate.gate_number} />
          </Cell>
          <Cell col={6}>
            <FC.TimePicker field={gate.open_time} />
          </Cell>
          <Cell col={6}>
            <FC.TimePicker field={gate.close_time} />
          </Cell>
          <Cell col={3}>
            <FC.Input field={gate.latitude} placeholder="00.00" />
          </Cell>
          <Cell col={3}>
            <FC.Input field={gate.longitude} placeholder="00.00" />
          </Cell>
          <Cell col={12} className="u-margin--none">
            <GateAddressFields address={address} {...addressProps} />

            <Grid className="u-padding--none">
              <Cell col={12} className="u-padding--bottom-20">
                <label
                  className="text--small text--lightblue u-margin--bottom-7"
                  htmlFor={gate.location_description.input_name}>
                  Location Description
                </label>
                <p>Write any special instructions your customers need to know, if necessary</p>

                <FC.Hidden field={gate.location_description} />
                <RichTextEditor
                  hideHeadingBlock
                  hideCodeBlock
                  hideFileTools
                  value={gate.location_description.value}
                  onChange={(value) => setValue(gate.location_description.input_name, value)}
                  name={`location_description[${idx}]`}
                  label={gate.location_description.label}
                />

                {gateErrors?.location_description?.message ? (
                  <span className="mdl-textfield__error">
                    {gateErrors?.location_description?.message}
                  </span>
                ) : (
                  undefined
                )}
              </Cell>
              <Cell col={12}>
                {idx > 0 ? (
                  <Button
                    type="button"
                    className="mdl-button--light"
                    onClick={deleteConfirm(gate, idx)}>
                    Delete Gate
                  </Button>
                ) : (
                  undefined
                )}
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </Cell>
    </Grid>
  )
}

const GateAddressFields = ({ address, countries, states }) => {
  const { setValue, watch } = useFormContext()

  return (
    <Grid className="u-padding--none flex__items--end">
      <input type="hidden" value={address.id.value || ''} name={address.id.input_name} />
      <Cell col={6}>
        <FC.Input field={address.address1} />
      </Cell>
      <Cell col={6}>
        <FC.Input field={address.address2} />
      </Cell>
      <Cell col={3}>
        <FC.Input field={address.city} />
      </Cell>
      <Cell col={3}>
        <FC.Input field={address.postal_code} />
      </Cell>
      <Cell col={3}>
        <FC.Input
          field={address.subregion}
          as={MdlSelect}
          options={states}
          selected={watch(address.subregion.input_name, address.subregion.value)}
          includeBlankOption={true}
          onOptionSelected={(option) => setValue(address.subregion.input_name, option?.id)}
        />
      </Cell>
      <Cell col={3}>
        <FC.Input
          field={address.country}
          as={MdlSelect}
          options={countries}
          selected={watch(address.country.input_name, address.country.value)}
          includeBlankOption={true}
          onOptionSelected={(option) => setValue(address.country.input_name, option?.id)}
        />
      </Cell>
    </Grid>
  )
}

export default GatesFields
