import { Textfield } from 'react-mdl'
import { AmsButton, Modal } from '@components/shared'

const NotDeliveredFragment = ({ email, error, onChange }) => (
  <>
    <p className="mb-0">
      Send a test email to a specified email address to see a real preview of your campaign before
      releasing it to your contacts.
    </p>
    <Textfield
      floatingLabel
      label="Send To (Email Address)"
      placeholder="Enter test email address"
      className="mt-20 textfield__input--full"
      value={email}
      error={error ? 'Email could not be sent. Please try another email address.' : false}
      onChange={onChange}
    />
  </>
)

const DeliveredFragment = ({ email, onClick }) => (
  <>
    <p className="mb-5">
      A test email has been sent to <span className="text--bold text--darkgray">{email}</span>
    </p>
    <a href="#error" onClick={onClick} className="text--bold u-text-decoration--none">
      Change Email Address
    </a>
  </>
)

const TestEmailModal = ({ email, open, state, onChange, onReset, onCancel, onSubmit }) => {
  const handleKeyPress = React.useCallback((event) => {
    if (/^enter$/i.test(event.key)) {
      event.preventDefault()
    }
  }, [])

  React.useEffect(() => {
    if (open) {
      window.addEventListener('keypress', handleKeyPress)
    } else {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [open, handleKeyPress])

  return (
    <Modal open={open} id="test-email-modal" className="text--left">
      <Modal.Title className="pt-10">Send Test Email</Modal.Title>
      <Modal.Content>
        {state === 'delivered' ? (
          <DeliveredFragment email={email} onClick={onReset} />
        ) : (
          <NotDeliveredFragment email={email} onChange={onChange} error={state === 'error'} />
        )}

        <div className="text--right pt-20">
          <AmsButton variant="light" type="button" onClick={onCancel}>
            Cancel
          </AmsButton>

          <AmsButton variant="gray" type="button" onClick={onSubmit}>
            {state !== 'delivered' ? 'Send Test' : 'Resend Test'}
          </AmsButton>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default TestEmailModal
