import queryString from 'query-string'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { Card } from 'react-mdl'
import { DataTable, SearchForm, Paginate } from '@components/shared'
const { useState, useEffect, useCallback, useMemo } = React

const initialSortBy = 'name'
const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

const propTypes = {
  endpoint: PropTypes.string.isRequired,
  // Array of formatted columns for each row
  columns: PropTypes.array.isRequired,
}

const SearchTraits = ({ endpoint, columns }) => {
  // Maintain state of current search filters and sort options
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
  })

  // Fetch traits on load or when the searchUrl changes
  const { loading, get } = useFetch(searchUrl)
  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    get().then((res) => setData(res))
  }, [searchUrl, get])

  // Activate or deactivate individual traits and update the index with the new value
  const activateReq = useFetch(endpoint, { headers: window.__AMS__.formToken })
  const toggleActivate = useCallback(
    (row) => async (e) => {
      const newData = { ...data }
      const rowIdx = newData.results.findIndex((r) => r.id === row.id)
      const currentRow = newData.results[rowIdx]
      currentRow.active = e.target.checked
      setData(newData)

      activateReq.cache.clear()
      await activateReq.put(`/${row.id}/activate.json`, {
        camp_trait: { active: e.target.checked },
      })
    },
    [data, activateReq]
  )

  // Append options & toggle function to each row
  const mappedRows = useMemo(() => {
    return data.results.map((row) => ({
      ...row,
      toggleActivate: toggleActivate(row),
      mdlRowProps: { disabled: !row.active },
    }))
  }, [data, toggleActivate])

  const topic = searchState.query.length > 0 ? 'Attribute Search Results' : 'All Attributes'

  return (
    <Card shadow={0} className="block--full">
      <SearchForm
        searchFilters={searchState.query}
        onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
        placeholder="Search Attributes"
        helperText="Search Attribute Name and Category Name"
      />

      <DataTable
        topic={topic}
        loading={loading}
        rows={mappedRows}
        columns={columns}
        rowKeyColumn="id"
        onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
        initialSortBy={searchState.order}
        initialIsSortAcending={searchState.asc === 1}
      />

      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}
    </Card>
  )
}

export default SearchTraits
