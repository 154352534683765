import { Grid, Cell, Textfield, Button } from 'react-mdl'
import classNames from 'classnames'
import useFetch from 'use-http'
import { useForm, Controller } from 'react-hook-form'
import { Well, Modal, Btn } from '@components/shared'
const { useState, useMemo } = React

const Checkout = ({
  customer,
  isRefund = false,
  initialType = null,
  paymentDetail,
  paymentTypes,
  paymentTypeGroups,
  endpoint,
  confirmationPath,
  receiptPath,
}) => {
  const initialGroup =
    paymentTypeGroups.find((g) => g.id === initialType?.payment_type_group_id) || null
  const [selectedType, setSelectedType] = useState(initialType)
  const [currentForm, setCurrentForm] = useState(initialGroup)
  const [success, setSuccess] = useState(false)

  const { post, loading, response } = useFetch(endpoint, {
    headers: window.__AMS__.formToken,
    cachePolicy: 'no-cache',
  })
  const { control, handleSubmit, errors, setError } = useForm()

  const selectMethod = (group, newType) => () => {
    setSelectedType(newType)
    setCurrentForm(group.name)
  }

  const onSubmit = async (data) => {
    const res = await post('/', data)

    if (res.errors) {
      Object.entries(res.errors).forEach(([key, ary]) => {
        setError(`payment[${key}]`, { type: 'manual', message: ary.join(', ') })
      })
    } else if (response.ok) {
      setSuccess(true)
    }
  }

  const groupedTypes = useMemo(
    () =>
      paymentTypes.reduce((curr, val) => {
        const group = curr.find((i) => i.id === val.payment_type_group_id)

        if (!group.payment_types) {
          group.payment_types = []
        }

        group.payment_types.push(val)
        return curr
      }, paymentTypeGroups),
    [paymentTypes, paymentTypeGroups]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isRefund ? (
        <Grid className="px-20">
          <Cell col={12} className="pb-20 u-border--bottom">
            <h3 className="text--med m-0">Payment Method</h3>
            {groupedTypes.map((group) => (
              <Grid noSpacing key={group.id}>
                <Cell col={12}>
                  <h4 className="text--med-small my-10">{group.name}</h4>
                </Cell>
                <Cell col={12} className="d-flex flex__justify--start">
                  {group.payment_types.map((type) => (
                    <button
                      key={type.id}
                      type="button"
                      style={{ width: '250px' }}
                      className={classNames(
                        'm-5 px-20 py-10 text--med u-border--round mdl-shadow--2dp u-border--none pointer d-flex flex__items--center flex__justify--center',
                        type.id === selectedType?.id
                          ? 'bg--lightblue text--white'
                          : 'bg--well text--darkblue'
                      )}
                      onClick={selectMethod(group, type)}>
                      <i className="material-icons md-36 mr-10" aria-hidden>
                        {group.name === 'Credit' ? 'credit_card' : 'local_atm'}
                      </i>

                      {type.name}
                    </button>
                  ))}
                </Cell>
              </Grid>
            ))}
          </Cell>
        </Grid>
      ) : null}

      {currentForm === 'Other' && !isRefund ? (
        <Grid className="px-20 pb-20 u-border--bottom">
          <Controller
            as={<input />}
            control={control}
            type="hidden"
            defaultValue="cash"
            name="payment[payment_type]"
            id="payment[payment_type]"
          />

          <Cell col={12}>
            <h3 className="text--med">How much has the customer handed you?</h3>
          </Cell>
          <Cell col={3}>
            <Controller
              as={Textfield}
              floatingLabel
              name="payment[amount]"
              label="Amount Received"
              placeholder="$00.00"
              defaultValue=""
              control={control}
              error={errors?.payment?.amount?.message}
            />
          </Cell>
          <Cell col={9}>
            <Well
              icon="warning"
              iconClass="text--darkyellow"
              content="Ensure that you entered the correct amount received in order to hand the right change to the customer"
            />
          </Cell>
        </Grid>
      ) : null}
      {currentForm === 'Credit' && !isRefund ? (
        <Grid className="px-20 pb-20 u-border--bottom">
          <Controller
            as={<input />}
            control={control}
            type="hidden"
            defaultValue="credit"
            name="payment[payment_type]"
            id="payment[payment_type]"
          />
          {errors?.payment ? (
            <Cell col={12}>
              <Well
                icon="error"
                iconClass="text--brightred"
                content="There was an issue with the credit card information provided. Please Try Again"
              />
            </Cell>
          ) : null}
          <Cell col={12}>
            <Controller
              as={Textfield}
              floatingLabel
              name="payment[name_on_card]"
              label="Full Name on Card"
              placeholder="Enter full name on card"
              defaultValue=""
              control={control}
              error={errors?.payment?.name_on_card?.message}
            />
          </Cell>
          <Cell col={3}>
            <Controller
              as={Textfield}
              floatingLabel
              name="payment[card_number]"
              label="Card Number"
              placeholder="**** **** **** ****"
              defaultValue=""
              control={control}
              error={errors?.payment?.card_number?.message}
            />
          </Cell>
          <Cell col={3}>
            <Controller
              as={Textfield}
              floatingLabel
              name="payment[expiry_date]"
              label="Expiry Date"
              placeholder="MM/YYYY"
              defaultValue=""
              control={control}
              error={errors?.payment?.expiry_date?.message}
            />
          </Cell>
          <Cell col={3}>
            <Controller
              as={Textfield}
              floatingLabel
              name="payment[csv]"
              label="CSV"
              placeholder="000"
              defaultValue=""
              control={control}
              error={errors?.payment?.csv?.message}
            />
          </Cell>
        </Grid>
      ) : null}

      {isRefund ? (
        <Grid className="p-20 text--med-lg">
          <Cell col={2}>
            <h5>Payment Type</h5>
            {initialType}
          </Cell>
          <Cell col={2}>
            <h5>Details</h5>
            {paymentDetail}
          </Cell>
        </Grid>
      ) : null}

      <Grid className="p-20">
        <Cell col={12} className="u--align-right">
          <Btn.Spinner
            loading={loading}
            disabled={!selectedType}
            type="submit"
            className="mdl-button--gray mdl-button--wide">
            {isRefund ? 'Process Refund' : 'Process Payment'}
          </Btn.Spinner>
        </Cell>
      </Grid>

      {success ? (
        <SuccessModal
          customer={customer}
          confirmationPath={confirmationPath}
          receiptPath={receiptPath}
        />
      ) : null}
    </form>
  )
}

const SuccessModal = ({ customer, confirmationPath, receiptPath }) => {
  return (
    <Modal open={true} id="transaction_success" allowEscape={false}>
      <Modal.Title id="transaction_success">Transaction Successful!</Modal.Title>
      <Modal.Content>
        <p className="text--med">
          A copy of the receipt was sent to <strong>{customer.email_address}</strong>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button href={confirmationPath} className="mdl-button--gray">
          Continue to Confirmation Page
        </Button>
        <Button href={receiptPath} target="_BLANK">
          Print Receipt
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default Checkout
