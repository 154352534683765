import { Textfield } from 'react-mdl'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import IsolatedScroll from 'react-isolated-scroll'

// Render a custom react-mdl Textfield component instead of a plain input
const TypeaheadInput = (textFieldProps) => {
  // Capture the input's ref sent from Autosuggest's `renderInputComponent`
  const { ref, className, fullWidth, ...inputProps } = textFieldProps

  // Textfield's input ref is stored in its `this.inputRef` property
  // So we need to bubble that up here for Autosuggest to use
  // Based off of the suggestion here: https://git.io/JJokZ
  const callAutosuggestRef = (mdlTextField) => {
    if (mdlTextField !== null) {
      // Notice `ref` is being called as a function here.
      // This is how Autosuggest's `this.input` is set.
      ref(mdlTextField.inputRef)
    }
  }

  return (
    <Textfield
      floatingLabel
      {...inputProps}
      ref={callAutosuggestRef}
      className={`${className} always-floating-label ${fullWidth ? 'textfield__input--full' : ''}`}
    />
  )
}

// Prevent scrolling the window when you reach the end of visible suggestions
// https://git.io/JJokZ
const TypeaheadSuggestionsContainer = ({ containerProps, children }) => {
  const { ref, ...restContainerProps } = containerProps
  // Similar to TypeaheadInput, we need to capture isolatedScroll's
  // `ref` via it's `component` property
  const callRef = (isolatedScroll) => {
    if (isolatedScroll !== null) {
      ref(isolatedScroll.component)
    }
  }

  return (
    <IsolatedScroll ref={callRef} {...restContainerProps}>
      {children}
    </IsolatedScroll>
  )
}

const TypeaheadSuggestion = ({ suggestion, query }) => {
  const matches = AutosuggestHighlightMatch(suggestion, query)
  const parts = AutosuggestHighlightParse(suggestion, matches)

  return (
    <span>
      {parts.map((part, index) => {
        const className = part.highlight ? 'text--darkblue' : null

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        )
      })}
    </span>
  )
}

export { TypeaheadInput, TypeaheadSuggestionsContainer, TypeaheadSuggestion }
