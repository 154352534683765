const CampaignExpandableTable = ({ item, columns, expandTarget }) => (
  <section>
    <div className="table__container--responsive">
      <table className="mdl-data-table block--full">
        <thead>
          <tr>
            {columns.map((c, idx) => (
              <th
                key={`header-${idx}`}
                className="mdl-data-table__cell--non-numeric"
                scope="col"
                style={{ width: c.width }}>
                <span className="text--med">{c.name}</span>
              </th>
            ))}

            <th className="mdl-data-table__cell--non-numeric" scope="col" style={{ width: '20%' }}>
              <span className="visuallyhidden">Expand Row</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {!!item ? (
            <tr>
              {columns.map((c, idx) => (
                <td key={`column-${idx}`} className="text--left">
                  {c.cellFormatter(item)}
                </td>
              ))}
              <td className="text--left">
                <button
                  type="button"
                  className="mdlext-js-collapsible mdl-button"
                  aria-controls={expandTarget}
                  aria-expanded="true">
                  <span className="mdlext-aria-expanded-more-less" aria-label="toggle" />
                </button>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className="p-0">
                <div className="data-table__no-results-slim">No list has been selected</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </section>
)

export default CampaignExpandableTable
