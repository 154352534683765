// MdlSelect is global
import { Grid, Cell, Button } from 'react-mdl'
import ChargeSplitFieldset from '../ChargeSplitFieldset'
import SurchargeFieldset from '../SurchargeFieldset'
import { NumericTextfield } from '@components/shared'
import { useCsrfToken } from '@hooks'
import { formatCurrency, formatTitleCase, generateErrors } from '@utils'

const { useState, Fragment } = React

const PricingRecordForm = ({
  name,
  url,
  cancelPath,
  method,
  errors: nestedErrors = {},
  productVariation = {},
  transactionTypes = [],
  salesChannels = [],
  locationClasses = [],
  accountFunds = [],
  surcharges = [],
  selectedTransactionTypeId = '',
  selectedLocationClassId = '',
  selectedSalesChannelId = '',
}) => {
  const errors = generateErrors(nestedErrors) || {}
  const { charges = [] } = productVariation || {}
  const { product_variation_surcharges: initialSurcharges = [] } = productVariation || {}
  const csrfToken = useCsrfToken()

  if (selectedSalesChannelId) productVariation.transaction_type_id = selectedTransactionTypeId
  if (selectedSalesChannelId) productVariation.sales_channel_id = selectedSalesChannelId
  if (selectedLocationClassId) productVariation.location_class_id = selectedLocationClassId

  const [selectedTransactionType, setSelectedTransactionType] = useState(
    transactionTypes.find((option) => option.id === productVariation.transaction_type_id)
  )
  const [selectedSalesChannel, setSelectedSalesChannel] = useState(
    salesChannels.find((option) => option.id === productVariation.sales_channel_id)
  )
  const [selectedLocationClass, setSelectedLocationClass] = useState(
    locationClasses.find((option) => option.id === productVariation.location_class_id)
  )
  const [contractorFee, setContractorFee] = useState(
    formatCurrency(productVariation.contractor_fee)
  )

  const handleOptionSelected = (setSelected) => (value) => {
    setSelected(value)
  }

  const handleContractorFeeFormat = (value) => {
    setContractorFee(value)
  }

  return (
    <form id={`${name}-form`} action={url} acceptCharset="UTF-8" method="post">
      <Grid className="u-padding--x-20 u-padding--top-20">
        <input type="hidden" name="_method" value={method} />
        <input type="hidden" name="authenticity_token" value={csrfToken} />

        <Cell col={12}>
          <MdlSelect
            label="Transaction Type"
            name={`${name}[transaction_type_id]`}
            options={transactionTypes}
            nameField="transaction_type"
            selected={selectedTransactionType}
            onOptionSelected={handleOptionSelected(setSelectedTransactionType)}
            className="always-floating-label"
            error={errors.transaction_type_id}
          />
        </Cell>
        <Cell col={12}>
          <MdlSelect
            label="Sales Channel"
            name={`${name}[sales_channel_id]`}
            options={salesChannels}
            selected={selectedSalesChannel}
            onOptionSelected={handleOptionSelected(setSelectedSalesChannel)}
            className="always-floating-label"
            error={errors.sales_channel_id}
          />
        </Cell>
        <Cell col={12}>
          <MdlSelect
            label="Location Class"
            name={`${name}[location_class_id]`}
            options={locationClasses}
            selected={selectedLocationClass}
            onOptionSelected={handleOptionSelected(setSelectedLocationClass)}
            className="always-floating-label"
            error={errors.location_class_id}
          />
        </Cell>
      </Grid>

      <div className="u-padding--x-30">
        <h2 className="text--med-lg u-margin--none">Price</h2>
      </div>

      {!!charges &&
        charges.map((charge, index) => {
          const inputName = `${name}[charges_attributes][${index}]`
          return (
            <Fragment key={charge.charge_type_cd}>
              <input type="hidden" name={`${inputName}[id]`} value={charge.id || ''} />
              <input type="hidden" name={`${inputName}[charge_type]`} value={charge.charge_type} />
              <ChargeSplitFieldset
                charge={charge}
                chargeType={charge.charge_type}
                chargeName="charge"
                chargeCanBePercent={false}
                label={formatTitleCase(charge.charge_type.replace('_', ' '))}
                name={inputName}
                accountFunds={accountFunds}
                className="u-margin--bottom-20"
                errors={errors.charges && errors.charges[index]}
              />
            </Fragment>
          )
        })}

      <Grid className="u-padding--x-20">
        <Cell col={2}>
          <NumericTextfield
            label="Contractor Fee (if applicable)"
            name={`${name}[contractor_fee]`}
            floatingLabel
            value={contractorFee}
            onFormat={handleContractorFeeFormat}
            format="currency"
            error={errors.contractor_fee}
          />
        </Cell>

        <Cell col={12}>
          <h2 className="text--med-lg u-margin--none">Taxes</h2>
          <SurchargeFieldset
            name={`${name}[product_variation_surcharges_attributes]`}
            surcharges={surcharges}
            initialSurcharges={initialSurcharges}
            taxOnly
          />
        </Cell>

        <Cell col={12} className="d-flex flex__justify--end">
          <Button href={cancelPath} className="mdl-button--light mdl-button--wide">
            Cancel
          </Button>
          <Button
            type="submit"
            className="mdl-button--gray mdl-button--wide mdl-button--wide-centered">
            Save
          </Button>
        </Cell>
      </Grid>
    </form>
  )
}

export default PricingRecordForm
