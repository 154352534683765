import { Tab, Tabs } from 'react-mdl'

const selectEndpoint = (activeTab, endpoints) => {
  if (activeTab === 2) {
    return endpoints.largeZones
  } else if (activeTab === 1) {
    return endpoints.mediumZones
  } else if (activeTab === 0) {
    return endpoints.smallZones
  }
}

const ZoneTabsHeader = ({ activeTab, zoneType, endpoints }) => {
  const endpoint = selectEndpoint(activeTab, endpoints)
  return (
    <React.Fragment>
      <Tabs
        className="mdl-layout__tab-bar mdl-layout__tab-bar--accent"
        activeTab={activeTab}
        ripple>
        <Tab className="mdl-layout__tab" href={endpoints.smallZones}>
          Sites
        </Tab>
        <Tab className="mdl-layout__tab" href={endpoints.mediumZones}>
          Loops
        </Tab>
        <Tab className="mdl-layout__tab" href={endpoints.largeZones}>
          Sections
        </Tab>
      </Tabs>

      <div className="mdl-grid mdl-grid--no-spacing flex__items--center search-form">
        <div className="mdl-cell mdl-cell--6-col">
          <h5>{zoneType}s List</h5>
        </div>
        <div className="mdl-cell mdl-cell--6-col">
          <a
            href={`${endpoint}/new`}
            className="mdl-button mdl-button--wide mdl-button--accent u-pull--right">
            New {zoneType}
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ZoneTabsHeader
