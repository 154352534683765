import { Cell, Grid, Textfield } from 'react-mdl'
import CampaignContentTable from './CampaignContentTable'
import TestEmailForm from '@components/outreach/TestEmailForm'

const CampaignContentForm = ({
  emailCampaign,
  errors,
  emailTemplates,
  emailTemplatePolicy,
  emailTemplateUrl,
  emailTemplatesUrl,
}) => {
  const prefill = emailTemplates.find((l) => l.id === emailCampaign.email_template_id)
  const [emailTemplate, setEmailTemplate] = React.useState(prefill)
  const [subject, setSubject] = React.useState(emailCampaign.subject)
  const [fromEmail, setFromEmail] = React.useState(emailCampaign.from_email)

  const fieldName = (attr) => `outreach_email_campaign[${attr}]`
  const errorMsg = (attr) => (errors[attr] || []).filter((m) => m !== 'is invalid').join(', ')

  return (
    <section>
      <div className="mdl-card block--full p-20">
        <h2 className="text--med-lg m-0">Content</h2>
        <p className="text--lightgray">
          Choose the email you would like to send for this email campaign. To create a new email,
          use the{' '}
          <a href={`${emailTemplateUrl}/new`} className="u-text-decoration--none">
            Email Builder
          </a>
        </p>

        <div className="d-flex flex__justify--space-between flex__items--baseline">
          <MdlSelect
            required
            label="Select Email"
            options={emailTemplates}
            className="always-floating-label"
            name={fieldName('email_template_id')}
            includeBlankOption="Email Name"
            onOptionSelected={(e) => setEmailTemplate(e)}
            selected={emailTemplate}
            error={errorMsg('email_template')}
          />
          {emailTemplatePolicy.create && (
            <a
              href={`${emailTemplatesUrl}`}
              className="mdl-button mdl-button--accent mdl-button--wide m-0 mdl-js-button mdl-js-ripple-effect">
              New Email
            </a>
          )}
        </div>
      </div>

      <CampaignContentTable emailTemplate={emailTemplate} emailTemplateUrl={emailTemplateUrl} />

      <div className="p-20 background--white">
        <Grid noSpacing className="pl-0">
          <Cell col={6} className="ml-0">
            <Textfield
              floatingLabel
              label="* From"
              name={fieldName('from_email')}
              className="textfield__input--full always-floating-label"
              placeholder="From"
              defaultValue={fromEmail}
              error={errorMsg('from_email')}
              onChange={(e) => setFromEmail(e.target.value)}
            />
          </Cell>
          <Cell col={6} className="d-flex flex__justify--end flex__items--center mr-0">
            <TestEmailForm
              emailTemplate={emailTemplate}
              emailTemplateUrl={emailTemplateUrl}
              variant="gray"
            />
          </Cell>
          <Cell col={6} className="ml-0">
            <Textfield
              floatingLabel
              label="* Subject"
              name={fieldName('subject')}
              className="textfield__input--full always-floating-label"
              placeholder="Subject"
              defaultValue={subject}
              error={errorMsg('subject')}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Cell>
          <Cell col={12} className="ml-0">
            <input
              required="required"
              aria-label="file upload"
              type="file"
              name={fieldName('file')}
            />
            <p className="text--lightgray ">File format: PDF up to 10MB.</p>
          </Cell>
          {emailCampaign.file_file_name && (
            <Cell col={12}>Attachment: {emailCampaign.file_file_name}</Cell>
          )}
        </Grid>
      </div>

      {!!emailTemplate && (
        <div id="email-preview" className="p-20 email--preview">
          <p className="text--med text--bold text--lightgray">Email Preview</p>
          <iframe
            style={{ width: '100%', height: '50vh' }}
            srcDoc={emailTemplate.template_content}
            title="Email Preview"
          />
        </div>
      )}
    </section>
  )
}

export default CampaignContentForm
