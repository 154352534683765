// A set of utility components to build up Tables. Replaces react-mdl's Table.
// Use all or some
//
// Usage:
//
//   const columns = [
//     { name: 'name', display: 'Display Name', cellFormatter: (data) => <div>{data}</div> }
//   ]
//   const rows = [
//     { id: 1, name: 'Example Row' }
//   ]
//
//   <Table>
//     <Table.Caption>My Title</Table.Caption>
//     <Table.Colgroup columns={columns} />
//     <Table.Head>
//       {columns.map((column) => (
//         <Table.Header key={column.name} column={column}/>
//       ))}
//     </Table.Head>
//     <Table.Body rows={rows} columns={columns} rowKeyColumn="id" />
//   </Table>

import classNames from 'classnames'
import { TableHeader } from 'react-mdl'
const { Expandy } = window

const Table = ({ className, children, ...otherProps }) => {
  return (
    <table className={classNames('mdl-data-table', className)} {...otherProps}>
      {children}
    </table>
  )
}

Table.Caption = ({ children }) => <caption className="visuallyhidden">{children}</caption>

Table.Colgroup = ({ columns }) => {
  return (
    <colgroup>
      {columns.map(({ colGroupStyle, colGroupClass }, idx) => (
        <col key={`col-${idx}`} style={colGroupStyle} className={colGroupClass} />
      ))}
    </colgroup>
  )
}

Table.Head = ({ children }) => (
  <thead>
    <tr>{children}</tr>
  </thead>
)

Table.Header = ({ column, onClick, onKeyDown, className, children, ...otherProps }) => {
  return (
    <th
      className={classNames(
        'data-table__table-header',
        {
          'mdl-data-table__cell--non-numeric': !column.numeric,
        },
        className
      )}
      key={column.name}
      onClick={!column.nosort && !!onClick ? (e) => onClick(e) : undefined}
      onKeyDown={onKeyDown ? (e) => onKeyDown(e) : undefined}
      tabIndex={column.nosort ? undefined : 0}
      scope="col"
      {...otherProps}>
      <span className={classNames({ visuallyhidden: column.hideHeader })}>{column.display}</span>
      {children}
    </th>
  )
}

const Row = ({ row, columns, expandTd }) => {
  const rowProps = row.mdlRowProps || {}

  const [expanded, setExpanded] = React.useState(false)

  return (
    <React.Fragment>
      <tr className={row.className} {...rowProps}>
        {columns.map((column, idx) => (
          <td
            key={column.name}
            className={classNames(column.className, {
              'mdl-data-table__cell--non-numeric': !column.numeric,
            })}>
            {column.cellFormatter
              ? column.cellFormatter(row[column.name], row, idx)
              : row[column.name]}
          </td>
        ))}
        {expandTd && (
          <td className="mdl-data-table__cell--narrow pl-0">
            <Expandy onClick={() => setExpanded(!expanded)} expanded={expanded} />
          </td>
        )}
      </tr>
      {expanded && (
        <tr className="background--white">
          <td colSpan={columns.length + 1}>{expandTd(row)}</td>
        </tr>
      )}
    </React.Fragment>
  )
}

Table.Body = ({ rows, columns, rowKeyColumn, expandTd, ...otherProps }) => {
  return (
    <tbody {...otherProps}>
      {rows.map((row) => (
        <Row
          key={row[rowKeyColumn]}
          row={row}
          columns={columns}
          rowKeyColumn={rowKeyColumn}
          expandTd={expandTd}
        />
      ))}
    </tbody>
  )
}

export default Table
