function StatusChip({ colorClass, statusText, addClass = '', reduceTextSize = false }) {
  return (
    <span className={`mdl-chip mdl-chip--skinny ${colorClass} mr-0 ${addClass}`}>
      <span className={`mdl-chip__text ${reduceTextSize ? 'mdl-chip__text--small' : ''}`}>
        {statusText}
      </span>
    </span>
  )
}

export default StatusChip
