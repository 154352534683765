import React from 'react'

import { Cell, Checkbox, Grid, Textfield } from 'react-mdl'

export default class PasswordRequirementsForm extends React.Component {
  constructor(props) {
    super(props)

    let parsedConfig = {}
    try {
      parsedConfig = JSON.parse(this.props.config)
    } catch (_e) {
      // This is fine
    }

    const configKeys = [
      'min_characters',
      'min_letters',
      'min_digits',
      'min_uppercase',
      'min_lowercase',
      'min_special',
      'max_consecutive',
      'max_repeated',
      'no_repeat_history',
      'disallow_current_username',
      'disallow_current_email',
      'forbidden_terms',
      'expiration_days',
    ]

    const configValues = this.initializeConfigValues(parsedConfig, configKeys)
    const configErrors = this.initializeConfigErrors(this.props.errors || {}, configKeys)
    const configFormValue = this.configFormValueFromObject(configValues, configKeys)

    this.state = {
      configKeys: configKeys,
      configFormValue: configFormValue,
      errors: configErrors,
      ...configValues,
    }
  }

  initializeConfigValues = (configValues, configKeys) => {
    const configState = {}
    configKeys.forEach((key) => {
      let initialEnabledState = false
      if (this.configRequired(key)) {
        initialEnabledState = true
      } else if (configValues[key] && configValues[key].enabled !== undefined) {
        initialEnabledState = configValues[key].enabled
      }

      let initialValue = ''
      if (configValues[key] && configValues[key].value) {
        initialValue = configValues[key].value
      }

      let initialType = 'string'
      if (this.props.defaults[key] && this.props.defaults[key].type) {
        initialType = this.props.defaults[key].type
      }

      configState[key] = {
        value: initialValue,
        enabled: initialEnabledState,
        type: initialType,
      }

      if (configValues[key]) {
        configState[key].value = configValues[key].value
        configState[key].enabled = configValues[key].enabled
      } else if (
        configState[key].type === 'boolean' &&
        typeof configState[key].value !== 'boolean'
      ) {
        // If no existing value exists for type boolean config, mirror enabled
        configState[key].value = configState[key].enabled
      }
    })
    return configState
  }

  initializeConfigErrors = (errors, configKeys) => {
    const errorState = {}
    configKeys.forEach((key) => {
      errorState[key] = {
        value: undefined,
        enabled: undefined,
      }

      if (errors[key]) {
        errorState[key].value = errors[key].value
        errorState[key].enabled = errors[key].enabled
      }
    })
    return errorState
  }

  // This is essentially an input mask
  validNewValue = (configKey, value) => {
    const configDetails = this.state[configKey]
    if (configDetails && configDetails.type) {
      const configType = configDetails.type
      switch (configType) {
        case 'integer':
          let nextChar = value.charAt(value.length - 1)
          return !!nextChar.match(/[0-9]/g)
        case 'boolean':
          return value === true || value === false || ['true', 'false', 'on', 'off'].includes(value)
        case 'csv':
          return true
        default:
          return false
      }
    } else {
      return true
    }
  }

  validateMinimum = (configKey, configValue) => {
    const configMinDetails = this.props.minimums[configKey]
    if (configMinDetails && configMinDetails.value && configMinDetails.comparison) {
      const checkValue = configMinDetails.value
      const checkComparison = configMinDetails.comparison

      switch (checkComparison) {
        case 'min':
          if (parseInt(configValue) < parseInt(checkValue)) {
            return `Must be at least ${checkValue}`
          } else {
            return undefined
          }
        case 'max':
          if (parseInt(configValue) > parseInt(checkValue)) {
            return `Must be at most ${checkValue}`
          } else {
            return undefined
          }
        case 'boolean':
          if (configValue !== true) {
            return `Must be enabled`
          } else {
            return undefined
          }
        case 'csv_inclusion':
          let parsedConfigValue = configValue
          if (typeof parsedConfigValue === 'string') {
            parsedConfigValue = parsedConfigValue.split(',').map((e) => e.trim())
          }
          let parsedCheckValue = checkValue
          if (typeof parsedCheckValue === 'string') {
            parsedCheckValue = parsedCheckValue.split(',').map((e) => e.trim())
          }
          const missing = parsedCheckValue.filter((e) => !parsedConfigValue.includes(e))
          if (missing.length > 0) {
            return `Must include ${missing.join(', ')}`
          } else {
            return undefined
          }
        default:
          return undefined
      }
    } else {
      return undefined
    }
  }

  typeCastConfigValue = (configKey, configValue) => {
    if (this.props.defaults[configKey] && this.props.defaults[configKey].type) {
      const type = this.props.defaults[configKey].type
      switch (type) {
        case 'integer':
          return parseInt(configValue)
        case 'boolean':
          return configValue === true || configValue === 'true' || configValue === 'on'
        default:
          return configValue
      }
    } else {
      return configValue
    }
  }

  handleConfigValueChangeFactory = (configKey) => {
    return (e) => {
      const statePayload = {
        errors: this.state.errors,
      }

      statePayload[configKey] = this.state[configKey]
      if (e.target.value.length === 0 || this.validNewValue(configKey, e.target.value)) {
        let newValue = e.target.value
        if (newValue.length > 0) {
          newValue = this.typeCastConfigValue(configKey, newValue)
        }
        statePayload[configKey].value = newValue

        // The error referenced here does not apply with non-nil values,
        // and in this block we know we no longer have a nil value.
        if (
          statePayload.errors[configKey] &&
          statePayload.errors[configKey].value === 'Required if enabled'
        ) {
          statePayload.errors[configKey].value = undefined
        }

        const minError = this.validateMinimum(configKey, newValue)
        statePayload.errors[configKey].value = minError

        const configFormValue = this.configFormValueFromObject(statePayload, this.state.configKeys)
        this.setState({
          ...statePayload,
          configFormValue: configFormValue,
        })
      }
    }
  }

  handleConfigEnabledChangeFactory = (configKey) => {
    return (e) => {
      const statePayload = {
        errors: this.state.errors,
      }

      statePayload[configKey] = this.state[configKey]

      if (
        e.target.checked &&
        this.state[configKey].value.length === 0 &&
        this.state[configKey].type !== 'boolean'
      ) {
        statePayload.errors[configKey].value = 'Required if enabled'
      } else if (!e.target.checked) {
        statePayload.errors[configKey].value = undefined
      }

      statePayload[configKey].enabled = e.target.checked
      // For boolean type configs, mirror enabled and value settings
      if (this.state[configKey].type === 'boolean') {
        statePayload[configKey].value = e.target.checked
      }

      const configFormValue = this.configFormValueFromObject(statePayload, this.state.configKeys)
      this.setState({
        ...statePayload,
        configFormValue: configFormValue,
      })
    }
  }

  configEnabled = (configKey) => {
    if (this.state[configKey]) {
      return this.state[configKey].enabled
    }
  }

  configRequired = (configKey) => {
    if (this.props.minimums[configKey] && this.props.minimums[configKey].enabled !== undefined) {
      return this.props.minimums[configKey].enabled === true
    }
  }

  configFormValueFromObject = (configObject, configKeys) => {
    var formData = {}
    configKeys.forEach((key) => {
      if (
        configObject[key] &&
        configObject[key].enabled !== undefined &&
        configObject[key].value !== undefined
      ) {
        let typeValue = configObject[key].type
        if (!typeValue) {
          typeValue = this.props.defaults[key].type
        }
        formData[key] = {
          value: configObject[key].value,
          enabled: configObject[key].enabled,
          type: typeValue,
        }
      } else if (
        this.state && // Needed because this function is called in the constructor
        this.state[key] &&
        this.state[key].enabled !== undefined &&
        this.state[key].value !== undefined
      ) {
        formData[key] = {
          value: this.state[key].value,
          enabled: this.state[key].enabled,
          type: this.state[key].type,
        }
      }
    })

    return JSON.stringify(formData)
  }

  checkboxForConfigEnabled = (configKey) => {
    if (this.state[configKey]) {
      var errorText
      if (this.state.errors[configKey] && this.state.errors[configKey].enabled) {
        errorText = (
          <small className="text--brightred">{this.state.errors[configKey].enabled}</small>
        )
      }

      if (this.configRequired(configKey)) {
        return (
          <Cell col={2} className="u-padding--top-16">
            <p className="u-margin--bottom-none">Required</p>
            {errorText}
          </Cell>
        )
      } else {
        return (
          <Cell col={2} className="u-padding--top-16">
            <Checkbox
              ripple
              label="Enable"
              checked={this.state[configKey].enabled}
              onChange={this.handleConfigEnabledChangeFactory(configKey)}
            />
            {errorText}
          </Cell>
        )
      }
    }
  }

  inputHtmlForConfigValue = (configKey, configKeyAsHuman) => {
    let configType = 'string'
    if (this.props.defaults[configKey] && this.props.defaults[configKey].type) {
      configType = this.props.defaults[configKey].type
    }

    var errorText
    if (this.state.errors[configKey]) {
      errorText = this.state.errors[configKey].value
    }

    switch (configType) {
      case 'boolean':
        // Here we basically mirror the enabled setting
        return (
          <React.Fragment>
            <input
              type="hidden"
              value={!!this.state[configKey].enabled}
              id={`${this.props.config_input_name}[${configKey}][value]`}
            />
            <p className="u-padding--top-16">{configKeyAsHuman}</p>
          </React.Fragment>
        )
      default:
        // General textfield for string, unknown, or other types
        return (
          <Textfield
            floatingLabel
            label={configKeyAsHuman}
            className="textfield__input--full"
            error={errorText}
            onChange={this.handleConfigValueChangeFactory(configKey)}
            value={this.state[configKey].value}
            disabled={!(this.configEnabled(configKey) || this.configRequired(configKey))}
            aria-required={this.configEnabled(configKey) || this.configRequired(configKey)}
          />
        )
    }
  }

  rowForConfig = (configKey, description) => {
    if (this.state[configKey]) {
      const configKeyAsHuman = configKey.replace(/_/g, ' ').replace(/\w\S*/g, (str) => {
        return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
      })

      var configDefault
      if (this.props.defaults[configKey]) {
        if (!this.props.defaults[configKey].enabled) {
          configDefault = 'Disabled'
        } else if (this.props.defaults[configKey].type === 'boolean') {
          if (this.props.defaults[configKey].value == true) {
            configDefault = 'Enabled'
          } else {
            configDefault = 'Disabled'
          }
        } else {
          configDefault = this.props.defaults[configKey].value
        }
      }

      var configMinimum
      if (this.props.minimums[configKey]) {
        if (
          this.props.minimums[configKey].value === undefined ||
          this.props.minimums[configKey].value === '' ||
          this.props.minimums[configKey].value === null
        ) {
          configMinimum = 'None'
        } else {
          configMinimum = this.props.minimums[configKey].value
        }
      }

      return (
        <Grid key={configKey + '_container'} className="u-margin--bottom-10">
          <Cell col={5} className="u-padding--top-16">
            <h5>{configKeyAsHuman}</h5>
            <p>{description}</p>
          </Cell>
          {this.checkboxForConfigEnabled(configKey)}
          <Cell col={3}>{this.inputHtmlForConfigValue(configKey, configKeyAsHuman)}</Cell>
          <Cell col={2} className="u-padding--top-16">
            <p className="u-margin--bottom-10">Default: {configDefault}</p>
            <p className="u-margin--bottom-10">Minimum: {configMinimum}</p>
          </Cell>
        </Grid>
      )
    }
  }

  render = () => {
    const configRows = [
      this.rowForConfig('min_characters', 'Minimum overall password length'),
      this.rowForConfig('min_letters', 'Minimum number of letters'),
      this.rowForConfig('min_digits', 'Minimum number of numbers'),
      this.rowForConfig('min_uppercase', 'Minimum number of uppercase letters'),
      this.rowForConfig('min_lowercase', 'Minimum number of lowercase letters'),
      this.rowForConfig(
        'min_special',
        'Minimum number of special characters (i.e., non-alphanumeric)'
      ),
      this.rowForConfig(
        'max_consecutive',
        'Maximum number of consecutive characters (e.g., abc, 1234). For example, a value of 1 means that "ab" is allowed, a value of 0 means "ab" is not allowed.'
      ),
      this.rowForConfig(
        'max_repeated',
        'Maximum number of repeated characters (e.g., aaa, 777). For example, a value of 1 means that "22" is allowed, a value of 0 means "22" is not allowed.'
      ),
      this.rowForConfig(
        'no_repeat_history',
        'The number of most recent passwords that the user cannot use again when changing their password. For example, a value of 1 means that the user cannot use their current password when changing, but can use the password they used before their current password.'
      ),
      this.rowForConfig(
        'disallow_current_username',
        'If this account has a non-email username for login, do not allow the password to contain that string.'
      ),
      this.rowForConfig(
        'disallow_current_email',
        'Do not allow the email prefix for this account to be used in the password.  For example, for an email like "sportsman@gmail.com", a password "sportsman7" will not be allowed.'
      ),
      this.rowForConfig(
        'forbidden_terms',
        'Comma-separated, case-insensitive list of terms that passwords cannot contain.  For example, with a config value of "password, hello", the passwords "password", "PASSWORD", "PasSWoRds", and "hello123" will not be allowed, however "p4ssword" and "hel10_world" will be allowed.'
      ),
      this.rowForConfig(
        'expiration_days',
        'Number of days until a password expires. When a user changes their password, the expiration days for their account is reset.'
      ),
    ]

    return (
      <React.Fragment>
        <input
          type="hidden"
          name={this.props.config_input_name}
          value={this.state.configFormValue}
        />
        <Grid>
          <Cell col={5}>
            <h5 className="text--underline">Description</h5>
          </Cell>
          <Cell col={2}>
            <h5 className="text--underline">Enabled</h5>
          </Cell>
          <Cell col={3}>
            <h5 className="text--underline">Config Value</h5>
          </Cell>
          <Cell col={2}></Cell>
        </Grid>
        {configRows}
      </React.Fragment>
    )
  }
}
