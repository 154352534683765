import { Switch } from 'react-mdl'
import { onKeyActivate } from '@utils'
import useFetch from 'use-http'
import classNames from 'classnames'
import { Btn } from '@components/shared'

const { useState } = React

const ToggleSwitchRow = (props) => {
  const [isChecked, setIsChecked] = useState(props.active)

  const { put, cache } = useFetch(props.activatePath, {
    headers: window.__AMS__.formToken,
  })

  const handleChange = async (e) => {
    setIsChecked(!isChecked)
    if (props.activatePath) {
      cache.clear()
      await put({ camp_policy: { active: e.target.checked } })
    }
  }

  const rowClass = classNames('mdlext-accordion__tab d-flex block--full px-20', {
    'bg--tanyellow': props.urgent,
    'bg--white': !props.urgent,
    'is-disabled': !isChecked,
  })

  return (
    <header className={rowClass}>
      <span className="mdlext-accordion__tab__caption flex__grow--1">{props.name}</span>

      {props.activatePath ? (
        <div className="mr-30">
          <Switch
            className="mdl-switch--accent"
            checked={isChecked}
            onChange={handleChange}
            onKeyUp={onKeyActivate(handleChange)}
          />
        </div>
      ) : null}

      {props.editPath ? (
        <Btn.Icon light name="edit" className="mr-20" href={props.editPath} />
      ) : null}
      <i className="mdlext-aria-toggle-material-icons"></i>
    </header>
  )
}

export default ToggleSwitchRow
