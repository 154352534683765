import { Button, Cell, Card } from 'react-mdl'
import Condition from './Condition'

// The component that will render each group of conditions
// conditionList  list of all the conditions for this block
// setConditionList- sets the conditions in the parent component for the block
// titleText- text shown at the top of the row
// betweenText- text that will display the word AND or OR depending on which condition that is chosen
// conditionAttributes- the tree of options that the user can select, the data in the select statements
const ConditionBlock = ({
  conditionList,
  setConditionList,
  titleText,
  betweenText,
  conditionAttributes,
}) => {
  const removeCondition = (key) => {
    setConditionList(conditionList.filter((v, i) => v.key !== key))
  }

  return (
    <Cell col={12}>
      <Card shadow={2} className="block--full mb-30">
        <div className="table__container--responsive">
          <table className="mdl-data-table mdl-js-data-table block--full">
            <thead>
              <tr className="text--med u-padding--top-10">
                <th className="mdl-data-table__cell--non-numeric" colSpan={2}>
                  <h5 className="text--gray">
                    Customers with {titleText.toUpperCase()} of the following conditions:
                  </h5>
                </th>
              </tr>
            </thead>
            <tbody>
              {conditionList.map((c, i) => (
                <React.Fragment key={c.key}>
                  {i > 0 && (
                    <tr className="text--uppercase bg--lightgray" style={{ height: 'auto' }}>
                      <td
                        className="mdl-data-table__cell--centered p-0"
                        colSpan={2}
                        style={{ height: '24px' }}>
                        <strong>{betweenText}</strong>
                      </td>
                    </tr>
                  )}
                  <Condition
                    conditionStatement={c}
                    setConditionStatement={(v) => {
                      setConditionList(
                        conditionList.map((item, index) =>
                          index === i ? { ...v, key: item.key } : item
                        )
                      )
                    }}
                    showRemove={true}
                    className="categorized-table-row"
                    onRemove={() => removeCondition(c.key)}
                    conditionAttributes={conditionAttributes}
                  />
                </React.Fragment>
              ))}

              <tr>
                <th className="bg--lightgray u--align-left" colSpan={2}>
                  <Button
                    type="button"
                    className="mdl-button--accent"
                    onClick={() =>
                      setConditionList([
                        ...conditionList,
                        // This is the determining the unique key for the above react fragment
                        {
                          key:
                            conditionList.length === 0
                              ? 1
                              : conditionList[conditionList.length - 1].key + 1,
                        },
                      ])
                    }>
                    Add Another Condition
                  </Button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </Cell>
  )
}

export default ConditionBlock
