import { Icon } from 'react-mdl'

const IconToggleButton = () => {
  const [leftSelected, setLeftSelected] = React.useState(true)
  const [selectedClasses, setSelectedClasses] = React.useState([
    'icon-toggle__button--selected',
    'icon-toggle__icon--selected',
    '',
    '',
  ])

  const selectSide = (e) => {
    const isSelected = e.target.className.lastIndexOf('selected')
    if (isSelected === -1) {
      setLeftSelected(!leftSelected)
    }
  }

  const handleKeyEnter = (e) => {
    if (event.key === 'Enter') {
      selectSide(e)
    }
  }

  React.useEffect(() => {
    setSelectedClasses(selectedClasses.reverse())
  }, [leftSelected])

  return (
    <div className="icon-toggle">
      <div
        className={`icon-toggle__button icon-toggle__button--left ${selectedClasses[0]}`}
        onClick={selectSide}
        onKeyPress={handleKeyEnter}
        tabIndex="0"
        aria-selected={leftSelected}>
        <Icon className={`icon-toggle__icon ${selectedClasses[1]}`} name="list"></Icon>
      </div>
      <div
        className={`icon-toggle__button icon-toggle__button--right ${selectedClasses[3]}`}
        onClick={selectSide}
        onKeyPress={handleKeyEnter}
        tabIndex="0"
        aria-selected={!leftSelected}>
        <Icon className={`icon-toggle__icon ${selectedClasses[2]}`} name="map"></Icon>
      </div>
    </div>
  )
}

export default IconToggleButton
