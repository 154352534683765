import { Grid, Cell, Button, Icon } from 'react-mdl'
import { formatDollars } from '@utils'
import { Currency } from '@components/shared'
import BookingHistory from '../BookingHistory'
const { useState, Fragment } = React

const BookingDetails = ({
  booking,
  cartItem,
  index,
  path,
  count,
  isReview,
  handleRemoval,
  handleEdit,
  reservationHistory,
  bookingHistory,
}) => {
  const [expanded, setExpanded] = useState(false)
  const textClass = isReview ? 'text--med-lg' : 'text--med'
  const start = moment(booking.start_at).format('ddd, MMM D YYYY')
  const end = moment(booking.end_at).format('ddd, MMM D YYYY')

  const previousBookingItem = reservationHistory
    ? reservationHistory.previous_cart.booking_items.find(
        (i) => i.booking_id === booking.cloned_from_id
      )
    : null

  const currentSubtotal = cartItem.subtotal - (previousBookingItem?.subtotal || 0)

  return (
    <Fragment>
      <Grid className="mdl-grid--no-spacing m-0 u-border--bottom px-20 flex__items--center">
        <Cell col={1} className="py-10">
          <Button
            onClick={() => setExpanded(!expanded)}
            aria-label={`Expand Booking #${index}`}
            className="mdl-button--icon mdl-button--light">
            <Icon name={expanded ? 'expand_less' : 'expand_more'} />
          </Button>
        </Cell>
        <Cell col={10} className="py-10">
          <span className={textClass} style={{ fontWeight: 'bold' }}>
            Booking {`${index + 1}/${count}`} -
          </span>
          &nbsp;
          <a
            className={`u-text-decoration--none ${textClass}`}
            href={`${path}/sites/${booking.zone_id}`}>
            {booking.zones_display}
          </a>
        </Cell>
        <Cell col={1} className={`pl-20 py-10 ${textClass}`}>
          <Currency.Colors amount={currentSubtotal} />
        </Cell>
      </Grid>
      {expanded && (
        <Fragment>
          <Grid className="mdl-grid--no-spacing m-0 camp-details-list px-20 py-20 flex__items--center">
            <Cell col={6} className="text--med pb-5">
              Booking Details
            </Cell>
            {isReview && (
              <Cell col={6} className="d-flex flex__justify--end">
                <Button
                  onClick={() => handleEdit(booking)}
                  className="mdl-button--wide mdl-button--accent">
                  Edit Booking
                </Button>
                <Button
                  onClick={() => handleRemoval(booking)}
                  className="mdl-button--wide mdl-button--light">
                  Remove Booking
                </Button>
              </Cell>
            )}
            <Cell col={2}>
              <dt>Check-In</dt>
              <dd>{start}</dd>
            </Cell>
            <Cell col={2}>
              <dt>Check-Out</dt>
              <dd>{end}</dd>
            </Cell>
            <Cell col={2}>
              <dt>
                <abbr name="Number of">No.</abbr> Occupants
              </dt>
              <dd>{booking.occupant_count}</dd>
            </Cell>
            <Cell col={2}>
              <dt>
                <abbr name="Number of">No.</abbr> Vehicles
              </dt>
              <dd>{booking.vehicle_count}</dd>
            </Cell>
          </Grid>
          <Grid className="mdl-grid--no-spacing m-0 camp-details-list px-20 py-10 flex__items--center">
            <Cell col={12} className="text--med pb-5">
              Primary Occupant Details
            </Cell>
            {!isReview && (
              <Cell col={2}>
                <dt>Customer ID</dt>
                <dd>{booking.primary_occupant.customer_id}</dd>
              </Cell>
            )}
            <Cell col={2}>
              <dt>Primary Occupant</dt>
              <dd>{`${booking.primary_occupant.first_name} ${booking.primary_occupant.last_name}`}</dd>
            </Cell>
            {!isReview && (
              <Cell col={2}>
                <dt>Date Of Birth</dt>
                <dd>{booking.occupant_count.dob}</dd>
              </Cell>
            )}
            <Cell col={2}>
              <dt>
                Phone <abbr name="Number">No.</abbr>
              </dt>
              <dd>{booking.primary_occupant.phone_number}</dd>
            </Cell>
            <Cell col={2}>
              <dt>Email</dt>
              <dd>{booking.primary_occupant.email}</dd>
            </Cell>
          </Grid>
          <Grid className="mdl-grid--no-spacing m-0 camp-details-list px-20 py-20 flex__items--start">
            <Cell col={12} className="text--med pb-5">
              Price Breakdown
            </Cell>
            <Cell col={2}>
              <dt>Date</dt>
              {booking.booking_nights.map((night) => (
                <dd key={night.id}>{moment(night.date).format('MM/DD/YYYY')}</dd>
              ))}
            </Cell>
            <Cell col={2}>
              <dt>Pricing Record</dt>
              {booking.booking_nights.map((night) => (
                <dd key={night.id}>{night.transaction_type_display}</dd>
              ))}
            </Cell>
            <Cell col={2}>
              <dt>Price</dt>
              {booking.booking_nights.map((night) => (
                <dd key={night.id}>{formatDollars(night.price)}</dd>
              ))}
            </Cell>
          </Grid>

          {bookingHistory ? (
            <BookingHistory bookingHistory={bookingHistory[booking.booking_number]} />
          ) : null}
        </Fragment>
      )}
    </Fragment>
  )
}

export default BookingDetails
