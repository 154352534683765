import { Grid, Cell, Card, Button } from 'react-mdl'
import { DataTable, Btn, FC } from '@components/shared'
import { formatDateFromDb, formatDollars } from '@utils'
const { Fragment, useMemo } = React

const ParentZones = ({ loop, section }) => (
  <div>
    {loop || '-'}
    <br />
    {section ? <span className="text--med-small text--lightgray">{section}</span> : '-'}
  </div>
)

const ChangeBookingSite = ({ booking, reservationsPath }) => {
  const columns = [
    {
      name: 'name',
      display: 'Name',
      nosort: true,
    },
    {
      name: 'parent_zone_names',
      display: 'Loops & Sections',
      nosort: true,
      cellFormatter: ({ loop, section }) => <ParentZones loop={loop} section={section} />,
    },
    {
      name: 'site_type_name',
      display: 'Site Type',
      nosort: true,
    },
    {
      name: 'start_at',
      display: 'Check-In',
      nosort: true,
      cellFormatter: (value) => formatDateFromDb(value),
    },
    {
      name: 'end_at',
      display: 'Check-Out',
      nosort: true,
      cellFormatter: (value) => formatDateFromDb(value),
    },
    {
      name: 'occupant_count',
      display: 'Occupants',
      nosort: true,
    },
    {
      name: 'vehicle_count',
      display: 'Vehicles',
      nosort: true,
    },
  ]

  const summedSubtotal = booking.zone.subtotal || booking.cart_item.subtotal
  const name = 'reservation[bookings_attributes][]'
  const site = {
    ...booking,
    ...booking.zone,
  }

  return (
    <Card shadow={0} className="block--full mb-20">
      <FC.Form action={`${reservationsPath}/${booking.reservation_id}`} method="put">
        <input type="hidden" name="booking_id" value={booking.id} />
        <input type="hidden" name="reservation[bypass_booking_validation]" value="1" />
        <input type="hidden" name={`${name}[id]`} value={booking.id} />
        <input type="hidden" name={`${name}[zone_id]`} value={booking.zone_id} />
        <input type="hidden" name={`${name}[start_at]`} value={booking.start_at} />
        <input type="hidden" name={`${name}[end_at]`} value={booking.end_at} />
        <input type="hidden" name={`${name}[occupant_count]`} value={booking.occupant_count} />
        <input type="hidden" name={`${name}[vehicle_count]`} value={booking.vehicle_count} />

        <Grid className="p-20">
          <Cell col={6}>
            <h2 className="text--med-lg m-0">Site Selected</h2>
          </Cell>

          <Cell col={6} className="u--align-right d-flex flex__items--center flex__justify--end">
            <div className="py-15 px-20 mr-10 bg--lighterblue text--darkblue u-line-height--1">
              Subtotal &mdash; {formatDollars(summedSubtotal)}
            </div>
            <Button type="submit" raised className="mdl-button--gray">
              Continue to Booking
            </Button>
          </Cell>
        </Grid>

        <Fragment>
          <DataTable topic="Selected Sites" rows={[site]} columns={columns} rowKeyColumn="id" />
        </Fragment>
      </FC.Form>
    </Card>
  )
}

export default ChangeBookingSite
