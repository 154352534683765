import { Grid, Cell, Button, Icon } from 'react-mdl'
import { useFormContext } from 'react-hook-form'
import { useNestedAttributes } from '@hooks'
import { FC } from '@components/shared'
const { useState, Fragment } = React
const dateFormat = 'MM/DD/YYYY'

const SeasonsFields = () => {
  const { fields } = useFormContext()
  const { append } = useNestedAttributes()
  const initialSeasons = Object.entries(fields.park_seasons_attributes || {}).map((s) => s?.[1])
  const [seasons, setSeasons] = useState(initialSeasons)

  const addSeason = () => {
    setSeasons(append(seasons))
  }

  const deleteSeason = (season, idx) => () => {
    // TODO: deleting fields inside of useForm via the _delete attribute
    // doesn't work as expected. The fields don't get unregistered and still return
    // validation errors due to being blank. For now, we update our local state manually.
    // useForm's useFieldArray may be a good way to solve this.
    if (season.id.value) {
      const allSeasons = [...seasons]
      allSeasons[idx]._destroy.value = true
      setSeasons(allSeasons)
    } else {
      setSeasons(seasons.filter((_s, i) => i !== idx))
    }
  }

  return (
    <Grid className="mdl-grid--no-spacing u-margin--none">
      <Cell col={8}>
        {seasons.map((season, idx) => (
          <Fragment key={`season-${idx}`}>
            <input type="hidden" defaultValue={season.id.value || ''} name={season.id.input_name} />
            <input
              type="hidden"
              value={season._destroy.value || false}
              name={season._destroy.input_name}
            />

            {season._destroy.value !== true ? (
              <Grid className="mdl-grid--no-spacing u-margin--none">
                <Cell col={5}>
                  <FC.Datepicker field={season.start_at} dateFormat={dateFormat} />
                </Cell>
                <Cell col={1} className="d-flex flex__items--center flex__justify--center">
                  to
                </Cell>
                <Cell col={5}>
                  <FC.Datepicker field={season.end_at} dateFormat={dateFormat} />
                </Cell>
                <Cell col={1} className="u-padding--10">
                  {idx > 0 ? (
                    <Button
                      type="button"
                      className="mdl-button--icon mdl-button--light"
                      aria-label="Delete Season"
                      onClick={deleteSeason(season, idx)}>
                      <Icon name="delete_forever" className="md-18" aria-hidden="true" />
                    </Button>
                  ) : (
                    undefined
                  )}
                </Cell>
              </Grid>
            ) : (
              undefined
            )}
          </Fragment>
        ))}

        <Button type="button" accent onClick={addSeason}>
          Add Another Peak Season
        </Button>
      </Cell>
    </Grid>
  )
}

export default SeasonsFields
