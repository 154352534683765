import { Spinner, Textfield } from 'react-mdl'

const ImageRow = ({ image, imageIdx, name, filePath, removeUploadedImage, edit }) => {
  const [altText, setAltText] = React.useState(image.alt_text)
  const [errors, setErrors] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const deleteImage = () => {
    setLoading(true)
    setErrors(null)

    const deletePath = filePath + '/' + image.id

    Fetchable.delete(deletePath)
      .then((res) => res.json())
      .then((response) => {
        setLoading(false)
        if (response && response.errors) {
          setErrors(response.errors)
        } else {
          removeUploadedImage(imageIdx)
        }
      })
  }

  const renderAltText = React.useMemo(() => {
    if (edit) {
      const charactersRemaining = altText ? 100 - altText.length : 100

      return (
        <div>
          <Textfield
            label="Alt Text"
            className="textfield__input--full"
            name={`${name}[alt_text]`}
            maxLength="100"
            value={altText || ''}
            onChange={(e) => setAltText(e.target.value)}
          />
          <p className="u-margin--bottom-none u-pull--right">
            {charactersRemaining} characters remaining
          </p>
          <input type="hidden" name={`${name}[id]`} defaultValue={image.id} />
        </div>
      )
    } else {
      return <p>{altText}</p>
    }
  }, [altText, edit, image.id, name])

  return (
    <tr className="images-table__row">
      <td className="mdl-data-table__cell--non-numeric">
        <img src={image.image_url} alt={altText} />
      </td>
      <td className="mdl-data-table__cell--non-numeric">{renderAltText}</td>
      <td>
        {!loading && edit && (
          <button className="mdl-button mdl-js-button mdl-button--icon" onClick={deleteImage}>
            <i className="material-icons">delete</i>
          </button>
        )}
        {loading && <Spinner />}
        {errors && <p className="text--brightred text--small u-margin--none">{errors}</p>}
      </td>
    </tr>
  )
}

export default ImageRow
