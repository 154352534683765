import { DataTable, AttributeChip, NpmapIcon } from '@components/shared'

const SitePriceSummaryTable = (props) => {
  const pricingRecordCols = [
    {
      name: 'transaction_type',
      display: 'Transaction Type',
      nosort: true,
      className: 'secondary-header sticky-header',
    },
    {
      name: 'sales_channel',
      display: 'Sales Channel',
      nosort: true,
      className: 'secondary-header sticky-header',
    },
    {
      name: 'base_price',
      display: 'Base Price',
      nosort: true,
      className: 'secondary-header sticky-header',
    },
    {
      name: 'reservation_fee',
      display: 'Reservation Fee',
      nosort: true,
      className: 'secondary-header sticky-header',
    },
    {
      name: 'status',
      display: 'Status',
      nosort: true,
      className: 'secondary-header sticky-header',
      cellFormatter: (status) => statusChip(status),
    },
  ]

  // total up premiums and format for display
  const premiumAttributesTotal = React.useMemo(() => {
    let amountTotal = props.premiumAttributes.reduce((acc, cur) => {
      return cur.trait_premium.amount !== null ? (acc += parseFloat(cur.trait_premium.amount)) : acc
    }, 0.0)

    let percentTotal = props.premiumAttributes.reduce((acc, cur) => {
      return cur.trait_premium.percent !== null
        ? (acc += parseFloat(cur.trait_premium.percent))
        : acc
    }, 0.0)

    if (percentTotal > 0 && amountTotal > 0) {
      return `$${amountTotal} + ${percentTotal}%`
    } else {
      return amountTotal > 0 ? `+ $${amountTotal}` : `+ ${percentTotal}%`
    }
  }, [props.premiumAttributes])

  const premiumAttributesCount = React.useMemo(() => {
    return props.premiumAttributes.length || 0
  }, [props.premiumAttributes.length])

  const premiumAttributeCols = [
    {
      name: 'icon_name',
      display: 'Icon',
      nosort: true,
      className: 'secondary-header',
      cellFormatter: (icon) => (icon ? <NpmapIcon name={icon} /> : undefined),
    },
    {
      name: 'name',
      display: 'Name',
      nosort: true,
      className: 'secondary-header',
    },
    {
      name: 'category',
      display: 'Category',
      nosort: true,
      className: 'secondary-header',
    },
    {
      name: 'premium_formatted',
      display: 'Premium',
      nosort: true,
      className: 'secondary-header',
      cellFormatter: (premium_formatted) => (
        <AttributeChip style={{ width: 83 }} className="mdl-alert-chip">
          {premium_formatted}
        </AttributeChip>
      ),
    },
    {
      name: 'type',
      display: 'Type',
      nosort: true,
      className: 'secondary-header',
    },
    {
      name: 'parent',
      display: 'Parent',
      nosort: true,
      className: 'secondary-header',
    },
  ]

  const statusChip = (status) => {
    return status ? (
      <AttributeChip style={{ width: 83 }} className="mdl-active-chip">
        ENABLED
      </AttributeChip>
    ) : (
      <AttributeChip style={{ width: 83 }} className="mdl-neutral-chip">
        DISABLED
      </AttributeChip>
    )
  }

  return (
    <React.Fragment>
      <div className="mdlext-accordion__header">
        <div className="mdlext-accordion__header-columns">
          <div className="mdl-accordion__header-item">Site Type</div>
          <div className="mdl-accordion__header-item">Pricing Records</div>
        </div>
      </div>
      <ul
        className="mdlext-accordion mdlext-js-accordion mdlext-accordion--vertical u-border--top"
        role="tablist"
        aria-multiselectable="true">
        <li className="mdlext-accordion__panel">
          <header className="mdlext-accordion__tab bg--white p-0">
            <div
              className="d-flex flex__justify--space-between flex__items--center pl-20"
              style={{ width: '90%' }}>
              <div className="text--med flex--1">
                {props.siteTypeName ? props.siteTypeName : '\u2014'}
              </div>
              <div className="text--med flex--1">
                {props.pricingRecords ? props.pricingRecords.length : 0}
              </div>
            </div>
            <i className="mdlext-aria-toggle-material-icons mr-20"></i>
          </header>
          <section className="mdlext-accordion__tabpanel bg--well p-0">
            {props.pricingRecords && (
              <div style={{ maxHeight: 455 }}>
                <DataTable
                  topic="Pricing Records"
                  columns={pricingRecordCols}
                  rows={props.pricingRecords}
                  rowKeyColumn="id"
                  className="mt-5"
                  noResultsText="No pricing records"
                />
              </div>
            )}
            {!props.pricingRecords && (
              <div className="text--med-small text--center text--lightgray p-20">
                No pricing records available for this site.
              </div>
            )}
          </section>
        </li>
      </ul>
      <div className="mdlext-accordion__header mt-20">
        <div className="mdlext-accordion__header-columns">
          <div className="mdl-accordion__header-item">Premium Attributes</div>
          <div className="mdl-accordion__header-item">Premium Total</div>
        </div>
      </div>
      <ul
        className="mdlext-accordion mdlext-js-accordion mdlext-accordion--vertical u-border--top"
        role="tablist"
        aria-multiselectable="true">
        <li className="mdlext-accordion__panel">
          <header className="mdlext-accordion__tab bg--white p-0">
            <div
              className="d-flex flex__justify--space-between flex__items--center pl-20"
              style={{ width: '90%' }}>
              <div className="text--med flex--1">{premiumAttributesCount}</div>
              <div className="text--med flex--1">{premiumAttributesTotal}</div>
            </div>
            <i className="mdlext-aria-toggle-material-icons mr-20"></i>
          </header>
          <section className="mdlext-accordion__tabpanel bg--well p-0">
            {premiumAttributesCount >= 1 && (
              <DataTable
                topic="Premium Attributes"
                columns={premiumAttributeCols}
                rows={props.premiumAttributes}
                initialSortBy="name"
                rowKeyColumn="id"
                className="mt-5"
                noResultsText="No Premium Attributes"
              />
            )}
            {premiumAttributesCount === 0 && (
              <div className="text--med-small text--center text--lightgray p-20">
                No pricing records available for this site.
              </div>
            )}
          </section>
        </li>
      </ul>
    </React.Fragment>
  )
}

export default SitePriceSummaryTable
