import { Button, Cell, Grid, Textfield } from 'react-mdl'
import { useDidUpdate } from '@hooks'
import { valuePresent, formatDateToDb } from '@utils'
import { Rangepicker } from '@components/shared'

const { useEffect, useState, useRef } = React

const propTypes = {
  // Event handler when search query is submitted. Takes array of search query strings as param
  onSearch: PropTypes.func.isRequired,

  // Pre-populate search filters
  searchFilters: PropTypes.object,
}

const AvailabilitySearchForm = ({ onSearch, searchFilters: initSearchFilters = {} }) => {
  const [searchFilters, setSearchFilters] = useState(initSearchFilters)
  const dateFormat = 'MM/DD/YYYY'

  // Start date, end date, and occupants required for search
  const searchEnabled =
    searchFilters.start_date && searchFilters.end_date && searchFilters.occupants
  const selectedDates = [searchFilters.start_date, searchFilters.end_date].filter(
    (date) => date != null
  )

  const handleSearch = (e) => {
    e.preventDefault()

    if (searchEnabled) {
      onSearch(searchFilters)
    }
  }

  const handleClearFilters = () => {
    setSearchFilters({})
    // Clears search results
    onSearch({})
  }

  const onChangeDates = (value) => {
    if (value.length === 1) {
      setSearchFilters({
        ...searchFilters,
        start_date: formatDateToDb(value[0], 'YYYY-MM-DD'),
        end_date: null,
      })
    } else if (value.length === 2) {
      setSearchFilters({
        ...searchFilters,
        start_date: formatDateToDb(value[0], 'YYYY-MM-DD'),
        end_date: formatDateToDb(value[1], 'YYYY-MM-DD'),
      })
    }
  }

  return (
    <form onSubmit={handleSearch}>
      <Grid className="block--full px-20">
        <Cell col={12}>
          <h2 className="text--med-lg my-10 u-border--bottom">Search Available Sites</h2>
        </Cell>
        <Cell col={4} tablet={12}>
          <Rangepicker
            value={selectedDates}
            label="Check In and Check Out *"
            aria-label="Check In and Check Out"
            placeholder="Select Date Range"
            dateFormat={dateFormat}
            onChange={onChangeDates}
            options={{ showMonths: 2 }}
          />
        </Cell>
        <Cell col={2} tablet={12}>
          <Textfield
            floatingLabel
            placeholder="0"
            className="textfield__input--full"
            pattern="-?[0-9]*(\.[0-9]+)?"
            error="Must be a number"
            label="Total Occupants *"
            value={searchFilters.occupants || ''}
            onChange={(e) => setSearchFilters({ ...searchFilters, occupants: e.target.value })}
          />
        </Cell>
        <Cell col={2} tablet={12}>
          <Textfield
            floatingLabel
            placeholder="0"
            className="textfield__input--full"
            pattern="-?[0-9]*(\.[0-9]+)?"
            error="Must be a number"
            label="No. of Vehicles"
            value={searchFilters.vehicles || ''}
            onChange={(e) => setSearchFilters({ ...searchFilters, vehicles: e.target.value })}
          />
        </Cell>

        <Cell col={4} tablet={12}>
          <div className="mt-10">
            <Button
              ripple
              className="mdl-button--gray"
              onClick={handleSearch}
              disabled={!searchEnabled}>
              Search
            </Button>
            {!Object.values(searchFilters).filter((val) => valuePresent(val)).length == 0 ? (
              <Button className="mdl-button--light" onClick={handleClearFilters}>
                Clear Search
              </Button>
            ) : null}
          </div>
        </Cell>
      </Grid>
    </form>
  )
}

AvailabilitySearchForm.propTypes = propTypes
export default AvailabilitySearchForm
