import ConditionBlock from './ConditionBlock'
import { Grid, Textfield, Cell } from 'react-mdl'
import SegmentedListDetails from './SegmentedListDetails'

const NewEditCondition = ({
  segmented_list,
  condition_attributes,
  submit_url,
  submit_method,
  cancel_url,
}) => {
  const validString = (string) => string && string.trim().length > 0

  const validConditionStatement = (conditionStatement) => {
    return (
      conditionStatement.column &&
      conditionStatement.operator &&
      ((conditionStatement.value !== undefined &&
        (typeof conditionStatement.value !== 'string' || validString(conditionStatement.value))) ||
        (validString(conditionStatement.min_value) && validString(conditionStatement.max_value)))
    )
  }
  const validConditionList = (conditionList) => conditionList.every(validConditionStatement)

  const prepList = (list) => list && list.map((c, i) => ({ ...c, key: i }))

  const [segmentedList, setSegmentedList] = React.useState({
    ...segmented_list,
    query_parameters: {
      and: prepList(segmented_list.query_parameters && segmented_list.query_parameters.and) || [
        { key: 1 },
      ],
      or: prepList(segmented_list.query_parameters && segmented_list.query_parameters.or) || [],
    },
  })

  const [nameError, setNameError] = React.useState()

  const nameValid = segmentedList.name && segmentedList.name.trim().length > 0
  const queryValid =
    validConditionList(segmentedList.query_parameters.and) &&
    validConditionList(segmentedList.query_parameters.or)

  const valid =
    nameValid &&
    queryValid &&
    (segmentedList.query_parameters.and.length > 0 || segmentedList.query_parameters.or.length > 0)

  const onSave = () => {
    var it
    if (submit_method === 'create')
      it = window.Fetchable.post(submit_url, { outreach_segmented_list: segmentedList })
    else
      it = window.Fetchable.put(
        submit_url,
        { outreach_segmented_list: segmentedList },
        { redirect: 'manual' }
      )

    it.then((response) => {
      if (response.type === 'opaqueredirect' || response.redirected)
        window.location.href = response.url
      else return response.json()
    })
      .then((data) => {
        if (data.errors && data.errors.name) setNameError(data.errors.name[0])
      })
      .catch(function(err) {})
  }

  return (
    <form>
      <Grid>
        <Cell col={5}>
          <Textfield
            floatingLabel
            label="Segmented List Name"
            defaultValue={segmentedList.name}
            placeholder="Enter Segmented List Name"
            error={(queryValid && !nameValid && "can't be blank") || nameError}
            onChange={(e) => {
              setNameError(null)
              setSegmentedList({ ...segmentedList, name: e.target.value })
            }}
          />
        </Cell>

        <Cell col={12}>
          <h2 className="text--med-lg m-0">Conditions</h2>
          <p className="text--lightgray">
            The following conditions define the customers included in this segmented list to be used
            in your campaigns.
          </p>
        </Cell>

        <ConditionBlock
          titleText={'all'}
          betweenText={'and'}
          conditionList={segmentedList.query_parameters.and}
          setConditionList={(cl) =>
            setSegmentedList({
              ...segmentedList,
              query_parameters: { ...segmentedList.query_parameters, and: cl },
            })
          }
          conditionAttributes={condition_attributes}
        />
        <ConditionBlock
          titleText={'one'}
          betweenText={'or'}
          conditionList={segmentedList.query_parameters.or}
          setConditionList={(cl) =>
            setSegmentedList({
              ...segmentedList,
              query_parameters: { ...segmentedList.query_parameters, or: cl },
            })
          }
          conditionAttributes={condition_attributes}
        />

        <Cell col={12}>
          <SegmentedListDetails
            segmentedList={segmentedList}
            conditionAttributes={condition_attributes}
          />
        </Cell>
      </Grid>

      <Grid className="px-20 pb-20 pt-40">
        <Cell col={12} className="d-flex flex__justify--end">
          <a
            className="mdl-button mdl-js-button mdl-button--light mdl-button--wide mdl-button--wide-centered"
            href={cancel_url}>
            Cancel
          </a>
          <input
            type="submit"
            name="commit"
            value="Save"
            disabled={!valid}
            onClick={(e) => {
              e.preventDefault()
              onSave()
            }}
            className="mdl-button mdl-js-button mdl-button--gray mdl-button--wide mdl-button--wide-centered"
            data-disable-with="Save"
          />
        </Cell>
      </Grid>
    </form>
  )
}

export default NewEditCondition
