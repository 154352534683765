import CampaignExpandableTable from './CampaignExpandableTable'

const CampaignAudienceTable = ({ segmentedList, segmentedListUrl }) => {
  const locale = document.querySelector('html').getAttribute('lang')
  const formatDate = (ts) => new Date(ts).toLocaleDateString(locale)

  const columns = [
    {
      name: 'Name',
      width: '70%',
      cellFormatter: (item) => (
        <a href={`${segmentedListUrl}/${item.id}`} className="text--med-lg text--bold">
          {item.name}
        </a>
      ),
    },
    {
      name: 'Created',
      width: '20%',
      cellFormatter: (item) => <span className="text--med">{formatDate(item.created_at)}</span>,
    },
  ]

  return (
    <CampaignExpandableTable
      item={segmentedList}
      columns={columns}
      expandTarget="segmented-list-details"
    />
  )
}

export default CampaignAudienceTable
