// A generic, reusable, accessible Modal dialog using dialog-polyfill and react-focus-lock
//
// Usage:
//
//  <Modal open={isOpen} id="unique_id" onClose={closeFn} onCancel={cancelFn}>
//    <Modal.Title id="unique_id">
//      New Category
//    </Modal.Title>
//
//    <Modal.Content>
//      Some Content!
//    </Modal.Content>
//
//    <Modal.Actions>
//      <Button className="mdl-button--gray" onClick={closeFn}>
//        Confirm
//      </Button>
//      <Button className="mdl-button--light" onClick={cancelFn}>
//        Cancel
//      </Button>
//    </Modal.Actions>
//  </Modal>

import { FocusOn } from 'react-focus-on'
import classNames from 'classnames'
import { Modal as ReactModal } from './react-dialog-polyfill'

const propTypes = {
  // Modal ID must be unique
  id: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['dialog', 'alertdialog']),
  className: PropTypes.string,
  // Toggle open or closed
  open: PropTypes.bool,
  // Optionally disable dismissing the modal with the escape key
  allowEscape: PropTypes.bool,
  width: PropTypes.oneOf([500, 600, 750]),
  // onClose is called by the "Close" button or by pressing the Escape key
  onClose: PropTypes.func,
  // onCancel is called by the "Cancel" button
  onCancel: PropTypes.func,
  children: PropTypes.node,
}

const Modal = ({
  id,
  role = 'dialog',
  className = '',
  open,
  allowEscape = true,
  width = 600,
  onClose,
  onCancel,
  children,
  ...otherProps
}) => {
  const escapePressed = (e) => {
    if (!allowEscape) {
      e.preventDefault()
    }
  }

  return (
    <ReactModal
      id={id}
      className={classNames(`mdl-dialog mdl-dialog--${width} u-padding--20`, className)}
      open={open}
      onCancel={onCancel || onClose}
      onClose={onClose}
      role={role}
      aria-modal
      aria-labelledby={`${id}_label`}
      {...otherProps}>
      {/*
        FocusOn prevents the user from tabbing out of the modal window
        and removes access to anything behind the modal
      */}
      <FocusOn enabled={open} onEscapeKey={escapePressed}>
        {children}
      </FocusOn>
    </ReactModal>
  )
}

Modal.Title = ({ id, className = '', children }) => {
  return (
    <h3 className={classNames('mdl-dialog__title text--lg', className)} id={`${id}_label`}>
      {children}
    </h3>
  )
}
Modal.Content = ({ className = '', children }) => {
  return <div className={classNames('mdl-dialog__content', className)}>{children}</div>
}
Modal.Actions = ({ className = '', children }) => {
  return <div className={classNames('mdl-dialog__actions', className)}>{children}</div>
}

Modal.propTypes = propTypes
export default Modal
