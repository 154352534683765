import { Cell, Grid } from 'react-mdl'
import ImageUpload from '@components/camp/ImageUpload'
import ImageRow from './ImageRow'

const ImagesTable = ({ label, id, filePath, images, name, description, edit }) => {
  const [imageRows, setImageRows] = React.useState(images)
  const [errors, setErrors] = React.useState(null)

  const addUploadedImage = (image) => {
    setImageRows([...imageRows, image])
    setErrors(null)
  }

  const removeUploadedImage = (imageIdx) => {
    const newImages = imageRows
    newImages.splice(imageIdx, 1)
    setImageRows([...newImages])
  }

  return (
    <React.Fragment>
      <Grid className="p-20 flex__items--center">
        <Cell col={6}>
          <h2 className="mdl-card__title-text">{label}</h2>
          {description && <div className="text--lightergray mt-10">{description}</div>}
        </Cell>
        {edit ? (
          <Cell col={6} className="u--align-right">
            <ImageUpload
              endpoint={filePath}
              onUpload={addUploadedImage}
              onError={(err) => setErrors(err)}
            />
            {errors ? <p className="text--brightred text--small m-0">{errors}</p> : null}
          </Cell>
        ) : null}
      </Grid>

      <div className="table__container--responsive">
        <table className="mdl-data-table block--full">
          <caption className="visuallyhidden">Uploaded Images</caption>

          <colgroup>
            <col style={{ width: '250px' }} />
            <col />
            <col style={{ width: '80px' }} />
          </colgroup>

          <thead>
            <tr>
              <th className="mdl-data-table__cell--non-numeric" scope="col">
                Image
              </th>
              <th className="mdl-data-table__cell--non-numeric" scope="col">
                Alt Text
              </th>
              <th className="mdl-data-table__cell--non-numeric" scope="col">
                <span className="visuallyhidden">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {imageRows.map((image, imageIdx) => (
              <ImageRow
                image={image}
                imageIdx={imageIdx}
                name={`${name}[${imageIdx}]`}
                filePath={filePath}
                removeUploadedImage={removeUploadedImage}
                key={`image-${image.id}`}
                edit={edit}
              />
            ))}
            {!imageRows.length && (
              <tr>
                <td colSpan="3" className="mdl-data-table__cell--centered">
                  No images have been uploaded
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default ImagesTable
