const propTypes = {
  // String of filter name
  filter: PropTypes.string.isRequired,

  // Event handler when remove icon is clicked
  onRemove: PropTypes.func,
}

const FilterChip = ({ filter, onRemove }) => {
  const handleClick = () => {
    onRemove(filter)
  }

  return (
    <div className="filter-chip">
      <span className="filter-chip__filter-text">{filter}</span>
      <span
        aria-label="Remove"
        className="filter-chip__cancel-icon material-icons"
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyPress={handleClick}>
        cancel
      </span>
    </div>
  )
}

FilterChip.propTypes = propTypes
export default FilterChip
