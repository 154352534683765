import useFetch from 'use-http'
import { AmsButton } from '@components/shared'

import TestEmailModal from './TestEmailModal'

const TestEmailForm = ({ emailCampaignId, emailTemplate, emailTemplateUrl, variant }) => {
  const [email, setEmail] = React.useState('')
  const [open, setOpen] = React.useState(false)
  // states: new, delivered, error
  const [state, setState] = React.useState('new')

  const { post, response } = useFetch(emailTemplateUrl, {
    headers: { ...window.__AMS__.formToken, Accept: 'application/json' },
    cachePolicy: 'no-cache',
  })

  const onSubmit = async () => {
    const url =
      `/${emailTemplate.id}/test` + (emailCampaignId ? `?campaign_id=${emailCampaignId}` : '')
    await post(url, { email, format: 'json' })

    setState(response.ok ? 'delivered' : 'error')
  }

  const onCancel = () => {
    setEmail('')
    setOpen(false)
    setState('new')
  }

  const onChange = ({ target: { value } }) => setEmail(value)

  return (
    <>
      <AmsButton variant={variant} type="button" onClick={() => setOpen(true)}>
        Send Test Email
      </AmsButton>
      <TestEmailModal
        email={email}
        open={open}
        state={state}
        onChange={onChange}
        onReset={() => setState('new')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  )
}

export default TestEmailForm
