const { useEffect, useRef, useState } = React

// Icon string comes from Material Icons
const getIconHTML = (icon) => `<i class="rich-text-editor__format-icon material-icons">${icon}</i>`

// Trix currently doesn't have a great way to config icons, so this will have to do
const customizeIcons = (toolbarElement) => {
  const strikeButton = toolbarElement.querySelector('[data-trix-attribute=strike]')
  strikeButton.innerHTML = getIconHTML('strikethrough_s')
  strikeButton.classList.remove('trix-button--icon-strike')
  strikeButton.classList.add('rich-text-editor__button')

  const linkButton = toolbarElement.querySelector('[data-trix-attribute=href]')
  linkButton.innerHTML = getIconHTML('insert_link')
  linkButton.classList.remove('trix-button--icon-link')
  linkButton.classList.add('rich-text-editor__button')

  const decreaseNestingButton = toolbarElement.querySelector(
    '[data-trix-action=decreaseNestingLevel]'
  )
  decreaseNestingButton.innerHTML = getIconHTML('format_indent_decrease')
  decreaseNestingButton.classList.remove('trix-button--icon-decrease-nesting-level')
  decreaseNestingButton.classList.add('rich-text-editor__button')

  const increaseNestingButton = toolbarElement.querySelector(
    '[data-trix-action=increaseNestingLevel]'
  )
  increaseNestingButton.innerHTML = getIconHTML('format_indent_increase')
  increaseNestingButton.classList.remove('trix-button--icon-increase-nesting-level')
  increaseNestingButton.classList.add('rich-text-editor__button')
}

const RichTextEditor = ({
  name,
  label,
  value,
  characterLimit,
  placeholder,
  onChange,
  hideHeadingBlock,
  hideCodeBlock,
  hideFileTools,
}) => {
  const [isMaxCharacters, setIsMaxCharacters] = useState(false)
  const trixToolbar = useRef()
  const trixEditor = useRef()

  const toolbarClass = 'rich-text-editor__toolbar'

  const hideToolsByClassName = {
    [`${toolbarClass}--hide-heading-block`]: hideHeadingBlock,
    [`${toolbarClass}--hide-code-block`]: hideCodeBlock,
    [`${toolbarClass}--hide-file-tools`]: hideFileTools,
  }

  const toolbarClasses = Object.entries(hideToolsByClassName)
    .filter(([key, value]) => value)
    .reduce((accumulator, [key, value]) => `${accumulator} ${key}`, toolbarClass)

  useEffect(() => {
    if (trixToolbar.current) {
      customizeIcons(trixToolbar.current)
    }
  }, [])

  const handleTrixChange = (event) => {
    const { editor } = event.target
    const string = editor.getDocument().toString()
    const characterCount = string.length - 1

    if (characterLimit) {
      setIsMaxCharacters(characterCount >= characterLimit)
    }

    if (onChange) {
      onChange(editor.element.value)
    }
  }

  const handleKeyPress = (event) => {
    if (isMaxCharacters) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (trixEditor.current) {
      trixEditor.current.addEventListener('trix-change', handleTrixChange)
      trixEditor.current.addEventListener('keypress', handleKeyPress)
      trixEditor.current.addEventListener('paste', handleKeyPress)
    }

    return () => {
      trixEditor.current.removeEventListener('trix-change', handleTrixChange)
      trixEditor.current.removeEventListener('keypress', handleKeyPress)
      trixEditor.current.removeEventListener('paste', handleKeyPress)
    }
  }, [handleTrixChange, handleKeyPress])

  return (
    <div className="rich-text-editor u-margin--top-20">
      <input className="hidden" type="hidden" name={name} id={name} defaultValue={value || ''} />
      <trix-toolbar ref={trixToolbar} id={`${name}-toolbar`} class={toolbarClasses}></trix-toolbar>
      <trix-editor
        title={label}
        ref={trixEditor}
        toolbar={`${name}-toolbar`}
        input={name}
        class="rich-text-editor__textarea trix-content"
        placeholder={placeholder}></trix-editor>
      {isMaxCharacters && (
        <span>
          <small className="text--brightred">{`Text cannot exceed ${characterLimit} characters.`}</small>
        </span>
      )}
    </div>
  )
}

export default RichTextEditor
