const SuppliersTable = ({ suppliers, chooseSupplier }) => {
  function clickRow(supplier) {
    chooseSupplier(supplier)
  }

  let rows = suppliers.map((supplier, i) => {
    return (
      <tr key={i} onClick={clickRow.bind(this, supplier)} style={{ cursor: 'pointer' }}>
        <td className="mdl-data-table__cell--non-numeric">{supplier.name}</td>
      </tr>
    )
  })

  return (
    <table className="mdl-data-table mdl-js-data-table block--full">
      <tbody>{rows}</tbody>
    </table>
  )
}

export default SuppliersTable
