import { Textfield } from 'react-mdl'
import WaiverQuestions from './WaiverQuestions'

const WaiverFields = ({ program }) => {
  // Assuming only 1 waiver allowed per program for now
  const waiver = program.waivers[0]
  const waiverInputName = (name) => `program[waivers_attributes][0][${name}]`

  return (
    <div className="mdl-grid">
      <input type="hidden" name={waiverInputName('id')} value={waiver.id || ''} />
      <div className="mdl-cell mdl-cell--3-col">
        <dt className="text--med text--normal">
          {I18n.t('events.views.program_waiver_settings.waiver_details')}
        </dt>
      </div>
      <dd className="mdl-cell mdl-cell--9-col">
        <Textfield
          floatingLabel
          label={I18n.t('activerecord.attributes.waiver.name')}
          name={waiverInputName('name')}
          defaultValue={waiver.name || ''}
          className="textfield__input--full"
          error={waiver.errors['name']}
        />
      </dd>

      <div className="mdl-cell mdl-cell--3-col">
        <dt className="text--med text--normal">
          <span className="visuallyhidden">
            {I18n.t('events.views.program_waiver_settings.waiver_text')}
          </span>
        </dt>
      </div>
      <dd className="mdl-cell mdl-cell--9-col">
        <label className="text--darkblue" htmlFor={waiverInputName('content')}>
          {I18n.t('events.views.program_waiver_settings.waiver_display_text')}
        </label>
        <input
          className="hidden"
          type="hidden"
          id={waiverInputName('content')}
          name={waiverInputName('content')}
          value={waiver.content || ''}
        />
        <trix-editor
          className="rich-text-editor__textarea trix-content"
          input={waiverInputName('content')}></trix-editor>
        {waiver.errors['content'] ? (
          <span className="mdl-textfield__error u-visible">{waiver.errors['content']}</span>
        ) : null}
      </dd>

      <div className="mdl-cell mdl-cell--12-col">
        <WaiverQuestions
          questions={waiver.waiver_questions}
          questionError={waiver.errors['base']}
        />
      </div>
    </div>
  )
}

export default WaiverFields
