import SideNav from './SideNav'
import ParkMap from './ParkMap'
import { MapViewContext, useMapViewStore } from './mapViewContext'

const { useObserver } = mobxReactLite

const MapView = ({ park, backPath, endpoint, canEdit }) => {
  const store = useMapViewStore(park, endpoint, backPath, canEdit)

  return useObserver(() => (
    <MapViewContext.Provider value={store}>
      <div className="mdl-layout__drawer" style={{ width: '280px' }}>
        <SideNav />
      </div>

      <div className="mdl-layout__content" style={{ marginLeft: '280px' }}>
        <div id="page-content" className="page-content">
          <div style={{ height: '100%' }}>
            <ParkMap />
          </div>
        </div>
      </div>
    </MapViewContext.Provider>
  ))
}

export default MapView
