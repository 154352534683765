import classNames from 'classnames'
import { formatDollars } from '@utils'

const Colors = ({ amount, className, children, ...props }) => {
  return (
    <span
      className={classNames(className, {
        'text--brightred': amount < 0,
        'text--darkblue': amount > 0,
        'text--lightgray': amount === 0,
      })}
      {...props}>
      {children ? children : formatDollars(amount)}
    </span>
  )
}

export const Currency = {
  Colors,
}
