import React from 'react'
import ExecutionerDrawApplicationProducts from './ExecutionerDrawApplicationProducts'
const { MdlText, $ } = window
export default class ExecutionerForm extends React.Component {
  constructor(props) {
    super(props)

    this.setExecutionerDefaults(props.executioner)

    this.state = {
      executioner: props.executioner,
      availableProducts: [],
      assignedProducts: props.assignedOptions,
      selectedYear: props.selectedYear || props.drawYears[0],
    }

    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleSelectYear = this.handleSelectYear.bind(this)
    this.loadDrawAppProducts = this.loadDrawAppProducts.bind(this)
  }

  componentDidMount() {
    this.loadDrawAppProducts()

    var date_pickers = $('input.date-input-attribute')
    date_pickers.map(function(idx) {
      $(date_pickers[idx]).datepicker({
        format: 'MMMM D, YYYY - hh:mmA',
        year: true,
        custom_class: 'date-input-attribute',
      })
    })
  }

  setExecutionerDefaults(executioner) {
    if (executioner.tag_return_window) {
      executioner.tag_return_window = moment(executioner.tag_return_window)
        .tz(window.timeZone)
        .format('MMMM D, YYYY - hh:mmA')
    }
    if (executioner.schedule_start_at) {
      executioner.schedule_start_at = moment(executioner.schedule_start_at)
        .tz(window.timeZone)
        .format('MMMM D, YYYY - hh:mmA')
    }
    if (executioner.schedule_end_at) {
      executioner.schedule_end_at = moment(executioner.schedule_end_at)
        .tz(window.timeZone)
        .format('MMMM D, YYYY - hh:mmA')
    }

    executioner.type = executioner.type_cd === 0 ? 'draw' : 'fcfs'
  }

  get drawAppProductsUrl() {
    let url = `/contracts/${this.props.contractId}/executioners/draw_app_products.json`
    return url + `?license_year_name=${this.state.selectedYear.id}`
  }

  loadDrawAppProducts() {
    return $.getJSON(this.drawAppProductsUrl, (res) => {
      this.setState({ availableProducts: res })
    })
  }

  handleTypeChange(option) {
    const executioner = this.state.executioner
    executioner.type = option.id
    this.setState({ executioner: executioner })
  }

  handleSelectYear(val) {
    if (this.state.selectedYear.id === val.id) {
      return
    }

    this.setState({ selectedYear: val, assignedProducts: [] }, () => {
      this.loadDrawAppProducts().done(() => {
        this.executionProductInstance.clearSelections()
      })
    })
  }

  render() {
    return (
      <div className="mdl-grid block--full u-padding--20">
        <div className="mdl-cell mdl-cell--8-col">
          <MdlSelect
            name="executioner[type]"
            selected={this.state.executioner.type}
            options={this.props.executionerTypes}
            onOptionSelected={this.handleTypeChange.bind(this)}
            label="Execution Type"
            error={this.props.errors.type}
          />
        </div>

        <div className="mdl-cell mdl-cell--8-col">
          <MdlText
            name="executioner[display_name]"
            label="Display Name"
            defaultValue={this.props.executioner.display_name}
            onChange={() => {}} // no callback needed here, but react will print warnings if no onChange method is specified
            error={this.props.errors.display_name}
          />
        </div>

        <div className="mdl-cell mdl-cell--8-col">
          <MdlText
            name="executioner[description]"
            label="Description"
            defaultValue={this.props.executioner.description}
            onChange={() => {}} // no callback needed here, but react will print warnings if no onChange method is specified
            error={this.props.errors.description}
          />
        </div>

        {this.state.executioner.type !== 'fcfs' && (
          <div className="mdl-cell mdl-cell--8-col">
            <MdlSelect
              name="executioner[execution_configuration_id]"
              selected={this.props.executioner.execution_configuration_id}
              options={this.props.executionConfigurations}
              includeBlankOption={true}
              label="Execution Configuration"
              error={this.props.errors.execution_configuration}
            />
          </div>
        )}

        <div className="mdl-cell mdl-cell--8-col">
          <MdlSelect
            name="executioner[license_year_name]"
            selected={this.state.selectedYear}
            options={this.props.drawYears}
            onOptionSelected={this.handleSelectYear}
            label="License Year"
            error={this.props.errors.license_year_name}
          />
        </div>

        <div className="mdl-cell mdl-cell--8-col">
          <ExecutionerDrawApplicationProducts
            allOptions={this.state.availableProducts}
            assignedOptions={this.state.assignedProducts}
            ref={(instance) => {
              this.executionProductInstance = instance
            }}
          />
        </div>
        {this.state.executioner.type === 'fcfs' && (
          <div className="mdl-cell mdl-cell--8-col">
            {/*eslint-disable */}
            <Chipper
              assignedOptions={this.props.selectedFcfsModules}
              availableOptions={this.props.allFcfsModules}
              name={'executioner[fcfs_modules][]'}
              primaryField={'name'}
              title="FCFS modules"
            />
            {/*eslint-disable */}
          </div>
        )}

        <div
          className={
            'mdl-cell mdl-cell--8-col' + (this.state.executioner.type !== 'fcfs' ? '' : ' hide')
          }>
          <div
            className={
              'mdl-textfield mdl-js-textfield mdl-textfield--floating-label' +
              (this.props.errors.tag_return_window ? ' is-invalid' : '')
            }>
            <input
              className="mdl-textfield__input date-input-attribute"
              type="text"
              name="executioner[tag_return_window]"
              readOnly="readonly"
              defaultValue={this.state.executioner.tag_return_window}
            />
            <label className="mdl-textfield__label">Tag Return Window</label>
            {this.props.errors.tag_return_window && (
              <span className="mdl-textfield__error">{this.props.errors.tag_return_window}</span>
            )}
          </div>
        </div>

        <div
          className={
            'mdl-cell mdl-cell--8-col' + (this.state.executioner.type === 'fcfs' ? '' : ' hide')
          }>
          <div
            className={
              'mdl-textfield mdl-js-textfield mdl-textfield--floating-label' +
              (this.props.errors.schedule_start_at ? ' is-invalid' : '')
            }>
            <input
              className="mdl-textfield__input date-input-attribute"
              type="text"
              name="executioner[schedule_start_at]"
              readOnly="readonly"
              defaultValue={this.state.executioner.schedule_start_at}
            />
            <label className="mdl-textfield__label">Schedule Start</label>
            {this.props.errors.schedule_start_at && (
              <span className="mdl-textfield__error">{this.props.errors.schedule_start_at}</span>
            )}
          </div>
        </div>

        <div
          className={
            'mdl-cell mdl-cell--8-col' + (this.state.executioner.type === 'fcfs' ? '' : ' hide')
          }>
          <div
            className={
              'mdl-textfield mdl-js-textfield mdl-textfield--floating-label' +
              (this.props.errors.schedule_end_at ? ' is-invalid' : '')
            }>
            <input
              className="mdl-textfield__input date-input-attribute"
              type="text"
              name="executioner[schedule_end_at]"
              readOnly="readonly"
              defaultValue={this.state.executioner.schedule_end_at}
            />
            <label className="mdl-textfield__label">Schedule End</label>
            {this.props.errors.schedule_end_at && (
              <span className="mdl-textfield__error">{this.props.errors.schedule_end_at}</span>
            )}
          </div>
        </div>

        {this.state.executioner.type === 'fcfs' && (
          <div className="mdl-cell mdl-cell--8-col">
            <MdlText
              type="number"
              min="0"
              name="executioner[remove_days_prior]"
              label="Remove Days Prior (to end of season)"
              defaultValue={this.props.executioner.remove_days_prior}
              onChange={() => {}} // no callback needed here, but react will print warnings if no onChange method is specified
              error={this.props.errors.remove_days_prior}
            />
          </div>
        )}

        {this.state.executioner.type === 'fcfs' && (
          <div className="mdl-cell mdl-cell--8-col">
            <MdlText
              type="number"
              min="0"
              name="executioner[minimum_minutes_hidden]"
              label="Start Time for Delay (minutes)"
              defaultValue={this.props.executioner.minimum_minutes_hidden}
              onChange={() => {}} // no callback needed here, but react will print warnings if no onChange method is specified
              error={this.props.errors.minimum_minutes_hidden}
            />
          </div>
        )}

        {this.state.executioner.type === 'fcfs' && (
          <div className="mdl-cell mdl-cell--8-col">
            <MdlText
              type="number"
              min="0"
              name="executioner[maximum_minutes_hidden]"
              label="End Time for Delay (minutes)"
              defaultValue={this.props.executioner.maximum_minutes_hidden}
              onChange={() => {}} // no callback needed here, but react will print warnings if no onChange method is specified
              error={this.props.errors.maximum_minutes_hidden}
            />
          </div>
        )}

        {this.state.executioner.type === 'fcfs' && (
          <div className="mdl-cell mdl-cell--8-col">
            <MdlCheckbox
              label="Purchase Creates Application"
              name="executioner[fcfs_purchase_creates_application]"
              checked={this.props.executioner.fcfs_purchase_creates_application}
            />
          </div>
        )}
      </div>
    )
  }
}
