import { Card, Grid, Cell, Button } from 'react-mdl'
import useFetch from 'use-http'
import { formatDollars } from '@utils'
import { Btn, DataTable, Currency } from '@components/shared'
import BookingDetails from '../BookingList/BookingDetails'
import RemoveItemModal from './RemoveItemModal'
import NavigateToEditModal from './NavigateToEditModal'

const { useState, Fragment, useCallback, useMemo } = React

const ReservationReview = ({
  initialCart,
  initReservationHistory,
  bookingHistory,
  path,
  customer,
  customerPath,
}) => {
  const [cart, setCart] = useState(initialCart)
  const [reservationHistory, setReservationHistory] = useState(initReservationHistory)
  const [consumablesExpanded, setConsumablesExpanded] = useState(false)
  const [itemToRemove, setItemToRemove] = useState(null)
  const [editItem, setEditItem] = useState(null)

  const reservationPath = `${path}/reservations`
  const cartPath = `${reservationPath}/${cart.reservation.id}/carts/${cart.id}`

  const { post, response } = useFetch(cartPath, {
    headers: window.__AMS__.formToken,
    cachePolicy: 'no-cache',
  })

  const { del } = useFetch(reservationPath, {
    headers: window.__AMS__.formToken,
    cachePolicy: 'no-cache',
  })

  const { get: refreshHistory } = useFetch(`${cartPath}/history`, { cachePolicy: 'no-cache' })

  const bookingIds = new Set(cart.cart_items.map((i) => i.booking_id))

  const handleResponse = useCallback(
    (res) => {
      if (response.ok) {
        setCart(res)
        return true
      } else {
        console.error(response)
        return false
      }
    },
    [response]
  )

  const handleBookingRemoval = useCallback(
    (booking) => {
      if (bookingIds.size > 1) {
        let cartItem = cart.cart_items.find((item) => item.booking_id === booking.id)
        setItemToRemove(cartItem)
      } else {
        if (window.confirm('Are you sure you want to cancel this reservation?')) {
          del(`/${cart.reservation.id}`, { id: cart.reservation.id, format: 'html' })
          window.location = `${reservationPath}/new`
        }
      }
    },
    [cart.cart_items, reservationPath, bookingIds, cart.reservation.id, del]
  )

  const removeConsumable = useCallback(
    async (uuid) => {
      const res = await post('/remove_from_cart', { uuid, format: 'json' })
      handleResponse(res)
      setItemToRemove(null)
      setReservationHistory(await refreshHistory())
    },
    [handleResponse, post, refreshHistory]
  )

  const removeBooking = useCallback(
    async (booking_id) => {
      const res = await post('/remove_from_cart', { booking_id, format: 'json' })
      handleResponse(res)
      setItemToRemove(null)
      setReservationHistory(await refreshHistory())
    },
    [handleResponse, post, refreshHistory]
  )

  const onItemRemoval = useCallback(
    (identifier, itemType) => {
      itemType === 'ConsumableItem' ? removeConsumable(identifier) : removeBooking(identifier)
    },
    [removeBooking, removeConsumable]
  )

  const onQuantityUpdate = useCallback(
    async (item, quantity) => {
      if (quantity === '') return
      const res = await post('/update_item', { ...item, quantity, format: 'json' })
      handleResponse(res)
      setReservationHistory(await refreshHistory())
    },
    [post, handleResponse, refreshHistory]
  )

  const cancelRemoval = () => {
    setItemToRemove(null)
  }

  const cancelEdit = () => {
    setEditItem(null)
  }

  const renderEditModal = () => {
    if (editItem === 'customer') {
      return (
        <NavigateToEditModal
          cancelEdit={cancelEdit}
          path={`${reservationPath}/${cart.reservation.id}/customers`}
          viewName="Customer Details"
        />
      )
    } else {
      return (
        <NavigateToEditModal
          cancelEdit={cancelEdit}
          path={`${reservationPath}/${cart.reservation.id}/bookings/${editItem.id}/edit`}
          viewName="Booking"
        />
      )
    }
  }

  const renderBookings = useMemo(() => {
    const bookings = cart.reservation.bookings.filter((b) => bookingIds.has(b.id))
    const handleBookingEdit = (booking) => {
      setEditItem(booking)
    }
    return bookings.map((booking, index) => {
      const cartItem = cart.booking_items.find((i) => i.booking_id === booking.id)
      return (
        <Card key={booking.id} className="mdl-shadow--2dp block--full mt-20 px-0">
          <BookingDetails
            booking={booking}
            cartItem={cartItem}
            index={index}
            count={bookings.length}
            path={path}
            key={booking.id}
            isReview={true}
            handleRemoval={handleBookingRemoval}
            handleEdit={handleBookingEdit}
            reservationHistory={reservationHistory}
            bookingHistory={bookingHistory}
          />
        </Card>
      )
    })
  }, [cart, handleBookingRemoval, path, bookingIds, reservationHistory, bookingHistory])

  const rows = useMemo(() => {
    return cart.consumable_items.map((item) => {
      let booking = cart.reservation.bookings.find((booking) => booking.id === item.booking_id)
      return {
        ...item,
        siteName: booking.zone.name,
        occupantFullName: `${booking.primary_occupant.first_name} ${booking.primary_occupant.last_name}`,
      }
    })
  }, [cart])

  const columns = [
    {
      name: 'name',
      display: 'Item',
      nosort: true,
    },
    {
      name: 'zone',
      display: 'Booking',
      colGroupStyle: { width: '35%' },
      nosort: true,
      cellFormatter: (_, row) => (
        <span>
          {row.siteName}, {row.occupantFullName}
        </span>
      ),
    },
    {
      name: 'subtotal',
      display: 'Item Unit Price',
      nosort: true,
      cellFormatter: (subtotal) => <span>${subtotal}</span>,
    },
    {
      name: 'quantity',
      display: 'Quantity',
      colGroupStyle: { width: '5%' },
      nosort: true,
      cellFormatter: (quantity, row) => (
        <input
          id={`${row.name}+${quantity}`}
          type="number"
          min="1"
          max="99"
          onChange={(e) => onQuantityUpdate(row, e.target.value)}
          defaultValue={quantity}
          className="u-border--lightgray u-border--round text--med"
          style={{ width: 40 }}
        />
      ),
    },
    {
      name: 'subtotal_with_quantity',
      display: 'Item Total',
      nosort: true,
      cellFormatter: (subtotal_with_quantity) => (
        <span>{formatDollars(subtotal_with_quantity)}</span>
      ),
    },
    {
      name: 'remove',
      display: 'Remove',
      nosort: true,
      hideHeader: true,
      colGroupStyle: { width: '5%' },
      cellFormatter: (_, row) => (
        <Btn.Icon
          light
          className="u-pull--right"
          name="delete"
          label={`Remove Add-On ${row.name}`}
          onClick={() => setItemToRemove(row)}
        />
      ),
    },
  ]

  const priceSummary = reservationHistory ? reservationHistory : cart

  return (
    <Fragment>
      <Card className="mdl-shadow--2dp block--full mt-20">
        <Grid className="camp-details-list block--full px-20">
          <div className="py-10 px-0 d-flex block--full flex__items--center flex__justify--space-between u-border--bottom">
            <div className="text--med-lg">Customer Details</div>
            <div>
              <Button
                type="button"
                onClick={() => setEditItem('customer')}
                className="mdl-button--wide mdl-button--accent mr-0">
                Edit Customer Details
              </Button>
            </div>
          </div>
          <Cell col={12} className="text--med pt-20">
            Customer Information
          </Cell>
          <Cell col={2}>
            <dt>Customer ID</dt>
            <dd>{customer.customer_number}</dd>
          </Cell>
          <Cell col={2}>
            <dt>Customer Full Name</dt>
            <dd>
              {customer.first_name} {customer.last_name}
            </dd>
            <a href={customerPath} className="u-text-decoration--none">
              Go to Full Profile&nbsp;<i className="material-icons md-18">open_in_new</i>
            </a>
          </Cell>
          <Cell col={2}>
            <dt>Date of Birth</dt>
            <dd>{customer.dob}</dd>
          </Cell>
          <Cell col={3}>
            <dt>Email</dt>
            <dd>{customer.email_address}</dd>
          </Cell>
          <Cell col={2}>
            <dt>
              Phone <abbr name="Number">No.</abbr>
            </dt>
            <dd>{customer.home_phone || customer.mobile_phone}</dd>
          </Cell>
        </Grid>
      </Card>
      {renderBookings}
      <Card className="mdl-shadow--2dp block--full mt-20">
        <Grid
          className={`mdl-grid--no-spacing px-20 mx-0 my-0 flex__items--center ${
            consumablesExpanded ? '' : 'u-border--bottom'
          }`}>
          <Cell col={1} className="py-10">
            <Btn.Icon
              light
              aria-label={`Expand Add-Ons`}
              name={consumablesExpanded ? 'expand_less' : 'expand_more'}
              onClick={() => setConsumablesExpanded(!consumablesExpanded)}
            />
          </Cell>
          <Cell col={10} className="py-10">
            <span className="text--med-lg" style={{ fontWeight: 'bold' }}>
              Add-Ons ({cart.consumable_items.length})
            </span>
            &nbsp;
            <a
              className="u-text-decoration--none text--med"
              href={`${reservationPath}/${cart.reservation.id}/carts/${cart.id}`}>
              + Add More
            </a>
          </Cell>
          <Cell col={1} className="pl-20 py-10 text--med-lg">
            <Currency.Colors amount={priceSummary.consumable_subtotal} />
          </Cell>
        </Grid>
        {consumablesExpanded && (
          <DataTable
            topic="Add-Ons"
            rows={rows}
            columns={columns}
            rowKeyColumn="uuid"
            noResultsText="No Add-Ons in Cart"
          />
        )}
      </Card>
      {itemToRemove ? (
        <RemoveItemModal
          cancelRemoval={cancelRemoval}
          removeItem={onItemRemoval}
          item={itemToRemove}
        />
      ) : null}
      {editItem ? renderEditModal() : null}
      <Card className="mdl-shadow--2dp block--full mt-20 px-20">
        <Grid className="mdl-grid--no-spacing m-0 flex__items--center u--align-right">
          <Cell col={12} className="u-border--bottom text--med-lg py-10 my-10 u--align-left">
            Cart Total
          </Cell>
          <Cell col={10} className="text--med">
            Sub-Total:
          </Cell>
          <Cell col={2} className="text--med  text--bold">
            <Currency.Colors amount={priceSummary.subtotal} />
          </Cell>
          <Cell col={10} className="text--med pt-10">
            Tax:
          </Cell>
          <Cell col={2} className="text--med text--bold">
            <Currency.Colors amount={priceSummary.tax} />
          </Cell>
          <Cell col={10} className="text--lg pt-10">
            Amount Owed:
          </Cell>
          <Cell col={2} className="text--lg text--lightblue text--bold">
            <Currency.Colors amount={priceSummary.grand_total} />
          </Cell>
          <Cell col={12} className="py-20">
            <a
              href={`${cartPath}/checkout`}
              className="mdl-button mdl-button--wide mdl-button--gray u-pull--right">
              Continue to Checkout
            </a>
          </Cell>
        </Grid>
      </Card>
    </Fragment>
  )
}

export default ReservationReview
