import { Button, Grid, Cell } from 'react-mdl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useYupModel } from '@hooks'
import OpenPeriodFields from './OpenPeriodFields'
import SeasonsFields from './SeasonsFields'
import GatesFields from './GatesFields'
import { FC } from '@components/shared'
const { useEffect } = React

let renderCount = 0

const OperationForm = ({ fields, park, parkOpenPeriods, endpoint, cancelPath, addressProps }) => {
  const { schema } = useYupModel(fields, 'camp_park')
  const form = useForm({
    resolver: yupResolver(schema),
  })

  // Fetch and set any errors sent back from the server
  const { setError } = form
  useEffect(() => {
    fields.errors.forEach((err) => setError(err.input_name, { type: 'manual', message: err.value }))
  }, [fields.errors, setError])

  const onSubmit = (_data, e) => {
    e.target.submit()
  }

  return (
    <FC.Form
      fields={fields}
      form={form}
      action={endpoint}
      method="put"
      onSubmit={form.handleSubmit(onSubmit)}>
      <Grid className="u-padding--20">
        <Cell col={12}>
          <h2 className="text--med-lg u-margin--y-none">Open to Public</h2>
          <OpenPeriodFields parkOpenPeriods={parkOpenPeriods} />
        </Cell>
        <Cell col={12}>
          <h2 className="text--med-lg u-margin--y-none">Park Reservations</h2>
          <Grid className="u-padding--none">
            <Cell col={3} className="u-margin--left-none always-floating-label">
              <FC.TimePicker field={fields.check_in_time} />
            </Cell>
            <Cell col={3}>
              <FC.TimePicker field={fields.check_out_time} />
            </Cell>
          </Grid>
        </Cell>
        <Cell col={12}>
          <h2 className="text--med-lg u-margin--y-none">Peak Seasons</h2>
          <SeasonsFields />
        </Cell>
        <Cell col={12}>
          <h2 className="text--med-lg u-margin--y-none">Park Gates</h2>
        </Cell>
      </Grid>

      <GatesFields addressProps={addressProps} />

      <Grid className="u-padding--20">
        <Cell col={12} className="d-flex flex__justify--end">
          <Button
            type="button"
            href={cancelPath}
            className="mdl-button--light mdl-button--wide mdl-button--wide-centered">
            Cancel
          </Button>
          <Button
            type="submit"
            className="mdl-button--gray mdl-button--wide mdl-button--wide-centered">
            Save
          </Button>
        </Cell>
      </Grid>
    </FC.Form>
  )
}

export default OperationForm
