import { Grid, Cell, Button, Checkbox, Spinner } from 'react-mdl'
import useFetch from 'use-http'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useDidMount, useYupModel } from '@hooks'
import { onKeyActivate } from '@utils'
import { Btn, FC, Modal } from '@components/shared'
const { Fragment, useState, useEffect, useCallback } = React

const CustomerFormModal = ({ customer = null, customersPath, cancel, submit }) => {
  const { get, post, put, loading, response, cache } = useFetch(customersPath, {
    headers: window.__AMS__.formToken,
  })
  const [fields, setFields] = useState(null)
  const [addressOptions, setAddressOptions] = useState({})

  useDidMount(() => {
    const fetchFields = async () => {
      const path = customer ? `/${customer.id}/edit.json` : '/new.json'
      const res = await get(path)
      setFields(res.form)
      setAddressOptions(res.address_options)
    }

    fetchFields()
  })

  const handleSubmit = useCallback(
    async (data) => {
      cache.clear()

      let res
      if (customer?.id) {
        res = await put(`/${customer.id}`, { ...data, format: 'json' })
      } else {
        res = await post('/', { ...data, format: 'json' })
      }

      if (response.ok) {
        submit(res.customer)
      } else {
        setFields(res.form)
      }
    },
    [customer, post, put, cache, response, submit]
  )

  return (
    <Modal
      open={true}
      id="customer_form"
      onClose={cancel}
      onCancel={cancel}
      style={{ maxWidth: '1000px' }}>
      <Modal.Title id="customer_form">
        {customer
          ? `Edit Details - ${customer.full_name} ${customer.customer_number}`
          : 'Create New Customer'}
      </Modal.Title>

      {fields ? (
        <CustomerForm
          fields={fields}
          cancel={cancel}
          submit={handleSubmit}
          loading={loading}
          addressOptions={addressOptions}
        />
      ) : (
        <Fragment>
          {loading ? (
            <div className="p-40 d-flex flex__justify--center">
              <Spinner role="progressbar" aria-valuetext="Loading" />
            </div>
          ) : null}
        </Fragment>
      )}
    </Modal>
  )
}

const CustomerForm = ({ fields, cancel, submit, loading, addressOptions }) => {
  const [confirmed, setConfirmed] = useState(false)
  const { schema } = useYupModel(fields, 'customer')
  const form = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    fields.errors.forEach((err) =>
      form.setError(err.input_name, { type: 'manual', message: err.value })
    )
  }, [fields.errors]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data) => {
    submit(data)
  }

  const address = fields.addresses_attributes[0]
  const baseErrors = fields.errors.find((e) => e.input_name === 'base')
  const { watch, setValue } = form

  return (
    <FC.Form fields={fields} form={form} onSubmit={form.handleSubmit(onSubmit)}>
      <Modal.Content>
        {baseErrors ? <div className="mdl-alert mdl-alert--error">{baseErrors.value}</div> : null}
        <Grid className="u-padding--none flex__items--end">
          <Cell col={12}>
            <h5>Personal Information</h5>
          </Cell>
          <Cell col={4}>
            <FC.Input field={fields.first_name} />
          </Cell>
          <Cell col={4}>
            <FC.Input field={fields.last_name} />
          </Cell>
          <Cell col={4}>
            <FC.Input
              field={fields.dob}
              type="date"
              label="Date of Birth"
              className="always-floating-label"
            />
          </Cell>
          <Cell col={4}>
            <FC.Input field={fields.email_address} />
          </Cell>
          <Cell col={4}>
            <FC.Input field={fields.primary_phone} label="Phone Number" />
          </Cell>
        </Grid>

        <Grid className="u-padding--none flex__items--end">
          <Cell col={12}>
            <h5>Address</h5>
          </Cell>
          <FC.Hidden field={address.id} />
          <FC.Hidden field={address.mailing} defaultValue={true} />
          <FC.Hidden field={address.physical} defaultValue={true} />
          <Cell col={4}>
            <FC.Input field={address.address1} />
          </Cell>
          <Cell col={4}>
            <FC.Input field={address.address2} />
          </Cell>
          <Cell col={4}>
            <FC.Input field={address.city} />
          </Cell>
          <Cell col={4}>
            <FC.Input
              field={address.subregion}
              as={MdlSelect}
              options={addressOptions.states || []}
              selected={watch(address.subregion.input_name, address.subregion.value)}
              includeBlankOption={true}
              onOptionSelected={(option) => setValue(address.subregion.input_name, option?.id)}
              label="State"
            />
          </Cell>
          <Cell col={4}>
            <FC.Input
              field={address.country}
              as={MdlSelect}
              options={addressOptions.countries || []}
              selected={watch(address.country.input_name, address.country.value)}
              includeBlankOption={true}
              onOptionSelected={(option) => setValue(address.country.input_name, option?.id)}
            />
          </Cell>
          <Cell col={4}>
            <FC.Input field={address.postal_code} />
          </Cell>
        </Grid>
      </Modal.Content>

      <Grid className="p-20">
        <Cell col={6}>
          <Checkbox
            label="I Confirm that I have verified this Customer’s information"
            ripple
            onChange={(e) => setConfirmed(e.target.checked)}
            onKeyDown={onKeyActivate('click')}
          />
        </Cell>
        <Cell col={6} className="u--align-right">
          <Button type="button" className="mdl-button--light" onClick={cancel}>
            Cancel this Action
          </Button>
          <Btn.Spinner
            type="submit"
            loading={loading}
            className="mdl-button--gray"
            disabled={!confirmed}>
            {fields.id.value ? 'Save Changes' : 'Create Customer'}
          </Btn.Spinner>
        </Cell>
      </Grid>
    </FC.Form>
  )
}

export default CustomerFormModal
