export default class SupplyKitOrderRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: props.order,
      expanded: false,
    }
    this.handleExpand = this.handleExpand.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.shipOrder = this.shipOrder.bind(this)
    this.handleTrackingChange = this.handleTrackingChange.bind(this)
  }

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  handleTrackingChange(e) {
    this.props.onTrackingNumberChange(this.state.order, e.target.value)
  }

  get tdStyles() {
    return {
      borderBottom: this.state.expanded ? 'none' : '1px solid #f0f0f0',
    }
  }

  get trackingNumber() {
    if (this.state.order.status === 'rejected') {
      return ''
    } else if (this.state.order.status === 'shipped') {
      return this.state.order.tracking_number
    } else {
      return (
        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label textfield__input--full">
          <input
            type="text"
            className="mdl-textfield__input string"
            onChange={this.handleTrackingChange}
            ref={(input) => (this._trackingNumberInput = input)}
            defaultValue={this.state.order.tracking_number}
          />
          <label
            className="mdl-textfield__label white-space-nowrap display-inline-table"
            style={{ fontSize: '14px' }}>
            Tracking Number
          </label>
        </div>
      )
    }
  }

  get locationAddress() {
    if (this.state.order.location && this.state.order.location.location) {
      let location = this.state.order.location.location
      return (
        <span>
          {location.addr1} {location.addr2}
          <br />
          {location.city}, {location.state_code} {location.postal_code}
        </span>
      )
    }
  }

  get orderStatus() {
    let options = [
      { id: 'pending', name: 'Pending' },
      { id: 'rejected', name: 'Rejected' },
      { id: 'shipped', name: 'Shipped' },
      { id: 'approved', name: 'Approved' },
    ]
    const statuses = ['pending', 'shipped', 'rejected', 'approved']

    if (statuses[this.state.order.status_cd] == 'shipped') {
      return (
        <span className="mdl-chip mdl-active-chip mdl-chip--skinny">
          <span className="mdl-chip__text">Shipped</span>
        </span>
      )
    } else if (statuses[this.state.order.status_cd] == 'rejected') {
      return (
        <span
          className="mdl-chip mdl-warning-chip mdl-chip--skinny"
          style={{ backgroundColor: '#f44242' }}>
          <span className="mdl-chip__text">Rejected</span>
        </span>
      )
    } else if (statuses[this.state.order.status_cd] == 'approved') {
      return (
        <span
          className="mdl-chip mdl-warning-chip mdl-chip--skinny"
          style={{ backgroundColor: '#4A90E2' }}>
          <span className="mdl-chip__text">Approved</span>
        </span>
      )
    } else {
      return (
        <ChipButton
          options={options}
          label="Pending"
          backgroundColor="#5B6C7F"
          onSelect={this.changeStatus}
        />
      )
    }
  }

  changeStatus(newStatus, e) {
    if (newStatus.id == 'shipped') {
      let trackingNumber = this._trackingNumberInput.value
      if (trackingNumber) {
        this.shipOrder()
      } else {
        let response = confirm(
          'Are you sure you want to mark this order shipped without providing a tracking number?'
        )
        if (response) {
          this.shipOrder()
        }
      }
    }
    if (newStatus.id == 'rejected') {
      let response = confirm('Are you sure you want to reject this order?')
      if (response) {
        this.rejectOrder()
      }
    }
    if (newStatus.id == 'approved') {
      this.approveOrder()
    }
  }

  shipOrder() {
    $.ajax({
      url: `/suppliers/${this.props.order.supply_kit.supplier_id}/supply_kit_orders/${this.props.order.id}`,
      type: 'PUT',
      dataType: 'json',
      data: {
        supply_kit_order: {
          status: 'shipped',
          tracking_number: this._trackingNumberInput.value,
        },
      },
      success: (response) => {
        this.setState({
          order: response,
        })
      },
    })
  }

  rejectOrder() {
    $.ajax({
      url: `/suppliers/${this.props.order.supply_kit.supplier_id}/supply_kit_orders/${this.props.order.id}`,
      type: 'PUT',
      dataType: 'json',
      data: {
        supply_kit_order: {
          status: 'rejected',
        },
      },
      success: (response) => {
        this.setState({
          order: response,
        })
      },
    })
  }

  approveOrder() {
    $.ajax({
      url: `/suppliers/${this.props.order.supply_kit.supplier_id}/supply_kit_orders/${this.props.order.id}`,
      type: 'PUT',
      dataType: 'json',
      data: {
        supply_kit_order: {
          status: 'approved',
        },
      },
      success: (response) => {
        this.setState({
          order: response,
        })
      },
    })
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    componentHandler.upgradeDom()
  }

  render() {
    const timeZone = window.timeZone
    const locationData = this.props.locationData[0]
    return (
      <tbody style={{ color: '#18304C', display: 'table-row-group' }}>
        <tr>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {this.orderStatus}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {moment(this.state.order.created_at)
              .tz(timeZone)
              .format('MM/DD/YYYY h:mma')}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {this.state.order.location.name}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {this.state.order.supply_kit.description}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {this.state.order.quantity}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {this.trackingNumber}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {locationData[this.state.order.location_id]
              ? moment(locationData[this.state.order.location_id].lastOrderDate)
                  .tz(timeZone)
                  .format('MM/DD/YYYY h:mma')
              : ''}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {locationData[this.state.order.location_id]
              ? locationData[this.state.order.location_id].lastOrderQty
              : ''}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            {locationData[this.state.order.location_id]
              ? locationData[this.state.order.location_id].ytdQuantity
              : ''}
          </td>
          <td
            className="mdl-data-table__cell--non-numeric white-space-normal"
            style={this.tdStyles}>
            <button
              className="mdl-button mdl-js-button mdl-button--icon"
              onClick={this.handleExpand}>
              <i className="material-icons">
                {this.state.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </button>
          </td>
        </tr>
        {this.state.expanded && (
          <tr>
            <td></td>
            <td className="mdl-data-table__cell--non-numeric">
              <span style={{ color: '#5B6C7F' }}>Location Number</span>
              <br />
              <br />
              {this.state.order.location.id}
              <br />
              <br />
            </td>
            <td className="mdl-data-table__cell--non-numeric" colSpan={5}>
              <span style={{ color: '#5B6C7F' }}>Location Address</span>
              <br />
              <br />
              {this.locationAddress}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tbody>
    )
  }
}
