import { Textfield, Grid, Cell } from 'react-mdl'
import useFetch from 'use-http'
import classNames from 'classnames'
import { onKeyActivate, sanitizedStringRegex } from '@utils'
import { useDidMount } from '@hooks'
import NpmapIcon from '@components/shared/NpmapIcon'
const { Fragment, useState } = React

const IconPicker = ({ id, value, name, iconJsonPath, selectedIcons, enableSearch = false }) => {
  const [icons, setIcons] = useState([])
  const [selected, setSelected] = useState(value || '')
  const { get, loading } = useFetch(iconJsonPath)
  const visibleIcons = icons.filter((i) => !i.hidden)

  useDidMount(() => {
    const fetchIcons = async () => {
      // fetch json file containing icon metadata
      let res = await get()
      // Filter all icons down to specific ones if a default list was provided
      if (selectedIcons) {
        res = res.filter((i) => selectedIcons.includes(i.icon))
      }
      setIcons(res)
    }

    fetchIcons()
  })

  const toggleIcon = (icon) => () => {
    setSelected(selected === icon ? null : icon)
  }

  const searchIcons = (e) => {
    const newIcons = [...icons]
    const regex = sanitizedStringRegex(e.target.value)

    if (regex) {
      newIcons.forEach((icon) => {
        const searchStr = [icon.name, icon.tags].flat().join(', ')
        icon.hidden = !regex.test(searchStr)
      })
    } else {
      newIcons.forEach((icon) => (icon.hidden = false))
    }

    setIcons(newIcons)
  }

  return (
    <Fragment>
      {enableSearch ? (
        <Grid className="u-padding--x-20">
          <Cell col={5}>
            <form id="icon_search" onSubmit={(e) => e.preventDefault()}>
              <Textfield
                floatingLabel
                className="always-floating-label textfield__input--full"
                label="Search Icons"
                name="search_icons"
                defaultValue=""
                placeholder="Keyword"
                onChange={searchIcons}
              />
            </form>
          </Cell>
          <Cell col={6} offset={1}>
            <div className="text--small text--lightgray" id="icon_preview">
              Preview
            </div>
            <div
              className="trait-icon-picker__preview u-margin--top-10"
              aria-labelledby="icon_preview">
              <NpmapIcon name={selected} />
            </div>
          </Cell>
        </Grid>
      ) : null}

      <div
        className={classNames('trait-icon-picker u-padding--20 u-margin--bottom-20', {
          'trait-icon-picker--mini': !enableSearch,
        })}
        role="radiogroup"
        aria-labelledby={id}>
        {!loading && visibleIcons.length === 0 ? (
          <p className="text--lightgray u-padding--10">No results</p>
        ) : null}

        {icons.map(({ icon, name, tags, hidden }, idx) => (
          <button
            role="radio"
            type="button"
            key={`icon-${idx}`}
            onClick={toggleIcon(icon)}
            onKeyDown={onKeyActivate('click')}
            aria-checked={selected === icon}
            className={classNames('trait-icon__button', {
              hidden,
              'trait-icon__button--active': selected === icon,
            })}>
            <span className="trait-icon__icon">
              <NpmapIcon name={icon} />
            </span>
            <span className="trait-icon__label" title={tags.join(', ')}>
              {name}
            </span>
          </button>
        ))}

        <input type="hidden" name={name} value={selected || ''} />
      </div>
    </Fragment>
  )
}

export default IconPicker
