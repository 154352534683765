const { MdlText } = window

class AppRequirements extends React.Component {
  constructor(props) {
    super(props)

    this.removeRequirement = this.removeRequirement.bind(this)
    this.handleUpdateName = this.handleUpdateName.bind(this)
    this.handleUpdateDescription = this.handleUpdateDescription.bind(this)
  }

  removeRequirement(e) {
    e.preventDefault()
    this.props.removeRecord(this.props.index)
  }

  handleUpdateName(e) {
    let requirement = this.props.record
    requirement.name = e.target.value
    this.props.onChange(this.props.index, requirement)
  }

  handleUpdateDescription(e) {
    let requirement = this.props.record
    requirement.description = e.target.value
    this.props.onChange(this.props.index, requirement)
  }

  get inputName() {
    return `program[application_requirements_attributes][${this.props.index}]`
  }

  get nameError() {
    if (this.props.record.errors) {
      return this.props.record.errors.name
    }
  }

  get removeButton() {
    if (!this.props.record.id) {
      return (
        <div>
          <button
            type="button"
            className="mdl-chip__action vm-dashboard-icon"
            onClick={this.removeRequirement}>
            <i className="material-icons">delete</i>
          </button>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    if (this.props.record.active || !this.props.record.id) {
      return (
        <div className="mdl-grid block--full">
          <input type="hidden" name={`${this.inputName}[id]`} value={this.props.record.id} />
          <input type="hidden" name={`${this.inputName}[active]`} value={true} />
          <div className="mdl-cell mdl-cell--4-col">
            <MdlText
              name={`${this.inputName}[name]`}
              label="Name"
              value={this.props.record.name}
              error={this.nameError}
              handleChange={this.handleUpdateName}
            />
          </div>
          <div className="mdl-cell mdl-cell--6-col">
            <MdlText
              name={`${this.inputName}[description]`}
              label="Description"
              value={this.props.record.description}
              handleChange={this.handleUpdateDescription}
            />
          </div>
          <div className="mdl-cell mdl-cell--1-col">{this.removeButton}</div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default AppRequirements
