import { Cell, Grid, RadioGroup, Radio } from 'react-mdl'
import ParentZoneDropdown from '@components/camp/ParentZoneDropdown'
import Well from '@components/shared/Well'

const { observer } = mobxReactLite

const SmallZoneParents = observer(
  ({
    zones,
    selectedZones,
    displayNames,
    name,
    onChange,
    active,
    activeWarningContent,
    premiumAttributes,
  }) => {
    const [mediumZoneDisabled, setMediumZoneDisabled] = React.useState(
      !selectedZones.medium && !!selectedZones.large
    )
    const [largeZoneDisabled, setLargeZoneDisabled] = React.useState(!!selectedZones.medium)
    const [isActive, setIsActive] = React.useState(active)

    const setLargeFromMedium = (mediumZone) => {
      let largeZone = zones.large.find((largeZone) => largeZone.id === mediumZone.parent_zone_id)
      onChange({ medium: mediumZone, large: largeZone })
    }

    const handleMediumChange = (zone) => {
      onChange({ medium: zone, large: selectedZones.large })
      if (zone !== undefined) {
        setLargeZoneDisabled(true)
        setLargeFromMedium(zone)
      } else {
        setLargeZoneDisabled(false)
      }
    }

    const handleLargeChange = (zone) => {
      onChange({ medium: selectedZones.medium, large: zone })
      if (zone !== undefined && !largeZoneDisabled) {
        setMediumZoneDisabled(true)
      } else {
        setMediumZoneDisabled(false)
      }
    }
    const hasPremiumAttributes = React.useCallback(
      (parent) => {
        return !!premiumAttributes.find((attr) => attr.parent === parent)
      },
      [premiumAttributes]
    )

    const warningContent = (displayName) => {
      return `This ${displayName} has attributes with premiums that will automatically affect the ${displayNames.small} price.`
    }

    return (
      <div className="u-padding--left-20">
        <Grid noSpacing className="u-margin--left-8">
          <Cell col={12}>
            <ParentZoneDropdown
              options={zones.medium}
              label={`${displayNames.medium} Name (If Applicable)`}
              selected={selectedZones.medium}
              name={name}
              warningContent={warningContent(displayNames.medium)}
              blankOption={`Select a ${displayNames.medium}`}
              disabled={mediumZoneDisabled}
              onChange={handleMediumChange}
              premiumAttributes={hasPremiumAttributes('Loop')}
            />
          </Cell>
          <Cell col={12}>
            <ParentZoneDropdown
              options={zones.large}
              label={`${displayNames.large} Name (If Applicable)`}
              name={name}
              selected={selectedZones.large}
              warningContent={warningContent(displayNames.large)}
              blankOption={`Select a ${displayNames.large}`}
              disabled={largeZoneDisabled}
              onChange={handleLargeChange}
              isParent={largeZoneDisabled}
              premiumAttributes={hasPremiumAttributes('Section')}
            />
          </Cell>
          <Cell col={2}>
            <label
              className="text--small text--darkblue u-margin--bottom-7"
              htmlFor="camp_small_zone[active]">
              Status
            </label>
            <RadioGroup
              className="d-flex flex__justify--space-between"
              name="camp_small_zone[active]"
              onChange={(e) => setIsActive(e.target.value)}
              value={isActive}>
              <Radio value="false">Inactive</Radio>
              <Radio value="true">Active</Radio>
            </RadioGroup>
          </Cell>
          <Cell col={2}>{/* spacer */}</Cell>
          <Cell col={6} className="u-padding--none">
            {isActive !== 'true' && (
              <Well icon="warning" iconClass="text--darkyellow" content={activeWarningContent} />
            )}
          </Cell>
        </Grid>
      </div>
    )
  }
)

export default SmallZoneParents
