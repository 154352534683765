import { Spinner } from 'react-mdl'
import classNames from 'classnames'
import Table from '@components/shared/Table'
import { onKeyActivate } from '@utils'
const { useState } = React

const compare = (sortBy, isSortAcending) => (a, b) => {
  if (a[sortBy] < b[sortBy]) return isSortAcending ? -1 : 1
  if (a[sortBy] > b[sortBy]) return isSortAcending ? 1 : -1
  return 0
}

const getSortModifier = (isSortAcending) =>
  isSortAcending
    ? 'data-table__table-header--sorted-ascending'
    : 'data-table__table-header--sorted-descending'

const getAriaSort = (isSortAcending) => (isSortAcending ? 'ascending' : 'descending')

const propTypes = {
  // String of topic of search
  topic: PropTypes.string.isRequired,

  // Array of column options for the data table
  columns: PropTypes.array.isRequired,

  // Boolean for has search has been filtered?
  isFiltered: PropTypes.bool,

  // Event handler when row is clicked
  onRowClick: PropTypes.func,

  // String of column name that will be row key
  rowKeyColumn: PropTypes.string,

  // Array of data that will populate data table
  rows: PropTypes.array,

  // String of column name to have rows sorted by initially
  initialSortBy: PropTypes.string,

  // Initial sort direction, defaults to asc
  initialIsSortAcending: PropTypes.bool,

  // Callback for any sorting action
  onSort: PropTypes.func,

  // Add content below main table data, but inside the table element
  tfoot: PropTypes.node,

  // Toggle loading animation inside table
  loading: PropTypes.bool,

  // Text to display when table is empty
  noResultsText: PropTypes.string,
}

const DataTable = ({
  topic,
  columns,
  isFiltered = false,
  onRowClick,
  rowKeyColumn,
  rows,
  initialSortBy,
  initialIsSortAcending = true,
  className,
  onSort,
  tfoot,
  loading = false,
  noResultsText = 'No search results',
  expandTd,
}) => {
  const [sortBy, setSortBy] = useState(initialSortBy)
  const [isSortAcending, setIsSortAscending] = useState(initialIsSortAcending)

  // If onSort is passed, assume the data is pre-sorted
  const sortedRows = onSort ? rows : rows.sort(compare(sortBy, isSortAcending))
  const mappedRows = sortedRows.map(({ className, mdlRowProps, ...row }) => ({
    ...row,
    className: classNames('data-table__row', className),
    mdlRowProps: {
      ...mdlRowProps,
      onClick: (e) => (onRowClick ? onRowClick(e, row) : null),
    },
  }))

  const sortRows = (columnName) => {
    let value

    if (sortBy === columnName) {
      value = !isSortAcending
    } else {
      value = true
    }

    setSortBy(columnName)
    setIsSortAscending(value)

    if (onSort) {
      onSort(columnName, value)
    }
  }

  const handleClick = (columnName) => () => {
    sortRows(columnName)
  }

  return (
    <Table
      aria-busy={loading ? 'true' : 'false'}
      className={classNames(
        'data-table block--full',
        { 'data-table--clickable': onRowClick },
        className
      )}>
      <Table.Caption>{topic}</Table.Caption>
      <Table.Colgroup columns={columns} />

      <Table.Head>
        {columns.map((column) => (
          <Table.Header
            key={column.name}
            column={column}
            className={classNames(
              { 'data-table__table-header--sortable': !column.nosort },
              column.name === sortBy ? getSortModifier(isSortAcending) : '',
              column.className
            )}
            onClick={handleClick(column.name)}
            onKeyDown={onKeyActivate(handleClick(column.name))}
            aria-sort={column.name === sortBy ? getAriaSort(isSortAcending) : undefined}>
            {!column.nosort && <SortIcon />}
          </Table.Header>
        ))}
        {expandTd && (
          <th scope="col">
            <span className="visuallyhidden">Expand Row</span>
          </th>
        )}
      </Table.Head>

      {mappedRows.length === 0 || loading ? (
        <EmptyTableBody loading={loading} noResultsText={noResultsText} />
      ) : (
        <Table.Body
          rows={mappedRows}
          columns={columns}
          rowKeyColumn={rowKeyColumn}
          expandTd={expandTd}
        />
      )}

      {tfoot ? tfoot : undefined}
    </Table>
  )
}

const SortIcon = () => (
  <div className="sort-icon" aria-hidden="true">
    <span className="sort-icon__arrow sort-icon__arrow--up material-icons">arrow_drop_up</span>
    <span className="sort-icon__arrow sort-icon__arrow--down material-icons">arrow_drop_down</span>
  </div>
)

const EmptyTableBody = ({ loading, noResultsText }) => (
  <tbody>
    <tr>
      <td colSpan="42">
        {loading ? (
          <div className="table-loader">
            <Spinner className="table-loader__spinner" singleColor />
          </div>
        ) : (
          <div className="data-table__no-results">{noResultsText}</div>
        )}
      </td>
    </tr>
  </tbody>
)

DataTable.propTypes = propTypes
export default DataTable
