// Hook to wrap `useFetch` and set common headers for easier use with Rails controllers
// Automatically sends headers for content type and the CSRF token so it's ready
// to go for any requests to a Rails controller.
// No need to use the `.json` extension either.
//
// Example:
//   const { getWithParams, loading, data = [] } = useRailsFetch(myUrl)
//   const getActiveData = async () => {
//     await getWithParams('', { filter: { active: true } })
//   }
//
//   <button onClick={getActiveData} disabled={loading}>Get some data!</button>
//   <div>
//     {data.map((item) => <p>{item}</p>)}
//   </div>
//
// Note: use-http's `<Provider>` feature would be preferable over a custom hook to set
// default global options. But unfortunately, since AMS has many "entrypoint"
// components via react_rails, this is not convenient for us.

import useFetch from 'use-http'
import { paramsToString } from '@utils'

export const useRailsFetch = (path, options = { headers: {} }, dependencyArray = null) => {
  // Make caching opt-in by default since it may not be desired in AMS as frequently.
  // Options are 'cache-first' or 'no-cache'
  options = {
    cachePolicy: 'no-cache',
    ...options,
  }

  // Set basic default headers that Rails wants, but allow them to be overridden
  options.headers = {
    'X-CSRF-Token': window.__AMS__.formToken['X-CSRF-Token'],
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  }

  const useFetchHook = useFetch(path, options, dependencyArray)

  const getWithParams = (currentPath = '', params = {}) => {
    return useFetchHook.get(`${currentPath}${paramsToString(params)}`)
  }

  // Return the original `useFetchHook` to use the native hook
  // except for the custom `getWithParams`
  return { getWithParams, ...useFetchHook }
}
