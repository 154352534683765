import { RadioGroup, Radio } from 'react-mdl'
import { useDidUpdate } from '@hooks'

import SegmentedListDetails from '../SegmentedList/SegmentedListDetails'
import CampaignAudienceTable from './CampaignAudienceTable'

const CampaignAudienceForm = ({
  emailCampaign,
  errors,
  conditionAttributes,
  segmentedLists,
  segmentedListUrl,
  segmentedPolicy,
  uploadedLists,
  uploadedListUrl,
  uploadedListPolicy,
}) => {
  const AUDIENCE_TYPES = {
    'Outreach::SegmentedList': 'Segmented List',
    'Outreach::DistributionList': 'Uploaded List',
  }

  const errorMsg = (attr) => (errors[attr] || []).filter((m) => m !== 'is invalid').join(', ')

  const prefill =
    emailCampaign.audience_type === 'Outreach::SegmentedList'
      ? segmentedLists.find((l) => l.id === emailCampaign.audience_id)
      : null

  const [audienceType, setAudienceType] = React.useState(
    emailCampaign.audience_type || 'Outreach::SegmentedList'
  )
  const [audienceID, setAudienceID] = React.useState(emailCampaign.audience_id)
  const [segmentedList, setSegmentedList] = React.useState(prefill)

  const fieldName = (attr) => `outreach_email_campaign[${attr}]`

  useDidUpdate(() => {
    setAudienceID(null)
    setSegmentedList(null)
  }, [audienceType])

  useDidUpdate(() => {
    if (audienceType === 'Outreach::SegmentedList') {
      setSegmentedList(audienceID)
    }
  }, [audienceID])

  return (
    <section>
      <div className="mdl-card block--full px-20">
        <h2 className="text--med-lg m-0">Audience</h2>

        <p className="text--lightgray">
          Choose the list of contacts you would like to send this email campaign to.
        </p>

        <fieldset>
          <RadioGroup
            name={fieldName('audience_type')}
            className="mdl-radio-collection d-inline-block"
            onChange={(e) => setAudienceType(e.target.value)}
            value={audienceType}>
            {Object.keys(AUDIENCE_TYPES).map((key, idx) => (
              <Radio key={`mdl-radio-${idx}`} value={key}>
                {AUDIENCE_TYPES[key]}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>

        {audienceType === 'Outreach::SegmentedList' && (
          <>
            <div className="d-flex flex__justify--space-between flex__items--baseline">
              <MdlSelect
                label=""
                options={segmentedLists}
                className="always-floating-label"
                name="outreach_email_campaign[audience_id]"
                includeBlankOption="Select Segmented List"
                onOptionSelected={(l) => setAudienceID(l)}
                selected={audienceID}
                error={errorMsg('audience')}
              />
              {segmentedPolicy.create && (
                <a
                  href={`${segmentedListUrl}/new`}
                  className="mdl-button mdl-button--accent mdl-button--wide m-0 mdl-js-button mdl-js-ripple-effect">
                  New Segmented List
                </a>
              )}
            </div>

            {errors['audience'] && <div className="py-10" />}

            <CampaignAudienceTable
              segmentedListUrl={segmentedListUrl}
              segmentedList={segmentedList}
            />

            {!!segmentedList && (
              <div id="segmented-list-details">
                <SegmentedListDetails
                  segmentedList={segmentedList}
                  conditionAttributes={conditionAttributes}
                />
              </div>
            )}
          </>
        )}

        {audienceType === 'Outreach::DistributionList' && (
          <div className="d-flex flex__justify--space-between flex__items--baseline">
            <MdlSelect
              label=""
              options={uploadedLists}
              className="always-floating-label"
              name="outreach_email_campaign[audience_id]"
              includeBlankOption="Select Uploaded List"
              onOptionSelected={(l) => setAudienceID(l)}
              selected={audienceID}
              error={errorMsg('audience')}
            />
            {uploadedListPolicy.create && (
              <a
                href={`${uploadedListUrl}/new`}
                className="mdl-button mdl-button--accent mdl-button--wide m-0 mdl-js-button mdl-js-ripple-effect">
                New Uploaded List
              </a>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default CampaignAudienceForm
