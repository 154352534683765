import SupplyKitOrdersTable from './SupplyKitOrdersTable'
import SuppliersTable from './SuppliersTable'

export default class SupplyKitOrdersIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suppliers: props.suppliers,
      okToExport: props.okToExport,
      supplier: null,
      title: 'Suppliers',
      orders: [],
      showExport: true,
      showEmail: false,
      filterStatus: { id: 'pending', name: 'Pending Orders' },
      locationData: [{}],
      showUploadModal: false,
      placeholderFileName: '',
      page: 1,
      pages: 1,
      totalRecords: 0,
      csv: '',
      csvUploadSupplierId: '',
      csvUploadError: '',
    }

    this.chooseSupplier = this.chooseSupplier.bind(this)
    this.fetchOrders = this.fetchOrders.bind(this)
    this.filterOrders = this.filterOrders.bind(this)
    this.handleTrackingNumberChange = this.handleTrackingNumberChange.bind(this)
    this.shipAll = this.shipAll.bind(this)
    this.emailApprovedOrders = this.emailApprovedOrders.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  chooseSupplier(supplier) {
    this.setState({
      supplier: supplier,
      title: `${supplier.name} Ship Supplies`,
    })
    this.fetchOrders(supplier, 'pending', 1)
  }

  fetchOrders(supplier, status, page) {
    let data = {}
    if (status) {
      data.status = status
    }
    if (page) {
      this.setState({ page: page })
      data.page = page
    }
    $.getJSON(`/suppliers/${supplier.id}/supply_kit_orders`, data, (response) => {
      this.setState({
        locationData: response.locationInfo,
        orders: response.orders,
        pages: response.pages,
        totalRecords: response.totalRecords,
      })
    })
  }

  componentDidMount() {
    componentHandler.upgradeDom()
  }

  componentDidUpdate(prevProps, prevState) {
    componentHandler.upgradeDom()
    prevState.csvUploadError !== '' && this.setState({ csvUploadError: '' })
  }

  get filters() {
    let options = [
      { id: '', name: 'All Orders' },
      { id: 'approved', name: 'Approved Orders' },
      { id: 'pending', name: 'Pending Orders' },
      { id: 'rejected', name: 'Rejected Orders' },
      { id: 'shipped', name: 'Shipped Orders' },
    ]

    return (
      <div className="supply-kit-orders__filters">
        <MdlSelect
          options={options}
          selected={this.state.filterStatus}
          onOptionSelected={this.filterOrders}
        />
      </div>
    )
  }

  get actions() {
    // do not create the export button if the user does not have the new 'Export Supply Orders' permission
    // hide the export button if the Orders filter is not set to 'Pending Orders', or if there are no pending orders
    const okToExport = this.state.okToExport
    const showExport = this.state.orders.length > 0 ? this.state.showExport : false
    const showEmail = this.state.orders.length > 0 ? this.state.showEmail : false
    const exportLink =
      '/suppliers/' + this.state.supplier.id.toString() + '/supply_kit_orders/export.csv'
    return (
      <div className="daily-sales-report__downloads">
        {okToExport && showExport ? (
          <a
            href={exportLink}
            style={{ height: '40px', cursor: 'pointer' }}
            className="mdlext-js-collapsible mdl-button mdl-button--colored mdl-button--raised u-pull--right text--small is-upgraded"
            data-upgraded=",MaterialExtCollapsible"
            aria-expanded="false"
            role="button">
            <i className="material-icons">file_download</i> EXPORT
          </a>
        ) : (
          <span></span>
        )}

        {showEmail ? (
          <button
            onClick={this.emailApprovedOrders}
            style={{ height: '40px', cursor: 'pointer' }}
            className="mdlext-js-collapsible mdl-button mdl-button--colored mdl-button--raised u-pull--right text--small is-upgraded"
            data-upgraded=",MaterialExtCollapsible"
            aria-expanded="false"
            role="button">
            <i className="material-icons">send</i> SEND FILE
          </button>
        ) : (
          <span></span>
        )}

        <button
          className="mdlext-js-collapsible mdl-button mdl-button--colored mdl-button--raised u-pull--right"
          style={{ fontSize: '12px', height: '40px' }}
          onClick={this.shipAll}>
          MARK ALL AS SHIPPED
        </button>
      </div>
    )
  }

  handleTrackingNumberChange(order, trackingNumber) {
    let orders = this.state.orders.slice()
    let existingOrder = orders.find((_o) => order.id == _o.id)
    existingOrder.tracking_number = trackingNumber

    this.setState({
      orders: orders,
    })
  }

  handleUploadChange = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onload = (e) => {
      const text = e.target.result
      const strippedStr = text.replace(/[^0-9a-zA-Z]/g, '')
      const getId = strippedStr.substr(-1)
      this.setState({ csvUploadSupplierId: parseInt(getId) })
    }
    reader.readAsText(file)
    this.setState({
      placeholderFileName: e.target.files[0].name,
      csv: e.target.files[0],
    })
  }

  handleUploadSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    const csv = this.state.csv
    data.append('file', csv)
    Fetchable.sendForm(
      `/suppliers/${this.state.csvUploadSupplierId}/supply_kit_orders/csv_batch_update`,
      'POST',
      data
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({ csvUploadError: 'File is invalid or has been altered.' })
      })
    this.setState({ showUploadModal: false })
  }

  filterOrders(option) {
    this.state.showExport = option.id == 'pending'
    this.state.showEmail = option.id == 'approved'
    this.setState({ filterStatus: option })
    this.fetchOrders(this.state.supplier, option.id, 1)
  }

  shipAll() {
    let _orders = this.state.orders
      .slice()
      .filter((order) => order.status == 'pending' && order.tracking_number)
    if (_orders.length === 0) {
      return
    }
    let data = {
      orders: _orders.map((o) => {
        return {
          status: 'shipped',
          tracking_number: o.tracking_number,
          id: o.id,
        }
      }),
    }
    $.post(
      `/suppliers/${this.state.supplier.id}/supply_kit_orders/batch_update`,
      data,
      (response) => {
        let orders = this.state.orders.slice()
        response.forEach((order) => {
          let existingOrder = orders.find((o) => o.id === order.id)
          Object.assign(existingOrder, order)
        })
        this.setState({
          orders: orders,
        })
      }
    )
  }

  emailApprovedOrders() {
    $.post(`/suppliers/${this.state.supplier.id}/supply_kit_orders/email`, {}, (response) => {})
  }

  goBack() {
    this.setState({
      supplier: null,
      title: 'Suppliers',
      orders: [],
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="supply-kit-orders">
          <div className="supply-kit-orders__header">
            {this.state.supplier && (
              <button className="mdl-button mdl-button--transparent" onClick={this.goBack}>
                <span className="material-icons js-toggle-order-icon" aria-hidden="true">
                  keyboard_backspace
                </span>
                Back
              </button>
            )}

            <div className="mdl-card__title">
              <h2 className="mdl-card__title-text">{this.state.title}</h2>
            </div>

            {this.state.title === 'Suppliers' && (
              <div
                onClick={() => this.setState({ showUploadModal: true })}
                className="mdl-button mdl-button--gray mdl-js-button mdl-js-ripple-effect mdl-cell mdl-cell--2-col">
                Upload Orders
              </div>
            )}
            {this.state.supplier && this.filters}
            {this.state.supplier && this.actions}
          </div>

          {this.state.csvUploadError && this.state.title === 'Suppliers' && (
            <section className="mdl-grid block--full u-margin--none u-padding--none">
              <div className="mdl-cell mdl-cell--12-col block--full u-margin--top-none u-padding--right-5">
                <span style={{ float: 'right', color: 'red' }}>
                  File is invalid or has been altered.
                </span>
              </div>
            </section>
          )}

          <section className="mdl-grid block--full u-padding--none">
            <div className="mdl-cell mdl-cell--12-col block--full u-margin--none">
              {this.state.supplier == null && (
                <SuppliersTable
                  suppliers={this.props.suppliers}
                  chooseSupplier={this.chooseSupplier}
                />
              )}
              {this.state.supplier && (
                <SupplyKitOrdersTable
                  orders={this.state.orders}
                  locationData={this.state.locationData}
                  supplier={this.state.supplier}
                  onTrackingNumberChange={this.handleTrackingNumberChange}
                  page={this.state.page}
                  pages={this.state.pages}
                  totalRecords={this.state.totalRecords}
                  fetchOrders={this.fetchOrders}
                  filterStatus={this.state.filterStatus}
                />
              )}
            </div>
          </section>
        </div>
        {/* Upload csv modal */}
        {this.state.showUploadModal && (
          <dialog
            open
            className="mdl-dialog mdl-dialog--500"
            style={{ zIndex: 99 }}
            role="dialog"
            aria-modal="true"
            aria-labelledby="dialog_label"
            aria-describedby="dialog_desc">
            <form onSubmit={(e) => this.handleUploadSubmit(e)}>
              <div className="mdl-dialog__title mdl-grid block--full">
                <div className="mdl-cell mdl-cell--12-col text--med-lg" id="dialog_label">
                  Upload Orders File
                </div>
              </div>
              <div className="mdl-dialog__content mdl-grid block--full">
                <div className="mdl-cell mdl-cell--12-col" id="dialog_desc">
                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--file u-padding-top--none">
                    <input
                      className="mdl-textfield__input"
                      placeholder={this.state.placeholderFileName || 'No file(s) selected'}
                      type="text"
                      id="uploadCsvFile"
                      readOnly
                    />
                    <div className="mdl-button mdl-button--primary mdl-button--icon mdl-button--file">
                      <i className="material-icons">attach_file</i>
                      <input
                        type="file"
                        name="csvFile"
                        id="ordersCsvFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => this.handleUploadChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mdl-dialog__actions">
                <button
                  type="submit"
                  className="mdl-button mdl-button--gray"
                  disabled={!this.state.csvUploadSupplierId}>
                  Upload
                </button>
                <button
                  type="button"
                  className="mdl-button mdl-button--light close"
                  onClick={() => this.setState({ showUploadModal: false })}>
                  Cancel
                </button>
              </div>
            </form>
          </dialog>
        )}
      </React.Fragment>
    )
  }
}
