import { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'
import { Textfield } from 'react-mdl'

const WaiverQuestionFields = ({ question, removeQuestion, checkboxQuestionLength }) => {
  const WaiverQuestionInputName = (name) =>
    `program[waivers_attributes][0][waiver_questions_attributes][${question.question_index}][${name}]`
  const WaiverDestroy = (destroy) =>
    `program[waivers_attributes][0][waiver_questions_attributes][${question.question_index}][${destroy}]`
  const label =
    question.input_type === 'checkbox'
      ? I18n.t('events.views.program_waiver_settings.checkbox_text')
      : I18n.t('events.views.program_waiver_settings.text_field_title')
  const inputType = question.input_type === 'checkbox' ? 'terms-checkbox-' : 'terms-textfield-'
  const [inputId] = useState(_uniqueId(inputType))
  const canDelete = question.input_type !== 'checkbox' || checkboxQuestionLength > 1
  const deleteWaiverQuestion = () => {
    removeQuestion(question.question_index)
  }

  return (
    <>
      {!question._destroy ? (
        <>
          <Textfield
            floatingLabel
            id={inputId}
            label={label}
            name={WaiverQuestionInputName('question')}
            defaultValue={question.question}
            className="textfield__input--full"
            error={question.errors['question']}
            style={{ textAlign: 'right' }}>
            {canDelete && (
              <button
                className="mdl-button mdl-js-button mdl-button--icon mdl-button--transparent"
                onClick={deleteWaiverQuestion}
                type="button"
                aria-label="delete this checkbox">
                <span className="material-icons" aria-hidden="true">
                  delete_forever
                </span>
              </button>
            )}
          </Textfield>
          <input type="hidden" name={WaiverQuestionInputName('id')} value={question.id || ''} />
          <input
            type="hidden"
            name={WaiverQuestionInputName('input_type')}
            value={question.input_type}
          />
        </>
      ) : (
        <>
          <input type="hidden" name={WaiverQuestionInputName('id')} value={question.id || ''} />
          <input type="hidden" name={WaiverDestroy('_destroy')} value={true} />
        </>
      )}
    </>
  )
}

export default WaiverQuestionFields
