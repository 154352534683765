import { Textfield } from 'react-mdl'
import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { formatDateFromDb, formatDateToDb } from '@utils'
const { Fragment, useCallback } = React
const { FlatDatepicker, _ } = window

// Main form with provider
const Form = ({ fields, form, children, method, ...props }) => {
  const csrfToken = window.__AMS__.formToken['X-CSRF-Token']
  const isPut = method === 'put'

  return (
    <FormProvider fields={fields} {...form}>
      <form acceptCharset="UTF-8" method={isPut ? 'post' : method} {...props}>
        {isPut ? <input type="hidden" name="_method" value="put" /> : undefined}
        <input type="hidden" name="authenticity_token" value={csrfToken} />

        {children}
      </form>
    </FormProvider>
  )
}

const Input = ({ field, ...props }) => {
  const { control, errors } = useFormContext()
  const fieldErrors = _.get(errors, field.input_name)

  return (
    <Controller
      as={Textfield}
      floatingLabel
      name={field.input_name}
      label={field.label}
      id={field.input_name}
      control={control}
      defaultValue={field.value || ''}
      error={fieldErrors?.message}
      {...props}
    />
  )
}

// `FC` Form controls
const Hidden = ({ field, ...props }) => {
  const { control } = useFormContext()

  return (
    <Controller
      as={<input />}
      control={control}
      type="hidden"
      defaultValue={field.value || ''}
      name={field.input_name}
      id={field.input_name}
      {...props}
    />
  )
}

const Datepicker = ({ field, dateFormat, disabled, options = {}, ...props }) => {
  const { errors, setValue } = useFormContext()
  const fieldErrors = _.get(errors, field.input_name)

  const onChange = useCallback(
    (_, value) => {
      setValue(field.input_name, formatDateToDb(value) || '')
    },
    [field, setValue]
  )

  return (
    <Fragment>
      <Hidden field={field} disabled={disabled} />
      <FlatDatepicker
        placeholder={dateFormat}
        value={formatDateFromDb(field.value)}
        aria-label={field.label}
        error={fieldErrors?.message}
        dateFormat={dateFormat}
        onChange={onChange}
        options={{ allowInput: true, ...options }}
        disabled={disabled}
        {...props}
      />
    </Fragment>
  )
}

const TimePicker = ({ field, disabled, options = {}, ...props }) => {
  const { errors, setValue } = useFormContext()
  const fieldErrors = _.get(errors, field.input_name)

  const onChange = useCallback(
    (_, value) => {
      setValue(field.input_name, value || '')
    },
    [field, setValue]
  )

  return (
    <Fragment>
      <Hidden field={field} disabled={disabled} />
      <FlatDatepicker
        placeholder="0:00"
        value={field.value}
        label={field.label}
        error={fieldErrors?.message}
        dateFormat="LT"
        onChange={onChange}
        options={{ allowInput: true, noCalendar: true, enableTime: true, ...options }}
        disabled={disabled}
        {...props}
      />
    </Fragment>
  )
}

export const FC = {
  Form,
  Input,
  Hidden,
  TimePicker,
  Datepicker,
}
