const propTypes = {
  // Name of the icon from the NPMap SVG sprite
  // https://github.com/kalkomey/symbol-library
  name: PropTypes.string,
  className: PropTypes.string,
}

const NpmapIcon = ({ name, className = '', ...otherProps }) => {
  return name ? (
    <svg
      viewBox="0 0 30 30"
      className={`npmap-icon ${className}`}
      role="img"
      focusable="false"
      {...otherProps}>
      <title>{name}</title>
      <use href={`${window.__AMS__.npmapSvgPath}#icon-${name}`} aria-hidden="true"></use>
    </svg>
  ) : null
}

NpmapIcon.propTypes = propTypes
export default NpmapIcon
