import { Cell, Grid } from 'react-mdl'

import Well from '@components/shared/Well'

const { observer } = mobxReactLite

const ParentZoneDropdown = observer((props) => {
  const disabled = props.disabled || false

  const handleChange = (zone) => {
    if (props.onChange) {
      props.onChange(zone)
    }
  }
  return (
    <Grid noSpacing className="u-padding--none">
      <Cell col={4}>
        <MdlSelect
          options={props.options}
          label={props.label}
          selected={props.selected}
          onOptionSelected={handleChange}
          includeBlankOption={props.blankOption}
          className="always-floating-label"
          name={props.name}
          disabled={disabled}
          isParent={props.isParent}
        />
      </Cell>
      <Cell col={6}>
        {props.premiumAttributes && (
          <Well icon="warning" iconClass="text--darkyellow" content={props.warningContent} />
        )}
      </Cell>
    </Grid>
  )
})

export default ParentZoneDropdown
