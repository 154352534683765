import { Card, Cell, Grid } from 'react-mdl'
import { onClickLink } from '@utils'
import { DataTable, Paginate, SearchFormDropdown } from '@components/shared'
import { formatDateFromDb } from '@utils'
import { useSearchQuery } from '@hooks'
import queryString from 'query-string'
import useFetch from 'use-http'

const { useState, useEffect } = React
const initialSortBy = 'created_at'
const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

const columns = [
  {
    name: 'name',
    display: 'Name',
    cellFormatter: (name, row) => <a href={row.url}>{name}</a>,
  },
  {
    name: 'created_at',
    display: 'Created',
    cellFormatter: (value) => formatDateFromDb(value),
  },
  {
    name: 'sent',
    display: 'Sent',
    cellFormatter: (sent, row) => row.sent,
  },
  {
    name: 'delivered',
    display: 'Delivered',
    cellFormatter: (stat, row) => row.delivered,
  },
  {
    name: 'opened',
    display: 'Opened',
    cellFormatter: (stat, row) => row.opened,
  },
  {
    name: 'clicked',
    display: 'Clicked',
    cellFormatter: (stat, row) => row.clicked,
  },
  {
    name: 'unsubscribed',
    display: 'Unsubscribed',
    cellFormatter: (stat, row) => row.unsubscribed,
  },
]

const propTypes = {
  endpoint: PropTypes.string.isRequired,
}

const CampaignList = ({ endpoint, users }) => {
  // Using search query params
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 0,
    page: params.page ? parseInt(params.page) : 1,
  })

  // Fetches the data
  const { get } = useFetch(searchUrl)
  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    get().then((res) => setData(res))
  }, [searchUrl, get])

  return (
    <Card shadow={0} style={{ width: '100%' }}>
      <Grid noSpacing style={{ width: '100%' }}>
        <Cell col={12}>
          <h2 className="text--med-lg m-0 pb-0 pl-20 pt-10">Search Campaigns</h2>
        </Cell>
      </Grid>

      <SearchFormDropdown
        searchFilters={searchState.query}
        onSearch={(filters, user_id) => {
          searchDispatch({ type: 'map', value: { query: filters, user_id: user_id } })
        }}
        placeholder="Search Campaign Name"
        helperText="Campaign Name"
        users={users}
      />
      <DataTable
        className="data-table--chevron"
        rows={data.results}
        columns={columns}
        onRowClick={(e, row) => onClickLink(e, row.url)}
        rowKeyColumn="url"
        topic={'Search Campaigns'}
        onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
        initialSortBy={searchState.order}
        initialIsSortAcending={searchState.asc === 1}
      />

      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}
    </Card>
  )
}

CampaignList.propTypes = propTypes

export default CampaignList
