import AddQuestionModal from './AddQuestionModal'
import AssignedQuestionsTable from './AssignedQuestionsTable'
const { ArrayUtils } = window

const { useState } = React

const RegistrationDetailQuestion = ({ event, surveyQuestions, details }) => {
  const detailType = 'registration'

  const [assignedQuestions, setAssignedQuestions] = useState(
    event[`program_event_${detailType}_detail_questions`]
  )

  const onDelete = (currentQuestion) => (e) => {
    e.preventDefault()
    setAssignedQuestions(
      updateAssignedQuestions(
        assignedQuestions,
        currentQuestion['registration_detail_question_id'],
        'registration_detail_question_id'
      )
    )
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAssignedQuestions(ArrayUtils.arrayReorder(assignedQuestions, oldIndex, newIndex))
  }

  const eventHasRegistrants = event.event_registrations_count > 0

  return (
    <React.Fragment>
      <AddQuestionModal
        details={details}
        event={event}
        detailType={detailType}
        assignedQuestions={assignedQuestions}
        setAssignedQuestions={setAssignedQuestions}
        disableButton={eventHasRegistrants}
      />
      <AssignedQuestionsTable
        surveyQuestions={surveyQuestions}
        assignedQuestions={assignedQuestions}
        disableDelete={eventHasRegistrants}
        onDelete={onDelete}
        onSortEnd={onSortEnd}
      />
      {assignedQuestions.flatMap((q, i) =>
        [`${detailType}_detail_question_id`, 'id', 'priority', '_destroy'].map((attr) => (
          <input
            key={`${i}-${attr}`}
            type="hidden"
            name={`program_event[program_event_${detailType}_detail_questions_attributes][][${attr}]`}
            value={q[attr] || ''}
          />
        ))
      )}
    </React.Fragment>
  )
}

// Remove the item if it hasn't been saved to the dB (has no id) or mark as
// _destroy if it has been saved
function updateAssignedQuestions(questions, id, key) {
  return questions
    .filter((q) => q[key] !== id || q.id)
    .map((q) => (q[key] === id ? { ...q, _destroy: true } : q))
}

export default RegistrationDetailQuestion
