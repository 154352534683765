import { Grid, Cell } from 'react-mdl'
import { NumericTextfield, Typeahead, Btn } from '@components/shared'
import { onKeyActivate } from '@utils'

const { useState } = React

const ChargeSplitField = ({
  name,
  parentInputName = '',
  split,
  accountFunds,
  chargeAmount,
  onAccountChange,
  onSplitFormat,
  onRemove,
  showRemove,
  chargeIsPercent = false,
  splitByPercent,
  errors = {},
  inputRef,
}) => {
  const initialAccountFund = accountFunds.find((option) => option.id === split.accountFundId)
  const [accountInput, setAccountInput] = useState(
    initialAccountFund ? initialAccountFund.name : ''
  )
  const [error, setError] = useState(errors.account_fund || '')

  const handleAccountInputChange = (event, value) => {
    setAccountInput(value)
  }

  const handleAccountChange = (suggestion) => {
    setError('')
    onAccountChange(suggestion)
  }

  const handleSplitFormat = (value) => {
    onSplitFormat(value)
  }

  const handleClick = () => {
    onRemove()
  }

  const amountInputName = `${name}[${splitByPercent ? 'percent' : 'amount'}]`

  return (
    <Grid className="u-padding--none">
      <input type="hidden" name={`${name}[_destroy]`} value={false} />
      <input type="hidden" readOnly name={`${name}[id]`} value={split.id || ''} />

      <Cell col={5}>
        <Typeahead
          inputRef={inputRef}
          label="Account"
          name={`${name}[account_fund_id]`}
          id={`${name}[account_fund_id]`}
          placeholder="Select Account"
          fullWidth
          value={accountInput}
          options={accountFunds}
          getOptionValue={(option) => (option ? option.name : '')}
          getOptionId={(option) => (option ? option.id : '')}
          onChange={handleAccountInputChange}
          onSelect={handleAccountChange}
          error={error}
        />
      </Cell>

      <Cell col={2} offset={1} className="u-padding--right-50">
        <NumericTextfield
          label={`Split ${splitByPercent ? 'Percent' : 'Amount'}`}
          id={amountInputName}
          name={amountInputName}
          floatingLabel
          value={splitByPercent ? `${split.percent}` : split.amount}
          maxValue={splitByPercent ? 100 : chargeAmount}
          onFormat={handleSplitFormat}
          format={splitByPercent ? 'percent' : 'currency'}
        />
      </Cell>

      {/* Clear the opposing field value */}
      <input type="hidden" name={`${name}[${splitByPercent ? 'amount' : 'percent'}]`} value="" />

      <Cell col={2} offset={1}>
        {chargeIsPercent ? (
          <output
            className="charge-split-fieldset__calculated-split"
            name="ResultSplitAmount"
            htmlFor={`${parentInputName} ${amountInputName}`}>
            <span className="u-pull--left">%</span>
            {split.percent}
          </output>
        ) : (
          <output
            className="charge-split-fieldset__calculated-split"
            name="ResultSplitAmount"
            htmlFor={`${parentInputName} ${amountInputName}`}>
            <span className="u-pull--left">$</span>
            {split.amount}
          </output>
        )}
      </Cell>

      <Cell
        col={1}
        className="d-flex flex__items--center flex__justify--center u-padding--bottom-10">
        {showRemove && (
          <Btn.Icon
            light
            name="delete"
            label="Remove Account"
            onClick={handleClick}
            onKeyDown={onKeyActivate(handleClick)}
          />
        )}
      </Cell>
    </Grid>
  )
}

export default ChargeSplitField
