// TODO: see if we can use shared `Table` component instead

import { Cell, Grid } from 'react-mdl'

const CategorizedTable = (props) => {
  const isEmpty = () => props.data.every((d) => d.rows.length === 0)
  return (
    <div className="table__container--responsive">
      <table className="mdl-data-table block--full">
        <thead>
          <tr>
            {props.header.map((h, i) => (
              <th
                key={`header-${i}`}
                className="mdl-data-table__cell--non-numeric"
                scope="col"
                style={{ width: h.width }}>
                {h.displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((d, dataIdx) => (
            <React.Fragment key={`fragment-${dataIdx}`}>
              {d.categoryName && (
                <tr key={`category-${dataIdx}`} className="categorized-table-row">
                  {props.header.map((_, i) => (
                    <td key={`category-filler-${i}`} className="categorized-table-cell">
                      {i === 0 ? d.categoryName : ''}
                    </td>
                  ))}
                </tr>
              )}
              {d.rows.map((r, rowIdx) => (
                <tr key={`datarow-${dataIdx}-${rowIdx}`}>
                  {props.header.map((h, cellIdx) => (
                    <td
                      key={`datacell-${dataIdx}-${rowIdx}-${cellIdx}`}
                      className="mdl-data-table__cell--non-numeric">
                      {r[h.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isEmpty() && props.emptyText ? (
        <Grid>
          <Cell col={12} className="text--center text--lightgray">
            {props.emptyText}
          </Cell>
        </Grid>
      ) : (
        ''
      )}
    </div>
  )
}

CategorizedTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
      rows: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  header: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      width: PropTypes.string,
    })
  ),
}

export default CategorizedTable
