import { Card, Tab, Tabs } from 'react-mdl'
import Bookings from './Bookings'
import Consumables from './Consumables'
const { useState } = React

const tabs = [
  { name: 'Bookings', component: Bookings },
  { name: 'Consumables', component: Consumables },
]

const ReservationDetail = ({ reservation, bookingHistory, reservationPath }) => {
  const [activeTab, setActiveTab] = useState(0)

  const TabComponent = tabs[activeTab].component

  return (
    <Card shadow={0} className="block--full">
      <Tabs
        className="mdl-layout__tab-bar mdl-layout__tab-bar--accent"
        activeTab={activeTab}
        href={'#'}
        ripple>
        {tabs.map(({ name }, index) => {
          return (
            <Tab
              key={index}
              className="mdl-layout__tab"
              onClick={() => {
                setActiveTab(index)
              }}>
              {name}
            </Tab>
          )
        })}
      </Tabs>
      <TabComponent
        reservation={reservation}
        bookingHistory={bookingHistory}
        reservationPath={reservationPath}
      />
    </Card>
  )
}

export default ReservationDetail
