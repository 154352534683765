/**
 * Attributes table for campgrounds
 *
 * TODO: when the user selects a parent zone, we need to change the values in the table (specifically the inherited attributes)
 * TODO: related to selecting a parent zone, we need to not show those attributes in the dropdown
 * TODO: default attributes from site types
 */

import { Button, Cell, Grid } from 'react-mdl'
import CategorizedTable from '@components/shared/CategorizedTable'
import { AttributeChip, NpmapIcon, Btn, Modal } from '@components/shared'
import Typeahead from '@components/shared/Typeahead/Typeahead'
import { useDidMount } from '@hooks'
const { useReducer, useCallback, useState, Fragment } = React

const propTypes = {
  editable: PropTypes.bool,
  data: PropTypes.array.isRequired,
  header: PropTypes.array,
  attributes: PropTypes.array,
  name: PropTypes.string,
  emptyText: PropTypes.string,
  customAttributesPath: PropTypes.string,
}

const ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  SUBTRACT: 'SUBTRACT',
  UPDATE_DEFAULT: 'UPDATE_DEFAULT',
  UPDATE_INHERITED: 'UPDATE_INHERITED',
}

const TRAIT_TYPES = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
}

const AttributesTable = (props) => {
  const defaultHeader = [
    { name: 'icon', displayName: 'Icon', width: '10%' },
    { name: 'name', displayName: 'Name' },
    { name: 'category', displayName: 'Category' },
    { name: 'premium_formatted', displayName: 'Premium' },
    { name: 'type', displayName: 'Type' },
    { name: 'parent', displayName: 'Parent' },
  ]

  const editableHeader = [
    { name: 'action', width: '5%' },
    { name: 'input', width: '0' },
  ]

  const initialHeader = () => {
    const result = props.header || defaultHeader
    if (props.editable) {
      return [...result, ...editableHeader]
    }

    return result
  }

  const [inputValue, setInputValue] = useState('')
  const [selectedAttribute, setSelectedAttribute] = useState()
  const [attributes, setAttributes] = useState(props.attributes)
  const [header, setHeader] = useState(initialHeader())
  const [modalOpen, setModalOpen] = useState(false)
  const [defaultCounter, setDefaultCounter] = useState(props.defaultUpdateCounter)
  const [inheritedCounter, setInheritedCounter] = useState(props.inheritedUpdateCounter)

  const getAction = (rowType, categoryType, parentType) => {
    if (rowType === TRAIT_TYPES.CUSTOM && parentType !== 'Site Type') return ACTIONS.REMOVE
    if (rowType === TRAIT_TYPES.CUSTOM && parentType === 'Site Type') return ACTIONS.SUBTRACT
    if (rowType === TRAIT_TYPES.DEFAULT && categoryType === TRAIT_TYPES.DEFAULT) return ACTIONS.ADD
  }

  const formatData = (_data) => {
    if (props.editable) {
      const newData = _data.map((d) => ({
        ...d,
        rows: d.rows.map((r) => {
          const c = r.columns
          return {
            ...r,
            columns: {
              ...c,
              action: getAction(r.type, d.type, r.columns.parent),
              input: r.type === TRAIT_TYPES.CUSTOM,
            },
          }
        }),
      }))
      if (props.onChange) props.onChange(newData)
      return newData
    }

    return _data
  }
  const dataReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.ADD: {
        if (action.dataIndex === 0) {
          return formatData([
            {
              ...state[0],
              rows: [...state[0].rows, action.data],
            },
            ...state.filter((s) => s.type !== action.data.type),
          ])
        } else {
          state[action.dataIndex].rows.push(action.data)
          return formatData(state)
        }
      }
      case ACTIONS.REMOVE: {
        return formatData(
          state.map((s, idx) => ({
            ...s,
            rows: s.rows.filter((r, rix) => {
              return action.attrId
                ? !(idx === action.dataIdx && r.id === action.attrId)
                : !(idx === action.dataIdx && rix === action.rowIdx)
            }),
          }))
        )
      }
      case ACTIONS.UPDATE_DEFAULT: {
        return formatData([
          {
            ...state[0],
            rows: [
              ...state[0].rows.filter((r) => {
                return r.columns.action === ACTIONS.REMOVE
              }),
            ],
          },
          { ...state[1], rows: action.data },
          { ...state[2] },
        ])
      }
      case ACTIONS.UPDATE_INHERITED: {
        return formatData([{ ...state[0] }, { ...state[1] }, { ...state[2], rows: action.data }])
      }
      default: {
        return []
      }
    }
  }
  /*
   * data format is
   * [
   *  {
   *    categoryName: string,
   *    type: 'custom' | 'inherited' | 'default', // determines remove button and form behavior,
   *    rows: [
   *      {
   *        id: string,
   *        columns: [...],
   *        type: 'custom' | 'inherited' | 'default', // automatically populated by category type initially, but then can be controlled later
   *      }
   *    ]
   *  }
   * ]
   */

  const [data, dispatch] = useReducer(dataReducer, props.data, formatData)

  const getTraitFromClick = useCallback(
    (dataIndex, rowIndex) => {
      return data.reduce((result, d, dix) => {
        if (dix === dataIndex) {
          return d.rows.find((_, rix) => rix === rowIndex)
        }
        return result
      }, null)
    },
    [data]
  )

  const getCategoryIndex = useCallback(
    (categoryType) => {
      return data.findIndex((category) => category.type === categoryType)
    },
    [data]
  )

  const removeFromTable = useCallback(
    (dataIdx, rowIdx) => {
      let trait = getTraitFromClick(dataIdx, rowIdx)

      setAttributes([
        ...attributes,
        {
          id: trait.id,
          ...trait.columns,
        },
      ])
      dispatch({
        type: ACTIONS.REMOVE,
        dataIdx,
        rowIdx,
      })
    },
    [attributes, getTraitFromClick]
  )

  const addToTable = useCallback(() => {
    dispatch({
      type: ACTIONS.ADD,
      data: {
        id: selectedAttribute.id,
        type: TRAIT_TYPES.CUSTOM,
        columns: selectedAttribute,
      },
      dataIndex: getCategoryIndex(TRAIT_TYPES.CUSTOM),
    })

    setAttributes(attributes.filter((a) => a.id !== selectedAttribute.id))
    setSelectedAttribute(undefined)
    setInputValue('')
  }, [selectedAttribute, attributes, getCategoryIndex])

  const moveAttributeToDefault = useCallback(
    (dataIdx, rowIdx) => {
      const trait = getTraitFromClick(dataIdx, rowIdx)

      dispatch({
        type: ACTIONS.REMOVE,
        dataIdx,
        rowIdx,
      })
      dispatch({
        type: ACTIONS.ADD,
        data: {
          id: trait.id,
          type: TRAIT_TYPES.DEFAULT,
          columns: trait.columns,
        },
        dataIndex: getCategoryIndex(TRAIT_TYPES.DEFAULT),
      })
    },
    [getTraitFromClick, getCategoryIndex]
  )

  const moveAttributeToCustom = useCallback(
    (dataIdx, rowIdx) => {
      const trait = getTraitFromClick(dataIdx, rowIdx)
      dispatch({
        type: ACTIONS.REMOVE,
        dataIdx,
        rowIdx,
      })
      dispatch({
        type: ACTIONS.ADD,
        data: {
          id: trait.id,
          type: TRAIT_TYPES.CUSTOM,
          columns: trait.columns,
        },
        dataIndex: getCategoryIndex(TRAIT_TYPES.CUSTOM),
      })
    },
    [getTraitFromClick, getCategoryIndex]
  )

  // remove any duplicates in default traits
  useDidMount(() => {
    if (data.length < 2) return
    if (data[0].type === TRAIT_TYPES.CUSTOM && data[1].type === TRAIT_TYPES.DEFAULT) {
      data[0].rows.forEach((customAttr) => {
        let defaultIdx = data[1].rows.findIndex((defaultAttr) => defaultAttr.id === customAttr.id)
        if (defaultIdx >= 0) {
          let defaultAttr = data[1].rows[defaultIdx]
          dispatch({
            type: ACTIONS.REMOVE,
            dataIdx: 0,
            attrId: customAttr.id,
          })
          dispatch({
            type: ACTIONS.ADD,
            data: {
              id: defaultAttr.id,
              type: TRAIT_TYPES.CUSTOM,
              columns: {
                ...defaultAttr.columns,
                parent: 'Site Type',
                action: ACTIONS.SUBTRACT,
              },
            },
            dataIndex: 0,
          })
          dispatch({
            type: ACTIONS.REMOVE,
            dataIdx: 1,
            attrId: defaultAttr.id,
          })
        }
      })
    }
  })

  React.useEffect(() => {
    if (props.defaultUpdateCounter !== defaultCounter) {
      dispatch({ type: ACTIONS.UPDATE_DEFAULT, data: props.data[1].rows })
      setDefaultCounter(props.defaultUpdateCounter)
    }
    if (props.inheritedUpdateCounter !== inheritedCounter) {
      dispatch({ type: ACTIONS.UPDATE_INHERITED, data: props.data[2].rows })
      setInheritedCounter(props.inheritedUpdateCounter)
    }
  }, [props.defaultUpdateCounter, props.inheritedUpdateCounter]) //eslint-disable-line

  const renderData = useCallback(() => {
    return data.map((d, dataIdx) => ({
      ...d,
      rows: d.rows.map((r, rowIdx) => {
        const c = r.columns
        return {
          ...c,
          icon: <NpmapIcon name={c.icon_name} />,
          premium_formatted: c.premium_formatted ? (
            <AttributeChip style={{ width: 83 }} className="mdl-alert-chip">
              {c.premium_formatted}
            </AttributeChip>
          ) : null,
          input: c.input ? <input type="hidden" name={`${props.name}[]`} value={r.id} /> : '',
          action:
            c.action === ACTIONS.REMOVE ? (
              <Btn.Icon light name="close" onClick={() => removeFromTable(dataIdx, rowIdx)} />
            ) : c.action === ACTIONS.ADD ? (
              <Btn.Icon gray name="add" onClick={() => moveAttributeToCustom(dataIdx, rowIdx)} />
            ) : c.action === ACTIONS.SUBTRACT ? (
              <Btn.Icon
                light
                name="remove"
                onClick={() => moveAttributeToDefault(dataIdx, rowIdx)}
              />
            ) : null,
        }
      }),
    }))
  }, [data, moveAttributeToCustom, moveAttributeToDefault, props.name, removeFromTable])

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <Fragment>
      {props.editable && (
        <Fragment>
          <Grid className="p-20">
            <Cell col={4}>
              <Typeahead
                label="Select Attributes"
                placeholder="Attribute Name"
                value={inputValue}
                options={attributes}
                getOptionValue={(option) => (option ? option.name : '')}
                getOptionId={(option) => (option ? option.id : '')}
                onChange={(e, value) => setInputValue(value)}
                onSelect={(suggestion) => {
                  setSelectedAttribute(suggestion)
                }}
              />
            </Cell>
            <Cell col={4}>
              <Button
                type="button"
                className="mdl-button--wide mdl-button--wide-centered mdl-button--gray"
                disabled={!selectedAttribute}
                onClick={addToTable}>
                Add to List
              </Button>
            </Cell>
            <Cell col={4}>
              {props.customAttributesPath ? (
                <Button
                  onClick={() => setModalOpen(true)}
                  accent
                  type="button"
                  className="mdl-button--wide mdl-button--wide-centered u-pull--right">
                  New Custom Attribute
                </Button>
              ) : null}
            </Cell>
          </Grid>
          <input type="hidden" name={`${props.name}[]`} />
        </Fragment>
      )}

      <CategorizedTable header={header} data={renderData()} emptyText={props.emptyText} />

      {/*
        When choosing "save and continue" from the modal, we need to pass the custom
        attributes path to redirect to after save
      */}
      {modalOpen ? (
        <input type="hidden" name="redirect_to_path" value={props.customAttributesPath} />
      ) : null}

      {props.customAttributesPath ? (
        <Modal
          open={modalOpen}
          id="save_confirmation_modal"
          onClose={closeModal}
          onCancel={closeModal}>
          <Fragment>
            <Modal.Title id="save_confirmation_modal">You’re about to leave this page.</Modal.Title>

            <Modal.Content className="pb-20">
              <p>
                You are about to leave this page. Any unsaved data will be lost.
                <br />
                Press "Cancel" to return to your page or "Save & Continue" to save your progress
                before leaving to the other page.
              </p>
            </Modal.Content>

            <Modal.Actions>
              <Button
                type="submit"
                className="mdl-button--wide mdl-button--wide-centered mdl-button--gray">
                Save &amp; Continue
              </Button>

              <Button
                type="button"
                className="mdl-button--wide mdl-button--light"
                onClick={closeModal}>
                Cancel
              </Button>
            </Modal.Actions>
          </Fragment>
        </Modal>
      ) : null}
    </Fragment>
  )
}

AttributesTable.propTypes = propTypes
export default AttributesTable
