// Enable keyboard activation via Enter or Space on custom elements
//
// Usage:
//
//   const handleKeyDown = (e) => {
//     doStuff(e.target.checked)
//   }
//   <button onKeyDown={onKeyActivate(handleKeyDown)} />
//
// Or if you just want to simulate a click on enter or space
//
//   <button onKeyDown={onKeyActivate('click')} />
//

const propTypes = {
  callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

export const onKeyActivate = (callback) => (event) => {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault() // Prevent spacebar from moving page down

    // If the callback is a string (i.e. event name), call it directly on the target
    // else, call the callback as a function
    if (typeof callback === 'string') {
      event.target[callback]()
    } else {
      callback(event)
    }
  }
}

onKeyActivate.propTypes = propTypes
