import { paramsToString } from '@utils'
const { location, history } = window

export const useHistoryState = () => {
  const baseUrl = [location.protocol, '//', location.host, location.pathname].join('')

  // Update current url with each new search param without adding to history stack
  const replaceState = (newParams) => {
    history.replaceState({}, document.title, `${baseUrl}${paramsToString(newParams)}`)
  }

  return { replaceState, paramsToString }
}
