import { useDidMount } from '@hooks'
import { generateUuid } from '@utils'
import * as mapLayers from '@components/camp/parks/MapView/mapLayers'
import {
  MapViewContext,
  MAP_MODES,
  DROP_TYPES,
} from '@components/camp/parks/MapView/mapViewContext'
import Toolbar from '@components/camp/parks/MapView/Toolbar'
import { SiteMarker, SectionMarker, LoopMarker, FacilityMarker } from './markers'
const { useContext } = React
const { L } = window
const { reaction } = mobx
const { observer, useObserver } = mobxReactLite

// Hardcoded default South Dakota
// coordinates and zoom level
const defaultLat = 44
const defaultLng = -100
const defaultZoom = 7
const mapMinZoom = 12
const defaultCoords = new L.LatLng(defaultLat, defaultLng)
const mapId = `leaflet-map-${Date.now()}`
const NEARBY_BUFFER_IN_METERS = 400

const ParkMap = observer(() => {
  const store = useContext(MapViewContext)

  // Initialize Leaflet Map
  useDidMount(() => {
    const map = L.map(mapId, {
      attributionControl: false,
      zoomControl: false,
      minZoom: mapMinZoom,
    })

    map.setView(defaultCoords, defaultZoom)
    mapLayers.initLayersAndControls(map)

    store.setMap(map)
    refreshMarkers()
    refreshLayers({ transition: true, animate: false })
  })

  const refreshMarkers = () => {
    const sectionMarkers = store.park.large_zones
      .map((i) => new SectionMarker(store, i))
      .filter((i) => i.valid)
    const loopMarkers = store.park.medium_zones
      .map((i) => new LoopMarker(store, i))
      .filter((i) => i.valid)
    const siteMarkers = store.park.small_zones
      .map((i) => new SiteMarker(store, i))
      .filter((i) => i.valid)
    const facilityMarkers = store.park.facility_locations
      .map((i) => new FacilityMarker(store, i))
      .filter((i) => i.valid)

    store.setMarkers({ sectionMarkers, loopMarkers, siteMarkers, facilityMarkers })
  }

  const markersWithNearbyFacilities = (markers) => {
    // Get bounds of site, section, and loop markers
    const markerCenter = L.featureGroup(markers.map((m) => m.marker))
      .getBounds()
      .getCenter()

    // Find facilities within the buffered distance to the center marker
    const nearbyFacilities = store.markers.facilityMarkers.filter((f) => {
      return markerCenter.distanceTo(f.marker.getLatLng()) <= NEARBY_BUFFER_IN_METERS
    })

    return [...markers, ...nearbyFacilities]
  }

  const refreshLayers = ({ transition, animate }) => {
    let newMarkers

    // Remove all existing markers from the map
    if (store.layerGroup) {
      store.layerGroup.remove()
    }

    switch (store.mode) {
      case MAP_MODES.PARK:
        newMarkers = store.markers.sectionMarkers
        break
      case MAP_MODES.SECTION:
        newMarkers = [...store.loopMarkersInSection, ...store.siteMarkersInSection]
        newMarkers = markersWithNearbyFacilities(newMarkers)
        break
      case MAP_MODES.LOOP:
        newMarkers = store.siteMarkersInLoop
        newMarkers = markersWithNearbyFacilities(newMarkers)
        break
      default:
        break
    }

    // Get all instances of a Leaflet marker
    newMarkers = newMarkers.map((m) => m.marker)

    // Group all markers into a LayerGroup and add it to the map
    store.layerGroup = L.layerGroup(newMarkers)
    store.layerGroup.addTo(store.map)

    // If transition args are passed, shift the map based on the new layers
    if (transition) {
      // Get bounds of all markers
      const bounds = L.featureGroup(newMarkers).getBounds()
      // Set map zoom to bounds of markers
      if (bounds.isValid()) {
        store.map.flyToBounds(bounds, { padding: [10, 10], animate })
      }
    }
  }

  // Toggle marker active/inactive state when hovered or clicked on from SideNav
  reaction(
    () => store.hoverMarker,
    (marker) => {
      store.allMarkers.forEach((m) => {
        if (marker && m.item_id === marker.item_id) {
          m.toggleHover(true)
        } else {
          m.toggleHover(false)
        }
      })
    }
  )

  // Toggle site marker popups when selected from the side bar
  reaction(
    () => store.selectedSite,
    (site) => {
      if (site) {
        const m = store.markers.siteMarkers.find((i) => i.item_id === site.id)
        if (m) {
          m.openPopup()
        }
      }
    }
  )

  // Refresh the layers based on which MAP_MODE
  // e.g. display and zoom in on sites when a loop is selected
  reaction(
    () => store.mode,
    () => refreshLayers({ transition: true, animate: true })
  )

  // Rebuild the markers to bind events for editing sites
  reaction(
    () => store.editMode,
    () => {
      refreshMarkers()
      refreshLayers({ transition: false })
    }
  )

  // When site or facility markers are added or removed, refresh the layers
  reaction(
    () => [store.markers.siteMarkers?.length, store.markers.facilityMarkers?.length],
    () => refreshLayers({ transition: false })
  )

  return useObserver(() => (
    <div className="map-view__container">
      {store.lastEvent ? (
        <div className="map-view__alert mdl-snackbar bg--white mdl-shadow--3dp">
          <div className="mdl-snackbar__text px-20 py-10 text--darkgray">
            {store.lastEvent.name} has been {store.lastEvent.verb}
          </div>
        </div>
      ) : null}
      {store.editMode ? <Toolbar /> : null}
      <ParkMapTarget />
    </div>
  ))
})

const ParkMapTarget = observer(() => {
  const store = useContext(MapViewContext)

  const onDragOver = (e) => {
    e.preventDefault()

    if (!store.editMode) {
      return
    }

    e.dataTransfer.dropEffect = 'move'
  }

  const onDrop = (e) => {
    e.preventDefault()

    if (!store.editMode) {
      return
    }

    const data = e.dataTransfer.getData('text/plain')
    let obj

    try {
      obj = JSON.parse(data)
    } catch (e) {
      console.error('No JSON')
      return
    }

    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    const coords = store.map.containerPointToLatLng(L.point([x, y]))

    switch (obj.type) {
      case DROP_TYPES.SITE:
        onDropSite(+obj.id, coords)
        break
      case DROP_TYPES.FACILITY:
        onDropFacility(+obj.id, coords)
        break
      default:
        console.error('Droppable object not found', obj)
        break
    }
  }

  const onDropSite = (siteId, coords) => {
    const site = store.park.small_zones.find((s) => s.id === siteId)
    if (!site) {
      return
    }

    const marker = new SiteMarker(store, site, coords)
    store.addSite(site, marker)
  }

  const onDropFacility = (fId, coords) => {
    const fac = store.park.all_facilities.find((s) => s.id === fId)
    if (!fac) {
      return
    }

    const facLocation = {
      // generate temporary ID before API responds w/ new record
      id: generateUuid(),
      facility_id: fac.id,
      name: fac.name,
      icon_name: fac.icon_name,
      longitude: fac.longitude,
      latitude: fac.latitude,
      description: fac.description,
      active: true,
    }

    const marker = new FacilityMarker(store, facLocation, coords)
    store.addFacility(facLocation, marker)
  }

  return <div id={mapId} onDragOver={onDragOver} onDrop={onDrop} style={{ height: '100%' }} />
})

export default ParkMap
