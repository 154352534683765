const { SortableHOC } = window

const AssignedQuestionsTable = ({
  assignedQuestions,
  surveyQuestions,
  disableDelete,
  onDelete,
  onSortEnd,
}) => {
  return (
    <Table
      collection="event_questions"
      helperClass="sortableBlockHelper"
      onSortEnd={onSortEnd}
      useDragHandle>
      <Headers />
      {surveyQuestions.map((item, index) => {
        return <SurveyQuestion key={`survey_question-${index}`} index={index} item={item} />
      })}
      {assignedQuestions
        .filter((q) => !q._destroy)
        .map((item, index) => {
          return (
            <EventQuestion
              key={`event_question-${index}`}
              item={item}
              onDelete={onDelete(item)}
              disableDelete={disableDelete}
              index={index}
            />
          )
        })}
    </Table>
  )
}

const Table = SortableHOC.SortableContainer(({ children }) => (
  <div className="block--full">{children}</div>
))

const Headers = () => {
  const headers = ['activerecord.models.question', 'common.required', 'common.level'].map(I18n.t)

  return (
    <div className="mdl-grid block--full " style={{ backgroundColor: '#edf4fc' }}>
      <div className="mdl-cell mdl-cell--1-col" />
      <div className="mdl-cell mdl-cell--6-col">{headers[0]}</div>
      <div className="mdl-cell mdl-cell--2-col">{headers[1]}</div>
      <div className="mdl-cell mdl-cell--2-col">{headers[2]}</div>
      <div className="mdl-cell mdl-cell--1-col" />
    </div>
  )
}

const SurveyQuestion = ({ item, index }) => {
  return (
    <div key={`row-${index}`} className="mdl-grid block--full u-border--top">
      <div className="mdl-cell mdl-cell--1-col" />
      <div className="mdl-cell mdl-cell--6-col">{item.question}</div>
      <div className="mdl-cell mdl-cell--2-col">
        {item.required ? I18n.t('common.yes') : I18n.t('common.no')}
      </div>
      <div className="mdl-cell mdl-cell--2-col">{I18n.t('common.program')}</div>
      <div className="mdl-cell mdl-cell--1-col" />
    </div>
  )
}

const EventQuestion = SortableHOC.SortableElement(({ item, index, onDelete, disableDelete }) => {
  return (
    <div key={`row-${index}`} className="mdl-grid block--full u-border--top">
      <div className="mdl-cell mdl-cell--1-col">
        <QuestionSortHandle />
      </div>
      <div className="mdl-cell mdl-cell--6-col">{item.question}</div>
      <div className="mdl-cell mdl-cell--2-col">
        {item.required ? I18n.t('common.yes') : I18n.t('common.no')}
      </div>
      <div className="mdl-cell mdl-cell--2-col">{I18n.t('common.event')}</div>
      <div className="mdl-cell mdl-cell--1-col">
        <button
          className="mdl-button mdl-js-button mdl-button--icon mdl-button--transparent-danger"
          onClick={onDelete}
          disabled={disableDelete}>
          <i className="material-icons">delete_forever</i>
        </button>
      </div>
    </div>
  )
})

const QuestionSortHandle = SortableHOC.SortableHandle(() => (
  <i className="material-icons handle u-pull--left">drag_handle</i>
))

export default AssignedQuestionsTable
