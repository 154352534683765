import { Btn } from '@components/shared'

const InstantFileUpload = (props) => {
  const hiddenFileInput = React.useRef(null)
  const [uploadedFile, setUploadedFile] = React.useState(props.file)
  const [errors, setErrors] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const openFileBrowser = () => {
    hiddenFileInput.current.click()
  }

  const handleRemoveFile = () => {
    setLoading(true)
    setErrors(null)

    const deletePath = props.filePath + '/' + uploadedFile.id

    Fetchable.delete(deletePath)
      .then((res) => res.json())
      .then((response) => {
        if (response && response.errors) {
          setErrors(response.errors)
        } else {
          setUploadedFile(null)
        }
        setLoading(false)
      })
  }

  const handleUploadFile = React.useCallback(
    (event) => {
      setLoading(true)
      setErrors(null)

      const file = event.target.files[0]
      let data = new FormData()
      data.append('file[file]', file)

      Fetchable.sendForm(props.filePath, 'POST', data)
        .then((res) => res.json())
        .then((response) => {
          if (response && response.errors) {
            setErrors(response.errors)
          } else {
            setUploadedFile(response.file)
          }
          setLoading(false)
        })
        .catch((res) => alert(res))
    },
    [props.filePath]
  )

  const inputForFile = React.useMemo(() => {
    if (uploadedFile) {
      return <input type="hidden" name={props.name} value={uploadedFile.id} />
    } else {
      return (
        <span>
          {/*
            Wrapped in a span to prevent React from reusing the input
            on 2nd render, causing an uncontrolled input error
          */}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleUploadFile}
            style={{ display: 'none' }}
            id={props.id}
          />
        </span>
      )
    }
  }, [handleUploadFile, props.id, props.name, uploadedFile])

  return (
    <React.Fragment>
      <div className="always-floating-label">
        <label htmlFor={props.id} className="mdl-textfield__label file">
          {props.label}
        </label>

        {uploadedFile ? (
          <div className="d-flex flex__justify--start">
            <div className="pr-20" style={{ maxWidth: '60%' }}>
              <span className="word-break-all">{uploadedFile.file_file_name}</span>
            </div>
            <Btn.Spinner
              loading={loading}
              type="button"
              onClick={handleRemoveFile}
              className="mdl-button--wide mdl-button--wide-centered mdl-button--light">
              Remove
            </Btn.Spinner>
          </div>
        ) : (
          <Btn.Spinner
            loading={loading}
            type="button"
            accent
            onClick={openFileBrowser}
            className="mdl-button--wide mdl-button--wide-centered">
            Upload
          </Btn.Spinner>
        )}
        {errors && <p className="text--brightred text--small">{errors}</p>}
        {inputForFile}
      </div>
    </React.Fragment>
  )
}

export default InstantFileUpload
