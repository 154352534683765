// Copied from https://github.com/woofers/react-dialog-polyfill/blob/525ad9c47ad498b3db6371b6cfd035993a9f6ea8/src/modal.js
// Edited to use window.dialogPolyfill

const { forwardRef, createRef, useEffect, useState } = React

const ModalBase = forwardRef((props, modal) => {
  const { children, open, ready, onCancel, onClose, isModal, ...otherProps } = props

  useEffect(() => {
    const ref = modal.current
    if (!ref || !ready || ref.open === open) {
      return
    }

    const show = isModal ? () => ref.showModal() : () => ref.show()
    const close = () => ref.close()

    const action = open ? show : close
    action()
  }, [ready, open, modal, isModal])

  const onCancelWrap = (e) => {
    e.preventDefault()
    onCancel(e, modal.current)
  }

  const onCloseWrap = (e) => {
    onClose(e, modal.current)
  }

  return (
    <dialog {...otherProps} ref={modal} onCancel={onCancelWrap} onClose={onCloseWrap}>
      {children}
    </dialog>
  )
})

ModalBase.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
}

const ModalWrapper = (p) => {
  const modal = createRef()
  const [ready, setReady] = useState()

  useEffect(() => {
    const ref = modal.current
    if (ready || !ref) {
      return
    }

    dialogPolyfill.registerDialog(ref)
    setReady(true)
  }, [modal, ready])

  return <ModalBase {...p} ready={ready} ref={modal} />
}

export const Modal = (p) => <ModalWrapper {...p} isModal={true} />
export const Dialog = (p) => <ModalWrapper {...p} isModal={false} />
