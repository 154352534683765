import PricingRecordList from '../PricingRecordList'

const PricingRecordView = ({
  transactionTypes = [],
  salesChannels = [],
  locationClasses = [],
  productVariations = [],
  endpoint = '',
}) => {
  return (
    <div className="bg--white u-border--round mdl-shadow--2dp">
      {transactionTypes.map((transactionType, index) => (
        <PricingRecordList
          key={index}
          salesChannels={salesChannels}
          locationClasses={locationClasses}
          transactionType={transactionType}
          productVariations={productVariations.filter(
            (variation) => variation.transaction_type_id === transactionType.id
          )}
          endpoint={endpoint}
        />
      ))}
    </div>
  )
}

export default PricingRecordView
