// For when you just need an effect to run once on mount with 0 dependencies

const { useEffect } = React

export const useDidMount = (f) => {
  useEffect(() => {
    // Cleanup effects when f returns a function
    return f && f()
  }, []) //eslint-disable-line
}
