import { Grid, Cell, Button } from 'react-mdl'
import { DataTable } from '@components/shared'
import { formatCurrency } from '@utils'

const columns = [
  {
    name: 'display_name',
    display: 'Item Name',
    nosort: true,
  },
  {
    name: 'variable_product_type',
    display: 'Item Type',
    nosort: true,
  },
  {
    name: 'subtotal',
    display: 'Item Unit Price',
    nosort: true,
    cellFormatter: (subtotal) => '$' + subtotal,
  },
  {
    name: 'quantity',
    display: 'Quantity',
    nosort: true,
  },
  {
    name: 'subtotal_with_quantity',
    display: 'Total',
    nosort: true,
    cellFormatter: (subtotal_with_quantity) => '$' + formatCurrency(subtotal_with_quantity),
  },
]

const Consumables = ({ reservation }) => {
  return (
    <React.Fragment>
      <div>
        <Grid>
          <Cell col={8} className="u-margin--y-none">
            <h1 className="text--med-lg">Add-On</h1>
          </Cell>
          <Cell col={4}>
            <Button
              accent
              type="button"
              className="mdl-button--wide mdl-button--wide-centered u-pull--right pt-4">
              Add New Consumable
            </Button>
          </Cell>
        </Grid>
      </div>

      <DataTable
        className=""
        topic={'topic'}
        rows={reservation.add_ons}
        columns={columns}
        rowKeyColumn="id"
      />
    </React.Fragment>
  )
}

export default Consumables
