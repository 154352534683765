// Like a regular Button, but Spinny and Loady
// Accepts any props that would otherwise be applied to an MDL Button
//
// Usage:
//   import { Btn } from '@components/shared'
//   <Btn.Spinner accent loading={thingIsLoading} onClick={submitThing}>
//     Upload Thing
//   </Btn.Spinner>
//

import { Button, Spinner } from 'react-mdl'
const { forwardRef } = React

const propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const BtnSpinner = forwardRef((props, ref) => {
  const { loading = false, type = 'button', children, className = '', ...otherProps } = props

  // Remove the default margin-right on mdl-button so the spinner stays centered
  const btnClass = `${className} u-margin--right-none`

  // Add margin-right on the wrapper so it displays as expected around other buttons
  const wrapperClass = 'd-inline-block u-position--relative u-margin--right-5'

  // Tell screen readers content is being loaded
  const ariaProps = {
    'aria-live': 'polite',
    'aria-busy': loading ? 'true' : 'false',
  }

  return (
    <div className={wrapperClass} {...ariaProps}>
      <Button ref={ref} type={type} disabled={loading} className={btnClass} {...otherProps}>
        {/* Make button text invisible but keep its physical space so it won't shrink */}
        <div className={loading ? 'u-invisible' : ''}>{children}</div>
      </Button>

      {/* Place the spinner atop the button */}
      {loading && (
        <Spinner className="u-absolute--center" role="progressbar" aria-valuetext="Loading" />
      )}
    </div>
  )
})

BtnSpinner.propTypes = propTypes
export default BtnSpinner
