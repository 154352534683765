import { Card } from 'react-mdl'
import queryString from 'query-string'
import useFetch from 'use-http'
import { DataTable, SearchForm, NpmapIcon } from '@components/shared'
import { onClickLink } from '@utils'
import { useSearchQuery } from '@hooks'

const MinMaxStay = ({ minStay, maxStay }) => (
  <div>
    Min. {minStay} Nights
    <br />
    Max. {maxStay} Nights
  </div>
)

const SiteTypesTable = ({ endpoint }) => {
  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
  })
  const { data = { results: [] }, loading } = useFetch(searchUrl, [searchUrl])

  const columns = [
    {
      name: 'iconName',
      display: 'Icon',
      cellFormatter: (name) => <NpmapIcon name={name} />,
      nosort: true,
      colGroupStyle: { width: '10%' },
    },
    {
      name: 'name',
      display: 'Name',
      nosort: true,
      cellFormatter: (name, row) => <a href={`${endpoint}/${row.id}`}>{name}</a>,
    },
    {
      name: 'minMaxStay',
      display: 'Min./Max. Stay',
      nosort: true,
      cellFormatter: ({ minStay, maxStay }) => <MinMaxStay minStay={minStay} maxStay={maxStay} />,
    },
    {
      name: 'maxOccupancy',
      display: 'Max. Occupancy',
      nosort: true,
    },
    {
      name: 'maxVehicles',
      display: 'Max. Vehicles',
      nosort: true,
    },
    {
      name: 'pricingRecordCount',
      display: 'Pricing Records',
      nosort: true,
      numeric: true,
      // TODO: link to pricing records
      cellFormatter: (value, row) => <a href={`${endpoint}/${row.id}/variations`}>{value}</a>,
    },
  ]

  const rows = React.useMemo(
    () =>
      data.results.map((siteType) => ({
        id: siteType.id,
        name: siteType.name,
        iconName: siteType.icon_name,
        minMaxStay: { minStay: siteType.min_stay, maxStay: siteType.max_stay },
        maxOccupancy: siteType.max_occupancy,
        maxVehicles: siteType.max_vehicles,
        pricingRecordCount: siteType.pricing_record_count,
        url: `${endpoint}/${siteType.id}`,
      })),
    [data.results, endpoint]
  )

  const topic = searchState.query.length > 0 ? 'Site Type Search Results' : 'All Site Types'

  return (
    <Card shadow={0} style={{ width: '100%' }}>
      <SearchForm
        searchFilters={searchState.query}
        onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
        placeholder="Search Site Types"
        helperText="Search Site Type Name"
      />

      <DataTable
        className="data-table--chevron"
        topic={topic}
        loading={loading}
        rows={rows}
        columns={columns}
        onRowClick={(e, row) => onClickLink(e, row.url)}
        rowKeyColumn="name"
        initialSortBy="name"
      />
    </Card>
  )
}
export default SiteTypesTable
