import { Button } from 'react-mdl'
import { Modal } from '@components/shared'

const CopyEvent = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const cancel = () => {
    setModalOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        className="mdl-button mdl-js-button mdl-button--icon mdl-button--transparent mr-0"
        aria-label="Copy this event"
        title="Copy this event"
        onClick={() => setModalOpen(true)}>
        <span className="material-icons">content_copy</span>
      </Button>

      <Modal open={modalOpen} id="copy_event" onClose={cancel} onCancel={cancel}>
        <Modal.Title id="copy_event">{props.modalTitle}</Modal.Title>
        <Modal.Content>
          <p>
            Event Name
            <br />
            {props.eventName}
          </p>
          <p>
            Location
            <br />
            {props.eventAddr1}
            {props.eventAddr2 && (
              <>
                <br />
                {props.eventAddr2}
              </>
            )}
            <br />
            {props.eventCity}, {props.eventStateCode} {props.eventPostalCode}
          </p>
          <p>
            Enrollment
            <br />
            {props.eventCapacity}
          </p>
          <p>
            Waitlist Enabled?
            <br />
            {props.waitlistEnabled}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={cancel} href={props.path}>
            Submit
          </Button>
          <Button className="mdl-button--light" onClick={cancel}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export default CopyEvent
