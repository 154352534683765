import React, { Fragment } from 'react'
import { Textfield, Grid, Cell, Checkbox } from 'react-mdl'
import { useObserver } from 'mobxReactLite'
import useTimeAndDateStore from './useTimeAndDateStore'
import { formatDateFromDb, onKeyActivate } from '@utils'

const { FlatDatepicker, I18n } = window

const TimeAndTravel = ({ name, value, start_at, end_at, no_hours, question, onChange = null }) => {
  const dateFormat = I18n.t('date.formats.form_date_hint')
  const {
    time_code: timeCode,
    collect_time_log: collectTimeLog,
    question: label,
    required,
  } = question

  const store = useTimeAndDateStore({
    startAt: start_at,
    endAt: end_at,
    detailValue: value,
    computeValue: collectTimeLog,
    noHours: no_hours,
  })

  const timeFieldOptions = {
    noCalendar: true,
    enableTime: true,
    minuteIncrement: 15,
  }

  return useObserver(() => (
    <fieldset>
      <Grid className="mdl-grid--nested">
        <Cell col={12}>
          <legend className="text--darkblue">{label}</legend>
        </Cell>

        {collectTimeLog ? (
          <input type="hidden" name={`${name}[value]`} value={store.computedValue || ''} />
        ) : (
          <input type="hidden" name={`${name}[value]`} value={store.detailValue || ''} />
        )}
        <input type="hidden" name={`${name}[start_at]`} value={store.startAt || ''} />
        <input type="hidden" name={`${name}[end_at]`} value={store.endAt || ''} />
        <input type="hidden" name={`${name}[no_hours]`} value={store.noHours} />

        <Cell col={4}>
          <FlatDatepicker
            label="Date"
            value={store.noHours ? '' : formatDateFromDb(store.date, dateFormat)}
            placeholder={store.noHours ? '' : dateFormat}
            dateFormat={dateFormat}
            onChange={(dates) => store.setDate(dates[0])}
            options={{ allowInput: true, maxDate: 'today' }}
            required={required}
            disabled={store.noHours}
          />
        </Cell>

        {collectTimeLog ? (
          <Fragment>
            <Cell col={2}>
              <FlatDatepicker
                placeholder={store.noHours ? '' : '0:00'}
                value={store.noHours ? '' : store.startAt}
                label={`${required ? '* ' : ''}Start Time`}
                dateFormat="LT"
                onChange={(dates) => store.setStartAt(dates[0])}
                forceUpdate={store.timestamp}
                options={timeFieldOptions}
                disabled={store.noHours}
              />
            </Cell>
            <Cell col={2}>
              <FlatDatepicker
                placeholder={store.noHours ? '' : '0:00'}
                value={store.noHours ? '' : store.endAt}
                label={`${required ? '* ' : ''}End Time`}
                dateFormat="LT"
                onChange={(dates) => store.setEndAt(dates[0])}
                forceUpdate={store.timestamp}
                options={timeFieldOptions}
                disabled={store.noHours}
              />
            </Cell>
            <Cell col={4}>
              <Textfield
                readOnly
                floatingLabel
                className="always-floating-label"
                type="number"
                label={label}
                value={store.computedValue}
                error={store.valueError}
                disabled={store.noHours}
              />
            </Cell>
            <Cell col={12}>
              <Checkbox
                label={I18n.t('events.views.instructor_results.no_hours_to_submit')}
                className="mb-10"
                checked={store.noHours}
                onKeyDown={onKeyActivate('click')}
                onChange={(event) => {
                  store.setNoHours(!store.noHours)
                }}
              />
            </Cell>
          </Fragment>
        ) : (
          <Cell col={4}>
            {timeCode === 'mileage' ? (
              <Textfield
                floatingLabel
                type="number"
                label={required ? `* ${label}` : label}
                required={required}
                value={store.detailValue}
                onChange={(e) => store.setValue(e.target.value)}
                error={store.valueError}
                step="1"
                min="1"
                max="999"
              />
            ) : (
              <Textfield
                floatingLabel
                type="number"
                label={required ? `* ${label}` : label}
                required={required}
                value={store.detailValue}
                onChange={(e) => store.setValue(e.target.value)}
                error={store.valueError}
                step="0.01"
                min="0"
                max="24"
              />
            )}
          </Cell>
        )}
      </Grid>
    </fieldset>
  ))
}

export default TimeAndTravel
