import queryString from 'query-string'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { DataTable, Paginate } from '@components/shared'
import { useEffect, useState } from 'react'

const ContactsTable = ({ customerEndpoint, endpoint, searchQuery }) => {
  const generateColumns = (url) => [
    {
      name: 'full_name',
      display: 'Contact Name',
      cellFormatter: (name, row) =>
        !!row.customer_id ? (
          <a href={`${url}/${row.id}`}>{name}</a>
        ) : (
          <span>{name || 'Unknown'}</span>
        ),
    },
    {
      name: 'email_address',
      display: 'Email',
      cellFormatter: (email) => email || 'Unknown',
    },
    {
      name: 'phone_number',
      display: 'Phone No.',
      cellFormatter: (phone) => phone || 'Unknown',
    },
    {
      name: 'full_address',
      display: 'Address',
      cellFormatter: (address) => address || 'Unknown',
    },
  ]

  const columns = generateColumns(customerEndpoint)
  const orderings = columns.map((c) => c.name)

  const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

  const ordering = (order) => {
    const isDefined = !!order
    const isValid = isDefined && orderings.includes(order)

    return isValid ? order : 'full_name'
  }

  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query,
    order: ordering(params.order),
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
  })

  const { get, loading } = useFetch(searchUrl, { cachePolicy: 'no-cache' })
  const [data, setData] = useState({ results: [] })

  useEffect(() => {
    const fetchContacts = async () => {
      const res = await get(queryString.stringify(searchQuery))
      setData(res)
    }

    fetchContacts()
  }, [get, searchUrl, searchQuery])

  return (
    <>
      <DataTable
        topic="Segmented Contacts"
        loading={loading}
        rows={data.results}
        columns={columns}
        rowKeyColumn="id"
        noResultsText="No contacts found"
        onSort={(name, isAcending) => {
          searchDispatch({ type: 'order', name, isAcending })
          searchDispatch({ type: 'page', value: 1 })
        }}
        initialSortBy="full_name"
        initialIsSortAcending={searchState.asc === 1}
      />
      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}
    </>
  )
}

export default ContactsTable
