import * as popups from './popups'
import * as mapIcons from '@components/camp/parks/MapView/mapIcons'
const { L } = window

const COORD_PRECISION = 9

const formatCoords = (lat, lng) => {
  if (lat && lng) {
    return new L.LatLng(
      parseFloat(lat).toPrecision(COORD_PRECISION),
      parseFloat(lng).toPrecision(COORD_PRECISION)
    )
  }
}

const getCoords = (marker) => {
  if (marker) {
    const { lat, lng } = marker.getLatLng()
    return formatCoords(lat, lng)
  }
}

// Superclass shared by all types of "markables"
class MarkerBase {
  store = null
  item_id = null
  // A markable is any item that can have a map marker
  // e.g. site, section, loop, or facility
  markable = null
  // A reference to the actual marker on the map
  marker = null
  // Store coords internally
  _coords = null

  constructor(store, markable, coords = null) {
    this.store = store
    this.markable = markable
    this.item_id = markable.id

    this._coords = coords || formatCoords(markable.latitude, markable.longitude)
  }

  // If a marker is present, get its latest coords
  // If no marker is present, we assume this is a new object who's coords
  // were passed to the constructor
  get coords() {
    return this.marker ? getCoords(this.marker) : this._coords
  }

  get valid() {
    return !!this.coords
  }

  toggleHover = (active) => {
    if (active) {
      this.marker.openTooltip()
      this.setIcon(active)
    } else {
      this.marker.closeTooltip()
      this.setIcon()
    }
  }

  bindDraggableTooltip = (marker = null) => {
    const coords = marker ? getCoords(marker) : this.coords

    const content = this.store.editMode
      ? `${this.markable.name} (Lat: ${coords.lat}, Lng: ${coords.lng})`
      : this.markable.name

    this.marker.bindTooltip(content, {
      direction: 'bottom',
      className: 'tooltip',
    })
  }
}

export class SectionMarker extends MarkerBase {
  constructor(...props) {
    super(...props)

    this.createMarker()
    this.bindEvents()
  }

  createMarker = () => {
    if (this.valid) {
      this.marker = L.marker(this.coords, { icon: mapIcons.mapPin() })
    }
  }

  setIcon = (active = false) => {
    this.marker.setIcon(mapIcons.mapPin(active))
  }

  bindEvents = () => {
    if (!this.valid) return

    this.marker.bindTooltip(this.markable.name, {
      direction: 'bottom',
      className: 'tooltip',
    })

    this.marker.on('mouseover', (e) => {
      this.store.setHoverMarker(this)
    })

    this.marker.on('mouseout', (e) => {
      this.store.setHoverMarker(null)
    })

    this.marker.on('click', (e) => {
      this.store.setSelectedSection(this.markable)
    })
  }
}

export class LoopMarker extends MarkerBase {
  constructor(...props) {
    super(...props)

    this.createMarker()
    this.bindEvents()
  }

  createMarker = () => {
    if (this.valid) {
      this.marker = L.marker(this.coords, { icon: mapIcons.mapPin() })
    }
  }

  setIcon = (active = false) => {
    this.marker.setIcon(mapIcons.mapPin(active))
  }

  bindEvents = () => {
    if (!this.valid) return

    this.marker.bindTooltip(this.markable.name, {
      direction: 'bottom',
      className: 'tooltip',
    })

    this.marker.on('mouseover', (e) => {
      this.store.setHoverMarker(this)
    })

    this.marker.on('mouseout', (e) => {
      this.store.setHoverMarker(null)
    })

    this.marker.on('click', (e) => {
      this.store.setSelectedLoop(this.markable)
    })
  }
}

export class SiteMarker extends MarkerBase {
  constructor(...props) {
    super(...props)

    this.createMarker()
    this.bindEvents()
  }

  get valid() {
    return this.markable.active && !!this.coords
  }

  createMarker = () => {
    if (this.valid) {
      this.marker = L.marker(this.coords, {
        icon: mapIcons.sitePin(this.markable.icon_name),
        draggable: this.store.editMode,
        autoPan: true,
      })
    }
  }

  openPopup = () => {
    if (!this.marker.isPopupOpen()) {
      this.marker.openPopup()
    }
  }

  setIcon = (active = false) => {
    this.marker.setIcon(mapIcons.sitePin(this.markable.icon_name, active))
  }

  bindEvents = () => {
    if (!this.valid) return

    this.bindDraggableTooltip()

    this.marker.bindPopup(popups.sitePopup(this.store.park, this.markable, this.store.editMode))

    this.marker.on('mouseover', () => {
      if (!this.store.isDragging) {
        this.store.setHoverMarker(this)
      }
    })

    this.marker.on('mouseout', () => {
      if (!this.store.isDragging) {
        this.store.setHoverMarker(null)
      }
    })

    this.marker.on('click', () => {
      this.store.setSelectedSite(this.markable)
    })

    if (this.store.editMode) {
      // Bind event to static delete button rendered inside the popup
      this.marker.on('popupopen', (e) => {
        const btn = document.getElementById(`remove-site-popup-${this.markable.id}`)
        btn.addEventListener('click', (e) => this.store.removeSite(this.markable))
      })

      this.marker.on('dragstart', () => {
        this.store.isDragging = true
        this.marker.unbindTooltip()
      })

      this.marker.on('dragend', (e) => {
        this.store.isDragging = false

        this.bindDraggableTooltip(e.target)
        this.marker.openTooltip()

        this.store.updateSite(this.markable, e.target)
      })
    }
  }
}

export class FacilityMarker extends MarkerBase {
  constructor(...props) {
    super(...props)

    this.createMarker()
    this.bindEvents()
  }

  rebindEvents = () => {
    this.marker.unbindTooltip()
    this.marker.unbindPopup()
    this.marker.clearAllEventListeners()
    this.bindEvents()
  }

  createMarker = () => {
    if (this.valid) {
      this.marker = L.marker(this.coords, {
        icon: mapIcons.facilityPin(this.markable.icon_name),
        draggable: this.store.editMode,
        autoPan: true,
      })
    }
  }

  setIcon = (active = false) => {
    this.marker.setIcon(mapIcons.facilityPin(this.markable.icon_name, active))
  }

  bindEvents = () => {
    if (!this.valid) return

    this.bindDraggableTooltip()

    this.marker.bindPopup(popups.facilityPopup(this.store.park, this.markable, this.store.editMode))

    this.marker.on('mouseover', () => {
      if (!this.store.isDragging) {
        this.store.setHoverMarker(this)
      }
    })

    this.marker.on('mouseout', () => {
      if (!this.store.isDragging) {
        this.store.setHoverMarker(null)
      }
    })

    // Can't bind these events until the saved record is returned from the API
    if (this.store.editMode && this.markable.id) {
      if (this.markable.id) {
        // Bind event to static delete button rendered inside the popup
        this.marker.on('popupopen', (e) => {
          const btn = document.getElementById(`remove-facility-popup-${this.markable.id}`)
          btn.addEventListener('click', (e) => this.store.removeFacility(this.markable))
        })
      }

      this.marker.on('dragstart', () => {
        this.store.isDragging = true
        this.marker.unbindTooltip()
      })

      this.marker.on('dragend', (e) => {
        this.store.isDragging = false

        this.bindDraggableTooltip(e.target)
        this.marker.openTooltip()

        this.store.updateFacility(this.markable, e.target)
      })
    }
  }
}
