import { NpmapIcon, Typeahead } from '@components/shared'
import { Grid, Cell, Icon } from 'react-mdl'

const ExpandingSiteTypesTable = ({ siteTypes, siteType, name, edit, siteTypesPath, onChange }) => {
  const [selectedSiteType, setSelectedSiteType] = React.useState(siteType || undefined)
  const [inputValue, setInputValue] = React.useState(selectedSiteType ? selectedSiteType.name : '')

  React.useEffect(() => {
    if (inputValue === '' && selectedSiteType) {
      setSelectedSiteType(undefined)
      if (onChange) onChange(null)
    }
  }, [inputValue, selectedSiteType, onChange])

  const onSelect = (siteType) => {
    setSelectedSiteType(siteType)
    if (onChange) {
      onChange(siteType)
    }
  }

  return (
    <React.Fragment>
      {edit && (
        <Grid className="u-padding--x-20 u-padding--y-none">
          <Cell col={4}>
            <Typeahead
              name={name}
              label="Select Site Type"
              placeholder="Site Type"
              value={inputValue}
              options={siteTypes}
              getOptionValue={(option) => (option ? option.name : '')}
              getOptionId={(option) => (option ? option.id : '')}
              onChange={(e, value) => setInputValue(value)}
              onSelect={onSelect}
            />
          </Cell>
        </Grid>
      )}
      <div className="mdlext-accordion__header">
        <div className="mdlext-accordion__header-columns">
          <div className="mdl-accordion__header-item">Icon</div>
          <div className="mdl-accordion__header-item">Name</div>
          <div className="mdl-accordion__header-item">Min./Max. Stay</div>
          <div className="mdl-accordion__header-item">Max. Occupancy</div>
          <div className="mdl-accordion__header-item">Max. Vehicles</div>
        </div>
      </div>
      <ul
        className="mdlext-accordion mdlext-js-accordion mdlext-accordion--vertical u-border--top"
        role="tablist"
        aria-multiselectable="true">
        <li className="mdlext-accordion__panel">
          {selectedSiteType ? (
            <React.Fragment>
              <header className="mdlext-accordion__tab bg--white u-padding--none d-flex flex__justify--center flex__items--center">
                <div
                  className="d-flex flex__justify--space-between flex__items--center u-padding--left-20"
                  style={{ width: '90%' }}>
                  <div className="flex--1">
                    <NpmapIcon name={selectedSiteType.icon_name}></NpmapIcon>
                  </div>
                  <div className="text--med flex--1">{selectedSiteType.name}</div>
                  <div className="flex--1">
                    <div className="text--med u-margin--bottom-5">
                      Min. {selectedSiteType.min_stay} Nights
                    </div>
                    <div className="text--med">Max. {selectedSiteType.max_stay} Nights</div>
                  </div>
                  <div className="text--med flex--1">{selectedSiteType.max_occupancy}</div>
                  <div className="d-flex flex--1 flex__items--center">
                    <span className="text--med">{selectedSiteType.max_vehicles}</span>
                    {edit && (
                      <a
                        href={`${siteTypesPath}/${selectedSiteType.id}`}
                        id="siteTypeEditButton"
                        className="mdl-button--fab mdl-button--light mdl-button--mini-fab u-margin--right-none u-pull--right">
                        <Icon name="create" />
                      </a>
                    )}
                  </div>
                </div>
                <i className="mdlext-aria-toggle-material-icons u-margin--right-20"></i>
              </header>
              <section className="mdlext-accordion__tabpanel bg--well u-padding--none">
                <div className="block--full d-flex flex__flow--column flex__justify--space-between u-padding--20 u-border--bottom">
                  <div className="text--lightgray text--med-small">Description</div>
                  <div
                    className="text--med"
                    dangerouslySetInnerHTML={{ __html: selectedSiteType.description }}
                  />
                </div>
                <div className="block--full d-flex flex__flow--column flex__justify--space-between u-padding--20">
                  <div className="text--lightgray text--med-small">Equipment Restrictions</div>
                  <div
                    className="text--med"
                    dangerouslySetInnerHTML={{ __html: selectedSiteType.equipment_restrictions }}
                  />
                </div>
                <div className="bg--white u-padding--none d-flex flex__justify--center u-padding--10">
                  <div className="text--med-small text--center text--lightgray">
                    No site type selected.
                  </div>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <header className="mdlext-accordion__tab bg--white u-padding--none d-flex flex__justify--center flex__items--center">
                <div className="text--med-small text--center text--lightgray">
                  No site type selected.
                </div>
              </header>
              <section
                style={{ height: 55 }}
                className="mdlext-accordion__tabpanel bg--well u-padding--none d-flex flex__justify--center flex__items--center">
                <span>Select a site type to view its details here.</span>
              </section>
            </React.Fragment>
          )}
        </li>
      </ul>
    </React.Fragment>
  )
}

export default ExpandingSiteTypesTable
