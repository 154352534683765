import qs from 'qs'
import { Cell, Textfield, Button } from 'react-mdl'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { DataTable } from '@components/shared'
const { Fragment, useState, useEffect, useRef } = React

const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

const CustomerSearch = ({ endpoint, columns, customer }) => {
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || {},
  })
  const { get, loading } = useFetch(searchUrl, { headers: window.__AMS__.formToken })
  const [data, setData] = useState({ results: [] })
  const formRef = useRef()

  useEffect(() => {
    const fetchCustomers = async () => {
      const res = await get()
      setData(res)
    }

    if (!customer && Object.entries(searchState.query).length > 0) {
      fetchCustomers()
    }
  }, [get, searchUrl, searchState, customer])

  const searchSubmit = (e) => {
    e.preventDefault()

    const data = new FormData(formRef.current)
    searchDispatch({ type: 'query', value: Object.fromEntries(data) })
  }

  const clearSearch = () => {
    searchDispatch({ type: 'query', value: {} })
    formRef.current.reset()
  }

  return (
    <Fragment>
      <form className="mdl-grid p-20" ref={formRef} onSubmit={searchSubmit}>
        <Cell col={12}>
          <p className="text--med text--lightgray">
            Please indicate who will be assigned to this Reservation
          </p>
        </Cell>
        <Cell col={4}>
          <Textfield
            floatingLabel
            label="Email Address"
            name="email_address"
            className="textfield__input--full"
            defaultValue={searchState.query?.email_address || ''}
          />
        </Cell>
        <Cell col={3}>
          <Textfield
            floatingLabel
            label="Phone Number"
            name="phone_number"
            defaultValue={searchState.query?.phone_number || ''}
          />
        </Cell>

        <Cell col={5}>
          <div>
            <Button
              type="submit"
              raised
              ripple
              className="mdl-button--gray mdl-button--wide"
              disabled={customer}>
              Search
            </Button>

            <Button
              className="mdl-button--light mdl-button--wide"
              onClick={clearSearch}
              disabled={customer}>
              Clear All
            </Button>
          </div>
        </Cell>
      </form>
      <DataTable
        topic="Customer Search Results"
        loading={loading}
        rows={data.results}
        columns={columns}
        rowKeyColumn="id"
      />
    </Fragment>
  )
}

export default CustomerSearch
