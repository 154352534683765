import { Button, Card } from 'react-mdl'
import { DataTable, Paginate, SearchForm } from '@components/shared'
import { formatDateFromDb } from '@utils'
import { useSearchQuery } from '@hooks'
import queryString from 'query-string'
import useFetch from 'use-http'
import { Btn } from '@components/shared'
import { Modal } from '@components/shared'

const { useState, useEffect } = React
const initialSortBy = 'updated_at'
const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

const propTypes = {
  endpoint: PropTypes.string.isRequired,
}

const topic = 'Email Templates'

const EmailTemplates = ({ campaignEndpoint, endpoint }) => {
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || [],
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 0,
    page: params.page ? parseInt(params.page) : 1,
  })

  // Fetches the data
  const { get } = useFetch(searchUrl)
  const { del, response } = useFetch(endpoint, {
    headers: { ...window.__AMS__.formToken, Accept: 'application/json' },
    cachePolicy: 'no-cache',
  })

  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    get().then((res) => setData(res))
  }, [searchUrl, get])

  const [deleteModalRow, setDeleteModalRow] = React.useState(null)

  const columns = [
    {
      name: 'name',
      display: 'Email Name',
      cellFormatter: (name, row) => <a href={row.edit_url}>{name}</a>,
    },
    {
      name: 'updated_at',
      display: 'Last Updated',
      cellFormatter: (value) => formatDateFromDb(value),
    },
    {
      name: 'email_campaigns',
      display: 'Assigned Campaigns',
      nosort: true,
      cellFormatter: (value) => (value || []).length,
    },
    {
      name: 'buttons',
      display: '',
      nosort: true,
      className: 'mdl-data-table__cell--narrow',
      cellFormatter: (name, row) => (
        <span style={{ white_space: 'nowrap' }}>
          <Btn.Icon light name="edit" className="mr-20" href={row.edit_url} />
          <Btn.Icon
            light
            name="content_copy"
            className="mr-20"
            href={`${endpoint}/new?email_template_id=${row.id}`}
          />
          <Btn.Icon light name="delete" onClick={() => setDeleteModalRow(row)} />
        </span>
      ),
    },
  ]

  async function deleteRow() {
    await del('/' + deleteModalRow.id)
    if (response.ok) {
      setData({ ...data, results: data.results.filter((r) => r.id !== deleteModalRow.id) })
      setDeleteModalRow(null)
    }
  }
  const expandTd = (row) => (
    <div className="email--preview">
      <div className="d-flex flex__justify--space-between flex__items--baseline">
        <div>
          <p className="text--med text--bold text--lightgray">Assigned Campaigns</p>
          {row.email_campaigns.map((c) => (
            <div key={`email-campaign-${c.id}`} className="text--left py-10">
              <a href={`${campaignEndpoint}/${c.id}`} className="text--med-lg text--bold">
                {c.name}
              </a>
            </div>
          ))}
        </div>
        <a
          href={`${endpoint}/${row.id}/edit?section=history`}
          className="mdl-button mdl-button--accent mdl-button--wide m-0 mdl-js-button mdl-js-ripple-effect">
          View Change History
        </a>
      </div>
      <div className="mt-20">
        <p className="text--med text--bold text--lightgray text--left">Email Preview</p>
        <iframe
          style={{ width: '100%', height: '50vh' }}
          srcDoc={row.template_content}
          title="Email Preview"
        />
      </div>
    </div>
  )

  return (
    <Card shadow={0} style={{ width: '100%' }}>
      <SearchForm
        searchFilters={searchState.query}
        onSearch={(filters) => searchDispatch({ type: 'query', value: filters })}
        placeholder="Search Email Name"
        helperText="Search Email Name"
      />
      <DataTable
        rows={data.results}
        columns={columns}
        rowKeyColumn="id"
        topic={topic}
        onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
        initialSortBy={searchState.order}
        initialIsSortAcending={searchState.asc === 1}
        expandTd={expandTd}
      />

      {data.meta ? (
        <Paginate {...data.meta} onClick={(num) => searchDispatch({ type: 'page', value: num })} />
      ) : null}

      <Modal
        open={!!deleteModalRow}
        id="confirm_delete"
        onClose={() => setDeleteModalRow(null)}
        onCancel={() => setDeleteModalRow(null)}>
        <Modal.Title id="confirm_delete">Are you sure you want to delete this email?</Modal.Title>
        <Modal.Content>
          Deleting this email will remove it from all the campaigns it has been assigned to. <br />
          Please note that active campaigns without email content will be stopped until updated with
          new content.
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={deleteRow}>
            Delete Email
          </Button>
          <Button className="mdl-button--light" onClick={() => setDeleteModalRow(null)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </Card>
  )
}
EmailTemplates.propTypes = propTypes
export default EmailTemplates
