import classNames from 'classnames'
import { Button } from 'react-mdl'

// TODO: REMOVE ME AND UPDATE SCSS STYLINGS TO USE react-mdl BUTTON

const variants = {
  light: 'mdl-button--light',
  gray: 'mdl-button--gray',
  accent: 'mdl-button--accent',
}

const AmsButton = ({ variant, ...props }) => (
  <Button {...props} className={classNames('mdl-button--raised', !!variant && variants[variant])} />
)

export default AmsButton
