import { onClickLink } from '@utils'
const { useState } = React

const Reservations = ({ reservations }) => {
  const [expandedReservationId, setExpandedReservationId] = useState(null)

  return (
    <div className="mdl-card mdl-card--2dp mdl-shadow--2dp block--full">
      <div className="mdl-card__title mdl-card__title">
        <h2 className="mdl-card__title-text">Reservations</h2>
      </div>

      <div className="table__container--responsive">
        <table className="mdl-data-table mdl-data-table-default-non-numeric block--full">
          <thead>
            <tr>
              <th className="mdl-data-table__cell--non-numeric" scope="col">
                Status
              </th>
              <th className="mdl-data-table__cell--non-numeric wrap-text" scope="col">
                Reservation ID
              </th>
              <th className="mdl-data-table__cell--non-numeric" scope="col">
                Bookings
              </th>
              <th className="mdl-data-table__cell--non-numeric" scope="col">
                Occupants
              </th>
              <th className="mdl-data-table__cell--non-numeric wrap-text" scope="col">
                Start Date
              </th>
              <th className="mdl-data-table__cell--non-numeric wrap-text" scope="col">
                End Date
              </th>
              <th id="reservationDetails" scope="col">
                <span className="visuallyhidden">Details</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {reservations.map((reservation) => {
              const occupantCount = reservation.bookings.reduce(
                (acc, cur) => acc + cur.occupant_count,
                0
              )
              const startDate = reservation.bookings
                .map((b) => new Date(b.start_at))
                .reduce((acc, cur) => (acc < cur ? acc : cur))
              const endDate = reservation.bookings
                .map((b) => new Date(b.end_at))
                .reduce((acc, cur) => (acc > cur ? acc : cur))

              return (
                <React.Fragment key={reservation.id}>
                  <tr>
                    <td className="mdl-data-table__cell--non-numeric">
                      {reservation.payment_status_string}
                    </td>
                    <td className="mdl-data-table__cell--non-numeric">
                      {reservation.reservation_number || 'N/A'}{' '}
                    </td>
                    <td className="mdl-data-table__cell--non-numeric">
                      {reservation.bookings.length}
                    </td>
                    <td className="mdl-data-table__cell--non-numeric">{occupantCount}</td>
                    <td className="mdl-data-table__cell--non-numeric">
                      {moment(startDate).format('LL')}
                    </td>
                    <td className="mdl-data-table__cell--non-numeric">
                      {moment(endDate).format('LL')}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="mdlext-js-collapsible mdl-button mdl-button--icon mdl-button--transparent mr-0"
                        aria-label={`View reservation ${reservation.reservation_number} details`}
                        onClick={() => {
                          if (reservation.id === expandedReservationId)
                            setExpandedReservationId(null)
                          else setExpandedReservationId(reservation.id)
                        }}>
                        <span className="material-icons mr-0" aria-hidden="true">
                          {reservation.id === expandedReservationId ? 'expand_less' : 'expand_more'}
                        </span>
                      </button>
                    </td>
                  </tr>
                  {reservation.id === expandedReservationId ? (
                    <tr onClick={(e) => onClickLink(e, reservation.path)}>
                      <td colSpan="7" headers="reservationDetails">
                        <table className="mdl-data-table mdl-data-table-default-non-numeric block--full data-table data-table--chevron data-table--clickable">
                          <thead>
                            <tr>
                              <th className="mdl-data-table__cell--non-numeric" scope="col">
                                Status
                              </th>
                              <th
                                className="mdl-data-table__cell--non-numeric wrap-text"
                                scope="col">
                                Primary Occupant
                              </th>
                              <th className="mdl-data-table__cell--non-numeric" scope="col">
                                Site & Loop
                              </th>
                              <th className="mdl-data-table__cell--non-numeric" scope="col">
                                Check-in
                              </th>
                              <th className="mdl-data-table__cell--non-numeric" scope="col">
                                Check-out
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {reservation.bookings.map(
                              ({ id, primary_occupant, zone, start_at, end_at, status_name }) => (
                                <tr key={id} className="text--med-small u-border--bottom">
                                  <td className="mdl-data-table__cell--non-numeric">
                                    <a href={reservation.path}>{status_name}</a>
                                  </td>
                                  <td className="mdl-data-table__cell--non-numeric text--darkblue">
                                    {primary_occupant && primary_occupant.first_name}{' '}
                                    {primary_occupant && primary_occupant.last_name}
                                  </td>
                                  <td className="mdl-data-table__cell--non-numeric">
                                    <span className={'text--darkblue'}>{zone.name}</span>
                                    <br />{' '}
                                    <span className="text--small">
                                      {zone.parent_medium_zone_name}{' '}
                                    </span>
                                  </td>
                                  <td className="mdl-data-table__cell--non-numeric">
                                    {moment(start_at).format('LL')}
                                  </td>
                                  <td className="mdl-data-table__cell--non-numeric">
                                    {moment(end_at).format('LL')}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Reservations
