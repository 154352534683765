import { Button, Grid, Cell, Textfield } from 'react-mdl'
import useFetch from 'use-http'
import { Btn, Modal } from '@components/shared'

const { useLocalStore, useObserver } = mobxReactLite

const CategoryInput = ({ url, options, value, name, label, errors, defaultOption, canCreate }) => {
  const { post, response, loading, cache } = useFetch(`${url}.json`, {
    headers: window.__AMS__.formToken,
  })

  const store = useLocalStore(() => ({
    categories: options,
    selected: value,
    newCat: '',
    newCatErrors: '',
    modalOpen: false,

    toggleModal: (status) => () => {
      store.modalOpen = status
    },

    onSubmit: async (e) => {
      e.preventDefault()
      if (!store.newCat) return

      cache.clear()
      const res = await post('', { trait_category: { name: store.newCat } })
      if (response.ok) {
        store.categories.push(res)
        store.selected = res.id
        store.modalOpen = false
        store.newCat = ''
        store.newCatErrors = ''
      } else {
        store.newCatErrors = res.errors
      }
    },
  }))

  return useObserver(() => (
    <React.Fragment>
      <Grid className="px-20">
        <Cell col={5}>
          <MdlSelect
            options={store.categories}
            selected={{ id: store.selected }}
            name={name}
            includeBlankOption={defaultOption}
            label={label}
            error={errors}
            required
            className="textfield__input--full always-floating-label"
          />
        </Cell>
        <Cell col={7}>
          {canCreate ? (
            <Button
              type="button"
              accent
              className="mdl-button--wide mdl-button--wide-centered"
              onClick={store.toggleModal(true)}>
              New Category
            </Button>
          ) : null}
        </Cell>
      </Grid>

      {canCreate ? (
        <Modal
          open={store.modalOpen}
          id="new_category_modal"
          onClose={store.toggleModal(false)}
          onCancel={store.toggleModal(false)}>
          <form onSubmit={store.onSubmit}>
            <Modal.Title id="new_category_modal">New Category</Modal.Title>

            <Modal.Content>
              <Textfield
                label="Category Name"
                className="textfield__input--full"
                floatingLabel
                value={store.newCat}
                onChange={(e) => (store.newCat = e.target.value)}
                error={store.newCatErrors}
              />
            </Modal.Content>

            <Modal.Actions>
              <Btn.Spinner type="submit" className="mdl-button--gray" loading={loading}>
                Create
              </Btn.Spinner>

              <Button
                type="button"
                className="mdl-button--light"
                onClick={store.toggleModal(false)}>
                Cancel
              </Button>
            </Modal.Actions>
          </form>
        </Modal>
      ) : null}
    </React.Fragment>
  ))
}

export default CategoryInput
