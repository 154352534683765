import { Grid, Cell } from 'react-mdl'
import BookingDetails from './BookingDetails'

const BookingList = ({ cart, reservationHistory, bookingHistory, path }) => {
  const bookings = cart.reservation.bookings
  const subtotal = cart.booking_items.reduce((acc, cur) => (acc += cur.subtotal), 0.0).toFixed(2)

  return (
    <React.Fragment>
      <Grid className="block--full px-20 u-border--bottom">
        <Cell col={2} className="text--med-lg my-10 py-10">
          Bookings ({bookings.length})
        </Cell>
        <Cell col={1} className="mdl-cell--9-offset text--med-lg my-10 py-10">
          ${subtotal}
        </Cell>
      </Grid>
      {bookings.map((booking, index) => {
        const cartItem = cart.booking_items.find((i) => i.booking_id === booking.id)
        return (
          <BookingDetails
            booking={booking}
            cartItem={cartItem}
            index={index}
            count={bookings.length}
            path={path}
            key={index}
            isReview={false}
            reservationHistory={reservationHistory}
            bookingHistory={bookingHistory}
          />
        )
      })}
    </React.Fragment>
  )
}

export default BookingList
