/**
 * Generate an object that has form error messages by recursively calling the argument
 * and spreading values of `errors` into their parent key so that the messages can be mapped
 * onto the form to the specific variant that caused the error.
 *
 * This solution is in place since we can't use `index_errors` until the application is
 * upgraded to Rails v5.
 *
 * @param {any} param An object with nested form error messages as `errors`
 * @return {any} An object of form error messages that is indexed with nested attributes or undefined
 *
 */
export const generateErrors = (param) => {
  if (!param) {
    return
  }

  if (Array.isArray(param)) {
    return param.map((item) => generateErrors(item))
  }

  if (typeof param === 'object') {
    return Object.entries(param).reduce((accumulator, [key, value]) => {
      if (key === 'errors') {
        return {
          ...accumulator,
          ...value,
        }
      }

      return {
        ...accumulator,
        [key]: generateErrors(value),
      }
    }, {})
  }

  return param
}
