import { Cell, Grid } from 'react-mdl'
import { Btn } from '@components/shared'
import { findOptionIndexes, mergeCurrentAttributes } from './conditionStatement'
const { FlatDatepicker } = window

const dateFormat = 'MM/DD/YYYY'

const Condition = ({
  conditionStatement,
  setConditionStatement,
  showRemove,
  onRemove,
  conditionAttributes,
}) => {
  const [optionIndexes, setOptionIndexes] = React.useState(
    findOptionIndexes(conditionStatement, conditionAttributes) || []
  )

  const completeStatement = (statement) =>
    statement.column && statement.operator && statement.value !== undefined
  const currentStatement =
    optionIndexes.length > 0 ? mergeCurrentAttributes(conditionAttributes, optionIndexes) : {}

  // Each one of theses corresponds to the type of input on the condition block
  //  option - dropdown menu, items needs to be passed in to generate that dropdown
  //  string - US-TX
  //  number - 45
  //  date - date picker
  const valueTypesNumber = () =>
    Betweenable((value, setValue, style) => (
      <div className="mdl-textfield mdl-js-textfield" style={style}>
        <input
          type="number"
          className="mdl-textfield__input text--center"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          aria-label="Number value"
        />
      </div>
    ))

  const ValueTypes = {
    number: valueTypesNumber,
    date_range: valueTypesNumber,

    string: () => (
      <Cell col={2}>
        <div className="mdl-textfield mdl-js-textfield textfield__input--full">
          <input
            type="text"
            className="mdl-textfield__input"
            onChange={(e) => setConditionStatement({ ...currentStatement, value: e.target.value })}
            value={conditionStatement.value || ''}
            aria-label="Text Value"
          />
        </div>
      </Cell>
    ),

    date: () =>
      Betweenable((value, setValue) => (
        <span style={{ display: 'inline-block' }}>
          <FlatDatepicker
            dateFormat={dateFormat}
            value={value}
            name={currentStatement.column}
            onChange={(dates) => setValue(moment(dates[0]).format(dateFormat))}
            aria-label="Date Range"
          />
        </span>
      )),
  }

  const Betweenable = (func) => {
    if (!currentStatement.operator) return null
    const suffix =
      currentStatement.units &&
      currentStatement.type !== 'date' &&
      (currentStatement.units.length === 1 ? (
        <span className="px-5" aria-label="Units">
          {currentStatement.units[0]}
        </span>
      ) : (
        <MdlSelect
          style={{ maxWidth: '70px' }}
          onOptionSelected={(option) =>
            setConditionStatement({
              ...currentStatement,
              ...conditionStatement,
              unit: option && option.id,
            })
          }
          selected={conditionStatement.unit || ''}
          aria-label="Select"
          options={currentStatement.units.map((u) => ({ id: u, name: u }))}
        />
      ))

    if (currentStatement.operator.includes('range')) {
      const { value, ...clearConditionStatement } = conditionStatement
      const styleOverride = { maxWidth: '60px' }
      return (
        <Cell col={4}>
          {func(
            conditionStatement.min_value || '',
            (v) =>
              setConditionStatement({
                ...currentStatement,
                ...clearConditionStatement,
                min_value: v,
              }),
            styleOverride
          )}
          <span className="px-5" aria-label="and">
            and
          </span>
          {func(
            conditionStatement.max_value || '',
            (v) =>
              setConditionStatement({
                ...currentStatement,
                ...clearConditionStatement,
                max_value: v,
              }),
            styleOverride
          )}

          {suffix}
        </Cell>
      )
    } else {
      const { min_value, max_value, ...clearConditionStatement } = conditionStatement
      return (
        <Cell col={2}>
          {func(conditionStatement.value || '', (v) =>
            setConditionStatement({ ...currentStatement, ...clearConditionStatement, value: v })
          )}
          {suffix}
        </Cell>
      )
    }
  }

  const buildOptions = ({ children, width }, depth = 0) => {
    const currentIndex = optionIndexes[depth]

    const cell = (
      <Cell col={width || 3} key={depth}>
        <MdlSelect
          onOptionSelected={(option) => {
            const indexes = option
              ? [...optionIndexes.slice(0, depth), option.id - 1]
              : optionIndexes.slice(0, depth)
            setOptionIndexes(indexes)
            const currentStatement = mergeCurrentAttributes(conditionAttributes, indexes)
            if (completeStatement(currentStatement)) setConditionStatement(currentStatement)
            else if (currentStatement.units && !currentStatement.unit)
              setConditionStatement({ ...currentStatement, unit: currentStatement.units[0] })
            else if (
              currentStatement.column !== conditionStatement.column ||
              currentStatement.type !== conditionStatement.type
            ) {
              setConditionStatement({})
            }
          }}
          includeBlankOption="Select"
          selected={currentIndex + 1}
          aria-label="Select a Condition"
          options={children.map((c, i) => ({ id: i + 1, name: c.name }))}
        />
      </Cell>
    )

    const child = currentIndex !== undefined && children[currentIndex]
    if (child && child.children) {
      return [cell, ...buildOptions(child, depth + 1)]
    } else {
      return [cell]
    }
  }

  return (
    <tr>
      <td className="pb-3">
        <Grid className="mdl-grid--nested text--med">
          {buildOptions({ children: conditionAttributes, width: 2 })}
          {currentStatement['value'] === undefined &&
            currentStatement.type &&
            ValueTypes[currentStatement.type]()}
        </Grid>
      </td>
      <td>
        {showRemove && (
          <Btn.Icon light name="close" onClick={onRemove} className="mdl-table-button--small" />
        )}
      </td>
    </tr>
  )
}
export default Condition
