import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const MoreInfoPopover = ({ content, label }) => {
  const template = <div className="px-15 pt-10" dangerouslySetInnerHTML={{ __html: content }} />
  const popoverBoundary = document.querySelector('#page-content')

  return (
    <Tippy
      allowHTML={true}
      content={template}
      interactive={true}
      placement="bottom"
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: popoverBoundary,
            },
          },
        ],
      }}
      role={null}
      theme="light"
      trigger="click">
      <button
        type="button"
        className="mdl-button mdl-button--icon mb-5"
        aria-label={`More info for ${label}`}>
        <span className="material-icons" aria-hidden="true">
          info_outline
        </span>
      </button>
    </Tippy>
  )
}

export default MoreInfoPopover
