import qs from 'qs'
import useFetch from 'use-http'
import { useSearchQuery } from '@hooks'
import { Grid, Card, Cell, Textfield, Button, Tab, Tabs } from 'react-mdl'
import { DataTable, Paginate, Btn } from '@components/shared'
import BookingCheckIn from '@components/camp/reservations/ReservationDetail/BookingCheckIn'
const { useState, useEffect, useMemo } = React

const initialSortBy = 'primary_occupant'

const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

// Tab names
// Upcoming: people who are scheduled to check in today
// Arrived: people who are here today
// Checked-in: people who arrived today
// Checked-out: people who checked out today
const tabs = [
  { display: 'Upcoming', tabName: 'upcoming_arrivals' },
  { display: 'Arrived', tabName: 'arrived' },
  { display: 'Checked-In', tabName: 'checked_in' },
  { display: 'Checked-Out', tabName: 'checked_out' },
]

const SearchBookings = ({ endpoint, parkPath, canEdit }) => {
  const { searchState, searchDispatch, searchUrl, updatedSearchUrl } = useSearchQuery(endpoint, {
    query: params.query || '',
    tab: params.tab || 'upcoming_arrivals',
    order: params.order || initialSortBy,
    asc: params.asc ? parseInt(params.asc) : 1,
    page: params.page ? parseInt(params.page) : 1,
  })
  const activeTabIdx = tabs.findIndex((t) => t.tabName === searchState.tab) || 0
  const activeTab = tabs[activeTabIdx]

  // Fetch sites on load or when the searchUrl changes
  const { get } = useFetch(searchUrl)

  // What gets sent over to be searched
  const [searchQuery, setSearchQuery] = useState(searchState.query)

  const [data, setData] = useState({ results: [] })
  useEffect(() => {
    // Sets the data from the request
    get().then((res) => setData(res))
  }, [get, searchUrl])

  const columns = useMemo(
    () => [
      {
        name: 'reservation',
        display: 'Reservation ID',
        cellFormatter: (reservation) => (
          // Link is used for keyboard navigation
          <a href={`${parkPath}/reservations/${reservation.id}`}>
            {reservation.reservation_number}
          </a>
        ),
        nosort: true,
      },
      {
        name: 'primary_occupant',
        display: 'Guest',
        cellFormatter: (primaryOccupant, { occupant_count }) => (
          <React.Fragment>
            {primaryOccupant.first_name} {primaryOccupant.last_name}
            <br />
            <span className="text--med-small text--lightgray">
              Total Occupants: {occupant_count}{' '}
            </span>
          </React.Fragment>
        ),
      },
      {
        name: 'site',
        display: 'Site',
        cellFormatter: (name, { zone }) => <span>{zone.name}</span>,
      },
      {
        name: 'zone',
        display: 'Loops & Sections',
        cellFormatter: ({ parent_large_zone_name, parent_medium_zone_name }) => (
          <React.Fragment>
            {parent_large_zone_name}
            <br />
            <span className="text--med-small text--lightgray">{parent_medium_zone_name}</span>
          </React.Fragment>
        ),
      },
      {
        name: 'start_at',
        display: 'Check-In',
        nosort: true,
        cellFormatter: (startAt) => moment(startAt).format('ll'),
      },
      {
        name: 'end_at',
        display: 'Check-Out',
        nosort: true,
        cellFormatter: (endAt) => moment(endAt).format('ll'),
      },
      {
        name: 'check_in_button',
        display: 'Check-In Button',
        nosort: true,
        hideHeader: true,
        colGroupStyle: { width: '220px' },
        cellFormatter: (_, row) => {
          if (canEdit && activeTab?.tabName === 'upcoming_arrivals') {
            return (
              <BookingCheckIn
                id={row.id}
                primaryOccupant={row.primary_occupant}
                startAt={row.start_at}
                checkedInAt={row.checked_in_at}
                checkedOutAt={row.checked_out_at}
                reservationPath={`${parkPath}/reservations/${row.reservation_id}`}
              />
            )
          }
        },
      },
      {
        name: 'reservation_details',
        display: 'Reservation Details',
        nosort: true,
        hideHeader: true,
        cellFormatter: (_, row) => {
          return (
            <Btn.Icon
              light
              name="chevron_right"
              label="View Reservation Details"
              href={`${parkPath}/reservations/${row.reservation_id}`}
            />
          )
        },
      },
    ],
    [canEdit, activeTab, parkPath]
  )

  const handleChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    // Set trimmed value back
    const query = searchQuery.trim()
    setSearchQuery(query)
    searchDispatch({ type: 'query', value: query })
  }

  const handleClearSearch = () => {
    const query = ''
    setSearchQuery(query)
    searchDispatch({ type: 'query', value: query, page: 1 })
  }

  return (
    <Card shadow={0} className="block--full">
      <Grid noSpacing className="search-form">
        <Cell col={12}>
          <h1 className="text--med-lg">Park Occupancy Bookings</h1>
        </Cell>
        <Cell col={6} tablet={12} className="search-form__textfield-container">
          <Textfield
            label={'Search by occupant, reservation #, phone #, email'}
            className="search-form__textfield"
            value={searchQuery}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </Cell>

        <Cell col={6} tablet={12} className="search-form__buttons-container">
          <div className="search-form__buttons">
            <Button ripple className="search-form__button mdl-button--gray" onClick={handleSearch}>
              Search
            </Button>

            {searchQuery.length !== 0 ? (
              <Button className="search-form__button mdl-button--light" onClick={handleClearSearch}>
                Clear Search
              </Button>
            ) : null}
          </div>
        </Cell>
      </Grid>

      <Tabs
        className="mdl-layout__tab-bar mdl-layout__tab-bar--accent"
        activeTab={activeTabIdx}
        ripple>
        {tabs.map(({ display, tabName }) => {
          const count = data.counts && data.counts[tabName]
          return (
            <Tab
              key={tabName}
              className="mdl-layout__tab"
              href={updatedSearchUrl({ query: searchQuery, tab: tabName, page: 1 })}>
              {display} {count && `(${count})`}
            </Tab>
          )
        })}
      </Tabs>
      <div>
        <DataTable
          topic={activeTab.display}
          rows={data.results}
          columns={columns}
          rowKeyColumn="id"
          onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })}
          initialSortBy={searchState.order}
          initialIsSortAcending={searchState.asc === 1}
        />
        {data.meta && (
          <Paginate
            {...data.meta}
            onClick={(num) => searchDispatch({ type: 'page', value: num })}
          />
        )}
      </div>
    </Card>
  )
}

export default SearchBookings
