import classNames from 'classnames'
import { Icon, Button } from 'react-mdl'
import { draggableSitePin } from '@components/camp/parks/MapView/mapIcons'
import {
  MapViewContext,
  MAP_MODES,
  DROP_TYPES,
} from '@components/camp/parks/MapView/mapViewContext'
import { onKeyActivate } from '@utils'
import { NpmapIcon } from '@components/shared'
const { Fragment, useState, useContext, useRef } = React
const { observer } = mobxReactLite

const SideNav = observer(() => {
  const store = useContext(MapViewContext)

  return (
    <div className="d-flex flex__flow--column pb-20" style={{ height: '100%' }}>
      <nav className="mdl-navigation" aria-label="local">
        <a href={store.backPath} className="mdl-navigation__link subnav--back subnav--active">
          <i className="material-icons js-toggle-order-icon">keyboard_backspace</i>Exit Map
        </a>
      </nav>
      <div className="page__heading flex__items--center px-20 pb-20">
        <div className="page__heading-text">
          <p className="page__heading-title">{store.park.name}</p>
        </div>
      </div>
      <div className="subnav__divider"></div>

      {store.mode === MAP_MODES.PARK ? (
        <Fragment>
          <nav className="mdl-navigation flex__grow--1" aria-label="local">
            {store.park.large_zones.map((lz) => (
              <SectionNavItem key={lz.id} section={lz} direction="right" />
            ))}
          </nav>

          <SideNavDetailsButton
            label="Park Details"
            href={store.park.path}
            editMode={store.canEdit && store.editMode}
          />
        </Fragment>
      ) : null}

      {store.mode === MAP_MODES.SECTION ? (
        <Fragment>
          <nav className="mdl-navigation flex__grow--1" aria-label="local">
            <SectionNavItem section={store.selectedSection} direction="left" showWarning={false} />

            {store.loopsInSection.map((loop) => (
              <LoopNavItem key={loop.id} loop={loop} direction="right" />
            ))}
          </nav>

          <SideNavDetailsButton
            label="Section Details"
            href={`${store.park.path}/sections/${store.selectedSection.id}`}
            editMode={store.canEdit && store.editMode}
          />
        </Fragment>
      ) : null}

      {store.mode === MAP_MODES.LOOP ? (
        <Fragment>
          <nav className="mdl-navigation flex__grow--1" aria-label="local">
            <LoopNavItem loop={store.selectedLoop} direction="left" showWarning={false} />

            {store.sitesInLoop.map((site) => (
              <SiteNavItem key={site.id} site={site} />
            ))}
          </nav>

          <SideNavDetailsButton
            label="Loop Details"
            href={`${store.park.path}/loops/${store.selectedLoop.id}`}
            editMode={store.canEdit && store.editMode}
          />
        </Fragment>
      ) : null}

      {store.canEdit ? (
        <div className="px-10 py-5">
          {store.editMode ? (
            <Button onClick={store.toggleEditMode} className="mdl-button--gray block--full">
              Exit Edit Mode
            </Button>
          ) : (
            <Button onClick={store.toggleEditMode} accent className="block--full">
              Edit Map
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
})

const SectionNavItem = observer(({ section, direction, showWarning = true }) => {
  const store = useContext(MapViewContext)

  const toggleSection = () => {
    if (store.selectedSection?.id === section.id) {
      store.setSelectedSection(null)
    } else {
      store.setSelectedSection(section)
    }
  }

  return (
    <NavItem
      label={section.name}
      direction={direction}
      isActive={section.id === store.hoverMarker?.id || section.id === store.selectedSection?.id}
      onClick={toggleSection}
      onKeyDown={onKeyActivate((e) => e.target.click())}
      onFocus={() => store.setHoverMarker(section)}
      onMouseEnter={() => store.setHoverMarker(section)}
      onBlur={() => store.setHoverMarker(null)}
      onMouseLeave={() => store.setHoverMarker(null)}
      icon={
        showWarning && section.any_unplaced_sites ? (
          <SideNavTooltip content="Contains unplaced sites" />
        ) : null
      }>
      {section.name}
    </NavItem>
  )
})

const LoopNavItem = observer(({ loop, direction, showWarning = true }) => {
  const store = useContext(MapViewContext)

  const toggleSection = () => {
    if (store.selectedLoop?.id === loop.id) {
      store.setSelectedLoop(null)
    } else {
      store.setSelectedLoop(loop)
    }
  }

  return (
    <NavItem
      label={loop.name}
      direction={direction}
      isActive={loop.id === store.hoverMarker?.id || loop.id === store.selectedLoop?.id}
      onClick={toggleSection}
      onKeyDown={onKeyActivate((e) => e.target.click())}
      onFocus={() => store.setHoverMarker(loop)}
      onMouseEnter={() => store.setHoverMarker(loop)}
      onBlur={() => store.setHoverMarker(null)}
      onMouseLeave={() => store.setHoverMarker(null)}
      icon={
        showWarning && loop.any_unplaced_sites ? (
          <SideNavTooltip content="Contains unplaced sites" />
        ) : null
      }>
      {loop.name}
    </NavItem>
  )
})

const SiteNavItem = observer(({ site }) => {
  const store = useContext(MapViewContext)
  const unplaced = site.unplaced
  let icon
  if (!site.active) {
    icon = <SideNavTooltip content="This site is not active" />
  } else if (store.editMode && unplaced) {
    icon = <TemporaryDragIndicator />
  } else if (unplaced) {
    icon = <SideNavTooltip content="This site has not yet been placed on the map" />
  }

  const onDragStart = (e) => {
    if (!store.editMode) {
      return
    }

    const { el, offset } = draggableSitePin(site.icon_name)
    e.dataTransfer.setDragImage(el, offset.x, offset.y)
    e.dataTransfer.setData('text/plain', JSON.stringify({ id: site.id, type: DROP_TYPES.SITE }))
  }

  return (
    <NavItem
      label={site.name}
      isActive={site.id === store.hoverMarker?.id || site.id === store.selectedSite?.id}
      onClick={() => store.setSelectedSite(site)}
      onKeyDown={onKeyActivate((e) => e.target.click())}
      onFocus={() => store.setHoverMarker(site)}
      onMouseEnter={() => store.setHoverMarker(site)}
      onBlur={() => store.setHoverMarker(null)}
      onMouseLeave={() => store.setHoverMarker(null)}
      draggable={unplaced && store.editMode}
      onDragStart={onDragStart}
      disabled={!site.active}
      icon={icon}>
      {site.name}
      <NpmapIcon
        name={site.icon_name}
        disabled={!site.active || unplaced}
        className="npmap-icon--small u-pull--right mr-10"
      />
    </NavItem>
  )
})

const NavItem = ({ label, isActive, direction = null, icon = null, children, ...props }) => {
  // show parent items e.g. Section or Loop as active
  const activeState = direction === 'left' || isActive

  return (
    <span
      role="button"
      tabIndex="0"
      className={classNames('mdl-navigation__link expandable-link pl-10 pr-10', {
        'mdl-navigation__link--active': activeState,
        'pl-40': !icon && direction !== 'left',
        'mdl-shadow--inset-2dp': direction === 'left',
      })}
      {...props}>
      {direction === 'left' ? (
        <Icon name="keyboard_arrow_left" aria-label={`Back to ${label}`} />
      ) : null}
      {icon}
      {children}
      {direction === 'right' ? (
        <Icon name="keyboard_arrow_right" className="u-pull--right" aria-hidden />
      ) : null}
    </span>
  )
}

const SideNavDetailsButton = ({ href, label, editMode }) => {
  const fullHref = editMode ? `${href}/edit` : href
  const fullLabel = editMode ? `Edit ${label}` : `View ${label}`

  return (
    <div className="px-10 py-5">
      <Button href={fullHref} target="_BLANK" accent className="block--full">
        {fullLabel} <Icon name="open_in_new" className="md-18" />
      </Button>
    </div>
  )
}

// Had to create a totally custom tooltip component because:
// - We're in a fixed position container, which causes regular tooltips to shift
//   down the height of the main AMS nav
// - Using ReactDOM.createPortal to append to the body instead works OK,
//   but the tooltips mysteriously disappear between renders and don't work
//   when the side nav items are shown and hidden
const SideNavTooltip = ({ content }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const ref = useRef(null)

  const toggleTooltip = () => {
    setShowTooltip((cur) => !cur)
  }

  const padding = 10
  const tooltipStyle = {
    top:
      ref.current && showTooltip
        ? `${ref.current.offsetTop + ref.current.offsetHeight + padding}px`
        : '-500px',
    left: ref.current && showTooltip ? `${ref.current.offsetLeft}px` : '-500px',
    fontSize: '12px',
  }

  return (
    <div className="d-inline-block" ref={ref}>
      <div
        className={`mdl-tooltip mdl-tooltip--bottom ${showTooltip ? 'is-active' : ''}`}
        style={tooltipStyle}
        aria-hidden>
        {content}
      </div>
      <Icon
        name="warning"
        className="text--darkyellow mr-5"
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
      />
    </div>
  )
}

// This `drag_indicator` icon appears to be missing from our
// material-icons font. So for now here's the inline svg
const TemporaryDragIndicator = () => {
  return (
    <span className="d-inline-block mr-5" aria-hidden>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          fill="#4e92df"
          d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        />
      </svg>
    </span>
  )
}

export default SideNav
