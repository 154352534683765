const PageHeading = ({ title, children }) => {
  return (
    <div className="page__heading flex__items--center flex__justify--space-between u-padding--bottom-10">
      <div className="page__heading-text">
        <h1 className="page__heading-title">
          <React.Fragment>{title}</React.Fragment>
        </h1>
      </div>

      <div className="page__heading-actions">{children}</div>
    </div>
  )
}

export default PageHeading
