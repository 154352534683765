export const positiveFractionRegex = /^(0|[1-9]\d*)?(\.\d*)?$/

export const sanitizedStringRegex = (value) => {
  const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  if (escapedValue === '') {
    return
  }

  return new RegExp('\\b' + escapedValue, 'i')
}
