import { Grid, Cell, Button } from 'react-mdl'
import ChargeSplitField from './ChargeSplitField'
import { useChargeSplit, DOLLAR, PERCENT } from './useChargeSplit'
import { NumericTextfield, RadioCollection } from '@components/shared'
import { formatCurrency, generateUuid, getNumeric, getPercent, onKeyActivate } from '@utils'

const { useState, useEffect, createRef, useRef } = React

const amountTypeOptions = [
  { name: 'Dollar', value: DOLLAR },
  { name: 'Percentage', value: PERCENT },
]

const getInitialAmount = (charge, split) =>
  charge.split_by_pct
    ? formatCurrency((getNumeric(split.percent) / 100) * getNumeric(charge.amount))
    : formatCurrency(split.amount)

const getInitialPercent = (charge, split) =>
  charge.split_by_pct ? getNumeric(split.percent) : getPercent(split.amount, charge.amount)

const ChargeSplitFieldset = ({
  name,
  label,
  charge: initialCharge,
  chargeName,
  chargeCanBePercent = false,
  accountFunds,
  className = '',
  errors = {},
}) => {
  const addingNewSplit = useRef(null)
  const charge = initialCharge || {}
  const chargeSplits = charge[`${chargeName}_splits`]
  // Incoming `charge` object can be Charge OR Surcharge
  // Surcharges can be either a percentage or dollar amount
  const [chargeIsPercent, setChargeIsPercent] = useState(
    chargeCanBePercent && (charge.charge_pct === true || charge.charge_pct === PERCENT)
  )

  const initialSplits = chargeSplits.map((split) => ({
    id: split.id,
    amount: getInitialAmount(charge, split),
    percent: getInitialPercent(charge, split),
    accountFundId: split.account_fund_id,
    key: generateUuid(),
    inputRef: createRef(),
  }))

  const [state, dispatch] = useChargeSplit({
    chargeAmount: formatCurrency(chargeIsPercent ? charge.percent : charge.amount),
    splits: initialSplits,
    selectedSplitMethod: charge.split_by_pct ? PERCENT : DOLLAR,
  })

  const { chargeAmount, splits, selectedSplitMethod } = state
  const {
    setChargeAmount,
    setSplitAmount,
    setSplitMethod,
    setAccountFundId,
    addSplit,
    removeSplit,
  } = dispatch

  // If the parent charge is a percentage, Split Accounts must also be split by percentages
  if (chargeIsPercent && selectedSplitMethod !== PERCENT) {
    setSplitMethod(PERCENT)
  }

  const handleChargeAmountChange = (e) => {
    setChargeIsPercent(e.target.value === PERCENT)
  }

  const handleChargeFormat = (value) => {
    setChargeAmount(value)
  }

  const handleSplitMethodChange = (event) => {
    setSplitMethod(event.target.value)
  }

  const handleAccountChange = (index) => (suggestion) => {
    setAccountFundId(index, suggestion.id)
  }

  const handleSplitFormat = (index) => (value) => {
    setSplitAmount(index, value)
  }

  const handleRemoveSplit = (index) => () => {
    removeSplit(index)
  }

  const handleClick = () => {
    addSplit()
    addingNewSplit.current = true
  }

  // Focus on the new account split after one has been added
  useEffect(() => {
    const lastSplit = splits[splits.length - 1]

    if (addingNewSplit.current && lastSplit && !lastSplit.id) {
      lastSplit.inputRef.current.focus()
      addingNewSplit.current = false
    }
  }, [splits.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const amountInputName = `${name}[${chargeIsPercent ? 'percent' : 'amount'}]`

  return (
    <div className={className}>
      <Grid className="u-padding--x-20">
        {chargeCanBePercent ? (
          <Cell col={12} className="u-margin--bottom-10">
            <RadioCollection
              label="Amount Type"
              name={`${name}[charge_pct]`}
              options={amountTypeOptions}
              value={chargeIsPercent ? PERCENT : DOLLAR}
              onChange={handleChargeAmountChange}
            />
          </Cell>
        ) : (
          undefined
        )}

        <Cell col={2}>
          <NumericTextfield
            label={label}
            id={amountInputName}
            name={amountInputName}
            floatingLabel
            value={chargeAmount}
            maxValue={chargeIsPercent ? 100 : undefined}
            onFormat={handleChargeFormat}
            format={chargeIsPercent ? 'percent' : 'currency'}
            error={charge.errors?.[`${chargeIsPercent ? 'percent' : 'amount'}`]}
          />
        </Cell>

        {/* Clear the opposing field value */}
        <input type="hidden" name={`${name}[${chargeIsPercent ? 'amount' : 'percent'}]`} value="" />

        <Cell col={12}>
          <h3 className="text--med u-margin--top-none u-margin--bottom-10">
            Split into Accounts for {label}
          </h3>
          <input type="hidden" name={`${name}[split_by_pct]`} value={selectedSplitMethod} />
          <RadioCollection
            label="Split Accounts by"
            name={`${name}_split_by_pct`}
            options={amountTypeOptions}
            value={selectedSplitMethod}
            onChange={handleSplitMethodChange}
            disabled={chargeIsPercent}
          />
        </Cell>
      </Grid>

      <div className="bg--well u-padding--x-20 u-padding--y-30">
        {splits.map((split, index) => (
          <ChargeSplitField
            key={split.key}
            split={split}
            name={`${name}[${chargeName}_splits_attributes][${index}]`}
            parentInputName={amountInputName}
            accountFunds={accountFunds}
            chargeAmount={chargeAmount}
            onAccountChange={handleAccountChange(index)}
            onSplitFormat={handleSplitFormat(index)}
            onRemove={handleRemoveSplit(index)}
            showRemove={!split.id && index !== 0}
            chargeIsPercent={chargeIsPercent}
            splitByPercent={selectedSplitMethod === PERCENT}
            errors={errors?.[`${chargeName}_splits`]?.[index]}
            inputRef={split.inputRef}
          />
        ))}

        <div className="u-padding--10">
          <Button
            accent
            type="button"
            onClick={handleClick}
            onKeyDown={onKeyActivate(handleClick)}
            className="mdl-button--wide">
            Add Another Account
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChargeSplitFieldset
