import { Button } from 'react-mdl'
import { Modal } from '@components/shared'

const RemoveItemModal = ({ cancelRemoval, removeItem, item }) => {
  const identifier = item.type === 'ConsumableItem' ? item.uuid : item.booking_id
  return (
    <Modal
      open={true}
      id="remove_item"
      allowEscape={true}
      onClose={cancelRemoval}
      onCancel={cancelRemoval}>
      <Modal.Title id="remove_item">Remove Item from Cart</Modal.Title>
      <Modal.Content>
        <p className="text--med">
          Are you sure you want to <strong className="text--brightred">remove this Item</strong>{' '}
          from the cart?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => removeItem(identifier, item.type)} className="mdl-button--gray">
          Yes Remove this Item
        </Button>
        <Button onClick={cancelRemoval} className="mdl-button--light">
          Cancel this Action
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RemoveItemModal
