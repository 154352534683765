import { Card, Grid, Cell, Button } from 'react-mdl'
import useFetch from 'use-http'
import { Btn, Modal } from '@components/shared'
import { formatCurrency } from '@utils'
const { useState, useCallback, useMemo } = React
const { MdlSelect } = window

const Cart = ({
  endpoint,
  cartItemTypes,
  consumables,
  reservation,
  initialCart,
  bookingPath,
  reviewPath,
}) => {
  const [cart, setCart] = useState(initialCart)
  const [newItem, setNewItem] = useState(null)
  const { post, loading, response } = useFetch(endpoint, {
    headers: window.__AMS__.formToken,
    cachePolicy: 'no-cache',
  })

  const openAddModal = (product) => () => {
    setNewItem(product)
  }

  const onCancel = (product) => {
    setNewItem(null)
  }

  const handleResponse = useCallback(
    (res) => {
      if (response.ok) {
        setCart(res)
        return true
      } else {
        console.error(response)
        return false
      }
    },
    [response]
  )

  const onSubmitAdd = useCallback(
    async (item) => {
      const res = await post('/add_to_cart', {
        type: cartItemTypes.ConsumableItem,
        name: item.display_name,
        quantity: item.quantity,
        consumable_product_id: item.id,
        product_variation_id: item.product_variations[0].id,
        booking_id: item.booking_id,
        subtotal: item.price,
        format: 'json',
      })

      if (handleResponse(res)) setNewItem(null)
    },
    [post, handleResponse, cartItemTypes.ConsumableItem]
  )

  const onSubmitRemove = useCallback(
    (uuid) => async () => {
      const res = await post('/remove_from_cart', { uuid, format: 'json' })
      handleResponse(res)
    },
    [post, handleResponse]
  )

  const onSubmitUpdate = useCallback(
    async (params) => {
      const res = await post('/update_item', { ...params, format: 'json' })
      handleResponse(res)
    },
    [post, handleResponse]
  )

  return (
    <Grid>
      <Cell col={8}>
        <Card shadow={0} className="block--full">
          <Grid className="block--full p-20">
            <Cell col={12}>
              <h2 className="mdl-card__title-text mb-20">Consumable Add-Ons</h2>
            </Cell>
            <Cell col={12}>
              <Consumables consumables={consumables} openAddModal={openAddModal} />
            </Cell>
          </Grid>
        </Card>
      </Cell>
      <Cell col={4}>
        <CartSummary
          cart={cart}
          cartItemTypes={cartItemTypes}
          onSubmitRemove={onSubmitRemove}
          onSubmitUpdate={onSubmitUpdate}
          loading={loading}
          bookingPath={bookingPath}
          reviewPath={reviewPath}
        />
      </Cell>

      {newItem ? (
        <AddToCartModal
          newItem={newItem}
          reservation={reservation}
          onCancel={onCancel}
          onSubmitAdd={onSubmitAdd}
          loading={loading}
        />
      ) : null}
    </Grid>
  )
}

const Consumables = ({ consumables, openAddModal }) => {
  const formattedConsumables = useMemo(
    () =>
      consumables.map((c) => {
        let price = c.product_variations?.[0]?.customer_price
        if (price) {
          price = formatCurrency(price)
        }
        return {
          ...c,
          price,
        }
      }),
    [consumables]
  )

  return (
    <div className="d-flex flex__wrap--wrap">
      {formattedConsumables.map((c) => {
        return (
          <div
            key={c.id}
            className="d-flex"
            style={{ width: '50%', flex: '0 50%', minHeight: '120px' }}>
            <button
              className="m-5 p-0 d-flex block--full bg--white u-border--round mdl-shadow--2dp u-border--lightgray pointer"
              type="button"
              onClick={openAddModal(c)}>
              <div style={{ width: '35%', height: '100%', overflow: 'hidden' }}>
                <img src={c.image} alt={c.display_name} aria-hidden width="100%" />
              </div>
              <div className="px-20 pt-20 u--align-left" style={{ width: '65%' }}>
                <h5 className="m-0 text--med">{c.print_name_temp}</h5>
                <p className="text--small u-overflow-ellipsis">{c.name}</p>

                <p className="m-0">${c.price}</p>
              </div>
            </button>
          </div>
        )
      })}
    </div>
  )
}

const CartSummary = ({
  cart,
  onSubmitRemove,
  onSubmitUpdate,
  loading,
  bookingPath,
  reviewPath,
  cartItemTypes,
}) => {
  const maxQuantity = 10
  const quantityOptions = Array.from(new Array(maxQuantity), (_, i) => i + 1).map((i) => ({
    id: i,
    name: i,
  }))

  const updateQuantity = useCallback(
    (item) => (option) => {
      onSubmitUpdate({ ...item, quantity: option.id })
    },
    [onSubmitUpdate]
  )

  const bookings = cart.cart_items.filter((i) => i.type === cartItemTypes.BookingItem)
  const consumables = cart.cart_items.filter((i) => i.type === cartItemTypes.ConsumableItem)

  return (
    <Card shadow={0} className="block--full">
      <h2 className="mt-20 mb-0 text--lg text--center">Reservation Summary</h2>

      <h3 className="my-20 px-30 text--med-small bg--lightgray">Bookings ({bookings.length})</h3>

      {bookings.map((booking, idx) => (
        <Grid noSpacing key={booking.uuid} className="px-30 block--full">
          <Cell col={9}>
            <h4 className="m-0 text--med">{booking.name}</h4>
            <p>{booking.details}</p>
          </Cell>
          <Cell col={3} className="u--align-right pt-10">
            ${formatCurrency(booking.subtotal)}
          </Cell>
        </Grid>
      ))}

      <h3 className="my-20 px-30 text--med-small bg--lightgray">Add-Ons ({consumables.length})</h3>

      {consumables.map((item) => (
        <Grid noSpacing key={item.uuid} className="px-30 block--full d-flex flex__items--center">
          <Cell col={7}>
            <h4 className="m-0 text--med">{item.name}</h4>

            <Button
              className="mdl-button--plain-link text--brightred"
              onClick={onSubmitRemove(item.uuid)}
              disabled={loading}>
              Remove From Cart
            </Button>
          </Cell>
          <Cell col={2}>
            <MdlSelect
              name={`${item.uuid}_quantity`}
              options={quantityOptions}
              selected={{ id: item.quantity }}
              onOptionSelected={updateQuantity(item)}
            />
          </Cell>
          <Cell col={3} className="u--align-right">
            ${formatCurrency(item.subtotal_with_quantity)}
          </Cell>
        </Grid>
      ))}

      <Grid
        noSpacing
        className="my-20 px-30 block--full bg--lighterblue d-flex flex__items--center">
        <Cell col={8}>
          <h3 className="m-0 text--med-small">Reservation Total</h3>
        </Cell>
        <Cell col={4} className="u--align-right">
          ${formatCurrency(cart.subtotal)}
        </Cell>
      </Grid>

      <Grid className="mb-10 px-30 block--full">
        <Cell col={12}>
          <Button href={bookingPath} className="mdl-button--light block--full">
            Back to Booking
          </Button>
        </Cell>
        <Cell col={12}>
          <Button href={reviewPath} className="mdl-button--gray block--full">
            Continue
          </Button>
        </Cell>
      </Grid>
    </Card>
  )
}

const AddToCartModal = ({ newItem, reservation, onCancel, onSubmitAdd, loading }) => {
  const [currentBooking, setCurrentBooking] = useState(null)
  const [currentQuantity, setCurrentQuantity] = useState(1)

  const addToCart = () => {
    onSubmitAdd({ ...newItem, quantity: currentQuantity, booking_id: currentBooking.id })
  }

  const bookingOptions = useMemo(() => {
    return reservation.bookings.map((b) => ({
      id: b.id,
      name: `${b.zone.name}, ${b.primary_occupant.first_name} ${b.primary_occupant.last_name}`,
    }))
  }, [reservation])

  return (
    <Modal open={true} id="add_to_cart" onClose={onCancel} onCancel={onCancel}>
      <Grid className="u-border--bottom mb-20">
        <Cell col={4}>
          <div style={{ width: '120px', height: '120px' }}>
            <img src={newItem.image} alt={newItem.display_name} aria-hidden width="100%" />
          </div>
          <h5 className="m-0">
            ${newItem.price} (<i>each</i>)
          </h5>
        </Cell>
        <Cell col={8}>
          <h4 id="add_to_cart" className="m-0">
            {newItem.display_name}
          </h4>
          <p>{newItem.name}</p>
        </Cell>
      </Grid>

      <Grid className="d-flex flex__items--center">
        <Cell col={4}>
          <Button
            className="mdl-button--gray"
            style={{ minWidth: '25px' }}
            onClick={() => setCurrentQuantity((curr) => curr - 1)}>
            -
          </Button>
          <span className="p-10 mr-5 my-5 u-border--lightgray u-border--round">
            {currentQuantity}
          </span>
          <Button
            className="mdl-button--gray"
            style={{ minWidth: '25px' }}
            onClick={() => setCurrentQuantity((curr) => curr + 1)}>
            +
          </Button>
        </Cell>
        <Cell col={8} className="always-floating-label">
          <MdlSelect
            label="Assign Item to Booking"
            name="booking_id"
            includeBlankOption="Select Booking"
            selected={currentBooking}
            options={bookingOptions}
            onOptionSelected={(option) => setCurrentBooking(option)}
          />
        </Cell>
      </Grid>
      <Modal.Actions>
        <Btn.Spinner
          loading={loading}
          disabled={!currentBooking}
          className="mdl-button--gray"
          onClick={addToCart}>
          Add to Cart
        </Btn.Spinner>
        <Button className="mdl-button--light" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default Cart
