import { useState } from 'react'
import WaiverFields from './WaiverFields'

const Waivers = ({ program, enforcementOptions }) => {
  const [enforcement, setEnforcement] = useState(program.waiver_settings.waiver_enforcement)
  const showWaiverFields = enforcement === 'enforce'
  const waiverSettingInputName = (name) => `program[waiver_settings_attributes][${name}]`

  return (
    <div className="mdl-grid block--full p-0">
      <input
        type="hidden"
        name={waiverSettingInputName('id')}
        value={program.waiver_settings.id || ''}
      />
      <dl>
        <div className="mdl-grid">
          <div className="mdl-cell mdl-cell--3-col">
            <dt className="text--med text--normal">
              {I18n.t('events.views.program_waiver_settings.require_waiver')}
            </dt>
          </div>
          <dd className="mdl-cell mdl-cell--9-col">
            <p>{I18n.t('events.views.program_waiver_settings.configuration_info')}</p>
            <MdlSelect
              label={I18n.t(
                'activerecord.attributes.events/program_waiver_setting.waiver_enforcement'
              )}
              name={waiverSettingInputName('waiver_enforcement')}
              options={enforcementOptions}
              selected={enforcement}
              onOptionSelected={(option) => {
                setEnforcement(option.id)
              }}
            />
          </dd>
        </div>
        {showWaiverFields ? <WaiverFields program={program} /> : null}
      </dl>
    </div>
  )
}

export default Waivers
