import { Checkbox } from 'react-mdl'
import { onKeyActivate } from '@utils'

const { MdlText, MdlValueSelect } = window

class ActivityTypes extends React.Component {
  constructor(props) {
    super(props)

    this.removeActivityType = this.removeActivityType.bind(this)
    this.handleUpdateName = this.handleUpdateName.bind(this)
    this.handleUpdateDataType = this.handleUpdateDataType.bind(this)
    this.handleUpdateGrant = this.handleUpdateGrant.bind(this)
    this.handleUpdateAwardEligible = this.handleUpdateAwardEligible.bind(this)
    this.handleUpdateDetailedTimeLog = this.handleUpdateDetailedTimeLog.bind(this)
  }

  removeActivityType(e) {
    e.preventDefault()
    this.props.removeRecord(this.props.index)
  }

  handleUpdateName(e) {
    let aType = this.props.record
    aType.name = e.target.value
    this.props.onChange(this.props.index, aType)
  }

  handleUpdateDataType(data_type) {
    let aType = this.props.record
    aType.data_type = data_type
    if (data_type === 'Mileage') {
      aType.collect_detailed_time_log = false
    }
    this.props.onChange(this.props.index, aType)
  }

  handleUpdateGrant(grant_id) {
    let aType = this.props.record
    aType.grant_id = grant_id
    this.props.onChange(this.props.index, aType)
  }

  handleUpdateAwardEligible(award_eligible) {
    let aType = this.props.record
    aType.award_eligible = award_eligible.target.checked
    this.props.onChange(this.props.index, aType)
  }

  handleUpdateDetailedTimeLog(event) {
    let aType = this.props.record
    aType.collect_detailed_time_log = event.target.checked
    this.props.onChange(this.props.index, aType)
  }

  get inputName() {
    return `program[activity_types_attributes][${this.props.index}]`
  }

  get nameError() {
    if (this.props.record.errors) {
      return this.props.record.errors.name
    }
  }

  get dataTypeError() {
    if (this.props.record.errors) {
      return this.props.record.errors.data_type
    }
  }

  get removeButton() {
    if (!this.props.record.id && this.props.recordCount > 1) {
      return (
        <div>
          <button
            type="button"
            className="mdl-chip__action vm-dashboard-icon"
            onClick={this.removeActivityType}>
            <i className="material-icons">delete</i>
          </button>
        </div>
      )
    } else {
      return null
    }
  }

  get enableDetailedTimeLog() {
    return this.props.record.data_type === 'Hours'
  }

  get collectDetailedTimeLog() {
    return this.enableDetailedTimeLog ? this.props.record.collect_detailed_time_log : false
  }

  render() {
    if (this.props.record.active || !this.props.record.id) {
      return (
        <div className="mdl-grid block--full">
          <input type="hidden" name={`${this.inputName}[id]`} value={this.props.record.id} />
          <input type="hidden" name={`${this.inputName}[active]`} value={true} />
          <div className="mdl-cell mdl-cell--3-col">
            <MdlText
              name={`${this.inputName}[name]`}
              label="Name"
              value={this.props.record.name}
              error={this.nameError}
              handleChange={this.handleUpdateName}
            />
          </div>
          <div className="mdl-cell mdl-cell--1-col">
            <MdlValueSelect
              selected={this.props.record.data_type}
              options={this.props.collections.data_types}
              onOptionSelected={this.handleUpdateDataType}
              label="Data Type"
              name={`${this.inputName}[data_type]`}
              error={this.dataTypeError}
            />
          </div>
          <div className="mdl-cell mdl-cell--3-col">
            <MdlValueSelect
              selected={this.props.record.grant_id}
              options={this.props.collections.grants}
              onOptionSelected={this.handleUpdateGrant}
              label="Grant"
              name={`${this.inputName}[grant_id]`}
              includeBlankOption={true}
            />
          </div>
          <div className="mdl-cell mdl-cell--2-col">
            <Checkbox
              className="mt-20"
              label="Award Eligible"
              checked={this.props.record.award_eligible}
              onChange={this.handleUpdateAwardEligible}
              onKeyDown={onKeyActivate('click')}
            />
            <input
              type="hidden"
              name={`${this.inputName}[award_eligible]`}
              value={this.props.record.award_eligible || '0'}
            />
          </div>
          <div className="mdl-cell mdl-cell--3-col">
            <Checkbox
              className="mt-20"
              label="Collect Detailed Time Log"
              checked={this.collectDetailedTimeLog}
              onChange={this.handleUpdateDetailedTimeLog}
              disabled={!this.enableDetailedTimeLog}
              onKeyDown={onKeyActivate('click')}
            />
            <input
              type="hidden"
              name={`${this.inputName}[collect_detailed_time_log]`}
              value={this.collectDetailedTimeLog || '0'}
            />
          </div>

          <div className="mdl-cell mdl-cell--1-col">{this.removeButton}</div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ActivityTypes
