import { Modal } from '@components/shared'
import { Button } from 'react-mdl'
const { useState } = React

const BookingCheckIn = ({
  id,
  primaryOccupant,
  startAt,
  checkedInAt,
  checkedOutAt,
  reservationPath,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false)
  const [modalOpenError, setModalOpenError] = useState(false)
  const catalogUrl = `${reservationPath}/bookings/${id}/update_check_in`
  const startDate = new Date(startAt)
  const today = new Date()

  let startDateIsToday = false
  if (
    startDate.getDate() === today.getDate() &&
    startDate.getMonth() === today.getMonth() &&
    startDate.getFullYear() === today.getFullYear()
  ) {
    startDateIsToday = true
  }

  const checkInStatus = () => {
    if (checkedOutAt) return 'Empty'
    if (checkedInAt) return 'Check-Out'
    if (startDateIsToday) return 'Check-In'
    return 'Empty'
  }

  const cancel = () => {
    setModalOpen(false)
  }

  const cancelConfirm = () => {
    setModalOpenConfirm(false)
    window.location.reload()
  }

  const cancelError = () => {
    setModalOpenError(false)
    window.location.reload()
  }

  const checkInButton = () => {
    if (checkInStatus() === 'Empty') return
    const buttonText = checkInStatus()

    return (
      <Button
        onClick={() => setModalOpen(true)}
        type="button"
        className="mdl-button--wide mdl-button--wide-centered mdl-button--gray u-pull--right pt-4">
        {buttonText}
      </Button>
    )
  }

  const checkInModalDirector = (primaryOccupant) => {
    if (checkInStatus() === 'Check-In') {
      return checkInModal(primaryOccupant)
    }
    if (checkInStatus() === 'Check-Out') {
      return checkOutModal(primaryOccupant)
    }
  }

  const checkInModal = (primaryOccupant) => {
    return (
      <Modal open={modalOpen} id="action_item" onClose={cancel} onCancel={cancel}>
        <Modal.Title id="action_item">
          Check-In{' '}
          <span className="text--bold">
            {primaryOccupant.first_name} {primaryOccupant.last_name}
          </span>
        </Modal.Title>
        <Modal.Content className="u-padding--bottom-50 text--med">
          You're about to{' '}
          <span className="text--bold">
            Check-In {primaryOccupant.first_name} {primaryOccupant.last_name}
          </span>
          . Do you wish to Continue?
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={checkInAction}>
            Yes Check-In Guest
          </Button>
          <Button className="mdl-button--light" onClick={cancel}>
            Cancel this Action
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const checkOutModal = (primaryOccupant) => {
    return (
      <Modal open={modalOpen} id="action_item" onClose={cancel} onCancel={cancel}>
        <Modal.Title id="action_item">
          Check-Out{' '}
          <span className="text--bold">
            {primaryOccupant.first_name} {primaryOccupant.last_name}
          </span>
        </Modal.Title>
        <Modal.Content className="u-padding--bottom-50 text--med">
          You're about to{' '}
          <span className="text--lightred text--bold">
            Check-Out {primaryOccupant.first_name} {primaryOccupant.last_name}
          </span>
          . Do you wish to Continue?
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={checkOutAction}>
            Yes Check-Out Guest
          </Button>
          <Button className="mdl-button--light" onClick={cancel}>
            Cancel this Action
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const confirmModal = (primaryOccupant) => {
    if (checkInStatus() === 'Check-In') {
      return confirmCheckInModal(primaryOccupant)
    }
    if (checkInStatus() === 'Check-Out') {
      return confirmCheckOutModal(primaryOccupant)
    }
  }

  const confirmCheckInModal = (primaryOccupant) => {
    return (
      <Modal
        open={modalOpenConfirm}
        id="confirm_item"
        onClose={cancelConfirm}
        onCancel={cancelConfirm}>
        <Modal.Title id="confirm_item">
          <span className="text--darkgreen">
            <span className="text--bold">
              {primaryOccupant.first_name} {primaryOccupant.last_name}{' '}
            </span>
            is Checked-In!
          </span>
        </Modal.Title>
        <Modal.Content className="text--med">
          {primaryOccupant.first_name} {primaryOccupant.last_name} is now{' '}
          <span className="text--darkgreen">Checked-In</span>. A Confirmation email was sent to{' '}
          <span className="text--bold">{primaryOccupant.email}</span>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={cancelConfirm}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const confirmCheckOutModal = (primaryOccupant) => {
    return (
      <Modal
        open={modalOpenConfirm}
        id="confirm_item"
        onClose={cancelConfirm}
        onCancel={cancelConfirm}>
        <Modal.Title id="confirm_item">
          <span className="text--bold">
            {primaryOccupant.first_name} {primaryOccupant.last_name}
          </span>
          <span> is Checked-Out</span>
        </Modal.Title>
        <Modal.Content className="text--med">
          {primaryOccupant.first_name} {primaryOccupant.last_name} is now
          <span className="text--bold"> Checked-Out</span>. A Confirmation email was sent to
          <span className="text--bold"> {primaryOccupant.email}</span>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--gray" onClick={cancelConfirm}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const confirmError = () => {
    const actionText = checkInStatus()

    return (
      <Modal open={modalOpenError} id="error_item" onClose={cancelError} onCancel={cancelError}>
        <Modal.Title id="error_item" className="text--brightred">
          ERROR
        </Modal.Title>
        <Modal.Content className="text--brightred text--med">
          There was a problem with <span className="text--bold">{actionText}</span>
        </Modal.Content>
        <Modal.Actions>
          <Button className="mdl-button--danger" onClick={cancelError}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const checkInAction = () => {
    cancel()
    const today = new Date()

    Fetchable.put(catalogUrl, {
      camp_booking: {
        checked_in_at: today,
      },
    }).then((res) => {
      if (res.ok) {
        setModalOpenConfirm(true)
      } else {
        setModalOpenError(true)
      }
    })
  }

  const checkOutAction = () => {
    cancel()
    const today = new Date()

    Fetchable.put(catalogUrl, {
      camp_booking: {
        checked_out_at: today,
      },
    }).then((res) => {
      if (res.ok) {
        setModalOpenConfirm(true)
      } else {
        setModalOpenError(true)
      }
    })
  }

  return (
    <React.Fragment>
      {checkInButton()}
      {checkInModalDirector(primaryOccupant)}
      {confirmModal(primaryOccupant)}
      {confirmError()}
    </React.Fragment>
  )
}

export default BookingCheckIn
