const { useEffect, useState } = React

/**
 * Hook to get auth token for forms
 */
export const useCsrfToken = () => {
  const [csrfToken, setCsrfToken] = useState('')

  useEffect(() => {
    const token = document.querySelector('meta[name="csrf-token"]').content
    setCsrfToken(token)
  }, [])

  return csrfToken
}
