import { Button } from 'react-mdl'
import { Modal } from '@components/shared'

const NavigateToEditModal = ({ cancelEdit, viewName, path }) => {
  const navigateToEdit = () => {
    window.location = path
  }
  return (
    <Modal
      open={true}
      id="navigate_to_edit"
      allowEscape={true}
      onClose={cancelEdit}
      onCancel={cancelEdit}>
      <Modal.Title id="navigate_to_edit">Edit {viewName}</Modal.Title>
      <Modal.Content>
        <p className="text--med">Are you sure you want to go back to the {viewName} page?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={navigateToEdit} className="mdl-button--gray">
          Yes Go Back to {viewName}
        </Button>
        <Button onClick={cancelEdit} className="mdl-button--light">
          Cancel this Action
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default NavigateToEditModal
